import './session-timeout.style.scss';
import UTEButton from 'src/components/ute-button';
import { Modal, Col, Row } from 'react-bootstrap';
import Countdown from 'react-countdown';

type SessionTimeoutProps = {
	showModal: boolean;
	onStayAlive: () => void;
	countDownMinutes: number;
	logout: () => void;
};

const SessionTimeout = ({ showModal, onStayAlive, countDownMinutes, logout }: SessionTimeoutProps) => {
	const getCountDown = (): Date => {
		const now = new Date();
		let ret = now;
		ret.setMinutes(now.getMinutes() + countDownMinutes);
		return ret;
	};

	return (
		<Modal
			className='session-timeout-modal'
			show={showModal}
			onHide={() => {
				logout();
			}}>
			<Modal.Header
				closeButton
				data-test-auto='60334535-F01C-41D3-AC34-CA9BC7B804DD'>
				<Modal.Title>Log Out</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row data-test-auto='F12E9E3A-5B0D-4463-9329-750FAA9B7D12'>
					<Col lg={12}>
						<p>
							Your session will expire in{' '}
							<Countdown
								date={getCountDown()}
								onComplete={() => {
									logout();
								}}
								renderer={(props) => (
									<strong>
										{props.minutes}:{props.seconds}
									</strong>
								)}
							/>
							. Once it expires, you will be automatically logged out of Client Portal.
						</p>
						<div>Do you want to continue working?</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<UTEButton
					dataTestAuto='C0280D93-94DD-438B-9AB9-4F5BC452FE66'
					onClick={() => {
						logout();
					}}
					className='btn btn-default'
					disabled={false}
					text='Logout'></UTEButton>
				<UTEButton
					dataTestAuto='CD93CDEF-A642-4D28-9C11-E6913BC2C2C0'
					onClick={() => {
						onStayAlive();
					}}
					className='btn btn-primary'
					disabled={false}
					text='Continue'></UTEButton>
			</Modal.Footer>
		</Modal>
	);
};

export default SessionTimeout;
