import React, { FC, useEffect, useState } from 'react';
import UTEModalLayout from 'src/components/ute-modal-layout';
import { IGatherDocumentModel, IOrganizerDocumentModel } from 'src/modules/documents/documents-types';
import {
	getGTRDocumentPreview,
	getGTRSourceDocumentPreview,
	getORGDocumentPreview,
	getORGSourceDocumentPreview,
} from './gtr-org-document-preview.apis';
import { FileViewer } from './FileViewer';

interface IGTRDocumentPreviewModal {
	show: boolean;
	onCancel: () => void;
	document: IGatherDocumentModel;
	organizerDocument: IOrganizerDocumentModel;
}
const GTRDocumentPreviewModal: FC<IGTRDocumentPreviewModal> = ({ show, onCancel, document, organizerDocument }) => {
	const [url, setUrl] = useState<string>('');
	useEffect(() => {
		if (document?.gatherId) {
			if (!document.documentType && document.sourceDocumentId) {
				getGTRSourceDocumentPreview(
					document,
					(data) => {
						setUrl(data.sasUrl);
					},
					() => {},
				);
			} else {
				getGTRDocumentPreview(
					document,
					(data) => {
						setUrl(data.sasUrl);
					},
					() => {},
				);
			}
		}
	}, [document]);

	useEffect(() => {
		if (organizerDocument?.documentId) {
			if (!organizerDocument.documentType && organizerDocument.sourceDocumentId) {
				getORGSourceDocumentPreview(
					organizerDocument,
					(data) => {
						setUrl(data);
					},
					() => {},
				);
			} else {
				getORGDocumentPreview(
					organizerDocument,
					(data) => {
						setUrl(data);
					},
					() => {},
				);
			}
		}
	}, [organizerDocument]);

	useEffect(() => {
		if (!show) {
			setUrl('');
		}
	}, [show]);

	const getTitle = () => document?.sourceDocumentFileName ||
	document?.efileFormName ||
	document?.fileName ||
	organizerDocument?.sourceDocumentFileName ||
	organizerDocument?.efileFormName ||
	organizerDocument?.fileName ||
	'';
	return (
		show && (
			<UTEModalLayout
				className='gtr-document-preview'
				width='1100'
				theme='light'
				title={getTitle()}
				hide={onCancel}
				onClickOutside={onCancel}>
				<FileViewer url={url} fileName={getTitle()} />
			</UTEModalLayout>
		)
	);
};

export default GTRDocumentPreviewModal;
