import React from 'react';

type SVGProps = {
	color?: string;
};
const DeleteIcon = ({ color }: SVGProps) => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M7.99951 14.9992C6.143 14.9992 4.36252 14.2617 3.04976 12.9489C1.73701 11.6362 0.999512 9.85569 0.999512 7.99918C0.999512 6.14266 1.73701 4.36218 3.04976 3.04943C4.36252 1.73667 6.143 0.999176 7.99951 0.999176C9.85603 0.999176 11.6365 1.73667 12.9493 3.04943C14.262 4.36218 14.9995 6.14266 14.9995 7.99918C14.9995 9.85569 14.262 11.6362 12.9493 12.9489C11.6365 14.2617 9.85603 14.9992 7.99951 14.9992ZM7.99951 15.9992C10.1212 15.9992 12.1561 15.1563 13.6564 13.656C15.1567 12.1557 15.9995 10.1209 15.9995 7.99918C15.9995 5.87744 15.1567 3.84261 13.6564 2.34232C12.1561 0.842031 10.1212 -0.000823975 7.99951 -0.000823975C5.87778 -0.000823975 3.84295 0.842031 2.34266 2.34232C0.842367 3.84261 -0.000488281 5.87744 -0.000488281 7.99918C-0.000488281 10.1209 0.842367 12.1557 2.34266 13.656C3.84295 15.1563 5.87778 15.9992 7.99951 15.9992Z'
				fill={color}
			/>
			<path
				d='M3.99951 7.99918C3.99951 7.86657 4.05219 7.73939 4.14596 7.64562C4.23973 7.55185 4.3669 7.49918 4.49951 7.49918H11.4995C11.6321 7.49918 11.7593 7.55185 11.8531 7.64562C11.9468 7.73939 11.9995 7.86657 11.9995 7.99918C11.9995 8.13178 11.9468 8.25896 11.8531 8.35273C11.7593 8.4465 11.6321 8.49918 11.4995 8.49918H4.49951C4.3669 8.49918 4.23973 8.4465 4.14596 8.35273C4.05219 8.25896 3.99951 8.13178 3.99951 7.99918Z'
				fill={color}
			/>
		</svg>
	);
};

export default DeleteIcon;
