import axios, { AxiosResponse } from 'axios';
import {
	ICompanyNameAndLogo,
	IUserDetail,
	emptyCompanyNameAndLogo,
} from 'src/context/ute-auth-context/auth-provider-types';
import {
	ACCESS_TOKEN_STORAGE_NAME,
	API_BASE_URL,
	DEFAULT_SESSION_TIMEOUT,
	SESSION_EXPIRED_WARNING,
} from 'src/helper/constants';
import {
	ICompanyProductsResponse,
	ICompanyProduct,
	ICompanyNameAndLogoResponse,
	RefreshTokenRequestVM,
	RefreshTokenResponseVM,
	IUserDetailResponse,
	emptyUserDetail,
	IUserExistsResponse,
	TaxpayerRedirectionSourcePage,
	InternalAccessTokenResponseVM,
	emptyInternalAccessTokenResponse,
	IUTEEnabledResponse,
	IDocumentResponse,
	IUTESubDomainResponse,
	SignatureRedirectionSourcePage,
	ProductType,
	ClientTypes,
	ClientViewAccessTokenResponseVM,
	emptyClientViewAccessTokenResponse,
	IUserRoleResponse,
	UserRole,
	IClientTracking,
	IPendoMetadata,
} from './common-types';
import { openWindowWithPostRequest } from 'src/helper/utils';
import { logger } from 'src/context/ute-auth-context/auth-provider';
import { apiCatchHandler, apiCatchHandlerNoData } from 'src/helper/utils';
import { ICaptchaResponse, initialTaxDocument, ITaxDocument } from '../documents/documents-types';
import { UTENotifier } from '../notification/ute-notifier';

export const getUserRole = (callback: (userRoleResponse: IUserRoleResponse) => void) => {
	const userRoleResponse: IUserRoleResponse = {
		role: UserRole.OneHub,
		error: false,
	};
	axios
		.get(`${API_BASE_URL}/api/helper/user-role`)
		.then((response: AxiosResponse<UserRole>) => {
			const { data } = response;
			userRoleResponse.role = data;
			callback(userRoleResponse);
		})
		.catch((error) => {
			userRoleResponse.error = true;
			apiCatchHandler(error, userRoleResponse, callback);
		});
};

export const fetchCompanyNameAndLogo = (
	companyId: number,
	callback: (companyNameAndLogoResponse: ICompanyNameAndLogoResponse) => void,
) => {
	const companyNameAndLogoResponse: ICompanyNameAndLogoResponse = {
		companyNameAndLogo: emptyCompanyNameAndLogo,
		error: false,
	};
	axios
		.get(`${API_BASE_URL}/api/company/get-company-info/${companyId}`, { withCredentials: true })
		.then((response: AxiosResponse<ICompanyNameAndLogo>) => {
			const { data } = response;
			companyNameAndLogoResponse.companyNameAndLogo = data;
			callback(companyNameAndLogoResponse);
		})
		.catch((error) => {
			companyNameAndLogoResponse.error = true;
			apiCatchHandler(error, companyNameAndLogoResponse, callback);
		});
};

export function getSessionTimeout(callback: (sessionTimeout: number) => void) {
	axios
		.get(`${API_BASE_URL}/api/helper/session-timeout`, { withCredentials: true })
		.then((response: AxiosResponse<number>) => {
			const { data } = response;
			callback(data);
		})
		.catch((error) => {
			apiCatchHandler(error, DEFAULT_SESSION_TIMEOUT, callback);
		});
}

export function markLogout(callback: () => void) {
	axios
		.post(`${API_BASE_URL}/api/account/mark-logout`, '', { withCredentials: true })
		.then(() => {
			callback();
		})
		.catch((error) => {
			apiCatchHandlerNoData(error, callback);
		});
}

export function validateRefreshToken(
	data: RefreshTokenRequestVM,
	successCallback: (data: RefreshTokenResponseVM) => void,
	errorCallback: () => void,
) {
	axios
		.post(`${API_BASE_URL}/api/account/refresh-token`, data, { withCredentials: true })
		.then((response: AxiosResponse<RefreshTokenResponseVM>) => {
			const { data } = response;
			successCallback(data);
		})
		.catch((error) => {
			apiCatchHandlerNoData(error, errorCallback);
		});
}

export const getCompanyProducts = (callback: (companyProductsResponse: ICompanyProductsResponse) => void) => {
	const companyProductsResponse: ICompanyProductsResponse = {
		products: [],
		error: false,
	};
	axios
		.get(`${API_BASE_URL}/api/dashboard/all-company-product`)
		.then((response: AxiosResponse<ICompanyProduct[]>) => {
			const { data } = response;
			companyProductsResponse.products = data;
			callback(companyProductsResponse);
		})
		.catch((error) => {
			companyProductsResponse.error = true;
			apiCatchHandler(error, companyProductsResponse, callback);
		});
};

export function getUserDetail(
	companyId: number,
	emailID: string,
	isResetPINRequest: boolean,
	callback: (userDetailResponse: IUserDetailResponse) => void,
) {
	const userDetailResponse: IUserDetailResponse = {
		userDetail: emptyUserDetail,
		error: false,
	};
	axios
		.get(`${API_BASE_URL}/api/user/${companyId}?email=${emailID}&isResetPINRequest=${isResetPINRequest}`, {
			withCredentials: true,
		})
		.then((response: AxiosResponse<IUserDetail>) => {
			const { data } = response;
			userDetailResponse.userDetail = data;
			callback(userDetailResponse);
		})
		.catch((error) => {
			userDetailResponse.error = true;
			apiCatchHandler(error, userDetailResponse, callback);
		});
}

export function checkUserExists(
	companyId: number,
	emailID: string,
	callback: (userExistsResponse: IUserExistsResponse) => void,
) {
	const userExistsResponse: IUserExistsResponse = {
		isExists: false,
		error: false,
	};
	axios
		.get(`${API_BASE_URL}/api/user/exists/${companyId}?email=${emailID}`, { withCredentials: true })
		.then((response: AxiosResponse<boolean>) => {
			const { data } = response;
			userExistsResponse.isExists = data;
			callback(userExistsResponse);
		})
		.catch((error) => {
			userExistsResponse.error = true;
			apiCatchHandler(error, userExistsResponse, callback);
		});
}

export function generateTPAPIURLForRecentActivity(
	documentId: number,
	signerType: ClientTypes,
	productType: ProductType,
	redirectionURL: TaxpayerRedirectionSourcePage = TaxpayerRedirectionSourcePage.TaxpayerFlow,
	oneHubUserEmail?: string,
) {
	const authToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_NAME);

	axios
		.get(
			`${API_BASE_URL}/api/taxpayerHelper/generate-taxpayer-redirection-url-for-document/${documentId}/${signerType}/${productType}`,
		)
		.then((response: AxiosResponse) => {
			const { data } = response;
			openWindowWithPostRequest(data.url, authToken, 'oneHubToken', redirectionURL.toString(), oneHubUserEmail);
		})
		.catch((error) => {
			logger && logger.trackWarning('Error while generateTPAPIURLForRecentActivity', { Error: error?.response?.data });
		});
}

export function generateTPClientViewAPIURLForRecentActivity(
	documentId: number,
	signerType: ClientTypes,
	productType: ProductType,
) {
	const authToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_NAME);

	axios
		.get(
			`${API_BASE_URL}/api/taxpayerHelper/generate-taxpayer-clientview-redirection-url-for-document/${documentId}/${signerType}/${productType}`,
		)
		.then((response: AxiosResponse) => {
			const { data } = response;
			openWindowWithPostRequest(data.url, authToken, 'oneHubToken', undefined, undefined, data.cpaUserId);
		})
		.catch((error) => {
			logger &&
				logger.trackWarning('Error while generateTPClientViewAPIURLForRecentActivity', {
					Error: error?.response?.data,
				});
		});
}

export function generateTaxpayerRedirectionAPIURL(
	clientId: string,
	productType: ProductType,
	redirectionURL: TaxpayerRedirectionSourcePage = TaxpayerRedirectionSourcePage.TaxpayerFlow,
	clientType: ClientTypes = ClientTypes.Taxpayer,
	oneHubUserEmail?: string,
) {
	const authToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_NAME);

	axios
		.get(`${API_BASE_URL}/api/taxpayerHelper/generate-taxpayer-redirection-api-url/${clientId}/${productType}`)
		.then((response: AxiosResponse) => {
			const { data } = response;
			openWindowWithPostRequest(
				data.url,
				authToken,
				'oneHubToken',
				redirectionURL.toString(),
				clientType.toString(),
				oneHubUserEmail,
			);
		})
		.catch((error) => {
			logger &&
				logger.trackWarning('Error while generateSSRTaxpayerRedirectionAPIURL', { Error: error?.response?.data });
		});
}

export function generateTaxpayerClientViewAPIURL(
	clientId: string,
	productType: ProductType,
	clientType: ClientTypes = ClientTypes.Taxpayer,
	redirectionURL: TaxpayerRedirectionSourcePage = TaxpayerRedirectionSourcePage.PreviewFlow,
) {
	const authToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_NAME);

	axios
		.get(
			`${API_BASE_URL}/api/taxpayerHelper/generate-taxpayer-clientview-redirection-api-url/${clientId}/${productType}`,
		)
		.then((response: AxiosResponse) => {
			const { data } = response;
			openWindowWithPostRequest(
				data.url,
				authToken,
				'oneHubToken',
				redirectionURL.toString(),
				clientType.toString(),
				undefined,
				data.cpaUserId,
			);
		})
		.catch((error) => {
			logger &&
				logger.trackWarning('Error while generateSSRTaxpayerClientViewRedirectionAPIURL', {
					Error: error?.response?.data,
				});
		});
}

export function generateExtTaxpayerRedirectionAPIURL(
	clientId: string,
	redirectionURL: TaxpayerRedirectionSourcePage = TaxpayerRedirectionSourcePage.TaxpayerFlow,
	clientType: ClientTypes = ClientTypes.Taxpayer,
	oneHubUserEmail?: string,
) {
	const authToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_NAME);

	axios
		.get(`${API_BASE_URL}/api/taxpayerHelper/generate-ext-taxpayer-redirection-api-url/${clientId}`)
		.then((response: AxiosResponse) => {
			const { data } = response;
			openWindowWithPostRequest(
				data.url,
				authToken,
				'oneHubToken',
				redirectionURL.toString(),
				clientType.toString(),
				oneHubUserEmail,
			);
		})
		.catch((error) => {
			logger &&
				logger.trackWarning('Error while generateExtTaxpayerRedirectionAPIURL', { Error: error?.response?.data });
		});
}

export function generateEXTTaxpayerClientViewAPIURL(
	clientId: string,
	productType: ProductType,
	clientType: ClientTypes = ClientTypes.Taxpayer,
	redirectionURL: TaxpayerRedirectionSourcePage = TaxpayerRedirectionSourcePage.PreviewFlow,
) {
	const authToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_NAME);

	axios
		.get(
			`${API_BASE_URL}/api/taxpayerHelper/generate-ext-taxpayer-clientview-redirection-api-url/${clientId}/${productType}`,
		)
		.then((response: AxiosResponse) => {
			const { data } = response;
			openWindowWithPostRequest(
				data.url,
				authToken,
				'oneHubToken',
				redirectionURL.toString(),
				clientType.toString(),
				undefined,
			);
		})
		.catch((error) => {
			logger &&
				logger.trackWarning('Error while generateEXTTaxpayerClientViewRedirectionAPIURL', {
					Error: error?.response?.data,
				});
		});
}

export function generateSignatureTaxpayerAPIURL(
	clientGuid: string,
	redirectionURL: SignatureRedirectionSourcePage = SignatureRedirectionSourcePage.Esign,
) {
	const authToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_NAME);

	axios
		.get(`${API_BASE_URL}/api/taxpayerHelper/generate-signature-taxpayer-api-url/${clientGuid}`)
		.then((response: AxiosResponse) => {
			const { data } = response;
			openWindowWithPostRequest(data.url, authToken, 'oneHubToken', redirectionURL.toString());
		})
		.catch((error) => {
			logger && logger.trackWarning('Error while generateSignatureTaxpayerAPIURL', { Error: error?.response?.data });
		});
}

export function generateExchangeTaxpayerAPIURL(
	documentRequestGuid: string = '',
	redirectionURL: TaxpayerRedirectionSourcePage = TaxpayerRedirectionSourcePage.TaxpayerFlow,
) {
	const authToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_NAME);
	if (!authToken) {
		UTENotifier.Warning(SESSION_EXPIRED_WARNING);
		window.location.href = '/';
	}
	axios
		.get(`${API_BASE_URL}/api/taxpayerHelper/generate-exchange-taxpayer-api-url`)
		.then((response: AxiosResponse) => {
			const { data } = response;
			openWindowWithPostRequest(
				`${data.url}${documentRequestGuid}`,
				authToken,
				'oneHubToken',
				redirectionURL.toString(),
			);
		})
		.catch((error) => {
			logger && logger.trackWarning('Error while generateExchangeTaxpayerAPIURL', { Error: error?.response?.data });
		});
}

export function generateTaxpayerEncodedClientId(clientId: string, callback: (data: string) => void) {
	axios
		.get(`${API_BASE_URL}/api/taxpayerHelper/generate-encoded-clientId/${clientId}`)
		.then((response: AxiosResponse) => {
			const { data } = response;
			callback(data);
		})
		.catch((error) => {
			logger && logger.trackWarning('Error while generateTaxpayerEncodedClientId', { Error: error?.response?.data });
		});
}

export function getSignalRWebsocketURL(callback: (data: string) => void, errorCallback: () => void) {
	axios
		.get(`${API_BASE_URL}/api/helper/socket-connection-url`)
		.then((response: AxiosResponse) => {
			const { data } = response;
			callback(data);
		})
		.catch((error) => {
			apiCatchHandlerNoData(error, errorCallback);
		});
}

export function validateSubDomain(
	url: string,
	successCallback: (domainInfo: IUTESubDomainResponse) => void,
	errorCallback: () => void,
) {
	axios
		.post(`${API_BASE_URL}/api/helper/validate-subdomain`, JSON.stringify(url), {
			headers: { 'Content-Type': 'application/json-patch+json' },
		})
		.then((response: AxiosResponse<IUTESubDomainResponse>) => {
			const { data } = response;
			successCallback(data);
		})
		.catch((error) => {
			apiCatchHandlerNoData(error, errorCallback);
		});
}

export function getUTECompanySetting(companyId: number, callback: (uteEnabledResponse: IUTEEnabledResponse) => void) {
	const uteEnabledResponse: IUTEEnabledResponse = {
		uteEnabled: false,
		error: false,
	};
	axios
		.get(`${API_BASE_URL}/api/company/get-ute-status/${companyId}`, { withCredentials: true })
		.then((response: AxiosResponse<boolean>) => {
			const { data } = response;
			uteEnabledResponse.uteEnabled = data;
			callback(uteEnabledResponse);
		})
		.catch((error) => {
			uteEnabledResponse.error = true;
			apiCatchHandler(error, uteEnabledResponse, callback);
		});
}

export function fetchUserAccess(
	companyId: number,
	productType: number,
	token: string,
	callback: (userAccessTokenResponseVM: InternalAccessTokenResponseVM) => void,
) {
	const userAccessTokenResponseVM = emptyInternalAccessTokenResponse;
	axios
		.post(
			`${API_BASE_URL}/api/token-validator/taxpayer-access/${productType}/${companyId}?token=${encodeURIComponent(
				token,
			)}`,
			{
				withCredentials: true,
			},
		)
		.then((response: AxiosResponse<InternalAccessTokenResponseVM>) => {
			const { data } = response;
			userAccessTokenResponseVM.accessToken = data.accessToken;
			userAccessTokenResponseVM.refreshToken = data.refreshToken;
			userAccessTokenResponseVM.isSuccess = data.isSuccess;
			userAccessTokenResponseVM.id = data.id;
			userAccessTokenResponseVM.name = data.name;
			userAccessTokenResponseVM.emailAddress = data.emailAddress;
			userAccessTokenResponseVM.companyId = data.companyId;
			callback(userAccessTokenResponseVM);
		})
		.catch((error) => {
			apiCatchHandler(error, userAccessTokenResponseVM, callback);
		});
}

export function fetchClientView(
	companyId: number,
	productType: number,
	cpaUserId: number,
	token: string,
	callback: (clientViewAccessTokenResponseVM: ClientViewAccessTokenResponseVM) => void,
) {
	const clientViewAccessTokenResponseVM = emptyClientViewAccessTokenResponse;
	axios
		.post(
			`${API_BASE_URL}/api/token-validator/client-view/${productType}/${cpaUserId}/${companyId}?token=${encodeURIComponent(
				token,
			)}`,
			{
				withCredentials: true,
			},
		)
		.then((response: AxiosResponse<ClientViewAccessTokenResponseVM>) => {
			const { data } = response;
			callback(data);
		})
		.catch((error) => {
			apiCatchHandler(error, clientViewAccessTokenResponseVM, callback);
		});
}

export function getTaxDocumentInfo(
	documentId: number,
	signerType: ClientTypes,
	productType: ProductType,
	callback: (documentResponse: IDocumentResponse) => void,
) {
	const documentResponse: IDocumentResponse = {
		document: initialTaxDocument,
		error: false,
	};
	axios
		.get(`${API_BASE_URL}/api/taxpayerHelper/get-taxDocumentinfo/${documentId}/${signerType}/${productType}`)
		.then((response: AxiosResponse<ITaxDocument>) => {
			const { data } = response;
			documentResponse.document = data;
			callback(documentResponse);
		})
		.catch((error) => {
			documentResponse.error = true;
			apiCatchHandler(error, documentResponse, callback);
		});
}

export const getPendoMetadata = (callback: (data: IPendoMetadata) => void) => {
	axios
		.get(`${API_BASE_URL}/api/Company/pendo-metadata`, { withCredentials: true })
		.then((response: AxiosResponse<IPendoMetadata>) => {
			callback(response.data);
		})
		.catch((error) => {
			logger && logger.trackWarning('Error while getPendoMetadata', { Error: error?.response?.data });
		});
};

export function validateCaptcha(token: string, callback: (isValid: ICaptchaResponse) => void) {
	axios
		.post(`${API_BASE_URL}/api/helper/validate-captcha`, JSON.stringify(token), {
			headers: { 'Content-Type': 'application/json-patch+json' },
		})
		.then((response: AxiosResponse<ICaptchaResponse>) => {
			const { data } = response;
			callback(data);
		})
		.catch((error) => {
			apiCatchHandler(error, error, callback);
		});
}

export function saveClientTrackingEvent(event: IClientTracking) {
	axios
		.post(`${API_BASE_URL}/api/client-tracking`, event)
		.then((response: AxiosResponse<boolean>) => { })
		.catch((error) => { });
}
