import React, { useState } from 'react';
import styles from './locked-pin.module.scss';
import { LockedIcon } from 'src/assets/svg/locked-icon';
import UTEButton from 'src/components/ute-button';

type LockedPINProps = {
	setComponent: (component: string) => void;
};
const LockedPIN = ({ setComponent }: LockedPINProps) => {
	const onResetPin = () => {
		setComponent('reset-pin');
	};

	return (
		<>
			<div className={styles.lockedIcon}>
				<LockedIcon></LockedIcon>
			</div>

			<div className='uteLoginHeader'>Account Locked</div>
			<div className='uteLoginSubHeader'>
				Your account has been locked due to three failed
				<br />
				PIN attempts. Please reset your PIN to access
				<br />
				your account.
			</div>
			<div className={styles.resetPinContainer}>
				<UTEButton
					text='Reset PIN'
					className={styles.resetPin}
					onClick={onResetPin}
					disabled={false}
					dataTestAuto='bfaa4066-94aa-4de8-85ae-faa129de26c4'
				/>
			</div>
		</>
	);
};

export default LockedPIN;
