import React from 'react';

const Loader = () => {
	return (
		<div
			className='spinner-border loadingSpinnerColor'
			role='status'></div>
	);
};

export default Loader;
