import React, { ReactElement } from 'react';

type UTEButtonProps = {
	text: React.ReactNode;
	className: string;
	onClick: () => void;
	disabled: boolean;
	dataTestAuto: string;
	icon?: ReactElement<any, any>;
	title?: string;
};
const UTEButton = ({ text, className, onClick, disabled, dataTestAuto, icon, title }: UTEButtonProps) => {
	return (
		<button
			className={className}
			disabled={disabled}
			onClick={onClick}
			data-test-auto={dataTestAuto}
			title={title}>
			{icon}
			{text}
		</button>
	);
};

export default UTEButton;
