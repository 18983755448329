import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './enter-email.module.scss';
import { isValidEmailAddress, showCaptcha } from 'src/helper/utils';
import UTEButton from 'src/components/ute-button';
import Cookies from 'js-cookie';
import { CAPTCHA_PUBLIC_KEY } from 'src/helper/constants';

type EnterEmailProps = {
	emailID: string;
	setEmailID: (emailID: string) => void;
	handleEmailIDSubmit: (callback: () => void) => void;
	setComponent: (component: string) => void;
	accountCreatedEmail: string;
	resetEmail: string;
};

const EnterEmail = ({
	emailID,
	setEmailID,
	handleEmailIDSubmit,
	setComponent,
	accountCreatedEmail,
	resetEmail,
}: EnterEmailProps) => {
	const [isEmailIDDisabled, setIsEmailIDDisabled] = useState(true);
	let captcha: ReCAPTCHA | null;

	useEffect(() => {
		if (emailID.length > 0 && isValidEmailAddress(emailID)) setIsEmailIDDisabled(false);
	}, []);

	const handleEmailIDChange = (emailIDValue: string) => {
		setEmailID(emailIDValue);
		if (emailIDValue.length > 0 && isValidEmailAddress(emailIDValue)) {
			setIsEmailIDDisabled(false);
		} else {
			setIsEmailIDDisabled(true);
		}
	};

	const onClickContinue = () => {
		setIsEmailIDDisabled(true);
		handleEmailIDSubmit(() => {
			setIsEmailIDDisabled(false);
			const invalidEmailCount = parseInt((Cookies.get('invalidEmailCount') || '0').toString(), 10);
			if (invalidEmailCount >= 4) {
				captcha?.reset();
			}
		});
	};

	const setCaptchaRef = (ref: ReCAPTCHA | null) => {
		if (ref) {
			return (captcha = ref);
		}
	};

	const onReCAPTCHAChange = (token: string | null) => {
		if (token != null) Cookies.set('captchaToken', token, { expires: 1 / 24, secure: true, sameSite: 'Strict' });
	};

	return (
		<>
			<div className='uteLoginHeader'>Log&nbsp;in&nbsp;to&nbsp;your&nbsp;account</div>
			<div className='uteLoginSubHeader'>{accountCreatedEmail && 'Welcome back! '}Please enter your details.</div>
			<div className='formInputHeader'>Email ID</div>
			<input
				type='text'
				name='emailID'
				id='emailID'
				maxLength={320}
				className={styles.emailID}
				placeholder='Enter your email ID'
				value={emailID}
				onChange={(e) => {
					handleEmailIDChange(e.target.value);
				}}
				data-test-auto='afe63f31-4fb3-4e94-8c3b-3cb9fb50c293'
				disabled={accountCreatedEmail.length > 0 || resetEmail.length > 0}
			/>
			<div className={styles.forgotUserName}>
				<a
					className='formInputForgotText'
					onClick={() => {
						setComponent('recover-or-reset');
					}}>
					Recover or reset your email ID
				</a>
			</div>
			<div className={styles.captchaContainer}>
				{showCaptcha() && (
					<ReCAPTCHA
						ref={(r) => setCaptchaRef(r)}
						sitekey={CAPTCHA_PUBLIC_KEY}
						onChange={onReCAPTCHAChange}
					/>
				)}
			</div>
			<div className={styles.continueContainer}>
				<UTEButton
					text='Continue'
					className={styles.continue}
					onClick={onClickContinue}
					disabled={isEmailIDDisabled}
					dataTestAuto='849fd46e-3fbf-4c91-ba5e-b81329c95e53'
				/>
			</div>
			<div className={styles.registrationContainer}>
				<span className={styles.text}>Don’t have an account? </span>
				<span
					className={styles.signUp}
					onClick={() => {
						setComponent('register');
					}}>
					Sign Up
				</span>
			</div>
		</>
	);
};

export default EnterEmail;
