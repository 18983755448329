import React, { useContext, useEffect, useState } from 'react';
import styles from './recent-activity.module.scss';
import { IRecentActivity, IRecentActivityResponse, OneHubEventType } from './recent-activity-types';
import { getRecentActivityList } from './recent-activity-api';
import { UTENotifier } from '../notification/ute-notifier';
import { RECENT_ACTIVITY_CONSTANTS } from 'src/helper/constants';
import File from 'src/assets/svg/file';
import { formatDate, transformUserActivityToTaxDocument } from 'src/helper/utils';
import LoadingOverlay from 'react-loading-overlay';
import Loader from 'src/components/loader';
import PreviewPopupModal from '../document-preview/preview-pop-up';
import {
	generateExchangeTaxpayerAPIURL,
	generateExtTaxpayerRedirectionAPIURL,
	generateSignatureTaxpayerAPIURL,
	generateTPAPIURLForRecentActivity,
	generateTaxpayerEncodedClientId,
	getTaxDocumentInfo,
} from '../common/common-api';
import {
	IDocumentResponse,
	ProductType,
	SignatureRedirectionSourcePage,
	TaxpayerRedirectionSourcePage,
} from '../common/common-types';
import { initialTaxDocument, ITaxDocument } from '../documents/documents-types';
import ForwardTaxDocumentContainer from '../cc-recipient';
import { getCCRecipientDownloadableTaxDocuments } from '../cc-recipient/forward-taxdocument-api';
import {
	ICCRecipientDownloadableDocuments,
	IDownloadableDocumentsResponse,
} from '../cc-recipient/forward-taxdocument-types';
import { AuthContext } from '../../context/ute-auth-context/auth-provider';
import UTEClientViewWrapper from 'src/components/ute-client-view-wrapper';
import { useNavigate } from 'react-router-dom';

const RecentActivity = () => {
	const navigate = useNavigate();
	const [recentActivityList, setRecentActivityList] = useState<IRecentActivity[]>([]);
	const [showPreviewModal, setShowPreviewModal] = useState(false);
	const [activity, setActivity] = useState<IRecentActivity | null>(null);
	const [showCCRecipientModal, setShowCCRecipientModal] = useState(false);
	const [document, setDocument] = useState<ITaxDocument>(initialTaxDocument);
	const [encodedClientId, setEncodedClientId] = useState('');
	const [isDocumentLoading, setIsDocumentLoading] = useState(false);
	const [downloadableDocuments, setDownloadableDocuments] = useState<ICCRecipientDownloadableDocuments[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const { userDetail } = useContext(AuthContext);

	useEffect(() => {
		fetchRecentActivityList();
	}, []);

	const fetchRecentActivityList = () => {
		setIsLoading(true);
		getRecentActivityList((recentActivityResponse: IRecentActivityResponse) => {
			setIsLoading(false);
			if (recentActivityResponse.error || typeof recentActivityResponse.recentActivityList === 'string') {
				UTENotifier.Error(RECENT_ACTIVITY_CONSTANTS.ERROR_FETCH_RECENT_ACTIVITY);
			} else {
				setRecentActivityList(recentActivityResponse.recentActivityList);
			}
		});
	};

	const handleRecentActivityClick = (eachActivity: IRecentActivity) => {
		switch (eachActivity.productType) {
			case ProductType.Returns:
				handleRecentActivityClick_SSR(eachActivity);
				break;
			case ProductType.Extensions:
				handleRecentActivityClick_EXT(eachActivity);
				break;
			case ProductType.SignaturesOneOff:
				handleRecentActivityClick_SIG(eachActivity);
				break;
			case ProductType.Gather:
			case ProductType.Organizers:
				handleRecentActivityClick_GTR_ORG(eachActivity);
				break;
			case ProductType.Exchange:
				handleRecentActivityClick_SSE(eachActivity);
				break;
		}
	};

	const handleRecentActivityClick_SSE = (eachActivity: IRecentActivity) => {
		switch (eachActivity.eventId) {
			case OneHubEventType.View:
			case OneHubEventType.Download:
				setShowPreviewModal(true);
				setActivity(eachActivity);
				break;

			case OneHubEventType.Continue:
			case OneHubEventType.GetStarted:
				if (eachActivity.eventData.clientGuid) {
					generateExchangeTaxpayerAPIURL(eachActivity.eventData.clientGuid.toString());
				}
				break;
			case OneHubEventType.sendFilesToCPA:
				navigate('/documents'); // Route to DocumentsContainer page
				break;
		}
	};

	const handleRecentActivityClick_SIG = (eachActivity: IRecentActivity) => {
		switch (eachActivity.eventId) {
			case OneHubEventType.View:
				setShowPreviewModal(true);
				setActivity(eachActivity);
				break;

			case OneHubEventType.Download:
				if (eachActivity.eventData.clientGuid) {
					generateSignatureTaxpayerAPIURL(
						eachActivity.eventData.clientGuid.toString(),
						SignatureRedirectionSourcePage.Download,
					);
				}
				break;
			case OneHubEventType.GetStarted:
			case OneHubEventType.Continue:
				if (eachActivity.eventData.clientGuid) {
					generateSignatureTaxpayerAPIURL(eachActivity.eventData.clientGuid.toString());
				}
				break;
		}
	};

	const handleRecentActivityClick_GTR_ORG = (eachActivity: IRecentActivity) => {
		if (eachActivity.eventId === OneHubEventType.View) {
			setShowPreviewModal(true);
			setActivity(eachActivity);
		} else if (
			eachActivity.eventId === OneHubEventType.Download ||
			eachActivity.eventId === OneHubEventType.MoreActions
		) {
			generateTPAPIURLForRecentActivity(
				eachActivity.eventData.documentId,
				eachActivity.eventData.signerType,
				eachActivity.productType,
			);
		} else if (
			eachActivity.eventId === OneHubEventType.GetStarted ||
			eachActivity.eventId === OneHubEventType.Continue
		) {
			generateTPAPIURLForRecentActivity(
				eachActivity.eventData.documentId,
				eachActivity.eventData.signerType,
				eachActivity.productType,
			);
		}
	};

	const handleRecentActivityClick_SSR = (eachActivity: IRecentActivity) => {
		if (eachActivity.eventId === OneHubEventType.View) {
			setShowPreviewModal(true);
			setActivity(eachActivity);
		} else if (
			eachActivity.eventId === OneHubEventType.Download ||
			eachActivity.eventId === OneHubEventType.MoreActions
		) {
			generateTPAPIURLForRecentActivity(
				eachActivity.eventData.documentId,
				eachActivity.eventData.signerType,
				ProductType.Returns,
				eachActivity.eventId === OneHubEventType.MoreActions
					? TaxpayerRedirectionSourcePage.Signcompleted
					: TaxpayerRedirectionSourcePage.Download,
				userDetail.emailAddress,
			);
		} else if (
			eachActivity.eventId === OneHubEventType.GetStarted ||
			eachActivity.eventId === OneHubEventType.Continue
		) {
			generateTPAPIURLForRecentActivity(
				eachActivity.eventData.documentId,
				eachActivity.eventData.signerType,
				ProductType.Returns,
				TaxpayerRedirectionSourcePage.TaxpayerFlow,
				userDetail.emailAddress,
			);
		} else if (eachActivity.eventId === OneHubEventType.Forward) {
			getTaxDocumentInfo(
				eachActivity.eventData.documentId,
				eachActivity.eventData.signerType,
				eachActivity.productType,
				(documentResponse: IDocumentResponse) => {
					if (documentResponse.error) {
						UTENotifier.Error(RECENT_ACTIVITY_CONSTANTS.ERROR_FETCH_SSR_DOCUMENT_INFO);
					} else {
						displayCCRecipientModal(documentResponse.document);
					}
				},
			);
		}
	};

	const handleRecentActivityClick_EXT = (eachActivity: IRecentActivity) => {
		if (eachActivity.eventId === OneHubEventType.View) {
			setShowPreviewModal(true);
			setActivity(eachActivity);
		} else if (
			eachActivity.eventId === OneHubEventType.Download ||
			eachActivity.eventId === OneHubEventType.MoreActions
		) {
			generateTPAPIURLForRecentActivity(
				eachActivity.eventData.documentId,
				eachActivity.eventData.signerType,
				ProductType.Extensions,
				eachActivity.eventId === OneHubEventType.MoreActions
					? TaxpayerRedirectionSourcePage.Signcompleted
					: TaxpayerRedirectionSourcePage.Download,
				userDetail.emailAddress,
			);
		} else if (
			eachActivity.eventId === OneHubEventType.GetStarted ||
			eachActivity.eventId === OneHubEventType.Continue
		) {
			generateTPAPIURLForRecentActivity(
				eachActivity.eventData.documentId,
				eachActivity.eventData.signerType,
				ProductType.Extensions,
				TaxpayerRedirectionSourcePage.TaxpayerFlow,
				userDetail.emailAddress,
			);
		} else if (eachActivity.eventId === OneHubEventType.Forward) {
			getTaxDocumentInfo(
				eachActivity.eventData.documentId,
				eachActivity.eventData.signerType,
				eachActivity.productType,
				(documentResponse: IDocumentResponse) => {
					if (documentResponse.error) {
						UTENotifier.Error(RECENT_ACTIVITY_CONSTANTS.ERROR_FETCH_SSR_DOCUMENT_INFO);
					} else {
						displayCCRecipientModal(documentResponse.document);
					}
				},
			);
		}
	};

	const displayCCRecipientModal = (document: ITaxDocument) => {
		setDocument(document);
		fetchDownloadableDocuments(document);
		setShowCCRecipientModal(true);
	};

	const fetchDownloadableDocuments = (document: ITaxDocument) => {
		setIsDocumentLoading(true);
		generateTaxpayerEncodedClientId(document.clientGuid, (encodedClientId) => {
			setEncodedClientId(encodedClientId);
			getCCRecipientDownloadableTaxDocuments(
				encodedClientId,
				document.documentId,
				document.documentStatus,
				document.productType,
				(downloadableDocumentsResponse: IDownloadableDocumentsResponse) => {
					setDownloadableDocuments(downloadableDocumentsResponse.downloadableDocuments);
					setIsDocumentLoading(false);
				},
			);
		});
	};

	const hideCCRecipientModal = () => {
		setShowCCRecipientModal(false);
		setDocument(initialTaxDocument);
		setDownloadableDocuments([]);
	};

	const onPreviewModalCancel = () => {
		setShowPreviewModal(false);
		setActivity(null);
	};

	const getRecentActivity = (eachActivity: IRecentActivity, index: number) => {
		if (eachActivity.productType === ProductType.Gather || eachActivity.productType === ProductType.Organizers) {
			const eventName =
				eachActivity.eventId === OneHubEventType.GetStarted || eachActivity.eventId === OneHubEventType.Continue
					? `${eachActivity.eventData.userName} ${eachActivity.eventData.gatherFileData?.taxYear} ${eachActivity.productType === ProductType.Gather ? 'Gather Request' : 'Tax Organizer'}`
					: eachActivity.eventId === OneHubEventType.View ? eachActivity.eventData.gatherFileData?.fileName ?? '' : eachActivity.eventData.fileName;
			return (
				<li
					className={styles.listData}
					key={index}>
					<div
						className={styles.left}
						title={eventName}>
						<UTEClientViewWrapper dataTestAuto='1e41b53f-1d33-477b-a549-2761a2def944'>
							<span
								onClick={() => {
									handleRecentActivityClick(eachActivity);
								}}>
								<File />
								<span>{eventName}</span>
							</span>
						</UTEClientViewWrapper>
					</div>
					<div className={styles.right}>{formatDate(eachActivity.actedOn)}</div>
				</li>
			);
		} else {
			const isFilesSentToCPA =
				eachActivity.productType === ProductType.Exchange && eachActivity.eventId === OneHubEventType.sendFilesToCPA;
			const clientEventName = isFilesSentToCPA
				? `Files Sent: ${eachActivity.eventData.fileName}`
				: eachActivity.eventData.fileName;
			return (
				<li
					className={styles.listData}
					key={index}>
					<div
						className={styles.left}
						title={clientEventName}>
						<UTEClientViewWrapper dataTestAuto='1367ac39-9066-448b-929e-4c428f8b2d19'>
							<span
								onClick={() => {
									handleRecentActivityClick(eachActivity);
								}}>
								<File />
								<span>{clientEventName}</span>
							</span>
						</UTEClientViewWrapper>
					</div>
					<div className={styles.right}>{formatDate(eachActivity.actedOn)}</div>
				</li>
			);
		}
	};

	return (
		<section className={styles.recentActivitymodule}>
			<header>Recent Activity</header>
			<LoadingOverlay
				active={isLoading}
				spinner={<Loader />}
				text=''>
				<ul className={styles.recentActivityList}>
					{isLoading ? (
						<></>
					) : recentActivityList.length === 0 ? (
						<div className={styles.emptyRecentActivityMessage}>No recent activity to display.</div>
					) : (
						<>
							<li className={styles.listHeader}>
								<div className={styles.left}>Name</div>
								<div className={styles.right}>Opened</div>
							</li>
							{recentActivityList.map((eachActivity: IRecentActivity, index: number) => {
								return getRecentActivity(eachActivity, index);
							})}
						</>
					)}
				</ul>
			</LoadingOverlay>
			{showPreviewModal && activity && (
				<PreviewPopupModal
					clientType={activity?.eventData.signerType}
					id={activity.eventData.documentId}
					name={activity.eventData.fileName}
					onCancel={onPreviewModalCancel}
					productType={activity.productType}
					showModal={showPreviewModal}
					recentActivity={activity}
					exchangeFile={transformUserActivityToTaxDocument(activity)}
				/>
			)}
			{showCCRecipientModal && (
				<ForwardTaxDocumentContainer
					downloadableDocuments={downloadableDocuments}
					encodedClientId={encodedClientId}
					taxDocument={document}
					closeCCRecipientModal={hideCCRecipientModal}
					show={showCCRecipientModal}
					isDocumentLoading={isDocumentLoading}
				/>
			)}
		</section>
	);
};

export default RecentActivity;
