import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from 'src/helper/constants';
import {
	IAccountCreatedResponse,
	IGenerateOTPResponse,
	IValidateOTPResponse,
	validOTPDetailDefault,
} from './register-component-types';
import { apiCatchHandler } from 'src/helper/utils';
import { OTPValidationStatus } from 'src/modules/common/common-types';

export function generateOTP(
	companyId: number,
	emailID: string,
	callback: (generateOTPResponse: IGenerateOTPResponse) => void,
) {
	const generateOTPResponse: IGenerateOTPResponse = {
		error: false,
	};
	axios
		.get(`${API_BASE_URL}/api/otp/generate/${companyId}?email=${emailID}&registrationId=`, {
			withCredentials: true,
		})
		.then(() => {
			generateOTPResponse.error = false;
			callback(generateOTPResponse);
		})
		.catch((error) => {
			generateOTPResponse.error = true;
			apiCatchHandler(error, generateOTPResponse, callback);
		});
}

export function validateOTP(
	companyId: number,
	emailId: string,
	otp: string,
	callback: (validateOTPResponse: IValidateOTPResponse) => void,
) {
	const validateOTPResponse: IValidateOTPResponse = {
		validOTPDetail: validOTPDetailDefault,
		error: false,
	};
	axios
		.post(`${API_BASE_URL}/api/otp/registration/validate/${companyId}?emailId=${emailId}&otp=${otp}`, '', {
			withCredentials: true,
		})
		.then((response: AxiosResponse<OTPValidationStatus>) => {
			const { data } = response;
			validateOTPResponse.validOTPDetail.status = data;
			callback(validateOTPResponse);
		})
		.catch((error) => {
			validateOTPResponse.error = true;
			apiCatchHandler(error, validateOTPResponse, callback);
		});
}

export function createAccount(
	companyId: number,
	name: string,
	emailAddress: string,
	pin: string,
	countryCode: string,
	mobileNumber: string,
	callback: (accountCreatedResponse: IAccountCreatedResponse) => void,
) {
	const accountCreatedResponse: IAccountCreatedResponse = {
		error: false,
		accountCreated: false,
	};
	const encodedCountryCode = encodeURIComponent(countryCode);
	const encodedMobileNumber = encodeURIComponent(mobileNumber);
	axios
		.post(
			`${API_BASE_URL}/api/pin/adduserpin/${companyId}?name=${name}&emailAddress=${emailAddress}&pin=${pin}&countryCode=${encodedCountryCode}&mobileNumber=${encodedMobileNumber}`,
			'',
			{
				withCredentials: true,
			},
		)
		.then((response: AxiosResponse<boolean>) => {
			const { data } = response;
			accountCreatedResponse.accountCreated = data;
			callback(accountCreatedResponse);
		})
		.catch((error) => {
			accountCreatedResponse.error = true;
			apiCatchHandler(error, accountCreatedResponse, callback);
		});
}
