import React, { FC } from 'react';

const BottomArrowUTEModal: FC = () => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_10681_28751)'>
				<rect
					width='16'
					height='16'
					fill='white'
					fillOpacity='0.01'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M1.64592 4.64494C1.69236 4.59838 1.74754 4.56143 1.80828 4.53623C1.86903 4.51102 1.93415 4.49805 1.99992 4.49805C2.06568 4.49805 2.13081 4.51102 2.19155 4.53623C2.2523 4.56143 2.30747 4.59838 2.35392 4.64494L7.99992 10.2919L13.6459 4.64494C13.6924 4.59845 13.7476 4.56158 13.8083 4.53642C13.8691 4.51126 13.9342 4.49831 13.9999 4.49831C14.0657 4.49831 14.1308 4.51126 14.1915 4.53642C14.2522 4.56158 14.3074 4.59845 14.3539 4.64494C14.4004 4.69143 14.4373 4.74662 14.4624 4.80736C14.4876 4.8681 14.5005 4.9332 14.5005 4.99894C14.5005 5.06468 14.4876 5.12978 14.4624 5.19052C14.4373 5.25126 14.4004 5.30645 14.3539 5.35294L8.35392 11.3529C8.30747 11.3995 8.2523 11.4364 8.19155 11.4617C8.13081 11.4869 8.06568 11.4998 7.99992 11.4998C7.93415 11.4998 7.86903 11.4869 7.80828 11.4617C7.74754 11.4364 7.69236 11.3995 7.64592 11.3529L1.64592 5.35294C1.59935 5.30649 1.56241 5.25132 1.5372 5.19057C1.512 5.12983 1.49902 5.06471 1.49902 4.99894C1.49902 4.93317 1.512 4.86805 1.5372 4.80731C1.56241 4.74656 1.59935 4.69139 1.64592 4.64494Z'
					fill='#212529'
				/>
			</g>
			<defs>
				<clipPath id='clip0_10681_28751'>
					<rect
						width='16'
						height='16'
						fill='white'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default BottomArrowUTEModal;
