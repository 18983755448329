export enum K1EmailStatus {
	NotSent = 0,
	PartiallySent,
	Sent,
}

export enum EngagementType {
	None = 0,
	E1040,
	E1065,
	E1120,
	E1120S,
	E1041,
	E1040NR,
	E990,
	E990PF,
	E990EZ,
}

export type IK1Details = {
	count: number;
	items: IK1DistributeModel[];
};

export const initialK1Details = {
	count: 0,
	items: [],
};

export type IK1DistributeModel = {
	documentId: number;
	clientGuid: string;
	taxYear: number;
	taxpayerName: number;
	k1EmailStatus: K1EmailStatus;
	engagementType: EngagementType;
};

export type IK1DistributeResponse = {
	k1Details: IK1Details;
	error: boolean;
};

export type IK1DocumentsPresentResponse = {
	isPresent: boolean;
	error: boolean;
};
