import { useEffect } from 'react';
import { CLIENT_PENDO_API_KEY } from 'src/helper/constants';

interface IPendoDetails {
	companyId: number;
	companyName: string;
	userId: number;
	name: string;
	email: string;
	isTestCompany: boolean;
	subscription: string;
}

const usePendo = (userDetails: IPendoDetails) => {
	useEffect(() => {
		const { companyId, companyName, userId, name, email, isTestCompany, subscription } = userDetails;

		if (!companyId || !companyName || !userId || !name || !email || !subscription) {
			return;
		}

		const existingScript = document.getElementById('pendo-script');

		if (!existingScript) {
			(function (p: any, e: any, n: any, d: any, o: any) {
				var v: any, w: any, x: any, y: any, z: any;
				o = p[d] = p[d] || {};
				o._q = o._q || [];
				v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
				for (w = 0, x = v.length; w < x; ++w)
					(function (m) {
						o[m] =
							o[m] ||
							function () {
								o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
							};
					})(v[w]);
				y = e.createElement(n);
				y.async = !0;
				y.id = 'pendo-script';
				y.src = 'https://cdn.pendo.io/agent/static/' + CLIENT_PENDO_API_KEY + '/pendo.js';
				z = e.getElementsByTagName(n)[0];
				z.parentNode.insertBefore(y, z);
			})(window, document, 'script', 'pendo', '');

			window.pendo?.initialize({
				visitor: {
					id: `${companyId}-${userId}-${email}`,
					email: email,
					name: name,
				},
				account: {
					accountid: companyId,
					accountname: companyName,
					firmtype: isTestCompany ? 'Internal' : 'Live',
					subscription: subscription,
				},
			});
		}
	}, [userDetails]);
};

export default usePendo;
