export type ISIGDocumentBasicInfo = {
    documentId: number;
    documentGuid: string;
    taxYear: number;
    documentName: string;
    signingGuid: string;
};

export type ISIGDocumentPreviewModel = {
    documentInfo: ISIGDocumentBasicInfo;
    sasUrl: string;
    companyId: number;
};

export const initialSIGDocumentBasicInfo: ISIGDocumentBasicInfo = {
    documentId: 0,
    documentGuid: '',
    taxYear: 0,
    documentName: '',
    signingGuid: '',
};

export const initialSIGDocumentPreviewModel: ISIGDocumentPreviewModel = {
    documentInfo: initialSIGDocumentBasicInfo,
    sasUrl: '',
    companyId: 0,
};