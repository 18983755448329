import axios, { AxiosResponse } from 'axios';
import { EXTTP_API_URL, FORWARD_TAXDOCUMENT_CONSTANTS, SSRTP_API_URL } from 'src/helper/constants';
import {
	ICCRecipientDownloadableDocuments,
	ICCRecipientModel,
	IDownloadableDocumentsResponse,
} from './forward-taxdocument-types';
import { DocumentStatus } from '../documents/documents-types';
import { UTENotifier } from '../notification/ute-notifier';
import { ProductType } from '../common/common-types';

export const getCCRecipientDownloadableTaxDocuments = (
	clientId: string,
	documentId: number,
	documentStatus: DocumentStatus,
	productType: ProductType,
	callback: (downloadableDocumentsResponse: IDownloadableDocumentsResponse) => void,
) => {
	const downloadableDocumentsResponse: IDownloadableDocumentsResponse = {
		downloadableDocuments: [],
		error: false,
	};
	let baseUrl: any;
	if (productType === ProductType.Returns) {
		baseUrl = SSRTP_API_URL;
	} else if (productType === ProductType.Extensions) {
		baseUrl = EXTTP_API_URL;
	} else {
		UTENotifier.Error(FORWARD_TAXDOCUMENT_CONSTANTS.ERROR_MESSAGE);
	}
	const apiUrl = `${baseUrl}/api/cc-recipient-manager/get-downloadable-forms/${documentId}/${documentStatus}/${clientId}`;

	axios
		.get(apiUrl)
		.then((response: AxiosResponse<ICCRecipientDownloadableDocuments[]>) => {
			const { data } = response;
			downloadableDocumentsResponse.downloadableDocuments = data;
			callback(downloadableDocumentsResponse);
		})
		.catch(() => {
			downloadableDocumentsResponse.error = true;
			callback(downloadableDocumentsResponse);
		});
};

export const forwardDocumentsToRecipients = (
	clientId: string,
	recipientInfo: ICCRecipientModel,
	taxPayerName: string,
	taxYear: number,
	productType: ProductType,
	oneHubUserEmail: string,
	successCallback: () => void,
	errorCallback: () => void,
) => {
	let baseUrl: any;

	if (productType === ProductType.Returns) {
		baseUrl = SSRTP_API_URL;
	} else if (productType === ProductType.Extensions) {
		baseUrl = EXTTP_API_URL;
	} else {
		UTENotifier.Error(FORWARD_TAXDOCUMENT_CONSTANTS.ERROR_FETCH_DOCUMENT);
	}
	axios
		.post(
			`${baseUrl}/api/cc-recipient-manager/forward-documents/${clientId}?taxPayerName=${taxPayerName}&taxYear=${taxYear}&oneHubEmail=${oneHubUserEmail}`,
			recipientInfo,
			{ withCredentials: true },
		)
		.then((response: AxiosResponse<boolean>) => {
			successCallback();
		})
		.catch(() => {
			errorCallback();
		});
};
