import { useContext, useState } from 'react';
import UTEOTPInput from 'src/components/ute-otp-input';
import { IOTPValidity, OTPValidityDefault } from 'src/components/ute-otp-input/ute-otp-input-types';
import {
	IRecoveryInformation,
	IRecoveryMailOTPResponse,
	IRecoveryMailResponse,
	IRecoveryPhoneNumberOTPResponse,
	IRecoveryPhoneNumberResponse,
	IValidateNewMailOTPResponse,
	IValidateNewMailResponse,
	RecoveryInformationDefault,
} from './reset-email-id-types';
import EnterRecoveryInformation from './enter-recovery-information';
import EnterNewEmailId from './enter-new-email-id';
import { UTENotifier } from 'src/modules/notification/ute-notifier';
import { CountryData } from 'src/modules/cc-recipient/country-data';
import '@sssuite-component-ui/react-phone-input-2/lib/style.css';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';
import {
	validateNewPrimaryMail,
	validateNewPrimaryMailOTP,
	validateRecoveryMail,
	validateRecoveryMailOTP,
	validateRecoveryPhoneNumber,
	validateRecoveryPhoneNumberOTP,
} from './reset-email-id-api';
import { OTPValidationStatus } from 'src/modules/common/common-types';
import { LOGIN_CONSTANTS, RESET_EMAILID_CONSTANTS, RIGISTER_CONSTANTS } from 'src/helper/constants';
import { emptyUser } from 'src/context/ute-auth-context/auth-provider-types';

type ResetEmailIdProps = {
	setComponent: (component: string) => void;
};
const ResetEmailId = ({ setComponent }: ResetEmailIdProps) => {
	const [recoveryType, setRecoveryType] = useState('');
	const [recoveryEmail, setRecoveryEmail] = useState('');
	const [existingEmail, setExistingEmail] = useState('');
	const [recoveryCountryCode, setRecoveryCountryCode] = useState('+1');
	const [recoveryMobileNumber, setRecoveryMobileNumber] = useState('');
	const [recoveryInformation, setRecoveryInformation] = useState<IRecoveryInformation>(RecoveryInformationDefault);
	const [otp, setOTP] = useState('');
	const [otpValidity, setOTPValidity] = useState<IOTPValidity>(OTPValidityDefault);
	const [newEmail, setNewEmail] = useState('');
	const [newEmailError, setNewEmailError] = useState(false);
	const [actionInProgress, setActionInProgress] = useState(false);
	const { companyId, updateAuthToken, updateUserDetails } = useContext(AuthContext);

	const onMobileChange = (fullValue: string, countryObject: CountryData): void => {
		setRecoveryCountryCode(`+${countryObject.dialCode}`);
		setRecoveryMobileNumber(fullValue.slice(countryObject.dialCode.length));
	};

	const handleRecoveryInfoSubmit = () => {
		setActionInProgress(true);
		if (recoveryType === 'Email') {
			validateRecoveryMail(companyId, existingEmail, recoveryEmail, (recoveryMailResponse: IRecoveryMailResponse) => {
				setActionInProgress(false);
				if (recoveryMailResponse.error) {
					UTENotifier.Error(RESET_EMAILID_CONSTANTS.ERROR_VALIDATING_MAIL);
				} else {
					if (recoveryMailResponse.validated) {
						setRecoveryInformation({ ...recoveryInformation, recoveryMatched: true });
						UTENotifier.Success(LOGIN_CONSTANTS.OTP_SENT);
					} else {
						UTENotifier.Error(RESET_EMAILID_CONSTANTS.RECOVERY_DETAIL_EXISTING_EMAIL_UNMATCH);
					}
				}
			});
		} else if (recoveryType === 'Phone') {
			validateRecoveryPhoneNumber(
				companyId,
				existingEmail,
				recoveryCountryCode,
				recoveryMobileNumber,
				(recoveryPhoneNumberResponse: IRecoveryPhoneNumberResponse) => {
					setActionInProgress(false);
					if (recoveryPhoneNumberResponse.error) {
						UTENotifier.Error(RESET_EMAILID_CONSTANTS.ERROR_VALIDATING_PHONE_NUMBER);
					} else {
						if (recoveryPhoneNumberResponse.validated) {
							setRecoveryInformation({ ...recoveryInformation, recoveryMatched: true });
							UTENotifier.Success(LOGIN_CONSTANTS.OTP_SENT);
						} else {
							UTENotifier.Error(RESET_EMAILID_CONSTANTS.RECOVERY_DETAIL_EXISTING_EMAIL_UNMATCH);
						}
					}
				},
			);
		}
	};

	const handleRecoveryOTPSubmit = () => {
		if (recoveryType === 'Email') {
			validateRecoveryMailOTP(companyId, recoveryEmail, otp, (recoveryMailOTPResponse: IRecoveryMailOTPResponse) => {
				if (recoveryMailOTPResponse.error) {
					setOTP('');
					setOTPValidity({ isValid: false, message: LOGIN_CONSTANTS.ERROR_VERIFY_OTP });
				} else {
					if (recoveryMailOTPResponse.otpResponse === OTPValidationStatus.Success) {
						setOTPValidity(OTPValidityDefault);
						setOTP('');
						setRecoveryInformation({ ...recoveryInformation, recoveryOTPValidated: true });
					} else if (recoveryMailOTPResponse.otpResponse === OTPValidationStatus.OTPExpired) {
						setOTPValidity({ isValid: false, message: LOGIN_CONSTANTS.OTP_EXPIRED });
						setOTP('');
					} else if (recoveryMailOTPResponse.otpResponse === OTPValidationStatus.InvalidOTP) {
						setOTPValidity({ isValid: false, message: LOGIN_CONSTANTS.INVALID_OTP });
						setOTP('');
					}
				}
			});
		} else if (recoveryType === 'Phone') {
			validateRecoveryPhoneNumberOTP(
				companyId,
				recoveryCountryCode,
				recoveryMobileNumber,
				otp,
				(recoveryPhoneNumberOTPResponse: IRecoveryPhoneNumberOTPResponse) => {
					if (recoveryPhoneNumberOTPResponse.error) {
						setOTP('');
						setOTPValidity({ isValid: false, message: LOGIN_CONSTANTS.ERROR_VERIFY_OTP });
					} else {
						if (recoveryPhoneNumberOTPResponse.otpResponse === OTPValidationStatus.Success) {
							setOTPValidity(OTPValidityDefault);
							setOTP('');
							setRecoveryInformation({ ...recoveryInformation, recoveryOTPValidated: true });
						} else if (recoveryPhoneNumberOTPResponse.otpResponse === OTPValidationStatus.OTPExpired) {
							setOTPValidity({ isValid: false, message: LOGIN_CONSTANTS.OTP_EXPIRED });
							setOTP('');
						} else if (recoveryPhoneNumberOTPResponse.otpResponse === OTPValidationStatus.InvalidOTP) {
							setOTPValidity({ isValid: false, message: LOGIN_CONSTANTS.INVALID_OTP });
							setOTP('');
						}
					}
				},
			);
		}
	};

	const handleRecoveryResendCode = () => {
		handleRecoveryInfoSubmit();
	};

	const handleNewEmailSubmit = () => {
		setActionInProgress(true);
		validateNewPrimaryMail(companyId, existingEmail, newEmail, (validateNewMailResponse: IValidateNewMailResponse) => {
			setActionInProgress(false);
			if (validateNewMailResponse.error) {
				UTENotifier.Error(RESET_EMAILID_CONSTANTS.ERROR_VALIDATING_NEW_MAIL);
				setNewEmailError(false);
			} else {
				if (validateNewMailResponse.validated) {
					setRecoveryInformation({ ...recoveryInformation, newEmailVaildated: true });
					setNewEmailError(false);
					UTENotifier.Success(LOGIN_CONSTANTS.OTP_SENT);
				} else {
					UTENotifier.Error(RIGISTER_CONSTANTS.ERROR_USER_EXIST);
					setNewEmailError(true);
				}
			}
		});
	};

	const handleNewEmailOTPSubmit = () => {
		validateNewPrimaryMailOTP(
			companyId,
			existingEmail,
			newEmail,
			otp,
			(validateNewMailOTPResponse: IValidateNewMailOTPResponse) => {
				if (validateNewMailOTPResponse.error) {
					setOTP('');
					setOTPValidity({ isValid: false, message: LOGIN_CONSTANTS.ERROR_VERIFY_OTP });
				} else {
					if (validateNewMailOTPResponse.otpResponse === OTPValidationStatus.Success) {
						setOTPValidity(OTPValidityDefault);
						setOTP('');
						UTENotifier.Success(RESET_EMAILID_CONSTANTS.SUCCESS_RESET_EMAILID);
						updateAuthToken('', emptyUser);
						updateUserDetails(emptyUser);
						setComponent('login');
					} else if (validateNewMailOTPResponse.otpResponse === OTPValidationStatus.OTPExpired) {
						setOTPValidity({ isValid: false, message: LOGIN_CONSTANTS.OTP_EXPIRED });
						setOTP('');
					} else if (validateNewMailOTPResponse.otpResponse === OTPValidationStatus.InvalidOTP) {
						setOTPValidity({ isValid: false, message: LOGIN_CONSTANTS.INVALID_OTP });
						setOTP('');
					}
				}
			},
		);
	};

	const handleNewEmailResendCode = () => {
		handleNewEmailSubmit();
	};

	return (
		<>
			{recoveryInformation.newEmailVaildated ? (
				<UTEOTPInput
					headerText={<>Verify&nbsp;your&nbsp;email</>}
					subHeaderText={
						<>
							A One-Time Access Code has been sent to
							<br />
							your email address; it{' '}
						</>
					}
					expiryText='expires in 20 minutes.'
					codeNotReceivedText='If you haven’t received the code yet, please check your spam/junk folder.'
					resendCodeText='Resend Code'
					otpSubmitText='Confirm'
					otpLength={8}
					otp={otp}
					setOTP={setOTP}
					otpValidity={otpValidity}
					setOTPValidity={setOTPValidity}
					handleOTPSubmit={handleNewEmailOTPSubmit}
					handleResendCode={handleNewEmailResendCode}
				/>
			) : recoveryInformation.recoveryOTPValidated ? (
				<EnterNewEmailId
					existingEmail={existingEmail}
					newEmail={newEmail}
					setNewEmail={setNewEmail}
					newEmailError={newEmailError}
					setNewEmailError={setNewEmailError}
					handleNewEmailSubmit={handleNewEmailSubmit}
					actionInProgress={actionInProgress}
				/>
			) : recoveryInformation.recoveryMatched ? (
				<UTEOTPInput
					headerText={<>Verify&nbsp;your&nbsp;identity</>}
					subHeaderText={
						<>
							A One-Time Access Code has been sent to your recovery
							<br />
							{recoveryType === 'Email' ? 'email address' : 'phone number'}; it{' '}
						</>
					}
					expiryText='expires in 20 minutes.'
					codeNotReceivedText='If you haven’t received the code yet, please check your spam/junk folder.'
					resendCodeText='Resend Code'
					otpSubmitText='Confirm'
					otpLength={recoveryType === 'Email' ? 8 : 6}
					otp={otp}
					setOTP={setOTP}
					otpValidity={otpValidity}
					setOTPValidity={setOTPValidity}
					handleOTPSubmit={handleRecoveryOTPSubmit}
					handleResendCode={handleRecoveryResendCode}
				/>
			) : (
				<EnterRecoveryInformation
					recoveryType={recoveryType}
					setRecoveryType={setRecoveryType}
					recoveryEmail={recoveryEmail}
					setRecoveryEmail={setRecoveryEmail}
					existingEmail={existingEmail}
					setExistingEmail={setExistingEmail}
					recoveryCountryCode={recoveryCountryCode}
					setRecoveryCountryCode={setRecoveryCountryCode}
					recoveryMobileNumber={recoveryMobileNumber}
					setRecoveryMobileNumber={setRecoveryMobileNumber}
					onMobileChange={onMobileChange}
					handleRecoveryInfoSubmit={handleRecoveryInfoSubmit}
					actionInProgress={actionInProgress}
				/>
			)}
		</>
	);
};

export default ResetEmailId;
