import React from 'react';

const PaperClip = () => {
	return (
		<svg
			width='8'
			height='16'
			viewBox='0 0 8 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M0.5 3C0.5 2.33696 0.763392 1.70107 1.23223 1.23223C1.70107 0.763392 2.33696 0.5 3 0.5C3.66304 0.5 4.29893 0.763392 4.76777 1.23223C5.23661 1.70107 5.5 2.33696 5.5 3V12C5.5 12.3978 5.34196 12.7794 5.06066 13.0607C4.77936 13.342 4.39782 13.5 4 13.5C3.60218 13.5 3.22064 13.342 2.93934 13.0607C2.65804 12.7794 2.5 12.3978 2.5 12V5C2.5 4.86739 2.55268 4.74021 2.64645 4.64645C2.74021 4.55268 2.86739 4.5 3 4.5C3.13261 4.5 3.25979 4.55268 3.35355 4.64645C3.44732 4.74021 3.5 4.86739 3.5 5V12C3.5 12.1326 3.55268 12.2598 3.64645 12.3536C3.74021 12.4473 3.86739 12.5 4 12.5C4.13261 12.5 4.25979 12.4473 4.35355 12.3536C4.44732 12.2598 4.5 12.1326 4.5 12V3C4.5 2.80302 4.4612 2.60796 4.38582 2.42597C4.31044 2.24399 4.19995 2.07863 4.06066 1.93934C3.92137 1.80005 3.75601 1.68956 3.57403 1.61418C3.39204 1.5388 3.19698 1.5 3 1.5C2.80302 1.5 2.60796 1.5388 2.42597 1.61418C2.24399 1.68956 2.07863 1.80005 1.93934 1.93934C1.80005 2.07863 1.68956 2.24399 1.61418 2.42597C1.5388 2.60796 1.5 2.80302 1.5 3V12C1.5 12.663 1.76339 13.2989 2.23223 13.7678C2.70107 14.2366 3.33696 14.5 4 14.5C4.66304 14.5 5.29893 14.2366 5.76777 13.7678C6.23661 13.2989 6.5 12.663 6.5 12V5C6.5 4.86739 6.55268 4.74021 6.64645 4.64645C6.74021 4.55268 6.86739 4.5 7 4.5C7.13261 4.5 7.25979 4.55268 7.35355 4.64645C7.44732 4.74021 7.5 4.86739 7.5 5V12C7.5 12.9283 7.13125 13.8185 6.47487 14.4749C5.8185 15.1313 4.92826 15.5 4 15.5C3.07174 15.5 2.1815 15.1313 1.52513 14.4749C0.868749 13.8185 0.5 12.9283 0.5 12V3Z'
				fill='#05386B'
			/>
		</svg>
	);
};

export default PaperClip;
