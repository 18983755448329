import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from './delete-email.module.scss';
import UTEButton from 'src/components/ute-button';

type DeleteEmailProps = {
	emailId: string;
	hide: () => void;
	deleteMergedEmail: () => void;
	deleteButtonDisabled: boolean;
};
const DeleteEmail = ({ emailId, hide, deleteMergedEmail, deleteButtonDisabled }: DeleteEmailProps) => {
	return (
		<>
			<Modal.Body className={styles.bodyStyle}>
				<div className={styles.deleteMessage}>
					Are you sure you would like to delete the following email?
					<br />
					<span>{emailId}</span>
				</div>
			</Modal.Body>
			<Modal.Footer className={styles.footerStyle}>
				<UTEButton
					text='No'
					className={styles.noButton}
					onClick={hide}
					disabled={deleteButtonDisabled}
					dataTestAuto='50478e80-7f24-4e5f-9a3f-41ac75b92225'
				/>
				<UTEButton
					text='Yes'
					className={styles.yesButton}
					onClick={deleteMergedEmail}
					disabled={deleteButtonDisabled}
					dataTestAuto='6d31839b-1805-4d39-86dd-5c2bb6963907'
				/>
			</Modal.Footer>
		</>
	);
};

export default DeleteEmail;
