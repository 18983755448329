import React from 'react';

interface ISvgProps {
	className?: string;
	fill?: string;
	onClick?: () => void;
}

const CloseIcon: React.FC<ISvgProps> = ({ fill = '#565A5E', onClick, className }) => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			onClick={onClick}
			className={className}>
			<path
				d='M0.292786 0.292166C0.480314 0.104695 0.734622 -0.000620803 0.999786 -0.000620803C1.26495 -0.000620803 1.51926 0.104695 1.70679 0.292166L7.99979 6.58517L14.2928 0.292166C14.385 0.196655 14.4954 0.120473 14.6174 0.0680643C14.7394 0.0156552 14.8706 -0.011931 15.0034 -0.0130848C15.1362 -0.0142386 15.2678 0.0110631 15.3907 0.061344C15.5136 0.111625 15.6253 0.185878 15.7192 0.279771C15.8131 0.373663 15.8873 0.485315 15.9376 0.608212C15.9879 0.731108 16.0132 0.862788 16.012 0.995567C16.0109 1.12835 15.9833 1.25957 15.9309 1.38157C15.8785 1.50357 15.8023 1.61392 15.7068 1.70617L9.41379 7.99917L15.7068 14.2922C15.8889 14.4808 15.9897 14.7334 15.9875 14.9956C15.9852 15.2578 15.88 15.5086 15.6946 15.694C15.5092 15.8794 15.2584 15.9846 14.9962 15.9868C14.734 15.9891 14.4814 15.8883 14.2928 15.7062L7.99979 9.41317L1.70679 15.7062C1.51818 15.8883 1.26558 15.9891 1.00339 15.9868C0.741189 15.9846 0.490376 15.8794 0.304968 15.694C0.11956 15.5086 0.0143908 15.2578 0.0121124 14.9956C0.00983399 14.7334 0.110628 14.4808 0.292786 14.2922L6.58579 7.99917L0.292786 1.70617C0.105315 1.51864 0 1.26433 0 0.999166C0 0.734001 0.105315 0.479693 0.292786 0.292166Z'
				fill={fill}
			/>
		</svg>
	);
};

export default CloseIcon;
