import { EngagementTypes } from 'src/modules/common/common-types';

export type ISSRDocumentPreview = {
	documentInfo: ISSRDocumentBasicInfo;
	fileGroups: ISSRDocumentPreviewFileGroup[];
};

export type ISSRDocumentBasicInfo = {
	documentId: number;
	documentGuid: string;
	taxYear: number;
	companyId: number;
	clientId: string;
	isSigned: boolean;
	clientName: string;
	engagementType: EngagementTypes;
};

export type ISSRDocumentPreviewFileGroup = {
	groupName: string;
	files: ISSRDocumentPreviewFile[];
};

export type ISSRDocumentPreviewFile = {
	fileName: string;
	friendlyFileName: string;
	groupId: DocumentGroupType;
	sasUrl: string;
	fileExtension: string;
};

export type K1ZipDownloadRequest = {
	documentGuid: string;
	taxYear: number;
	companyId: number;
	clientName: string;
	engagementType: EngagementTypes;
};

export enum DocumentGroupType {
	None = 0,
	Transmittals = 1,
	EFile = 2,
	Vouchers = 3,
	TaxReturns = 4,
	Invoice = 5,
	Deleted = 6,
	K1 = 7,
	PaperFile = 100,
	AdditionalEsignDocuments = 101,
	Attachments = 102,
}

export const initialDocumentInfo: ISSRDocumentBasicInfo = {
	clientId: '',
	companyId: 0,
	documentGuid: '',
	documentId: 0,
	isSigned: false,
	taxYear: 0,
	clientName: '',
	engagementType: EngagementTypes.None,
};

export const initialSSRDocumentPreview: ISSRDocumentPreview = {
	documentInfo: initialDocumentInfo,
	fileGroups: [],
};

export const initialSSRDocumentPreviewFile: ISSRDocumentPreviewFile = {
	fileName: '',
	friendlyFileName: '',
	groupId: DocumentGroupType.None,
	sasUrl: '',
	fileExtension: '',
};
