import React from 'react';
import styles from './ute-breadcrumb.module.scss';
import RightArrow from 'src/assets/svg/right-arrow';

type UTEBreadcrumbProps = {
	path: string | undefined;
	onClickLevel: (levelIds: string) => void;
};

const UTEBreadcrumb = ({ path, onClickLevel }: UTEBreadcrumbProps) => {
	const pathArray = path?.split(',');

	const handleLevelClick = (levelName: string) => {
		const index = pathArray?.indexOf(levelName);
		const levelsArray = pathArray?.slice(0, index + 1);
		onClickLevel(levelsArray?.join(':::'));
	};

	return (
		<div className={styles.breadcrumbs}>
			{pathArray?.map((eachLevel: string, index: number) => {
				if (index === pathArray?.length - 1) {
					return (
						<span
							key={eachLevel}
							id={eachLevel}
							className={styles.lastLevel}
							onClick={() => {
								handleLevelClick(eachLevel);
							}}>
							{eachLevel}
						</span>
					);
				} else {
					return (
						<React.Fragment key={eachLevel}>
							<span
								id={eachLevel}
								className={styles.eachLevel}
								onClick={() => {
									handleLevelClick(eachLevel);
								}}>
								{eachLevel}
							</span>
							&nbsp;&nbsp;
							<RightArrow />
							&nbsp;&nbsp;
						</React.Fragment>
					);
				}
			})}
		</div>
	);
};

export default UTEBreadcrumb;
