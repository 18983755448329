import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';
import { ERROR_COMPANY_NAME_LOGO, LOGIN_CONSTANTS } from 'src/helper/constants';
import { fetchCompanyNameAndLogo, getUTECompanySetting } from 'src/modules/common/common-api';
import { ICompanyNameAndLogoResponse, IUTEEnabledResponse } from 'src/modules/common/common-types';
import LoginLayout from 'src/modules/layout/login-layout';
import LoginComponent from 'src/modules/login/login-component';
import { UTENotifier } from 'src/modules/notification/ute-notifier';
import RegisterComponent from 'src/modules/register/register-component';
import ResetPinComponent from '../../modules/reset-pin/reset-pin-component';
import RecoverOrReset from 'src/modules/login/recover-or-reset';
import ResetEmailId from 'src/modules/login/reset-email-id';
import { useNavigate, useSearchParams } from 'react-router-dom';

const Login = () => {
	const { companyId, updateCompanyNameAndLogo, userDetail, sessionTimeInSeconds, isSessionTimedOut } =
		useContext(AuthContext);
	const [component, setComponent] = useState('login');
	const [accountCreatedEmail, setAccountCreatedEmail] = useState('');
	const [resetEmail, setResetEmail] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [params, setParams] = useSearchParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (isSessionTimedOut) {
			UTENotifier.Warning('You have been logged out due to ' + sessionTimeInSeconds / 60 + ' minutes of inactivity.');
		}
	}, [isSessionTimedOut]);

	useEffect(() => {
		const route = params.get('route');
		if (route === 'reset-pin') {
			setComponent('reset-pin');
			params.delete('route');
			setParams(params);
		}
	}, []);

	useEffect(() => {
		getUTECompanySetting(companyId, (uteEnabledCompanySetting: IUTEEnabledResponse) => {
			if (!uteEnabledCompanySetting.uteEnabled) {
				navigate('/disabled');
			} else if (uteEnabledCompanySetting.error) {
				UTENotifier.Error(LOGIN_CONSTANTS.ERROR_FETCH_COMPANY_SETTING);
			}
		});

		fetchCompanyNameAndLogo(Number(companyId), (companyNameAndLogoResponse: ICompanyNameAndLogoResponse) => {
			if (companyNameAndLogoResponse.error) {
				UTENotifier.Error(ERROR_COMPANY_NAME_LOGO);
			} else {
				updateCompanyNameAndLogo(companyNameAndLogoResponse.companyNameAndLogo);
				document.title = companyNameAndLogoResponse.companyNameAndLogo.name + ' : ssportal.com';
				setCompanyName(companyNameAndLogoResponse.companyNameAndLogo.name);
			}
		});
	}, []);

	return (
		<LoginLayout>
			{component === 'login' && (
				<LoginComponent
					setComponent={setComponent}
					accountCreatedEmail={accountCreatedEmail}
					resetEmail={resetEmail}
					loggedOutEmail={userDetail.emailAddress}
				/>
			)}
			{component === 'register' && (
				<RegisterComponent
					setComponent={setComponent}
					setAccountCreatedEmail={setAccountCreatedEmail}
				/>
			)}
			{component === 'reset-pin' && (
				<ResetPinComponent
					setComponent={setComponent}
					setResetEmail={setResetEmail}
				/>
			)}
			{component === 'recover-or-reset' && (
				<RecoverOrReset
					copmanyName={companyName}
					setComponent={setComponent}
				/>
			)}
			{component === 'reset-email-id' && <ResetEmailId setComponent={setComponent} />}
		</LoginLayout>
	);
};

export default Login;
