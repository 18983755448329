import React from 'react';

type SVGProps = {
	color?: string;
};

const PayIcon = ({ color='#05386B' }: SVGProps) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'>
			<g clip-path='url(#clip0_7566_4059)'>
				<rect
					width='16'
					height='16'
					fill='white'
					fill-opacity='0.01'
				/>
				<g clip-path='url(#clip1_7566_4059)'>
					<path
						d='M11 5.49927C11 5.36666 11.0527 5.23948 11.1464 5.14571C11.2402 5.05195 11.3674 4.99927 11.5 4.99927H13.5C13.6326 4.99927 13.7598 5.05195 13.8536 5.14571C13.9473 5.23948 14 5.36666 14 5.49927V6.49927C14 6.63188 13.9473 6.75905 13.8536 6.85282C13.7598 6.94659 13.6326 6.99927 13.5 6.99927H11.5C11.3674 6.99927 11.2402 6.94659 11.1464 6.85282C11.0527 6.75905 11 6.63188 11 6.49927V5.49927Z'
						fill={color}
					/>
					<path
						d='M2 1.99927C1.46957 1.99927 0.960859 2.20998 0.585786 2.58505C0.210714 2.96013 0 3.46883 0 3.99927V11.9993C0 12.5297 0.210714 13.0384 0.585786 13.4135C0.960859 13.7886 1.46957 13.9993 2 13.9993H14C14.5304 13.9993 15.0391 13.7886 15.4142 13.4135C15.7893 13.0384 16 12.5297 16 11.9993V3.99927C16 3.46883 15.7893 2.96013 15.4142 2.58505C15.0391 2.20998 14.5304 1.99927 14 1.99927H2ZM15 3.99927V8.99927H1V3.99927C1 3.73405 1.10536 3.4797 1.29289 3.29216C1.48043 3.10462 1.73478 2.99927 2 2.99927H14C14.2652 2.99927 14.5196 3.10462 14.7071 3.29216C14.8946 3.4797 15 3.73405 15 3.99927ZM14 12.9993H2C1.73478 12.9993 1.48043 12.8939 1.29289 12.7064C1.10536 12.5188 1 12.2645 1 11.9993V10.9993H15V11.9993C15 12.2645 14.8946 12.5188 14.7071 12.7064C14.5196 12.8939 14.2652 12.9993 14 12.9993Z'
						fill={color}
					/>
				</g>
			</g>
			<defs>
				<clipPath id='clip0_7566_4059'>
					<rect
						width='16'
						height='16'
						fill={color}
					/>
				</clipPath>
				<clipPath id='clip1_7566_4059'>
					<rect
						width='16'
						height='16'
						fill={color}
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
export default PayIcon;
