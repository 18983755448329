import { PdfSource } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import { useState, useEffect, useContext } from 'react';
import PdfPreview from 'src/components/pdf-preview';
import { IFileGroup } from 'src/components/pdf-preview/left-panel/left-panel-types';
import { DisplayDownloadFile } from 'src/helper/display-download-file';
import { IGatherDocumentModel, IOrganizerDocumentModel } from 'src/modules/documents/documents-types';
import LoadingOverlay from 'react-loading-overlay';
import { getFileExtension } from 'src/helper/utils';
import DocViewer, {
	BMPRenderer,
	IHeaderOverride,
	JPGRenderer,
	MSDocRenderer,
	PNGRenderer,
} from '@cyntler/react-doc-viewer';
import { DownloadIcon } from 'src/assets/svg/download-icon';
import UTEButton from 'src/components/ute-button';
import { IFileName } from 'src/modules/recent-activity/recent-activity-types';
import {
	getGTRDocumentPreview,
	getGTRSourceDocumentPreview,
	getORGDocumentPreview,
	getORGSourceDocumentPreview,
} from './gtr-org-document-preview.apis';
import { ClientHubEvents, IClientTracking, ProductType } from 'src/modules/common/common-types';
import { Preview_SupportedFileExtensions } from 'src/helper/constants';
import UTEClientViewWrapper from 'src/components/ute-client-view-wrapper';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';
import { saveClientTrackingEvent } from 'src/modules/common/common-api';

type GTRDocumentPreviewProps = {
	fileData: IFileName;
	productType: ProductType;
};

const GTRDocumentPreview = ({ fileData, productType }: GTRDocumentPreviewProps) => {
	const [documentUrl, setDocumentUrl] = useState<string>('');
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (productType === ProductType.Gather) {
			fetchDocument();
		} else if (productType === ProductType.Organizers) {
			fetchOrganizerDocument();
		}
	}, []);

	const fetchDocument = () => {
		setIsLoading(true);

		const isSourceDocument = !fileData.gatherFileData?.gatherDocumentType;
		if (isSourceDocument) {
			var document = {
				gatherId: fileData.documentId,
				taxYear: fileData.gatherFileData?.taxYear ?? 0,
				fileName: fileData.gatherFileData?.fileName,
				sourceDocumentId: fileData.gatherFileData?.sourceDocumentId,
				sourceDocumentFileName: fileData.fileName,
				clientId: fileData.gatherFileData?.clientId,
			} as IGatherDocumentModel;

			getGTRSourceDocumentPreview(
				document,
				(data) => {
					setDocumentUrl(data.sasUrl);
					setIsLoading(false);
				},
				() => {
					setIsLoading(false);
				},
			);
		} else {
			var document = {
				gatherId: fileData.documentId,
				taxYear: fileData.gatherFileData?.taxYear ?? 0,
				fileName: fileData.gatherFileData?.fileName,
				sourceDocumentId: fileData.gatherFileData?.sourceDocumentId,
				sourceDocumentFileName: fileData.fileName,
				clientId: fileData.gatherFileData?.clientId,
				fileGuid: fileData.gatherFileData?.fileGuid,
				documentType: fileData.gatherFileData?.gatherDocumentType,
			} as IGatherDocumentModel;

			getGTRDocumentPreview(
				document,
				(data) => {
					setDocumentUrl(data.sasUrl);
					setIsLoading(false);
				},
				() => {
					setIsLoading(false);
				},
			);
		}
	};

	const fetchOrganizerDocument = () => {
		setIsLoading(true);

		const isSourceDocument =
			!fileData.gatherFileData?.gatherDocumentType &&
			fileData.gatherFileData?.sourceDocumentId &&
			fileData.gatherFileData?.sourceDocumentId > 0;
		if (isSourceDocument) {
			var document = {
				documentGuid: fileData.fileGuid,
				sourceDocumentFileName: fileData.fileName,
				taxYear: fileData.gatherFileData?.taxYear ?? 0,
				fileName: fileData.gatherFileData?.fileName,
			} as IOrganizerDocumentModel;

			getORGSourceDocumentPreview(
				document,
				(url) => {
					setDocumentUrl(url);
					setIsLoading(false);
				},
				() => {
					setIsLoading(false);
				},
			);
		} else {
			const document = {
				documentId: fileData.documentId,
				taxYear: fileData.gatherFileData?.taxYear ?? 0,
				fileName: fileData.gatherFileData?.fileName,
				clientId: fileData.gatherFileData?.clientId,
				fileGuid: fileData.gatherFileData?.fileGuid,
				documentType: fileData.gatherFileData?.gatherDocumentType,
				documentGuid: fileData.fileGuid,
			} as IOrganizerDocumentModel;

			getORGDocumentPreview(
				document,
				(url) => {
					setDocumentUrl(url);
					setIsLoading(false);
				},
				() => {
					setIsLoading(false);
				},
			);
		}
	};
	const convertToPdfViewerModel = () => {
		let fileGroups: IFileGroup[] = [];
		let group: IFileGroup = {} as IFileGroup;
		group.name = 'Document';
		group.files = [];
		group.files.push({
			data: fileData,
			name: fileData.fileName,
			url: documentUrl,
		});
		fileGroups.push(group);
		return fileGroups;
	};

	const onFileDownload = () => {
		let displayDownloadFile = new DisplayDownloadFile();
		displayDownloadFile.directDownload(documentUrl);
	};

	const isSupportedFile = (fileName: string): boolean => {
		const ext = getFileExtension(fileName);
		return Preview_SupportedFileExtensions.includes(ext);
	};

	const NoRenderer = ({ }: any) => {
		return <div className='p-3'>No Renderer for: {getFileExtension(fileData.fileName)}</div>;
	};

	return (
		<>
			{
				<LoadingOverlay
					active={isLoading}
					spinner={''}
					text=''>
					{isSupportedFile(fileData.fileName) ? documentUrl !== '' && (
						<DocViewer
							pluginRenderers={[MSDocRenderer, JPGRenderer, PNGRenderer, BMPRenderer]}
							documents={[
								{
									uri: documentUrl,
									fileName: fileData.fileName,
									fileType: getFileExtension(fileData.fileName),
								},
							]}
							config={{
								header: {
									overrideComponent: DownloadComponent,
								},
								noRenderer: {
									overrideComponent: NoRenderer,
								},
							}}
							style={{ height: 500 }}
						/>
					) : (
						<PdfPreview
							pdfSource={PdfSource.createFromUrl(documentUrl)}
							fileGroups={convertToPdfViewerModel()}
							onFileSelect={() => { }}
							onFileDownload={onFileDownload}
							tooltip='Download'
							hideLeftPanel={true}
							fileExtension={`.${getFileExtension(fileData.fileName)}`}
						/>
					)}
				</LoadingOverlay>
			}
		</>
	);
};

export const DownloadComponent: IHeaderOverride = (state, previousDocument, nextDocument) => {
	const { userDetail } = useContext(AuthContext);
	if (!state.currentDocument || state.config?.header?.disableFileName) {
		return null;
	}
	const onFileDownload = () => {
		let displayDownloadFile = new DisplayDownloadFile();
		displayDownloadFile.directDownloadWithFileName(state.currentDocument?.uri || '', state.currentDocument?.fileName || '');
		let clientTracking: IClientTracking = {
			eventId: ClientHubEvents.DocumentDownloadedFromView,
			eventData: {
				emailId: userDetail.emailAddress,
				fileName: state.currentDocument?.fileName || '',
				newEmailId: '',
				oldEmailId: '',
			},
			actedBy: userDetail.emailAddress,
			registrationId: userDetail.id,
		};
		saveClientTrackingEvent(clientTracking);
	};
	return (
		<>
			{state.currentDocument.uri && (
				<UTEClientViewWrapper dataTestAuto='F48A59BC-957B-4DB5-8E8B-08071C2A207F'>
					<div
						className='sse-doc-viewer'
						style={{ margin: '12px' }}>
						<UTEButton
							className='download-btn'
							dataTestAuto='F48A59BC-957B-4DB5-8E8B-08071C2A207F'
							disabled={false}
							text='Download'
							title='Download'
							icon={<DownloadIcon></DownloadIcon>}
							onClick={() => {
								onFileDownload();
							}}></UTEButton>
					</div>
				</UTEClientViewWrapper>
			)}
		</>
	);
};
export default GTRDocumentPreview;
