import { Modal } from 'react-bootstrap';
import './cpa-message.scss';
import UTEButton from 'src/components/ute-button';
import { Markup } from 'interweave';

type IMessageModalProps = {
	show: boolean;
	onModalClose: () => void;
	message: string;
};

export const CpaMessageModal = ({ show, onModalClose, message }: IMessageModalProps) => {
	return (
		<>
			{show && (
				<>
					<div className='message-container'>
						<Modal.Body className='message-content'>
							<div className='message'>
								<Markup content={message} />
							</div>
						</Modal.Body>
						<Modal.Footer className='modal-footer'>
							<UTEButton
								disabled={false}
								className='ok-button'
								text='OK'
								onClick={onModalClose}
								dataTestAuto='bfaa4066-94aa-4de8-85ae-faa129de26c4'
							/>
						</Modal.Footer>
					</div>
				</>
			)}
		</>
	);
};
