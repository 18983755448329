import React from 'react';

interface ISvgProps {
	width?: string;
	height?: string;
	className?: string;
	onClick?: () => void;
}

const ClockSmallIcon: React.FC<ISvgProps> = ({ className }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='14'
			height='14'
			fill='none'
			viewBox='0 0 14.3 14.1'
			className={className}>
			<g
				fill='#565A5E'
				clipPath='url(#clip0_3780_21478)'>
				<path d='M7 3.063a.437.437 0 10-.875 0v4.812a.437.437 0 00.22.38l3.063 1.75a.438.438 0 00.434-.76L7 7.621V3.062z'></path>
				<path d='M7 14A7 7 0 107 0a7 7 0 000 14zm6.125-7A6.125 6.125 0 11.875 7a6.125 6.125 0 0112.25 0z'></path>
			</g>
			<defs>
				<clipPath id='clip0_3780_21478'>
					<path
						fill='#fff'
						d='M0 0H14V14H0z'></path>
				</clipPath>
			</defs>
		</svg>
	);
};

export default ClockSmallIcon;
