import React from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import ClipboardIcon from 'src/assets/svg/clipboard-icon';
import ClockSmallIcon from 'src/assets/svg/clock-small-icon';
import CloseIcon from 'src/assets/svg/close-icon';
import './notification-popover.style.scss';
import {
	INotification,
	INotificationResponse,
	NotificationEventType,
	NotificationMailEventType,
	INotificationViewModel,
} from 'src/context/ute-notification-context/notification-provider-types';
import {
	NOTIFICATION_CONSTANTS,
	NOTIFICATION_AND_REMINDER_CONSTANTS,
	EXTENSION_NOTIFICATION_AND_REMINDER_CONSTANTS,
	SIGNATURE_NOTIFICATION_AND_REMINDER_CONSTANTS,
	GATHER_NOTIFICATION_AND_REMINDER_CONSTANTS,
	ORGANIZER_NOTIFICATION_AND_REMINDER_CONSTANTS,
	EXCHANGE_NOTIFICATION_AND_REMINDER_CONSTANTS,
	EXCHANGE_NOTIFICATION_PLACEHOLDER_CONSTANTS,
} from 'src/helper/constants';
import BellIcon from 'src/assets/svg/bell-icon';
import { STRING_EMPTY, getDuration } from 'src/helper/utils';
import {
	updateNotificationInfo,
	clearNotifications,
	getClientGuId,
} from 'src/modules/user-notifications/user-notification-api';
import { UTENotifier } from 'src/modules/notification/ute-notifier';
import BellSleepIcon from 'src/assets/svg/bell-sleep-icon';
import { useNavigate } from 'react-router-dom';
import {
	generateExchangeTaxpayerAPIURL,
	generateExtTaxpayerRedirectionAPIURL,
	generateSignatureTaxpayerAPIURL,
	generateTaxpayerRedirectionAPIURL,
} from '../../common/common-api';
import {
	ClientTypes,
	ProductType,
	SignatureRedirectionSourcePage,
	TaxpayerRedirectionSourcePage,
} from 'src/modules/common/common-types';
import UTEClientViewWrapper from 'src/components/ute-client-view-wrapper';
import { getFormattedDateFromString } from 'src/modules/documents/documents-utility-functions';
interface INotificationPopoverProps {
	target: React.RefObject<any>;
	showNotification: boolean;
	setShowNotification: (showNotification: boolean) => void;
	notifications: INotification[];
	setNotifications: (notifications: INotification[]) => void;
	registrationId: number;
}

const NotificationPopover: React.FC<INotificationPopoverProps> = ({
	target,
	showNotification,
	setShowNotification,
	notifications,
	setNotifications,
	registrationId,
}) => {
	const navigate = useNavigate();

	const getNotificationText = (
		productType: ProductType,
		notificationType: NotificationMailEventType,
		metaData: string,
	): string => {
		let noteText: string = '';
		switch (productType) {
			case ProductType.Returns:
				if (metaData) {
					let note = NOTIFICATION_AND_REMINDER_CONSTANTS[notificationType];
					const metaDataArray = metaData.split(';');
					metaDataArray.forEach((metaDataItem) => {
						if (metaDataItem.trim() !== '') {
							const [key, value] = metaDataItem.split('=');
							note = note.replace(key, value);
						}
					});
					noteText = note;
				} else {
					noteText = NOTIFICATION_AND_REMINDER_CONSTANTS[notificationType];
				}
				break;

			case ProductType.Extensions:
				if (metaData) {
					let note = EXTENSION_NOTIFICATION_AND_REMINDER_CONSTANTS[notificationType];
					const metaDataArray = metaData.split(';');
					metaDataArray.forEach((metaDataItem) => {
						if (metaDataItem.trim() !== '') {
							const [key, value] = metaDataItem.split('=');
							note = note.replace(key, value);
						}
					});
					noteText = note;
				} else {
					noteText = EXTENSION_NOTIFICATION_AND_REMINDER_CONSTANTS[notificationType];
				}
				break;
			case ProductType.SignaturesOneOff:
				if (metaData) {
					let note = SIGNATURE_NOTIFICATION_AND_REMINDER_CONSTANTS[notificationType];
					const metaDataArray = metaData.split(';');
					metaDataArray.forEach((metaDataItem) => {
						if (metaDataItem.trim() !== '') {
							const [key, value] = metaDataItem.split('=');
							note = note.replace(key, value);
						}
					});
					noteText = note;
				} else {
					noteText = SIGNATURE_NOTIFICATION_AND_REMINDER_CONSTANTS[notificationType];
				}
				break;
			case ProductType.Gather:
				if (metaData) {
					let note = GATHER_NOTIFICATION_AND_REMINDER_CONSTANTS[notificationType];
					const metaDataArray = metaData.split(';');
					metaDataArray.forEach((metaDataItem) => {
						if (metaDataItem.trim() !== '') {
							const [key, value] = metaDataItem.split('=');
							note = note.replace(key, value);
						}
					});
					noteText = note;
				} else {
					noteText = GATHER_NOTIFICATION_AND_REMINDER_CONSTANTS[notificationType];
				}
				break;
			case ProductType.Organizers:
				if (metaData) {
					let note = ORGANIZER_NOTIFICATION_AND_REMINDER_CONSTANTS[notificationType];
					const metaDataArray = metaData.split(';');
					metaDataArray.forEach((metaDataItem) => {
						if (metaDataItem.trim() !== '') {
							const [key, value] = metaDataItem.split('=');
							note = note.replace(key, value);
						}
					});
					noteText = note;
				} else {
					noteText = ORGANIZER_NOTIFICATION_AND_REMINDER_CONSTANTS[notificationType];
				}
				break;
			case ProductType.Exchange:
				if (metaData) {
					let note = EXCHANGE_NOTIFICATION_AND_REMINDER_CONSTANTS[notificationType];
					const metaDataArray = metaData.split(';');
					metaDataArray.forEach((metaDataItem) => {
						if (metaDataItem.trim() !== '') {
							let [key, value] = metaDataItem.split('=');
							if (key === EXCHANGE_NOTIFICATION_PLACEHOLDER_CONSTANTS.SENTDATE) {
								value = getFormattedDateFromString(value);
							}
							note = note.replace(key, value);
						}
					});
					noteText = note;
				} else {
					noteText = EXCHANGE_NOTIFICATION_AND_REMINDER_CONSTANTS[notificationType];
				}
		}
		return noteText;
	};

	const clearNotificationAndReminders = () => {
		clearNotifications((notificationsResponse: INotificationResponse) => {
			if (notificationsResponse.error) {
				UTENotifier.Error(NOTIFICATION_CONSTANTS.ERROR_CLEAR_NOTIFICATIONS);
			} else {
				setNotifications(notificationsResponse.notifications);
			}
		});
	};

	const markNotificationReadStatus = (notification: INotification) => {
		updateNotificationInfo(
			notification.id,
			() => {
				let notificationNew = [...notifications];
				notificationNew.forEach((element) => {
					if (element.id === notification.id) {
						element.isAccessed = true;
					}
				});
				setNotifications(notificationNew);
			},
			() => {
				UTENotifier.Error(NOTIFICATION_CONSTANTS.ERROR_UPDATE_NOTIFICATIONS);
			},
		);
	};

	const onNotificationSelection = (notification: INotification) => {
		!notification.isAccessed && markNotificationReadStatus(notification);
		switch (notification.notificationType) {
			case NotificationMailEventType.InitialEmail:
			case NotificationMailEventType.SigningReminderEmail:
			case NotificationMailEventType.SignatureDelegateeEmail:
			case NotificationMailEventType.DelegateeDeclinedEmail:
			case NotificationMailEventType.SignatureDeclinedEmail:
			case NotificationMailEventType.SpouseReviewMail:
			case NotificationMailEventType.SourceDocumentUploadReopened:
			case NotificationMailEventType.OrganizerReminderEmail:
			case NotificationMailEventType.SourceDocumentReminderMail:
			case NotificationMailEventType.ReopenedGather:
			case NotificationMailEventType.ReopenedOrganizer:
			case NotificationMailEventType.DraftInitialEmail:
			case NotificationMailEventType.InitialDocumentRequestEmail:
			case NotificationMailEventType.DocumentRequestReminder:
			case NotificationMailEventType.DocumentRequestDocumentReminder:
			case NotificationMailEventType.DocumentRequestMore:
			case NotificationMailEventType.DocumentRequestReopen:
				getClientGuId(
					notification.id,
					(notificationModel: INotificationViewModel) => {
						if (
							notificationModel.clientGuid != STRING_EMPTY ||
							notificationModel.clientGuid != null ||
							notificationModel.clientGuid != undefined
						) {
							switch (notification.productId) {
								case ProductType.Returns: {
									notificationModel.clientType == ClientTypes.Delegatee
										? generateTaxpayerRedirectionAPIURL(
												notificationModel.clientGuid,
												ProductType.Returns,
												TaxpayerRedirectionSourcePage.DelegateeInitialize,
												ClientTypes.Delegatee,
										  )
										: generateTaxpayerRedirectionAPIURL(notificationModel.clientGuid, ProductType.Returns);
									break;
								}
								case ProductType.Extensions: {
									notificationModel.clientType == ClientTypes.Delegatee
										? generateExtTaxpayerRedirectionAPIURL(
												notificationModel.clientGuid,
												TaxpayerRedirectionSourcePage.DelegateeInitialize,
												ClientTypes.Delegatee,
										  )
										: generateExtTaxpayerRedirectionAPIURL(notificationModel.clientGuid);
									break;
								}
								case ProductType.SignaturesOneOff: {
									notification.notificationType == NotificationMailEventType.SignatureDeclinedEmail ||
									notification.notificationType == NotificationMailEventType.DelegateeDeclinedEmail
										? generateSignatureTaxpayerAPIURL(
												notificationModel.clientGuid,
												SignatureRedirectionSourcePage.Declined,
										  )
										: generateSignatureTaxpayerAPIURL(notificationModel.clientGuid);
									break;
								}
								case ProductType.Gather: {
									generateTaxpayerRedirectionAPIURL(notificationModel.clientGuid, ProductType.Gather);
									break;
								}
								case ProductType.Organizers: {
									generateTaxpayerRedirectionAPIURL(notificationModel.clientGuid, ProductType.Organizers);
									break;
								}
								case ProductType.Exchange: {
									generateExchangeTaxpayerAPIURL(notificationModel.clientGuid);
									break;
								}
							}
						} else {
							UTENotifier.Error(NOTIFICATION_CONSTANTS.ERROR_REDIRECTION_NOTIFICATIONS);
						}
					},
					() => {
						UTENotifier.Error(NOTIFICATION_CONSTANTS.ERROR_REDIRECTION_NOTIFICATIONS);
					},
				);
				break;
			case NotificationMailEventType.DownloadESignedFormsEmail:
			case NotificationMailEventType.DownloadTaxReturnEmail:
			case NotificationMailEventType.SignatureSignedEmail:
			case NotificationMailEventType.OrganizerCompleted:
			case NotificationMailEventType.SourceDocumentMarkedCompletedToCpa:
				navigate('/documents');
				setShowNotification(false);
				break;
			case NotificationMailEventType.InitialMessageEmail:
			case NotificationMailEventType.MessageExpiryReminder:
				let taxYear = new Date().getFullYear();
				const metaDataArray = notification.metaData.split(';');
				const metaDataItem = metaDataArray.find((item) => {
					const [key] = item.split('=');
					return key === EXCHANGE_NOTIFICATION_PLACEHOLDER_CONSTANTS.SENTDATE;
				});
				if (metaDataItem) {
					const [key, value] = metaDataItem.split('=');
					taxYear = new Date(value).getFullYear();
				}
				navigate('/documents', { state: { expandLevelIds: `Tax Year - ${taxYear}:::Received` } });
				setShowNotification(false);
				break;
			case NotificationMailEventType.UploadEstimatesEmail:
			case NotificationMailEventType.VoucherReminderEmail:
				navigate('/tax-payments');
				setShowNotification(false);
				break;
			default:
				break;
		}
	};

	return (
		<Overlay
			rootClose={true}
			placement='bottom'
			target={target.current}
			show={showNotification}
			onHide={() => setShowNotification(false)}>
			<Popover
				id='notification-popover'
				className='notificationPopover'>
				<Popover.Header as='h3'>
					<span>Notifications & Reminders</span>
					<CloseIcon
						className='closeIcon'
						fill='#808080'
						onClick={() => setShowNotification(false)}
					/>
				</Popover.Header>
				<Popover.Body>
					<div className='popover-body_topSection'>
						{notifications.length > 0 ? (
							notifications.map((notification: INotification) => {
								const { eventType, notificationType, isAccessed, createdOn, metaData } = notification;

								return (
									<UTEClientViewWrapper dataTestAuto='7ab7c989-380e-48b0-b60a-412ebf43bd1e'>
										<div
											className={`notificationContainer ${isAccessed ? 'read' : 'unread'}`}
											onClick={() => {
												(notification.productId == ProductType.Returns ||
													notification.productId == ProductType.Extensions ||
													notification.productId == ProductType.SignaturesOneOff ||
													notification.productId == ProductType.Gather ||
													notification.productId == ProductType.Organizers ||
													notification.productId == ProductType.Exchange) &&
													onNotificationSelection(notification);
											}}>
											<div
												className={`notificationContainer_leftSection ${
													eventType === NotificationEventType.Notification ? 'clipboardIcon' : 'bellIcon'
												}`}>
												{eventType === NotificationEventType.Notification ? <ClipboardIcon /> : <BellIcon />}
											</div>
											<div className='notificationContainer_rightSection'>
												<div className='notificationContainer_rightSection--text'>
													{getNotificationText(notification.productId, notificationType, metaData)}
												</div>
												<div className='flex-align-center'>
													<ClockSmallIcon className='notificationContainer_rightSection--clockIcon' />
													<span className='notificationContainer_rightSection--time'>{getDuration(createdOn)}</span>
												</div>
											</div>
										</div>
									</UTEClientViewWrapper>
								);
							})
						) : (
							<div className='emptyNotification'>
								<BellSleepIcon />
								<div className='emptyNotification_headerText'>No Notifications</div>
								<div className='emptyNotification_horizontalLine'></div>
								<div className='emptyNotification_message'>
									We will notify you when there
									<br /> is something new.
								</div>
							</div>
						)}
					</div>
					{notifications.length > 0 ? (
						<UTEClientViewWrapper dataTestAuto='3be8865a-62bf-442d-9ddf-02fdb5e2b914'>
							<div
								className='popover-body_bottomSection'
								onClick={clearNotificationAndReminders}>
								Clear All
							</div>
						</UTEClientViewWrapper>
					) : (
						<></>
					)}
				</Popover.Body>
			</Popover>
		</Overlay>
	);
};

export default NotificationPopover;
