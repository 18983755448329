import { ClientTypes, ProductType } from '../common/common-types';
import { ExchangeFileType, DocumentType } from '../documents/documents-types';

export type IFileName = {
	documentId: number;
	fileName: string;
	signerType: ClientTypes;
	gatherFileData?: IGatherFileData;
	signingId?: number;
	clientGuid?: string;
	fileGuid?: string;
	fileId?: number;
	userName?: string;
	exchangeFileType?: ExchangeFileType;
	exchangeFileData?: IExchangeFileData;
};

export interface IExchangeFileData {
	fileGuid?: string;
	isMessagePresent?: boolean;
	messageGuid?: string;
}

export interface IGatherFileData {
	sourceDocumentId?: number;
	gatherDocumentType?: DocumentType;
	fileName?: string;
	clientId?: string;
	fileGuid?: string;
	taxYear?: number;
}

export enum OneHubEventType {
	None = 0,
	GetStarted = 1,
	Continue,
	View,
	Download,
	Forward,
	MoreActions,
	sendFilesToCPA
}

export type IRecentActivity = {
	registrationId: number;
	eventId: OneHubEventType;
	actedBy: string;
	actedOn: string;
	eventData: IFileName;
	productType: ProductType;
};

export type IRecentActivityResponse = {
	error: boolean;
	recentActivityList: IRecentActivity[];
};

export type IUserActivity = {
	eventId: OneHubEventType;
	eventData: IFileName;
	productType: ProductType;
};
