import React, { useContext } from 'react';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';
import { CLIENT_VIEW_ACTION_NOT_PERMITTED } from 'src/helper/constants';
import { UTENotifier } from 'src/modules/notification/ute-notifier';

type UTEClientViewWrapperProps = {
	children: any;
	dataTestAuto: string;
	elementType?: 'block' | 'inline' | undefined;
};

const UTEClientViewWrapper = ({ children, dataTestAuto, elementType }: UTEClientViewWrapperProps) => {
	const { isClientView } = useContext(AuthContext);

	const handleClick = (event: any) => {
		if (isClientView) {
			UTENotifier.Warning(CLIENT_VIEW_ACTION_NOT_PERMITTED);
			event.stopPropagation();
		}
	};

	const Element = elementType === 'block' ? 'div' : 'span';

	return (
		<Element
			onClickCapture={handleClick}
			data-test-auto={dataTestAuto}>
			{children}
		</Element>
	);
};

export default UTEClientViewWrapper;
