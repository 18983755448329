import React, { useEffect } from 'react';
import styles from './contact-information.module.scss';
import PhoneInput  from '@sssuite-component-ui/react-phone-input-2';
import { DASHBOARD_CONSTANTS } from '../../../helper/constants';
import { UTENotifier } from '../../notification/ute-notifier';
import { getContactInformation } from './contact-information-api';
import { initialContactInformation } from './contact-information-types';

let contactInformation = initialContactInformation;
const ContactInformation = () => {
	useEffect(() => {
		fetchContactInformation();
	}, []);

	const fetchContactInformation = () => {
		getContactInformation((contactInformationResponse: { error: any; contactInformation: any }) => {
			if (contactInformationResponse.error) {
				UTENotifier.Error(DASHBOARD_CONSTANTS.ERROR_FETCH_CONTACT_INFORMATION);
			} else {
				contactInformation = contactInformationResponse.contactInformation;
			}
		});
	};
	return (
		<div>
			<div className={styles.headerContactInformation}>Contact&nbsp;Information</div>
			<div className={styles.body}>
				<div className={styles.bodyTitleContainer}>Name</div>
				<div className={styles.bodyValueContainer}>{contactInformation.name}&nbsp;</div>

				<div className={styles.bodyTitleContainer}>Phone</div>
				<div className={styles.bodyValueContainer}>
					<PhoneInput
						specialLabel={''}
						autoFormat={true}
						disabled={true}
						inputClass={styles.phoneNumberInput}
						placeholder={''}
						buttonClass={styles.countryCodeContainer }
						value={
							contactInformation.mobileNumber === undefined || contactInformation.mobileNumber === ''
								? ' '
								: `${contactInformation.countryCode} ${contactInformation.mobileNumber}`
						}
					/>
				</div>
				<div className={styles.bodyTitleContainer}>Email</div>
				<div className={styles.bodyValueContainer}>{contactInformation.emailAddress}</div>
			</div>
		</div>
	);
};

export default ContactInformation;
