import React, { useEffect, useState } from 'react';
import styles from './reset-pin.module.scss';
import { EyeClose, EyeOpen } from 'src/assets/svg/eye-icons';
import UTEButton from 'src/components/ute-button';
import { isValidNumber } from 'src/helper/utils';

type ResetPINProps = {
	pin: string;
	setPIN: (pin: string) => void;
	handlePINSubmit: () => void;
	isValidResetPin: boolean;
	setIsValidResetPin: (isValidResetPin: boolean) => void;
};
const ResetPIN = ({ pin, setPIN, handlePINSubmit, isValidResetPin, setIsValidResetPin }: ResetPINProps) => {
	const [inputPINType, setInputPINType] = useState('password');
	const [inputConfirmPINType, setInputConfirmPINType] = useState('password');
	const [confirmPIN, setConfirmPIN] = useState('');
	const [isPINDisabled, setIsPINDisabled] = useState(true);
	const [isError, setIsError] = useState(false);

	useEffect(() => {
		if (pin.length < 6 || confirmPIN.length < 6) {
			setIsError(false);
			setIsPINDisabled(true);
		} else {
			setIsPINDisabled(false);
		}
	}, [pin, confirmPIN]);

	const handlePINChange = (pinValue: string) => {
		if (pinValue === '' || isValidNumber(pinValue)) setPIN(pinValue);
	};

	const handleConfirmPINChange = (confirmPINValue: string) => {
		if (confirmPINValue === '' || isValidNumber(confirmPINValue)) setConfirmPIN(confirmPINValue);
	};

	const handlePINEyeClick = () => {
		setInputPINType(inputPINType === 'password' ? 'text' : 'password');
	};

	const handleConfirmPINEyeClick = () => {
		setInputConfirmPINType(inputConfirmPINType === 'password' ? 'text' : 'password');
	};

	const onResetPinContinue = () => {
		if (pin === confirmPIN) {
			setIsError(false);
			setIsPINDisabled(true);
			handlePINSubmit();
		} else {
			setIsError(true);
			setIsValidResetPin(true);
		}
	};

	return (
		<>
			<div className='uteLoginHeader'>Reset&nbsp;your&nbsp;PIN</div>
			<div className='uteLoginSubHeader'>
				Your account has been verified!
				<br />
				Create your new PIN.
			</div>
			<div className='formInputHeader'>New PIN</div>
			<div className={`${styles.pinContainer} ${(isError || !isValidResetPin) && styles.error}`}>
				<input
					type={inputPINType}
					name='pin'
					id='pin'
					className={styles.pin}
					placeholder='New 6-digit PIN'
					value={pin}
					onChange={(e) => {
						handlePINChange(e.target.value);
					}}
					maxLength={6}
					data-test-auto='DCB47F4A-262E-41CF-9AC7-FA3E0F504FD3'
				/>
				<span
					className={styles.eyeContainer}
					onClick={handlePINEyeClick}>
					{inputPINType === 'password' ? <EyeClose /> : <EyeOpen />}
				</span>
			</div>
			<div className={`formInputHeader ${styles.confirmPadding}`}>Confirm PIN</div>
			<div className={`${styles.pinContainer} ${(isError || !isValidResetPin) && styles.error}`}>
				<input
					type={inputConfirmPINType}
					name='confirmPIN'
					id='confirmPIN'
					className={styles.pin}
					placeholder='Confirm 6-digit PIN'
					value={confirmPIN}
					onChange={(e) => {
						handleConfirmPINChange(e.target.value);
					}}
					maxLength={6}
					data-test-auto='38AD24BC-A5E8-416D-8BBF-C61D831D76D3'
				/>
				<span
					className={styles.eyeContainer}
					onClick={handleConfirmPINEyeClick}>
					{inputConfirmPINType === 'password' ? <EyeClose /> : <EyeOpen />}
				</span>
			</div>
			{isError && isValidResetPin && (
				<div className={styles.errorContainer}>PINs do not match. Please ensure they are identical.</div>
			)}
			{!isValidResetPin && (
				<div className={styles.errorContainer}>Your new PIN cannot be the same as your current PIN.</div>
			)}
			<div className={styles.resetPinContainer}>
				<UTEButton
					text='Reset PIN'
					className={styles.resetPin}
					onClick={onResetPinContinue}
					disabled={isPINDisabled}
					dataTestAuto='a5bf78d9-221e-4d32-bc26-eb4ebcb4300b'
				/>
			</div>
		</>
	);
};

export default ResetPIN;
