import React, { useState } from 'react';
import { Modal, Col, Row } from 'react-bootstrap';
import styles from './taxpayment-modal.module.scss';
import UTEButton from 'src/components/ute-button';
import {
	IPaymentDetailResponseViewModel,getPaymentDue,VoucherNo, getRefunds, getOverPaymentApplied,ITaxingAuthority,
	getEstimatedVouchers
} from '../taxpayment-types';
import Loader from 'src/components/loader';
import LoadingOverlay from 'react-loading-overlay';

type PaymentInfoModalProps = {
	hide: () => void;
	taxingAuthorities: ITaxingAuthority[],
	gotoPayment: () => void;
	paymentDetailResponseViewModel:IPaymentDetailResponseViewModel;
};
const PaymentInfoModal = ({ paymentDetailResponseViewModel,taxingAuthorities, hide, gotoPayment }: PaymentInfoModalProps) => {
	const [isLoading, setisLoading] = useState(false);
	const paymentDue = getPaymentDue(paymentDetailResponseViewModel.vouchers, taxingAuthorities);
	const refunds = getRefunds(paymentDetailResponseViewModel.refunds, paymentDetailResponseViewModel.addedRefunds, taxingAuthorities);
	const overPaymentApplied = getOverPaymentApplied(paymentDetailResponseViewModel.refunds,paymentDetailResponseViewModel.addedRefunds, taxingAuthorities);
	const estimatedVouchers = getEstimatedVouchers(paymentDetailResponseViewModel.vouchers, taxingAuthorities);

	const autoIds: any = {};
		autoIds[VoucherNo[VoucherNo.Q1].toString()] = "4F660DE8-BE88-48AA-AF2C-51646238FE67";
		autoIds[VoucherNo[VoucherNo.Q2].toString()] = "5BDD3FEC-D42C-463F-83E0-FD2801B27286";
		autoIds[VoucherNo[VoucherNo.Q3].toString()] = "CD5C7E01-F18C-45D8-9690-8565E5F4CB66";
		autoIds[VoucherNo[VoucherNo.Q4].toString()] = "6366E3A8-1895-4017-91FF-97662F494CF8";

	const onPayAction=() =>{
		setisLoading(true);
		gotoPayment();
	}

	const formatCurrencyText =(value: any) =>{
		var InputVal = Math.floor(value);
        
        var formatted = "";

        formatted = InputVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        if (value % 1 !== 0) {
            formatted = formatted + "." + value.toFixed(2).toString().split('.')[1];
            }

        return formatted;
	}

	return (
		<LoadingOverlay active={isLoading}
			spinner={<Loader />}
			text=''>
			<Modal.Body className={styles.bodyStyle}>
				<Row data-test-auto='49E77CA3-CF5A-4D67-A425-625151830BFC'>
					<Col lg={6}>
						<div className={styles.taxreturnHeader}>{paymentDetailResponseViewModel.taxYear} TAX RETURN</div>
						<hr />
					</Col>
					<Col lg={6}>
						<div className={styles.taxreturnHeader}>{paymentDetailResponseViewModel.taxYear + 1} ESTIMATD PAYMENTS</div>
						<hr />
					</Col>
				</Row>
				<Row>
					<Col  lg={6}>
					{
						refunds.items && refunds.items.length > 0 && refunds.items.find(x => x.value > 0) &&
						<section key={refunds.groupName}>
							<div className={styles.paySectionTitle}  data-test-auto="3721C0B0-177B-45E5-87F2-EEA08D0B63BC">{refunds.groupName}</div>
							{
								refunds.items.map((item, i) => {
									if (item.value > 0)
										return (<div key={refunds.groupName + item.name + i} className={styles.paySectionTitle}>
											<div className={`${styles.floatLeft} ${styles.authorityEllipsis}`}>{item.name}</div>
											<div className={`${styles.currencyText} ${styles.greenColor} ${styles.payAmount}`}>${formatCurrencyText(item.value)}</div>
										</div>)
								})
							}
							{
								<div className={styles.subSection} data-test-auto="196ECDFA-277D-4127-B234-1B6BEEE72BD9">
									<div className={`${styles.greenColor} ${styles.floatLeft}`}><span className={`${styles.greenColor} ${styles.floatRight}`}>Total</span></div>
									<div className={`${styles.totalAmountGreen} ${styles.currencyText} ${styles.greenColor} ${styles.payAmount} ${styles.floatRight}`}>${formatCurrencyText(refunds.total)}</div>
								</div>
							}
						</section>
					}
                	{
						overPaymentApplied.items && overPaymentApplied.items.length > 0 &&
						<section key={overPaymentApplied.groupName}>
							<div className={styles.paySectionTitle} data-test-auto="B527F1A4-C6DC-46FC-A3FA-7538335DAC9A">{overPaymentApplied.groupName}</div>
							{
								overPaymentApplied.items.map((item, i) => {
									return (<div key={overPaymentApplied.groupName + item.name + i} className={styles.paySectionTitle}>
										<div className={`${styles.floatLeft} ${styles.authorityEllipsis}`}>{item.name}</div>
										<div className={`${styles.currencyText} ${styles.greenColor} ${styles.payAmount}`}>${formatCurrencyText(item.value)}</div>
									</div>)
								})
							}
							{
								<div className={styles.subSection}  data-test-auto="408C067A-6212-4D6D-BEC2-7E04F7F3064A">
									<div className={`${styles.greenColor} ${styles.floatLeft}`}>
										<span className={`${styles.greenColor} ${styles.floatRight}`}>Total</span>
									</div>
									<div className={`${styles.totalAmountGreen} ${styles.currencyText} ${styles.greenColor} ${styles.payAmount} ${styles.floatRight}`}>${formatCurrencyText(overPaymentApplied.total)}
									</div>
								</div>
							}
						</section>
                	}
					{
						paymentDue.items && paymentDue.items.length > 0 &&
						<section key={paymentDue.groupName}>
							<div className={styles.paySectionTitle} data-test-auto="DFC7A2FB-CE86-4006-94E3-9CDB00D8E1CF">{paymentDue.groupName}</div>
							{
								paymentDue.items.map((item, i) => {
									return (<div key={paymentDue.groupName + item.name + i} className={styles.paySectionTitle} >
										<div className={`${styles.floatLeft} ${styles.authorityEllipsis}`}>{item.name}</div>
										<div className={`${styles.payAmount} ${styles.currencyText} ${styles.redColor}`}>${formatCurrencyText(item.value)}</div>
									</div>)
								})
							}
							{
								<div className={styles.subSection} data-test-auto="E651FB56-E074-44F5-87CB-2EA5003E4EEF">
									<div className={`${styles.redColor} ${styles.floatLeft}`}>
										<span className={`${styles.redColor} ${styles.floatRight}`}>Total</span>
									</div>
									<div className={`${styles.totalAmountRed} ${styles.currencyText} ${styles.redColor} ${styles.payAmount} ${styles.floatRight}`}>${formatCurrencyText(paymentDue.total)}
									</div>
								</div>
							}
						</section>
                	}
					{
						paymentDetailResponseViewModel.taxYear > 0
						&& refunds.items
						&& refunds.items.length === 0
						&& overPaymentApplied.items
						&& overPaymentApplied.items.length === 0
						&& paymentDue.items
						&& paymentDue.items.length === 0
						&& 
						<div className={styles.marginTop20}>
							No Refunds or Payments Due
						</div>
					}
					</Col>
					<Col>
						{
							estimatedVouchers.map((value, index) => {
								if (value.items.length > 0) {
									return (
										<section key={value.groupName + index} data-test-auto={autoIds[value.groupName]}>
											<div className={styles.paySectionTitle}>{value.groupName}</div>
											{
												value.items.length > 0 ? (
													value.items.map((item, i) => {
														return (<div key={value.groupName + item.name + i} className={styles.paySectionTitle} >
															<div className={`${styles.floatLeft} ${styles.authorityEllipsis}`}>{item.name}</div>
															<div className={`${styles.currencyText} ${styles.redColor} ${styles.payAmount}`}>${formatCurrencyText(item.value)}</div>
														</div>)
													})
												)
													: ""
											}
											{
												value.items.length > 0 ? (
													<div className={styles.subSection}>
														<div className={`${styles.floatLeft} ${styles.redColor} ${styles.authorityEllipsis}`}>
															<span className={`${styles.floatRight} ${styles.redColor}`}>Total</span>
														</div>
														<div 
															className={`${styles.totalAmountRed} ${styles.currencyText} ${styles.redColor} ${styles.payAmount} ${styles.floatRight}`}>${formatCurrencyText(value.total)}
														</div>
													</div>

												) : ""
											}
										</section>
									);
								}
							})
						}
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className={styles.footerStyle}>
			<UTEButton
					text='Pay'
					className={styles.payButton}
					onClick={onPayAction}
					disabled={false}
					dataTestAuto='2d31839b-1805-4d39-86dd-5c2bb6963907'
				/>
				<UTEButton
					text='Ok'
					className={styles.okButton}
					onClick={hide}
					disabled={false}
					dataTestAuto='10478e80-7f24-4e5f-9a3f-41ac75b92225'
				/>
			</Modal.Footer>
		</LoadingOverlay>
	);
};

export default PaymentInfoModal;
