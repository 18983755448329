import React, { useContext } from 'react';
import {
	EXTSignatureStatus,
	GTRDocumentStatus,
	DocumentType,
	IGatherDocumentModel,
	INodeSelected,
	IPaginatedNode,
	ITaxDocument,
	ITaxReturnDetails,
	SSRSignatureStatus,
	IOrganizerDocumentModel,
	SIGSignatureStatus,
} from '../documents-types';
import Folder from 'src/assets/svg/folder';
import PaperClip from 'src/assets/svg/paper-clip';
import styles from './grid-node.module.scss';
import VerticalDivider from 'src/assets/svg/vertical-divider';
import Ellipsis from 'src/assets/svg/ellipsis';
import { ButtonToolbar, DropdownButton, OverlayTrigger, Popover } from 'react-bootstrap';
import { AuthContext } from '../../../context/ute-auth-context/auth-provider';
import {
	getFormattedDate,
	gtrOrgDownloadAction,
	openTaxPayerSummaryPage,
	showDateToolTip,
	showNumberOfFilesText,
	handleSignatureDownloadAction,
} from '../documents-utility-functions';
import { ProductType } from 'src/modules/common/common-types';
import { IUserActivity, OneHubEventType } from 'src/modules/recent-activity/recent-activity-types';
import { saveUserActivity } from 'src/modules/recent-activity/recent-activity-api';
import { isFilePreviewAvailable } from 'src/helper/utils';
import ExpiryAlarmIcon from 'src/assets/svg/expiry-alarm';
import UTEClientViewWrapper from 'src/components/ute-client-view-wrapper';

type GridNodeProps = {
	node: IPaginatedNode;
	index: number;
	level: number;
	showCCRecipientModal: (document: ITaxDocument) => void;
	showPreviewModal: (document: ITaxDocument, name: string) => void;
	onShowGTRPreviewModal: (document: IGatherDocumentModel) => void;
	onShowORGPreviewModal: (document: IOrganizerDocumentModel) => void;
	fetchTaxDocuments: (taxYear: number) => void;
	onNodeClick: (index: number, node: IPaginatedNode) => void;
	nodeSelected: INodeSelected;
	searchText: string;
	showExchangePreviewModal: (file: ITaxDocument) => void;
	downloadExchangeFiles: (file: ITaxDocument) => void;
	showCpaMessageModal: (file: ITaxDocument) => void;
};

enum ActionType {
	None = 0,
	MoreActions = 1,
	Download = 2,
}

const GridNode = ({
	node,
	index,
	nodeSelected,
	level,
	showCCRecipientModal,
	showPreviewModal,
	onShowGTRPreviewModal,
	onShowORGPreviewModal,
	onNodeClick,
	searchText,
	showExchangePreviewModal,
	downloadExchangeFiles,
	showCpaMessageModal,
}: GridNodeProps) => {
	const { userDetail, isClientView } = useContext(AuthContext);
	const displayName = (node: IPaginatedNode) => {
		const name = node.folderName ?? node.efileFormName;
		if (searchText.length === 0) {
			return <span>{name}</span>;
		}
		const isFile = Boolean(node.efileFormName);
		const regex = new RegExp(`(${searchText})`, 'gi');
		const parts = name.split(regex);
		return (
			<div>
				{parts.map((part, index) =>
					regex.test(part) ? (
						<span
							key={index}
							className={`${styles.highlight} ${isFile ? styles.displayName : ''}`.trim()}
							title={name}>
							{part}
						</span>
					) : (
						<span
							key={index}
							className={isFile ? styles.displayName : ''}
							title={name}>
							{part}
						</span>
					),
				)}
			</div>
		);
	};

	const getFileStatus = (node: IPaginatedNode) => {
		const document = node as ITaxReturnDetails;
		if (
			document.productType === ProductType.Returns ||
			document.productType === ProductType.Extensions ||
			document.productType === ProductType.SignaturesOneOff
		) {
			return getFormattedStatus(node);
		} else if (document.productType === ProductType.Gather) {
			return getGatherOrgFileStatus(document);
		} else if (document.productType === ProductType.Organizers) {
			return getGatherOrgFileStatus(document);
		}
	};

	const getFormattedStatus = (node: IPaginatedNode) => {
		let element;
		const signatureStatus = node.signatureStatus;
		if (node.productType === ProductType.Returns) {
			if (signatureStatus === SSRSignatureStatus.Uploaded) {
				element = <span className={styles.manuallySignedStyle}>Manually Signed</span>;
			} else if (signatureStatus === SSRSignatureStatus.PartiallySigned) {
				element = <span className={styles.partiallySignedStyle}>Partially Signed</span>;
			} else if (signatureStatus === SSRSignatureStatus.ESigned) {
				element = <span className={styles.eSignedStyle}>E-Signed</span>;
			} else if (signatureStatus === SSRSignatureStatus.ESignedInOffice) {
				element = <span className={styles.eSignedInOfficeStyle}>E-Signed &#8208; In Office</span>;
			} else if (signatureStatus === SSRSignatureStatus.SignedAndESigned) {
				element = <span className={styles.signedAndESignedStyle}>Signed And E-Signed</span>;
			}
		} else if (node.productType === ProductType.Extensions) {
			if (signatureStatus === EXTSignatureStatus.Uploaded) {
				element = <span className={styles.manuallySignedStyle}>Manually Signed</span>;
			} else if (signatureStatus === EXTSignatureStatus.PartiallySigned) {
				element = <span className={styles.partiallySignedStyle}>Partially Signed</span>;
			} else if (signatureStatus === EXTSignatureStatus.ESigned) {
				element = <span className={styles.eSignedStyle}>E-Signed</span>;
			} else if (signatureStatus === EXTSignatureStatus.Reviewed) {
				element = <span className={styles.reviewedStyle}>Reviewed</span>;
			} else if (signatureStatus === EXTSignatureStatus.PartiallyReviewed) {
				element = <span className={styles.partiallyReviewedStyle}>Partially Reviewed</span>;
			}
		} else if (node.productType === ProductType.SignaturesOneOff) {
			if (signatureStatus === SIGSignatureStatus.PartiallySigned) {
				element = <span className={styles.partiallySignedStyle}>Partially Signed</span>;
			} else if (signatureStatus === SIGSignatureStatus.ESigned) {
				element = <span className={styles.eSignedStyle}>E-Signed</span>;
			}
		}
		return element;
	};

	const getGatherOrgFileStatus = (node: ITaxReturnDetails) => {
		if (node.documentType === DocumentType.Organizer || node.documentType === DocumentType.CustomQuestion) {
			return getFormattedGatherORGStatus(node);
		} else if (
			node.documentType === DocumentType.Engagement ||
			node.documentType === DocumentType.AdditionalEFile ||
			node.documentType === DocumentType.OrganizerWithSignature
		) {
			return getFormattedGatherOrganizerSignStatus(node);
		} else if (!node.documentType && node.sourceDocumentId && node.sourceDocumentId > 0) {
			return getFormattedGTRORGSourceDocumentStatus(node);
		}
	};

	const getFormattedGatherOrganizerSignStatus = (node: ITaxReturnDetails) => {
		const signatureStatus = node.signatureStatus;
		let element;
		if (signatureStatus === GTRDocumentStatus.ManuallySigned) {
			element = <span className={styles.manuallySignedStyle}>Manually Signed</span>;
		} else if (signatureStatus === GTRDocumentStatus.PartiallySigned) {
			element = <span className={styles.partiallySignedStyle}>Partially Signed</span>;
		} else if (signatureStatus === GTRDocumentStatus.ESigned) {
			element = <span className={styles.eSignedStyle}>E-Signed</span>;
		} else if (signatureStatus === GTRDocumentStatus.Reviewed) {
			element = <span className={styles.reviewedStyle}>Reviewed</span>;
		} else if (signatureStatus === GTRDocumentStatus.PartiallyReviewed) {
			element = <span className={styles.partiallyReviewedStyle}>Partially Reviewed</span>;
		} else if (signatureStatus === GTRDocumentStatus.ELDownloaded) {
			element = <span className={styles.partiallyReviewedStyle}>Downloaded</span>;
		} else {
			<span className={styles.partiallyReviewedStyle}></span>;
		}
		return element;
	};

	const getFormattedGTRORGSourceDocumentStatus = (node: ITaxReturnDetails) => {
		const sourceDocumentStatus = node.sourceDocumentStatus;
		let element;
		if (!!sourceDocumentStatus) {
			element = <span className={styles.reviewedStyle}>Uploaded</span>;
		} else {
			element = <></>;
		}
		return element;
	};

	const getFormattedGatherORGStatus = (node: ITaxReturnDetails) => {
		const organizerStatus = node.documentStatus;
		let element;
		if (organizerStatus === GTRDocumentStatus.Completed || organizerStatus === GTRDocumentStatus.OrganizerDownloaded) {
			element = <span className={styles.completedStyle}>Completed</span>;
		} else if (organizerStatus === GTRDocumentStatus.ManuallyCompleted) {
			element = <span className={styles.manuallyCompletedStyle}>Manually Completed</span>;
		} else if (organizerStatus === GTRDocumentStatus.QuestionnaireCompleted) {
			element = <span className={styles.partiallyReviewedStyle}>Questionnaire Completed</span>;
		} else if (organizerStatus === GTRDocumentStatus.ClosedByFirm) {
			element = <span className={styles.completedStyle}>Closed By Firm</span>;
		} else {
			element = <span className={styles.partiallySignedStyle}></span>;
		}
		return element;
	};

	const createActions = () => {
		var productType: ProductType = node.productType;
		switch (productType) {
			case ProductType.Returns:
			case ProductType.Extensions:
				return createSSRActions();
			case ProductType.Gather:
				return createORGGTRActions();
			case ProductType.Exchange:
				return createExchangeActions();
			case ProductType.Organizers:
				return createORGGTRActions();
			case ProductType.SignaturesOneOff:
				return createSignatureActions();
		}
	};

	const handleGtrPreviewAction = (node: ITaxReturnDetails) => {
		const isSourceDocument = node.sourceDocumentId && node.sourceDocumentId > 0;
		const documentModel = {
			gatherId: node.documentId,
			clientGuid: node.clientGuid,
			clientId: node.clientId,
			gatherGuid: node.documentGuid,
			taxYear: node.taxYear,
			sourceDocumentId: node.sourceDocumentId,
			fileName: node.efileFormName,
			sourceDocumentFileName: node.sourceDocumentFileName,
			documentType: isSourceDocument ? undefined : node.documentType,
			fileGuid: node.fileGuid,
		};
		onShowGTRPreviewModal(documentModel as IGatherDocumentModel);
		if (!isClientView){
			const userActivity: IUserActivity = {
				eventId: OneHubEventType.View,
				eventData: {
					documentId: node.documentId,
					fileName: node.documentType ? node.efileFormName : node.sourceDocumentFileName ?? '',
					signerType: node.signerType,
					clientGuid: node.clientGuid,
					gatherFileData: {
						gatherDocumentType: node.documentType,
						sourceDocumentId: node.sourceDocumentId,
						fileName: node.efileFormName,
						clientId: node.clientId,
						taxYear: node.taxYear,
						fileGuid: node.fileGuid,
					},
				},
				productType: node.productType,
			};
			saveUserActivity(userActivity);
		}
	};

	const handleORGPreviewAction = (node: ITaxReturnDetails) => {
		const isSourceDocument = node.sourceDocumentId && node.sourceDocumentId > 0;
		const documentModel = {
			documentId: node.documentId,
			clientGuid: node.clientGuid,
			clientId: node.clientId,
			documentGuid: node.documentGuid,
			taxYear: node.taxYear,
			sourceDocumentId: node.sourceDocumentId,
			fileName: node.efileFormName,
			sourceDocumentFileName: node.sourceDocumentFileName,
			documentType: isSourceDocument ? undefined : node.documentType,
			fileGuid: node.fileGuid,
		};
		onShowORGPreviewModal(documentModel as IOrganizerDocumentModel);
	};

	const createSSRActions = () => {
		return (
			<ButtonToolbar className='btn-group-vertical'>
				<DropdownButton
					size='sm'
					title={<Ellipsis />}
					id='documentMoreActions'
					data-test-auto='271576c6-b729-4e50-b51a-480831e76dc4'
					className='documentMoreActions'>
					<li
						data-test-auto='b2970083-2474-4fc1-ae4f-080623edd883'
						key='Download'
						onClick={() => {
							openTaxPayerSummaryPage(node, ActionType.Download, userDetail, isClientView);
						}}>
						Download
					</li>
					<li
						data-test-auto='a08f98d1-e6eb-4522-a97f-dcae59bf187a'
						key='Forward'
						onClick={() => {
							showCCRecipientModal(node);
						}}>
						Forward
					</li>
					<li
						data-test-auto='F80C4006-FAA1-4821-8A0F-E5048F06AF68'
						key='More Actions'
						onClick={() => {
							openTaxPayerSummaryPage(node, ActionType.MoreActions, userDetail, isClientView);
						}}>
						More Actions
					</li>
				</DropdownButton>
			</ButtonToolbar>
		);
	};

	const createORGGTRActions = () => {
		return (
			<ButtonToolbar className='btn-group-vertical'>
				<DropdownButton
					size='sm'
					title={<Ellipsis />}
					id='documentMoreActions'
					data-test-auto='271576c6-b729-4a50-b51a-480831e76dc4'
					className='documentMoreActions'>
					<li
						data-test-auto='b2970083-2474-4fc1-ae4f-080623edd844'
						key='Download'
						onClick={() => {
							gtrOrgDownloadAction(node as ITaxReturnDetails, isClientView);
						}}>
						Download
					</li>
				</DropdownButton>
			</ButtonToolbar>
		);
	};

	const createExchangeActions = () => {
		const docmentData = node as ITaxReturnDetails;
		return (
			<span className={isFilePreviewAvailable(docmentData) ? '' : styles.spaceForEllipsis}>
				<ButtonToolbar className='btn-group-vertical'>
					<DropdownButton
						size='sm'
						title={<Ellipsis />}
						id='sse-documentMoreActions'
						data-test-auto='271576c6-b729-4e50-b51a-480831e76dc4'
						className='documentMoreActions'>
						<UTEClientViewWrapper dataTestAuto={'36cd713c-8e53-4094-b119-5bdbcd933bf8'}>
							<li
								data-test-auto='b2970083-2474-4fc1-ae4f-080623edd883'
								key='Download'
								onClick={() => {
									downloadExchangeFiles(docmentData);
								}}>
								Download
							</li>
						</UTEClientViewWrapper>

						{
							/* Message from CPA */
							docmentData && docmentData.isMessagePresent && (
								<li
									onClick={() => {
										showCpaMessageModal(docmentData);
									}}>
									Message From CPA
								</li>
							)
						}
					</DropdownButton>
				</ButtonToolbar>
			</span>
		);
	};

	const createSignatureActions = () => {
		return (
			<ButtonToolbar className='btn-group-vertical'>
				<DropdownButton
					size='sm'
					title={<Ellipsis />}
					id='documentMoreActions'
					data-test-auto='7397e289-19c3-46d1-816a-40ffbd6ed048'
					className='documentMoreActions'>
					<UTEClientViewWrapper dataTestAuto={'2311099f-5a13-4012-9e33-4c44cab311b3'}>
						<li
							data-test-auto='528774b1-ba7f-455c-9d2b-dadd1bfe0765'
							key='Download'
							onClick={() => {
								handleSignatureDownloadAction(node as ITaxReturnDetails);
							}}>
							Download
						</li>
					</UTEClientViewWrapper>
				</DropdownButton>
			</ButtonToolbar>
		);
	};

	const showPopover = (toolTip: string) => {
		return (
			<Popover
				id='popover-basic'
				className='datePopover'>
				<Popover.Body className='popoverBody'>{toolTip}</Popover.Body>
			</Popover>
		);
	};

	const isVerticalDividerVisible = (node: ITaxReturnDetails): boolean => {
		return node?.productType === ProductType.Exchange ? isFilePreviewAvailable(node) : true;
	};
	return (
		<>
			<tr
				className={`${styles.documentRow} ${index === nodeSelected.index ? styles.nodeSelected : ''}`}
				onClick={() => {
					onNodeClick(index, node);
				}}>
				<td className={styles.nameData}>
					<span
						className={node.folderName ? styles.nameContainer : styles.fileNameContainer}
						style={{
							maxWidth: `calc(100% - ${level * 20}px)`,
							marginLeft: level * 20,
							cursor: 'default',
						}}>
						{node.folderName ? <Folder /> : <PaperClip />}
						{displayName(node)}
						{node?.isShowExpiration ? (
							<OverlayTrigger
								placement='bottom'
								overlay={
									<Popover
										id='popover-basic'
										className='expiry-popover'>
										<Popover.Body>
											<div className='expiry-text-container'>
												<div className='expiry-text'>
													Access expires on {node?.expiryDate && getFormattedDate(node?.expiryDate)}
												</div>
												<div className='expiry-doc-name'>{node.efileFormName}</div>
											</div>
										</Popover.Body>
									</Popover>
								}>
								{({ ref, ...triggerHandler }) => (
									<span
										ref={ref}
										{...triggerHandler}
										className={styles.exipryAlarmIcon}>
										<ExpiryAlarmIcon />
									</span>
								)}
							</OverlayTrigger>
						) : (
							''
						)}
					</span>
				</td>
				<td className={styles.containsData}>{node.folderName ? showNumberOfFilesText(node.documentCount) : ''}</td>
				<td className={styles.dateData}>
					{node.efileFormName &&
						(node.productType === ProductType.Returns ||
							node.productType === ProductType.Extensions ||
							node.productType === ProductType.Gather ||
							node.productType === ProductType.Exchange ||
							node.productType === ProductType.Organizers ||
							node.productType === ProductType.SignaturesOneOff) && (
							<OverlayTrigger
								placement='bottom-end'
								delay={{ show: 100, hide: 100 }}
								overlay={showPopover(showDateToolTip(node.toolTip))}>
								<span>{getFormattedDate(node.signedOn)}</span>
							</OverlayTrigger>
						)}
				</td>
				<td className={styles.statusData}>{node.efileFormName && getFileStatus(node)}</td>
				<td className={styles.actionsData}>
					{node.efileFormName && (
						<div className={styles.actionsContainer}>
							{(node.productType === ProductType.Returns ||
								node.productType === ProductType.Extensions ||
								node.productType === ProductType.SignaturesOneOff) && (
								<span
									className={styles.view}
									onClick={() => {
										showPreviewModal(node, node.efileFormName);
									}}>
									View
								</span>
							)}
							{node.productType === ProductType.Gather && (
								<span
									className={styles.view}
									onClick={() => {
										handleGtrPreviewAction(node as ITaxReturnDetails);
									}}>
									View
								</span>
							)}
							{node.productType === ProductType.Organizers && (
								<span
									className={styles.view}
									onClick={() => {
										handleORGPreviewAction(node as ITaxReturnDetails);
									}}>
									View
								</span>
							)}
							{node.productType === ProductType.Exchange && (
								<span
									className={isFilePreviewAvailable(node as ITaxReturnDetails) ? styles.view : styles.hide}
									onClick={() =>
										isFilePreviewAvailable(node as ITaxReturnDetails) &&
										showExchangePreviewModal(node as ITaxReturnDetails)
									}>
									View
								</span>
							)}
							<span
								className={isVerticalDividerVisible(node as ITaxReturnDetails) ? styles.verticalDivider : styles.hide}>
								<VerticalDivider />
							</span>
							<span>{createActions()}</span>
						</div>
					)}
				</td>
			</tr>
		</>
	);
};

export default GridNode;
