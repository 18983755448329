import React from 'react';

const CheckListIcon: React.FC = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='35'
			height='35'
			fill='none'
			viewBox='0 0 35 35'>
			<g
				stroke='#A6A9AC'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.466'
				clipPath='url(#clip0_4206_19723)'>
				<path d='M4.226 1.43h26.548s2.795 0 2.795 2.795v26.548s0 2.794-2.795 2.794H4.226s-2.794 0-2.794-2.794V4.225s0-2.795 2.794-2.795z'></path>
				<path d='M7.719 11.211l2.096 2.096 5.589-5.589M7.719 25.184l2.096 2.095 5.589-5.589M19.596 12.608h8.383M19.596 25.184h8.383'></path>
			</g>
			<defs>
				<clipPath id='clip0_4206_19723'>
					<path
						fill='#fff'
						d='M0 0H35V35H0z'></path>
				</clipPath>
			</defs>
		</svg>
	);
};

export default CheckListIcon;
