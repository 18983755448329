import PaymentComponent from 'src/modules/tax-payments';
import LoggedInLayout from 'src/modules/layout/logged-in-layout';

const TaxPaymentsContainer = () => {
	return (
		<LoggedInLayout>
			<main className='pageMainContent'>
				<h1 className='pageMainHeader'>Tax Payments</h1>
				<PaymentComponent/>
			</main>
		</LoggedInLayout>
	);
};

export default TaxPaymentsContainer;
