import React from 'react';
import HammerSettingsIcon from 'src/assets/svg/hammer-settings-icon';
import SafeSendSuiteWhiteLogo from 'src/assets/svg/safesend-suite-white-logo';
import styles from './mobile-default-page.module.scss';

const MobileDefaultPage = () => {
	return (
		<section className={styles.mobilePageContainer}>
			<header>
				<SafeSendSuiteWhiteLogo />
			</header>
			<main className={styles.mainContent}>
				<HammerSettingsIcon />
				<p>
					Oops! It looks like our Client Portal feature is still enjoying its desktop&#8209;only status. Stay tuned for
					mobile magic!
				</p>
				<p>In the meantime, please access the Client Portal feature on your desktop.</p>
			</main>
		</section>
	);
};

export default MobileDefaultPage;
