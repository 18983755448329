export const QuestionMarkIcon: React.FC = () => {
	return (
		<svg
			width='80'
			height='80'
			viewBox='0 0 80 80'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M43.0043 75.1344C62.4066 75.1344 78.1355 59.4055 78.1355 40.0031C78.1355 20.6006 62.4066 4.87183 43.0043 4.87183C23.6018 4.87183 7.87305 20.6006 7.87305 40.0031C7.87305 59.4055 23.6018 75.1344 43.0043 75.1344Z'
				fill='#B5D5EA'
				stroke='#B5D5EA'
				strokeWidth='3.35'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M37.0043 75.1344C56.4066 75.1344 72.1355 59.4055 72.1355 40.0031C72.1355 20.6006 56.4066 4.87183 37.0043 4.87183C17.6018 4.87183 1.87305 20.6006 1.87305 40.0031C1.87305 59.4055 17.6018 75.1344 37.0043 75.1344Z'
				stroke='#0973BA'
				strokeWidth='3.35'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M27.418 33.6285C27.4182 31.9013 27.8854 30.2061 28.7702 28.7226C29.6549 27.239 30.9244 26.0222 32.444 25.201C33.9636 24.3797 35.677 23.9846 37.4026 24.0573C39.1285 24.1301 40.8027 24.668 42.2478 25.6142C43.6927 26.5604 44.8552 27.8797 45.6121 29.4325C46.3687 30.9852 46.6916 32.7136 46.5466 34.4349C46.4016 36.156 45.7938 37.8062 44.7878 39.2102C43.7818 40.6145 42.4149 41.7205 40.8317 42.4113C39.6925 42.9086 38.7232 43.7271 38.0426 44.767C37.3617 45.8069 36.9992 47.0228 36.9992 48.2654V49.5813'
				stroke='#0973BA'
				strokeWidth='3.35'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M37.0074 57.5655C36.5664 57.5655 36.209 57.2081 36.209 56.7671C36.209 56.326 36.5664 55.9686 37.0074 55.9686'
				stroke='#0973BA'
				strokeWidth='3.35'
			/>
			<path
				d='M37.0098 57.5655C37.4508 57.5655 37.8082 57.2081 37.8082 56.7671C37.8082 56.326 37.4508 55.9686 37.0098 55.9686'
				stroke='#0973BA'
				strokeWidth='3.35'
			/>
		</svg>
	);
};
