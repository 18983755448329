import axios, { AxiosResponse } from 'axios';
import { SSRTP_API_URL, EXTTP_API_URL } from 'src/helper/constants';
import { IPaymentDetailResponseViewModel, IPaymentData, ITaxingAuthority } from './taxpayment-types';
import { ProductType } from '../common/common-types';

export const getProductsPaymentDocuments = async (
	emailID: string,
	companyId: number,
	pageNumber: number,
	pageSize: number,
	callback: (paymentDetailsResponse: IPaymentData[]) => void,
	errorCallback?: (error: any) => void,
) => {
	try {
		const [paymentList_SSR, paymentList_SSEXT] = await Promise.all([
			axios.get(
				`${SSRTP_API_URL}/api/payment-management/document-payments/${emailID}/${companyId}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
				{ withCredentials: true },
			),
			axios.get(
				`${EXTTP_API_URL}/api/payment-management/document-payments/${emailID}/${companyId}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
				{ withCredentials: true },
			),
		]);

		const paymentData_SSR: IPaymentData = paymentList_SSR.data;
		const payment_SSEXT: IPaymentData = paymentList_SSEXT.data;

		paymentData_SSR.productType = ProductType.Returns;
		payment_SSEXT.productType = ProductType.Extensions;

		const productWisePaymentData: IPaymentData[] = [];
		productWisePaymentData.push(paymentData_SSR);
		productWisePaymentData.push(payment_SSEXT);

		callback(productWisePaymentData);
	} catch (error: any) {
		errorCallback && errorCallback(error);
	}
};

export function getAllSSRPaymentDocuments(
	emailID: string,
	companyId: number,
	pageNumber: number,
	pageSize: number,
	callback: (paymentDetailsResponse: IPaymentData) => void,
	errorCallback?: (error: any) => void,
) {
	axios
		.get(
			`${SSRTP_API_URL}/api/payment-management/document-payments/${emailID}/${companyId}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		)
		.then((response: AxiosResponse<IPaymentData>) => {
			const { data } = response;
			callback(data);
		})
		.catch((error) => {
			errorCallback && errorCallback(error);
		});
}

export function getAllExtensionPaymentDocuments(
	emailID: string,
	companyId: number,
	pageNumber: number,
	pageSize: number,
	callback: (paymentDetailsResponse: IPaymentData) => void,
	errorCallback?: (error: any) => void,
) {
	axios
		.get(
			`${EXTTP_API_URL}/api/payment-management/document-payments/${emailID}/${companyId}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		)
		.then((response: AxiosResponse<IPaymentData>) => {
			const { data } = response;
			callback(data);
		})
		.catch((error) => {
			errorCallback && errorCallback(error);
		});
}

export function getSSRPaymentDetails(
	clientId: string,
	callback: (paymentDetailResponseViewModel: IPaymentDetailResponseViewModel) => void,
	errorCallback?: (error: any) => void,
) {
	axios
		.get(`${SSRTP_API_URL}/api/payment-management/document-payment/${clientId}`)
		.then((response: AxiosResponse<IPaymentDetailResponseViewModel>) => {
			const { data } = response;
			callback(data);
		})
		.catch((error) => {
			errorCallback && errorCallback(error);
		});
}

export function getExtensionPaymentDetails(
	clientId: string,
	userEmailAddress: string,
	callback: (paymentDetailResponseViewModel: IPaymentDetailResponseViewModel) => void,
	errorCallback?: (error: any) => void,
) {
	axios
		.get(`${EXTTP_API_URL}/api/payment-management/document-payment/${userEmailAddress}/${clientId}`)
		.then((response: AxiosResponse<IPaymentDetailResponseViewModel>) => {
			const { data } = response;
			callback(data);
		})
		.catch((error) => {
			errorCallback && errorCallback(error);
		});
}

export function getAllTaxingAuthorities(
	companyId: number,
	callback: (taxingAuthorities: ITaxingAuthority[]) => void,
	errorCallback?: (error: any) => void,
) {
	axios
		.get(`${SSRTP_API_URL}/api/payment-management/taxing-authorities/${companyId}`)
		.then((response: AxiosResponse<any[]>) => {
			const { data } = response;
			let authorityList: ITaxingAuthority[] = [];
			data.forEach((taxingAuthority) => {
				authorityList.push({ id: taxingAuthority.Id, authorityName: taxingAuthority.AuthorityName });
			});
			callback(authorityList);
		})
		.catch((error) => {
			errorCallback && errorCallback(error);
		});
}
