import React, { useContext, useEffect } from 'react';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';
import LoggedInLayout from 'src/modules/layout/logged-in-layout';
import Dashboard from 'src/modules/dashboard';

const DashboardContainer: React.FC = () => {
	const { updateCompanyId, companyId } = useContext(AuthContext);

	useEffect(() => {
		updateCompanyId(Number(companyId));
	}, []);

	return (
		<LoggedInLayout>
			<Dashboard />
		</LoggedInLayout>
	);
};

export default DashboardContainer;
