import { useEffect, useState } from 'react';
import styles from './update-recovery.module.scss';
import UTEButton from '../../components/ute-button';
import UTEModalLayout from 'src/components/ute-modal-layout';
import RecoveryPopup from '../recovery-popup';
import { getRecoveryDetails } from '../recovery-popup/recovery-popup-api';
import { initialRecoveryModelResponse, IRecoveryModel } from '../recovery-popup/recovery-popup-types';
import { UTENotifier } from '../notification/ute-notifier';
import { RECOVERY_CONSTANTS } from 'src/helper/constants';
import PhoneInput from '@sssuite-component-ui/react-phone-input-2';

const UpdateRecoverySection = () => {
	const [showModal, setShowModal] = useState(false);
	const [recoveryModel, setRecoveryModel] = useState<IRecoveryModel>(initialRecoveryModelResponse);

	useEffect(() => {
		getRecoveryDetails(
			(response: IRecoveryModel) => {
				setRecoveryModel(response);
			},
			() => {
				UTENotifier.Error(RECOVERY_CONSTANTS.ERROR_GET_RECOVERY);
			},
		);
	}, []);
	return (
		<>
			{' '}
			<section
				id={styles.updateRecoverySection}
				className='pageModuleContainer'>
				<h2 className='pageModuleHeader'>Recovery Email or Phone Number</h2>
				<div>
					<p className='pageModuleDescription'>Update your recovery email or phone number</p>
				</div>
				<div>
					<span className={styles.recoveryOptionValue}>
						{recoveryModel.emailAddress !== '' && recoveryModel.emailAddress !== null ? (
							recoveryModel.emailAddress
						) : recoveryModel.phoneNumber !== '' && recoveryModel.phoneNumber !== null ? (
							<PhoneInput
								showAsLabel={true}
								value={recoveryModel.countryCode + recoveryModel.phoneNumber}></PhoneInput>
						) : (
							''
						)}
					</span>
				</div>
				<div>
					<UTEButton
						className={styles.btnDefault}
						dataTestAuto='719a7d90-e897-4ae7-bcfe-1cab003e7418'
						text='Update'
						onClick={() => {
							setShowModal(true);
						}}
						disabled={false}
					/>
				</div>
			</section>
			{showModal && (
				<UTEModalLayout
					width='402'
					theme='lightblue'
					title='Recovery Email or Phone Number'
					hide={() => {
						setShowModal(false);
					}}
					onClickOutside={() => {}}>
					<RecoveryPopup
						onCancel={() => {
							setShowModal(false);
						}}
						fromUpdateSetting={true}
						recoveryModel={recoveryModel}
						setRecoveryModel={setRecoveryModel}
						setShowRecoveryPopup={setShowModal}
					/>
				</UTEModalLayout>
			)}
		</>
	);
};

export default UpdateRecoverySection;
