import React from 'react';
import { IGatherDocumentModel, INodeSelected, IOrganizerDocumentModel, IPaginatedNode, ITaxDocument } from '../documents-types';
import styles from './documents-grid.module.scss';
import GridNode from '../grid-node';

type DocumentsGridProps = {
	paginatedRecords: IPaginatedNode[];
	isLoading: boolean;
	displayCCRecipientModal: (documentReceived: ITaxDocument) => void;
	onshowPreviewModal: (documentReceived: ITaxDocument, name: string) => void;
	fetchTaxDocuments: (taxYear: number) => void;
	onNodeClick: (index: number, node: IPaginatedNode) => void;
	nodeSelected: INodeSelected;
	searchText: string;
	onShowGTRPreviewModal: (document: IGatherDocumentModel) => void;
	onShowORGPreviewModal: (document: IOrganizerDocumentModel) => void;
	showExchangePreviewModal: (file: ITaxDocument) => void;
	downloadExchangeFiles: (file: ITaxDocument) => void;
	showCpaMessageModal: (file: ITaxDocument) => void;

};
const DocumentsGrid = ({
	paginatedRecords,
	isLoading,
	displayCCRecipientModal,
	onshowPreviewModal,
	fetchTaxDocuments,
	onNodeClick,
	nodeSelected,
	searchText,
	onShowGTRPreviewModal,
	onShowORGPreviewModal,
	showExchangePreviewModal,
	downloadExchangeFiles,
	showCpaMessageModal,
}: DocumentsGridProps) => {
	return (
		<tbody>
			{paginatedRecords.length === 0 && !isLoading ? (
				<td
					className={styles.messageRow}
					colSpan={5}>
					No search results found.
				</td>
			) : (
				paginatedRecords.map((node: IPaginatedNode, index: number) => (
					<GridNode
						key={index}
						node={node}
						index={index}
						level={0}
						showCCRecipientModal={displayCCRecipientModal}
						showPreviewModal={onshowPreviewModal}
						fetchTaxDocuments={fetchTaxDocuments}
						onNodeClick={onNodeClick}
						nodeSelected={nodeSelected}
						searchText={searchText}
						onShowGTRPreviewModal={onShowGTRPreviewModal}
						onShowORGPreviewModal={onShowORGPreviewModal}
						showExchangePreviewModal={showExchangePreviewModal}
						downloadExchangeFiles={downloadExchangeFiles}
						showCpaMessageModal={showCpaMessageModal}

					/>
				))
			)}
		</tbody>
	);
};

export default DocumentsGrid;
