import React, { useState } from 'react';
import UTEButton from 'src/components/ute-button';
import Accordion from 'react-bootstrap/Accordion';
import DatePicker from 'antd/lib/date-picker';
import 'antd/lib/date-picker/style';
import styles from './filter-documents.module.scss';
import CalendarIcon from 'src/assets/svg/calendar-icon';
import {
	FILTER_FILETYPE_OPTIONS,
	FILTER_STATUS_OPTIONS,
	FilterType,
	IFilter,
	initialFilter,
	IOptionsType,
} from '../documents-types';
import dayjs from 'dayjs';
import { UTENotifier } from 'src/modules/notification/ute-notifier';
import { TAX_DOCUMENTS_CONSTANTS } from 'src/helper/constants';

type FilterDocumentsProps = {
	filter: IFilter;
	handleFilter: (filter: IFilter) => void;
};

const FilterDocuments = ({ filter, handleFilter }: FilterDocumentsProps) => {
	const [localFilter, setLocalFilter] = useState<IFilter>(filter);
	const [fromDate, setFromDate] = useState(filter.fromDate.length > 0 ? dayjs(filter.fromDate, 'MM/DD/YYYY') : null);
	const [toDate, setToDate] = useState(filter.toDate.length > 0 ? dayjs(filter.toDate, 'MM/DD/YYYY') : null);

	const onChangeDate = (date, datestring: string, filterType: FilterType) => {
		if (filterType === FilterType.FROMDATE) {
			setFromDate(date);
			setLocalFilter({ ...localFilter, fromDate: datestring });
		} else if (filterType === FilterType.TODATE) {
			setToDate(date);
			setLocalFilter({ ...localFilter, toDate: datestring });
		}
	};

	const getFilterCount = (filterType: FilterType) => {
		if (filterType === FilterType.STATUS) {
			if (localFilter.filterStatus.length === 0) {
				return 0;
			}
			return localFilter.filterStatus.split(',').length;
		} else {
			if (localFilter.filterFileType.length === 0) {
				return 0;
			}
			return localFilter.filterFileType.split(',').length;
		}
	};

	const getCheckboxSelectionValue = (checkboxValue: string, filterType: FilterType): boolean => {
		if (filterType === FilterType.STATUS) {
			return localFilter.filterStatus.split(',').indexOf(checkboxValue) >= 0 ? true : false;
		} else {
			return localFilter.filterFileType.split(',').indexOf(checkboxValue) >= 0 ? true : false;
		}
	};

	const updateCheckboxSelectionValue = (checked: boolean, checkboxValue: string, filterType: FilterType) => {
		if (filterType === FilterType.STATUS) {
			let statusValue = '';
			FILTER_STATUS_OPTIONS.forEach((eachOption: IOptionsType) => {
				if (eachOption.value === checkboxValue && checked) {
					statusValue += checkboxValue + ',';
				} else if (document.getElementById(eachOption.value).checked) {
					statusValue += eachOption.value + ',';
				}
			});
			if (statusValue.length > 0) {
				statusValue = statusValue.substring(0, statusValue.length - 1);
			}
			setLocalFilter({ ...localFilter, filterStatus: statusValue });
		} else {
			let fileTypeValue = '';
			FILTER_FILETYPE_OPTIONS.forEach((eachOption: IOptionsType) => {
				if (eachOption.value === checkboxValue && checked) {
					fileTypeValue += checkboxValue + ',';
				} else if (document.getElementById(eachOption.value).checked) {
					fileTypeValue += eachOption.value + ',';
				}
			});
			if (fileTypeValue.length > 0) {
				fileTypeValue = fileTypeValue.substring(0, fileTypeValue.length - 1);
			}
			setLocalFilter({ ...localFilter, filterFileType: fileTypeValue });
		}
	};

	const clearAllFilters = () => {
		setLocalFilter(initialFilter);
		setFromDate(null);
		setToDate(null);
	};

	const onFilter = () => {
		if ((!fromDate || !dayjs(fromDate).isValid()) && toDate && dayjs(toDate).isValid()) {
			UTENotifier.Warning(TAX_DOCUMENTS_CONSTANTS.ERROR_FILTER_FROM_DATE_EMPTY);
			return;
		}
		if ((!toDate || !dayjs(toDate).isValid()) && fromDate && dayjs(fromDate).isValid()) {
			UTENotifier.Warning(TAX_DOCUMENTS_CONSTANTS.ERROR_FILTER_TO_DATE_EMPTY);
			return;
		}
		if (fromDate && toDate && fromDate > toDate) {
			UTENotifier.Warning(TAX_DOCUMENTS_CONSTANTS.ERROR_FILTER_FROM_DATE_GREATER_THAN_TO_DATE);
			return;
		}
		handleFilter(localFilter);
	};

	return (
		<section className={styles.filterContainer}>
			<section className={styles.filterOptions}>
				<Accordion
					flush
					alwaysOpen>
					<Accordion.Item eventKey='0'>
						<Accordion.Header>
							<div className='itemHeader'>
								<span className='itemHeaderText'>Date Range</span>
							</div>
						</Accordion.Header>
						<Accordion.Body>
							<section className='dateContainer'>
								<div className='fromContainer'>
									<label htmlFor='fromDate'>From</label>
									<DatePicker
										id='fromDate'
										className='fromDate'
										onChange={(date, datestring) => {
											onChangeDate(date, datestring, FilterType.FROMDATE);
										}}
										suffixIcon={<CalendarIcon />}
										placement='bottomLeft'
										popupClassName='fromDatePopup'
										value={fromDate}
										format='MM/DD/YYYY'
									/>
								</div>
								<div className='toContainer'>
									<label htmlFor='toDate'>To</label>
									<DatePicker
										id='toDate'
										className='toDate'
										onChange={(date, datestring) => {
											onChangeDate(date, datestring, FilterType.TODATE);
										}}
										suffixIcon={<CalendarIcon />}
										placement='bottomRight'
										popupClassName='toDatePopup'
										value={toDate}
										format='MM/DD/YYYY'
									/>
								</div>
							</section>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey='1'>
						<Accordion.Header>
							<div className='itemHeader'>
								<span className='itemHeaderText'>Status</span>
								{getFilterCount(FilterType.STATUS) > 0 ? (
									<span className='itemHeaderCount'>{getFilterCount(FilterType.STATUS)}</span>
								) : (
									<></>
								)}
							</div>
						</Accordion.Header>
						<Accordion.Body>
							<section className='statusContainer'>
								{FILTER_STATUS_OPTIONS.map((eachOption: IOptionsType) => {
									return (
										<div key={eachOption.value}>
											<input
												type='checkbox'
												name={eachOption.value}
												id={eachOption.value}
												value={eachOption.value}
												checked={getCheckboxSelectionValue(eachOption.value, FilterType.STATUS)}
												onChange={(e) => {
													updateCheckboxSelectionValue(e.target.checked, eachOption.value, FilterType.STATUS);
												}}
											/>
											<label htmlFor={eachOption.value}>{eachOption.name}</label>
										</div>
									);
								})}
							</section>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey='2'>
						<Accordion.Header>
							<div className='itemHeader'>
								<span className='itemHeaderText'>File Type</span>
								{getFilterCount(FilterType.FILETYPE) > 0 ? (
									<span className='itemHeaderCount'>{getFilterCount(FilterType.FILETYPE)}</span>
								) : (
									<></>
								)}
							</div>
						</Accordion.Header>
						<Accordion.Body>
							<section className='fileTypeContainer'>
								{FILTER_FILETYPE_OPTIONS.map((eachOption: IOptionsType) => {
									return (
										<div key={eachOption.value}>
											<input
												type='checkbox'
												name={eachOption.value}
												id={eachOption.value}
												value={eachOption.value}
												checked={getCheckboxSelectionValue(eachOption.value, FilterType.FILETYPE)}
												onChange={(e) => {
													updateCheckboxSelectionValue(e.target.checked, eachOption.value, FilterType.FILETYPE);
												}}
											/>
											<label htmlFor={eachOption.value}>{eachOption.name}</label>
										</div>
									);
								})}
							</section>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</section>
			<section className={styles.footerButtons}>
				<UTEButton
					text='Clear All'
					disabled={false}
					className={styles.clearAll}
					onClick={clearAllFilters}
					dataTestAuto='06141b85-b42d-481a-bd20-a6f10fe360cf'
				/>
				<UTEButton
					text='Apply'
					disabled={false}
					className={styles.apply}
					onClick={onFilter}
					dataTestAuto='60e3efcf-b070-49d3-b81e-ccc82bdd4a6d'
				/>
			</section>
		</section>
	);
};

export default FilterDocuments;
