import { AxiosError } from 'axios';
import { ErrorResponse } from 'react-router-dom';
import { IPendingTaxReturn } from 'src/modules/dashboard/dashboard-types';
import { ITaxRecord, FileSystemType, ITaxDocument } from 'src/modules/documents/documents-types';
import { UTENotifier } from 'src/modules/notification/ute-notifier';
import {
	EXCHANGE_FILE_TYPES,
	MAX_FAILED_ATTEMPT_COUNT,
	TAX_RETURN_FOLDER_NAME,
	VALIDATION_CONSTANTS,
} from './constants';
import { IUserActivity } from 'src/modules/recent-activity/recent-activity-types';
import Cookies from 'js-cookie';
export const STRING_EMPTY = '';
export function isValidEmailAddress(emailAddress: string) {
	// Below regex is very strict expression which causes page hang
	// const pattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/);
	// Hence using the below pattern for not very strict check
	const pattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
	return pattern.test(emailAddress);
}

export function isValidNumber(value: string) {
	const pattern = new RegExp(/^\d+$/);
	return pattern.test(value);
}

export class Guid {
	public static newGuid(): Guid {
		return new Guid(
			'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
				const r = (Math.random() * 16) | 0;
				const v = c == 'x' ? r : (r & 0x3) | 0x8;
				return v.toString(16);
			}),
		);
	}
	public static get empty(): string {
		return '00000000-0000-0000-0000-000000000000';
	}
	public get empty(): string {
		return Guid.empty;
	}
	public static isValid(str: string): boolean {
		const validRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
		return validRegex.test(str);
	}
	private value: string = this.empty;

	constructor(value?: string) {
		if (value) {
			if (Guid.isValid(value)) {
				this.value = value;
			}
		}
	}
	public toString() {
		return this.value;
	}

	public toJSON(): string {
		return this.value;
	}
}

export const fetchDeviceCookieValue = (emailID: string): string => {
	const length = emailID.length;
	const index = emailID.lastIndexOf('@');
	const cookieID = `${emailID.slice(0, index)}_${emailID.slice(index + 1, length)}_Device`;
	const cookies = document.cookie.split('; ');
	const cookie = cookies.find((cookie) => cookie.startsWith(`${cookieID}=`));
	if (cookie) {
		const value = cookie.split('=')[1];
		return value;
	}
	return '';
};

export const fetchAccountRegistrationConsentCookieValue = (emailID: string): string => {
	const length = emailID.length;
	const index = emailID.lastIndexOf('@');
	const cookieID = `${emailID.slice(0, index)}_${emailID.slice(index + 1, length)}_AccountRegistrationConsent`;
	const cookies = document.cookie.split('; ');
	const cookie = cookies.find((cookie) => cookie.startsWith(`${cookieID}=`));
	if (cookie) {
		const value = cookie.split('=')[1];
		return value;
	}
	return '';
};

export const sanitizeFileName = (fileName: string): string => {
	return fileName.replace(/[^0-9a-zA-Z. ]+/g, '_');
};

export const getPageName = () => {
	const pathname = window.location.pathname;
	const segments = pathname.split('/');
	const pageName = segments[segments.length - 1];
	return pageName;
};

export function openWindowWithPostRequest(
	url: string,
	data: string | null,
	fieldName: string,
	redirectionURLPath?: string,
	clientType?: string,
	oneHubUserEmail?: string,
	cpaUserId?: string,
) {
	var winName = '_self';
	var winURL = url;
	var form = document.createElement('form');
	form.setAttribute('method', 'post');
	form.setAttribute('action', winURL);
	form.setAttribute('target', winName);
	form.setAttribute('enctype', 'application/json');
	var input = document.createElement('input');
	input.type = 'hidden';
	input.name = fieldName;
	input.value = JSON.stringify(data);
	form.appendChild(input);
	if (clientType) {
		var iClientType = document.createElement('input');
		iClientType.type = 'hidden';
		iClientType.name = 'clientType';
		iClientType.value = clientType;
		form.appendChild(iClientType);
	}
	if (redirectionURLPath) {
		var iRedirectionPath = document.createElement('input');
		iRedirectionPath.type = 'hidden';
		iRedirectionPath.name = 'redirectionSourcePage';
		iRedirectionPath.value = redirectionURLPath;
		form.appendChild(iRedirectionPath);
	}
	if (oneHubUserEmail) {
		var oneHubEmail = document.createElement('input');
		oneHubEmail.type = 'hidden';
		oneHubEmail.name = 'oneHubEmail';
		oneHubEmail.value = oneHubUserEmail;
		form.appendChild(oneHubEmail);
	}
	if (cpaUserId) {
		var cpaUserIdControl = document.createElement('input');
		cpaUserIdControl.type = 'hidden';
		cpaUserIdControl.name = 'cpaUserId';
		cpaUserIdControl.value = cpaUserId;
		form.appendChild(cpaUserIdControl);
	}
	document.body.appendChild(form);

	form.target = winName;
	form.submit();
	document.body.removeChild(form);
}
export const convertISODateToLocalDate = (isoDate: string) => {
	const date = new Date(isoDate);
	return getFormattedDate(date);
};

export const getFormattedDate = (date: Date) => {
	if (!isNaN(date.getTime())) {
		const formattedDate =
			(date.getMonth() + 1).toString().padStart(2, '0') +
			'/' +
			date.getDate().toString().padStart(2, '0') +
			'/' +
			date.getFullYear().toString().padStart(4, '0');
		return formattedDate;
	}
};

export const validatePhone = (phoneNoId: any) => {
	var phoneNo = phoneNoId.trim();
	if (phoneNo.trim() == '') {
		UTENotifier.Warning(VALIDATION_CONSTANTS.EMPTY_PHONE_NUMBER_WARNING);
		return false;
	} else if (phoneNo.trim().length < 10 || /^[0-9]{1,10}$/.test(phoneNo) == false) {
		UTENotifier.Warning(VALIDATION_CONSTANTS.INVALID_PHONE_NUMBER_LEGTH_WARNING);
		return false;
	} else return true;
};

export const validateCountryCode = (countryCode: any) => {
	if (countryCode.trim() === '') {
		UTENotifier.Warning(VALIDATION_CONSTANTS.COUNTRY_CODE_WARNING);
		return false;
	} else return true;
};

export const nullAndEmptyCheck = (text: string) => {
	if (text == undefined || text == null || text.trim() == '') {
		return false;
	}
	return true;
};

export const apiCatchHandler = (error: AxiosError<ErrorResponse>, data: any, callback?: (data: any) => void) => {
	const errorResponseStatus = error.response?.status;
	if (errorResponseStatus !== 419 && errorResponseStatus !== 403 && errorResponseStatus !== 401) {
		callback && callback(data);
	}
};
export const apiCatchHandlerNoData = (error: AxiosError<ErrorResponse>, callback: () => void) => {
	const errorResponseStatus = error.response?.status;
	if (errorResponseStatus !== 419 && errorResponseStatus !== 403 && errorResponseStatus !== 401) {
		callback && callback();
	}
};

export const getDuration = (createdOn: Date) => {
	const currentDate = new Date();
	let createdDate;
	if (createdOn.toString().includes('Z')) {
		createdDate = new Date(createdOn);
	} else {
		createdDate = new Date(createdOn + 'Z');
	}
	const diff = currentDate.getTime() - createdDate.getTime();
	const diffInMinutes = Math.floor(diff / 60000);
	const diffInHours = Math.floor(diffInMinutes / 60);
	const diffInDays = Math.floor(diffInHours / 24);
	const diffInWeeks = Math.floor(diffInDays / 7);
	const diffInMonths = Math.floor(diffInDays / 30.44);
	const diffInYears = Math.floor(diffInDays / 365.25);

	if (diffInYears > 0) {
		return `${diffInYears} YEAR${diffInYears > 1 ? 'S' : ''} AGO`;
	} else if (diffInMonths > 0) {
		return `${diffInMonths} MONTH${diffInMonths > 1 ? 'S' : ''} AGO`;
	} else if (diffInWeeks > 0) {
		return `${diffInWeeks} WEEK${diffInWeeks > 1 ? 'S' : ''} AGO`;
	} else if (diffInDays > 1) {
		return `${diffInDays} DAYS AGO`;
	} else if (diffInDays === 1) {
		return `YESTERDAY`;
	} else if (diffInHours > 0) {
		return `${diffInHours}H AGO`;
	} else {
		return `${diffInMinutes}M AGO`;
	}
};

export const formatDate = (dateString: string) => {
	const date = new Date(dateString + 'Z');
	const now = new Date();

	const oneWeekAgo = new Date();
	oneWeekAgo.setDate(now.getDate() - 7);

	const optionsWeekday = { weekday: 'short' };
	const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true };
	const optionsOlder = { month: 'short', day: 'numeric' };

	if (date >= oneWeekAgo) {
		const weekday = new Intl.DateTimeFormat('en-US', optionsWeekday).format(date);
		const time = new Intl.DateTimeFormat('en-US', optionsTime).format(date);
		return `${weekday} at ${time}`;
	} else {
		return new Intl.DateTimeFormat('en-US', optionsOlder).format(date);
	}
};

export const getNumberOfFilesAndFoldersCount = (node: ITaxRecord) => {
	let fileCount = 0;
	let folderCount = 0;
	const traverse = (currentNode: ITaxRecord) => {
		if (currentNode.type === FileSystemType.File) {
			fileCount++;
		} else if (currentNode.type === FileSystemType.Folder) {
			folderCount++;
			currentNode.children.forEach(traverse);
		}
	};
	traverse(node);
	return fileCount;
};

export const getTaxYearFromName = (name: string) => {
	const length = TAX_RETURN_FOLDER_NAME.length;
	const taxYear = name.substring(length, name.length);
	return isNaN(Number(taxYear)) ? 0 : Number(taxYear);
};

export const isFilePreviewAvailable = (document: ITaxDocument): boolean => {
	const nameArr = document.efileFormName.split('.');
	const extension = nameArr[nameArr.length - 1].toLowerCase();
	return EXCHANGE_FILE_TYPES.includes(extension);
};

export const getIconWidth = (task: IPendingTaxReturn): number => {
	if (task.isMFJ && !task.isDelegated && !task.isReminderSent) {
		return 17;
	} else if (task.isMFJ && task.isDelegated && !task.isReminderSent) {
		return 124;
	} else if (task.isMFJ && !task.isDelegated && task.isReminderSent) {
		return 116;
	} else if (task.isMFJ && task.isDelegated && task.isReminderSent) {
		return 223;
	} else if (!task.isMFJ && !task.isDelegated && !task.isReminderSent) {
		return 0;
	} else if (!task.isMFJ && task.isDelegated && !task.isReminderSent) {
		return 107;
	} else if (!task.isMFJ && !task.isDelegated && task.isReminderSent) {
		return 99;
	} else if (!task.isMFJ && task.isDelegated && task.isReminderSent) {
		return 206;
	} else {
		return 0;
	}
};

export const getDueDate = (date: Date): string => {
	const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	// Format the date as "Aug 3, 2024"
	return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
};

export const getFileExtension = (fileName: string) => {
	const index = fileName.lastIndexOf('.');
	return index >= 0 ? fileName.substring(index + 1, fileName.length) : '';
};

// SSE transform UserActivity Object to type TaxDocument Object for download api call.
export const transformUserActivityToTaxDocument = (userActivity: IUserActivity): ITaxDocument => {
	const { eventData, productType } = userActivity;
	const { documentId, fileGuid, fileName, signerType, exchangeFileType, exchangeFileData } = eventData;

	let file: ITaxDocument = {
		documentId: documentId,
		documentGuid: fileGuid || '00000000-0000-0000-0000-000000000000',
		taxYear: new Date().getFullYear(),
		documentStatus: 0,
		signatureStatus: 0,
		returnType: 0,
		clientGuid: '00000000-0000-0000-0000-000000000000',
		signerType: signerType,
		signedOn: new Date().toISOString(),
		efileFormName: fileName,
		toolTip: 0,
		productType: productType,
		fileType: exchangeFileType,
		isMessagePresent: exchangeFileData?.isMessagePresent || false,
		messageGuid: exchangeFileData?.messageGuid || '00000000-0000-0000-0000-000000000000',
		clientId: '',
		signerName: '',
		fileGuid: exchangeFileData?.fileGuid || '00000000-0000-0000-0000-000000000000',
	};

	return file;
};

export const setInvalidEmailCookieCount = () => {
	let invalidEmailCount = Cookies.get('invalidEmailCount');
	if (invalidEmailCount) {
		invalidEmailCount = parseInt(invalidEmailCount, 10) + 1;
	} else {
		invalidEmailCount = 1;
	}
	Cookies.set('invalidEmailCount', invalidEmailCount, { expires: 1 / 24, secure: true, sameSite: 'Strict' });
};

export const saveAccountRegistrationConsentCookie = (emailID: string, consent: string) => {
	const length = emailID.length;
	const index = emailID.lastIndexOf('@');
	const cookieID = `${emailID.slice(0, index)}_${emailID.slice(index + 1, length)}_AccountRegistrationConsent`;
	Cookies.set(cookieID, consent, { secure: true, sameSite: 'Strict' });
};

export const showCaptcha = (): Boolean => {
	let invalidEmailCount = Cookies.get('invalidEmailCount');
	if (invalidEmailCount) {
		return MAX_FAILED_ATTEMPT_COUNT <= parseInt(invalidEmailCount, 10);
	} else {
		return false;
	}
};
