import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from 'src/helper/constants';
import { IRecoveryModel, IRecoveryPopUpModel } from './recovery-popup-types';
import { apiCatchHandler, apiCatchHandlerNoData } from 'src/helper/utils';

export const showRecoveryPopup = (successCallback: (response: boolean) => void, errorCallback: () => void) => {
	axios
		.get(`${API_BASE_URL}/api/recovery/show-recovery-popup`)
		.then((response: AxiosResponse<boolean>) => {
			successCallback(response.data);
		})
		.catch((error) => {
			apiCatchHandler(error, errorCallback);
		});
};

export const onSubmitRecovery = (
	recoveryModel: IRecoveryPopUpModel,
	successCallback: (response: boolean) => void,
	errorCallback: () => void,
) => {
	axios
		.post(`${API_BASE_URL}/api/recovery/update-recovery`, recoveryModel, {})
		.then((response: AxiosResponse<boolean>) => {
			successCallback(response.data);
		})
		.catch((error) => {
			apiCatchHandlerNoData(error, errorCallback);
		});
};

export const getRecoveryDetails = (successCallback: (response: IRecoveryModel) => void, errorCallback: () => void) => {
	axios
		.get(`${API_BASE_URL}/api/recovery/get-recovery-details`)
		.then((response: AxiosResponse<IRecoveryModel>) => {
			successCallback(response.data);
		})
		.catch((error) => {
			apiCatchHandler(error, errorCallback);
		});
};
