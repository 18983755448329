import React from 'react';

const BellSleepIcon: React.FC = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='100'
			height='100'
			fill='none'
			viewBox='0 0 100 100'>
			<path
				fill='#B5D5EA'
				stroke='#B5D5EA'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='3.35'
				d='M82.947 79.94V61.977a25.95 25.95 0 00-15.969-23.953 9.98 9.98 0 10-19.96 0 25.948 25.948 0 00-15.97 23.953v17.965a7.984 7.984 0 01-7.983 7.984h67.867a7.984 7.984 0 01-7.985-7.984z'></path>
			<path
				fill='#B5D5EA'
				d='M64.984 87.926a7.984 7.984 0 11-15.968 0'></path>
			<path
				stroke='#B5D5EA'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='3.35'
				d='M64.984 87.926a7.984 7.984 0 11-15.968 0M10.082 20.063h11.977L10.082 32.038h11.977M77.952 4.093h19.961l-19.96 19.96h19.96'></path>
			<path
				stroke='#0973BA'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='3.35'
				d='M75.947 79.94V61.977a25.95 25.95 0 00-15.969-23.953 9.98 9.98 0 10-19.96 0 25.948 25.948 0 00-15.97 23.953v17.965a7.984 7.984 0 01-7.983 7.984h67.867a7.984 7.984 0 01-7.985-7.984zM57.984 87.926a7.984 7.984 0 11-15.968 0M75.952 4.092h19.961l-19.96 19.961h19.96M8.082 20.063h11.977L8.082 32.038h11.977'></path>
		</svg>
	);
};

export default BellSleepIcon;
