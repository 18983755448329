import { ICompanyNameAndLogo, IUserDetail } from 'src/context/ute-auth-context/auth-provider-types';
import { ITaxDocument } from '../documents/documents-types';
import { ProductStatus } from 'src/helper/constants';

export enum ProductType {
	None = 0,
	Returns = 1,
	SignaturesOneOff = 2,
	SignaturesMailMerge = 3,
	Organizers = 4,
	Extensions = 5,
	Exchange = 6,
	Gather = 7,
	TicTieCalculate = 8,
	SSSuitePortal = 1001,
}

export const enum ClientTypes {
	Undefined = 0,
	Taxpayer,
	Spouse,
	Partner,
	PartnerShip,
	ShareHolder,
	CcRecipient,
	Delegatee,
	Controller,
}

export const enum EngagementTypes {
	None,
	E1040 = 1,
	E1065 = 2,
	E1120 = 3,
	E1120S = 4,
	E1041 = 5,
	E1040NR = 6,
	E990 = 7,
	E990PF = 8,
	E990EZ = 9,
}

export interface ICompanyProduct {
	productId: ProductType;
	productStatus: ProductStatus;
	isSubscribed: boolean;
	isProduct: boolean;
}

export interface ICompanyProductsResponse {
	error: boolean;
	products: ICompanyProduct[];
}

export type ICompanyNameAndLogoResponse = {
	companyNameAndLogo: ICompanyNameAndLogo;
	error: boolean;
};

export type IUTEEnabledResponse = {
	uteEnabled: boolean;
	error: boolean;
};

export const uteEnabledDefault = {
	uteEnabled: false,
	error: false,
};

export type IUTESubDomainResponse = {
	companyId: number;
	url: string;
};

export type RefreshTokenRequestVM = {
	registrationId: number;
	refreshToken: string;
	accessToken: string;
};

export type RefreshTokenResponseVM = {
	isSuccess: boolean;
	refreshToken: string;
	accessToken: string;
};

export type InternalAccessTokenResponseVM = {
	accessToken: string;
	isSuccess: boolean;
	refreshToken: string;
	id: number;
	name: string;
	emailAddress: string;
	companyId: number;
};

export type ClientViewAccessTokenResponseVM = {
	accessToken: string;
	isSuccess: boolean;
	refreshToken: string;
	id: number;
	name: string;
	emailAddress: string;
	companyId: number;
	isClientView: boolean;
};

export type IUserDetailResponse = {
	userDetail: IUserDetail;
	error: boolean;
};

export type IUserExistsResponse = {
	isExists: boolean;
	error: boolean;
};

export const emptyCompanyProducts: ICompanyProduct[] = [];

export const emptyUserDetail: IUserDetail = {
	id: -1,
	name: '',
	emailAddress: '',
	otpAttemptCount: 0,
	pinAttemptCount: 0,
};

export const emptyInternalAccessTokenResponse: InternalAccessTokenResponseVM = {
	accessToken: '',
	isSuccess: false,
	refreshToken: '',
	id: -1,
	name: '',
	emailAddress: '',
	companyId: -1,
};

export const emptyClientViewAccessTokenResponse: ClientViewAccessTokenResponseVM = {
	accessToken: '',
	isSuccess: false,
	refreshToken: '',
	id: -1,
	name: '',
	emailAddress: '',
	companyId: -1,
	isClientView: false,
};

export enum NotificationType {
	Info,
	Error,
	Success,
	Warning,
}

export enum TaxpayerRedirectionSourcePage {
	None = 0,
	TaxpayerFlow = 1,
	Signcompleted = 2,
	Pay = 3,
	Download = 4,
	DistributeK1 = 5,
	DelegateeInitialize = 6,
	PreviewFlow = 7,
}

export enum SignatureRedirectionSourcePage {
	None = 0,
	Esign = 1,
	Download = 2,
	Declined = 3,
}

export type IDocumentResponse = {
	document: ITaxDocument;
	error: boolean;
};

export enum UserRole {
	OneHub = 'OneHub',
	CPA = 'CPA',
}

export type IUserRoleResponse = {
	error: boolean;
	role: UserRole;
};

export type IClientTracking = {
	registrationId: number;
	eventId: ClientHubEvents;
	actedBy: string;
	eventData: IEventDetails;
};

export enum ClientHubEvents {
	None = 0,
	PINValidationSuccess = 1,
	OTPMailDelivered = 2,
	OTPAuthenticationFailed = 3,
	OTPAccessLocked = 4,
	PINAuthenticationFailed = 5,
	PINAccessLocked = 6,
	ResetPINRequested = 7,
	NewPINGenerated = 8,
	LoginSuccess = 9,
	MergedEmailId = 10,
	UnMergedEmailId = 11,
	PINUpdated = 12,
	EmailIDUpdated = 13,
	DocumentDownloadedFromView = 14,
}

export type IEventDetails = {
	fileName?: string;
	emailId: string;
	oldEmailId?: string;
	newEmailId?: string;
};

export enum OTPValidationStatus {
	Failure = 0,
	Success = 1,
	LockedByOTP = 3,
	OTPExpired = 4,
	InvalidOTP = 5,
}
export interface IPendoMetadata {
	isTestCompany: boolean;
	subscription: string;
};
