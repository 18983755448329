import axios, { AxiosResponse, AxiosError } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { UTENotifier } from 'src/modules/notification/ute-notifier';
import {  useContext } from 'react';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';

export type ErrorResponse = {
	logoutCause: LogoutCause;
	message:string
};

const enum LogoutCause {
	None = 0,
	UserEmailChange = 1,
	PINReset = 2,
}

let isToasterMessageSet:boolean =false;

const AxiosInterceptorsSetup = (authToken: string, navigate: NavigateFunction) => {
	const { updateAuthToken, updateRefreshToken } = useContext(AuthContext);

	axios.interceptors.request.use((config: any): any => {
		if (process.env.REACT_APP_API_URL) {
			let isApiUrl = config.url && (config.url.startsWith(process.env.REACT_APP_API_URL)
			 	|| config.url.startsWith(process.env.REACT_APP_SSRTP_API_URL)|| config.url.startsWith(process.env.REACT_APP_EXTTP_API_URL));
			if (isApiUrl) {
				config.withCredentials = true;
				if (authToken) config.headers['Authorization'] = `Bearer ${authToken}`;
			}
		}
		return config;
	});

	axios.interceptors.response.use(
		function (response: AxiosResponse) {
			return response;
		},
		function (error: AxiosError<ErrorResponse>) {
			const errorResponseStatus = error.response?.status;
			if (errorResponseStatus == 512) {
				if (location.pathname !== '/restricted-access') {
					error.response && navigate('/restricted-access');
				}
			} else if (errorResponseStatus == 419) {
				const data = error.response?.data;
				if(data && !isToasterMessageSet){
					UTENotifier.Success(data.message);
					setInterval(() => {
						updateAuthToken('');
						updateRefreshToken('');
					}, 5000);
				} 
				isToasterMessageSet=true;				
			} else if (errorResponseStatus === 403) {
				updateAuthToken('');
				updateRefreshToken('');
				window.location.href = "/disabled";						
			} 
			else if (errorResponseStatus === 401) {
				const configUrlInfo = error.response?.config.url?.split('/');
				const validURLs = ['negotiate'];
				const url = configUrlInfo && configUrlInfo.length > 0 && configUrlInfo[configUrlInfo.length - 1];
				if(url && validURLs.includes(url)){
					return;	
				}	
				updateAuthToken('');
				updateRefreshToken('');	
				window.location.href = "/";					
			} 
			else {
				if (window.location.href.includes('restricted-access')) {
					navigate('/');
				}
			}
			return Promise.reject(error);
		},
	);
};

export default AxiosInterceptorsSetup;
