export const ToasterCloseIcon: React.FC = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'>
			<g clipPath='url(#clip0_1396_34627)'>
				<path
					d='M0.292542 0.292105C0.48007 0.104634 0.734378 -0.000681838 0.999542 -0.000681838C1.26471 -0.000681838 1.51901 0.104634 1.70654 0.292105L7.99954 6.5851L14.2925 0.292105C14.3848 0.196594 14.4951 0.120412 14.6171 0.0680032C14.7391 0.0155942 14.8704 -0.011992 15.0031 -0.0131458C15.1359 -0.0142997 15.2676 0.0110021 15.3905 0.0612829C15.5134 0.111564 15.625 0.185817 15.7189 0.27971C15.8128 0.373602 15.8871 0.485254 15.9374 0.608151C15.9876 0.731047 16.0129 0.862727 16.0118 0.995506C16.0106 1.12829 15.9831 1.25951 15.9306 1.38151C15.8782 1.50351 15.8021 1.61386 15.7065 1.7061L9.41354 7.9991L15.7065 14.2921C15.8887 14.4807 15.9895 14.7333 15.9872 14.9955C15.9849 15.2577 15.8798 15.5085 15.6944 15.6939C15.509 15.8793 15.2581 15.9845 14.9959 15.9868C14.7337 15.9891 14.4811 15.8883 14.2925 15.7061L7.99954 9.4131L1.70654 15.7061C1.51794 15.8883 1.26534 15.9891 1.00314 15.9868C0.740944 15.9845 0.490132 15.8793 0.304724 15.6939C0.119316 15.5085 0.0141467 15.2577 0.0118683 14.9955C0.00958985 14.7333 0.110384 14.4807 0.292542 14.2921L6.58554 7.9991L0.292542 1.7061C0.105071 1.51858 -0.000244141 1.26427 -0.000244141 0.999105C-0.000244141 0.73394 0.105071 0.479632 0.292542 0.292105Z'
					fill='#898D91'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1396_34627'>
					<rect
						width='16'
						height='16'
						fill='white'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export const SuccessIcon: React.FC = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'>
			<g clipPath='url(#clip0_2251_2720)'>
				<path
					d='M15.9998 7.99927C15.9998 10.121 15.1569 12.1558 13.6566 13.6561C12.1563 15.1564 10.1215 15.9993 7.99976 15.9993C5.87802 15.9993 3.84319 15.1564 2.3429 13.6561C0.842611 12.1558 -0.000244141 10.121 -0.000244141 7.99927C-0.000244141 5.87754 0.842611 3.8427 2.3429 2.34241C3.84319 0.842122 5.87802 -0.000732422 7.99976 -0.000732422C10.1215 -0.000732422 12.1563 0.842122 13.6566 2.34241C15.1569 3.8427 15.9998 5.87754 15.9998 7.99927ZM12.0298 4.96927C11.9583 4.89809 11.8733 4.84204 11.7797 4.80448C11.6861 4.76692 11.5859 4.74863 11.4851 4.75068C11.3843 4.75273 11.2849 4.7751 11.1929 4.81644C11.1009 4.85777 11.0182 4.91724 10.9498 4.99127L7.47676 9.41627L5.38376 7.32227C5.24158 7.18979 5.05353 7.11766 4.85923 7.12109C4.66493 7.12452 4.47955 7.20323 4.34213 7.34065C4.20472 7.47806 4.12601 7.66344 4.12258 7.85775C4.11915 8.05205 4.19128 8.24009 4.32376 8.38227L6.96976 11.0293C7.04104 11.1004 7.12592 11.1565 7.21934 11.1941C7.31276 11.2318 7.4128 11.2502 7.5135 11.2483C7.6142 11.2465 7.71349 11.2243 7.80545 11.1833C7.89741 11.1422 7.98016 11.083 8.04876 11.0093L12.0408 6.01927C12.1768 5.87777 12.252 5.68854 12.2502 5.49223C12.2483 5.29591 12.1695 5.10815 12.0308 4.96927H12.0298Z'
					fill='#1F8747'
				/>
			</g>
			<defs>
				<clipPath id='clip0_2251_2720'>
					<rect
						width='16'
						height='16'
						fill='white'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export const DangerIcon: React.FC = () => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g
				id='icon-wrapper'
				clipPath='url(#clip0_2456_816)'>
				<path
					id='Union'
					d='M8.9817 1.56522C8.88271 1.39278 8.73998 1.24952 8.56791 1.14989C8.39584 1.05026 8.20053 0.997803 8.0017 0.997803C7.80287 0.997803 7.60756 1.05026 7.43549 1.14989C7.26343 1.24952 7.12069 1.39278 7.0217 1.56522L0.164702 13.2322C-0.292298 14.0102 0.255702 14.9992 1.1447 14.9992H14.8577C15.7467 14.9992 16.2957 14.0092 15.8377 13.2322L8.9817 1.56522ZM7.9997 4.99922C8.5347 4.99922 8.9537 5.46122 8.8997 5.99422L8.5497 9.50122C8.53794 9.63899 8.4749 9.76733 8.37306 9.86086C8.27121 9.95438 8.13797 10.0063 7.9997 10.0063C7.86143 10.0063 7.72819 9.95438 7.62635 9.86086C7.5245 9.76733 7.46146 9.63899 7.4497 9.50122L7.0997 5.99422C7.08713 5.86845 7.10105 5.74145 7.14055 5.62139C7.18005 5.50132 7.24426 5.39087 7.32905 5.29713C7.41383 5.20339 7.51731 5.12846 7.63282 5.07715C7.74833 5.02584 7.87331 4.99929 7.9997 4.99922ZM8.0017 10.9992C8.26692 10.9992 8.52127 11.1046 8.70881 11.2921C8.89634 11.4796 9.0017 11.734 9.0017 11.9992C9.0017 12.2644 8.89634 12.5188 8.70881 12.7063C8.52127 12.8939 8.26692 12.9992 8.0017 12.9992C7.73649 12.9992 7.48213 12.8939 7.2946 12.7063C7.10706 12.5188 7.0017 12.2644 7.0017 11.9992C7.0017 11.734 7.10706 11.4796 7.2946 11.2921C7.48213 11.1046 7.73649 10.9992 8.0017 10.9992Z'
					fill='#CC4A43'
				/>
			</g>
			<defs>
				<clipPath id='clip0_2456_816'>
					<rect
						width='16'
						height='16'
						fill='white'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export const WarningIcon: React.FC = () => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_7401_1164)'>
				<path
					d='M8.98195 1.56595C8.88296 1.39352 8.74022 1.25025 8.56815 1.15062C8.39608 1.051 8.20078 0.998535 8.00195 0.998535C7.80312 0.998535 7.60781 1.051 7.43574 1.15062C7.26367 1.25025 7.12094 1.39352 7.02195 1.56595L0.164946 13.233C-0.292054 14.011 0.255946 15 1.14495 15H14.8579C15.7469 15 16.2959 14.01 15.8379 13.233L8.98195 1.56595ZM7.99995 4.99995C8.53495 4.99995 8.95395 5.46195 8.89995 5.99495L8.54995 9.50195C8.53819 9.63972 8.47515 9.76806 8.3733 9.86159C8.27145 9.95511 8.13822 10.007 7.99995 10.007C7.86167 10.007 7.72844 9.95511 7.62659 9.86159C7.52474 9.76806 7.46171 9.63972 7.44995 9.50195L7.09995 5.99495C7.08738 5.86919 7.10129 5.74218 7.14079 5.62212C7.18029 5.50206 7.2445 5.3916 7.32929 5.29786C7.41408 5.20413 7.51756 5.12919 7.63307 5.07788C7.74858 5.02657 7.87355 5.00002 7.99995 4.99995ZM8.00195 11C8.26716 11 8.52152 11.1053 8.70905 11.2928C8.89659 11.4804 9.00195 11.7347 9.00195 12C9.00195 12.2652 8.89659 12.5195 8.70905 12.7071C8.52152 12.8946 8.26716 13 8.00195 13C7.73673 13 7.48238 12.8946 7.29484 12.7071C7.1073 12.5195 7.00195 12.2652 7.00195 12C7.00195 11.7347 7.1073 11.4804 7.29484 11.2928C7.48238 11.1053 7.73673 11 8.00195 11Z'
					fill='#D69F38'
				/>
			</g>
			<defs>
				<clipPath id='clip0_7401_1164'>
					<rect
						width='16'
						height='16'
						fill='white'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
