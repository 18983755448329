import { ClientTypes } from 'src/modules/common/common-types';

export enum NotificationEventType {
	Notification,
	Reminder,
}

export enum NotificationMailEventType {
	InitialEmail,
	VoucherReminderEmail,
	SigningReminderEmail,
	InvoicePaymentEmail,
	RecallNotificationEmail,
	DownloadESignedFormsEmail,
	UploadEstimatesEmail,
	SignatureDelegateeEmail,
	DelegationCancelledEmail,
	DownloadTaxReturnEmail,
	DelegateeDeclinedEmail,
	SignatureSignedEmail,
	SignatureCancelledEmail,
	SignatureDeclinedEmail,
	SpouseReviewMail,
	OrganizerCompleted,
	ReopenedGather,
	OrganizerReminderEmail,
	SourceDocumentReminderMail,
	SourceDocumentMarkedCompletedToCpa,
	SourceDocumentUploadReopened,
	ReopenedOrganizer,
	DraftInitialEmail,
	DocumentRequestDocumentReminder,
	DocumentRequestReminder,
	MessageExpiryReminder,
	DocumentRequestReopen,
	DocumentRequestMore,
	InitialMessageEmail,
	InitialDocumentRequestEmail,
}

export interface INotification {
	createdOn: Date;
	eventType: NotificationEventType;
	id: number;
	isAccessed: boolean;
	notificationType: NotificationMailEventType;
	productId: number;
	registrationId: number;
	metaData: string;
}

export const initialNotificationData: INotification = {
	createdOn: new Date(),
	eventType: NotificationEventType.Notification,
	id: 0,
	isAccessed: false,
	notificationType: NotificationMailEventType.InitialEmail,
	productId: 0,
	registrationId: 0,
	metaData: '',
};

export interface INotificationResponse {
	notifications: INotification[];
	error: boolean;
}

export const initialNotificationResponseData: INotificationResponse = {
	notifications: [],
	error: false,
};

export type INotificationContextValue = {
	userNotifications: INotification[];
	updateNotificationAlertMessage: (notificationInfo: INotificationAlertMessage) => void;
	updateNotifications: (notifications: INotification[]) => void;
	updateInitializationStatus: (value: boolean) => void;
	isConnectedInitialized: boolean;
};

export type INotificationAlertMessage = {
	users: number[];
	companyId: number;
	notificationId: number;
};

export const initialNotificationAlertMessage: INotificationAlertMessage = {
	users: [],
	companyId: 0,
	notificationId: 0,
};

export interface INotificationViewModel {
	clientGuid: string;
	clientType: ClientTypes;
}
