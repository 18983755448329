import React, { useContext, useEffect, useState } from 'react';
import styles from './dashboard.module.scss';
import ClockIcon from 'src/assets/svg/clock-icon';
import CheckListIcon from 'src/assets/svg/checklist-icon';
import {
	getDashboardDetails,
	getEXTPendingTasks,
	getPendingSignatureTasks,
	getSSEPendingTasks,
	getPendingTasks,
	getCompletedTasks,
} from './dashboard-api';
import { UTENotifier } from '../notification/ute-notifier';
import {
	ICompletedTaxReturn,
	ICompletedTaxReturnResponse,
	IDashboardOverview,
	IDashboardOverviewResponse,
	IPendingDocumentRequest,
	IPendingDocumentRequestResponse,
	IPendingSignatureReport,
	IPendingSignatureReportResponse,
	IPendingTaxReturn,
	IPendingTaxReturnResponse,
	initialDashboardData,
} from './dashboard-types';
import {
	DASHBOARD_CONSTANTS,
	LOGGED_IN_LAYOUT_CONSTANTS,
	ProductStatus,
	RECOVERY_CONSTANTS,
} from 'src/helper/constants';
import { convertISODateToLocalDate, getFormattedDate, Guid } from 'src/helper/utils';
import {
	generateExchangeTaxpayerAPIURL,
	generateExtTaxpayerRedirectionAPIURL,
	generateSignatureTaxpayerAPIURL,
	generateTaxpayerRedirectionAPIURL,
	generateTaxpayerClientViewAPIURL,
	generateEXTTaxpayerClientViewAPIURL,
} from '../common/common-api';
import {
	TaxpayerRedirectionSourcePage,
	ICompanyProductsResponse,
	ICompanyProduct,
	ProductType,
	ClientTypes,
} from 'src/modules/common/common-types';
import { getCompanyProducts } from 'src/modules/common/common-api';
import LoadingOverlay from 'react-loading-overlay';
import Loader from 'src/components/loader';
import ContactInformation from './contact-information';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';
import RecentActivity from '../recent-activity';
import { IUserActivity, OneHubEventType } from '../recent-activity/recent-activity-types';
import { saveUserActivity } from '../recent-activity/recent-activity-api';
import { ReviewPendingTasks } from './waiting-for-action-section/ReviewPendingTasks';
import { SignaturePendingTasks } from './waiting-for-action-section/SignaturePendingTasks';
import { DocumentRequestTask } from './waiting-for-action-section/DocumentRequestTask';
import SendFiles from '../send-files';
import UTEModalLayout from 'src/components/ute-modal-layout';
import CompletedTasksModal from './completed-tasks';
import { ExchangeFileType } from '../documents/documents-types';
import UTEClientViewWrapper from 'src/components/ute-client-view-wrapper';
import { showRecoveryPopup } from '../recovery-popup/recovery-popup-api';
import RecoveryPopup from '../recovery-popup';
import { initialRecoveryModelResponse } from '../recovery-popup/recovery-popup-types';

const productsToBeFetched = [ProductType.Returns, ProductType.Gather, ProductType.Organizers];

const Dashboard: React.FC = () => {
	const [dashboardOverview, setDashboardOverview] = useState<IDashboardOverview>(initialDashboardData);
	const [pendingTasks_ssr, setPendingTasks_ssr] = useState<IPendingTaxReturn[]>([]);
	const [tempPendingTasks_ssr, setTempPendingTasks_ssr] = useState<IPendingTaxReturn[]>([]);
	const [pendingTasks_ext, setPendingTasks_ext] = useState<IPendingTaxReturn[]>([]);
	const [tempPendingTasks_ext, setTempPendingTasks_ext] = useState<IPendingTaxReturn[]>([]);
	const [pendingSignatureTasks, setPendingSignatureTasks] = useState<IPendingSignatureReport[]>([]);
	const [tempPendingSignatureTasks, setTempPendingSignatureTasks] = useState<IPendingSignatureReport[]>([]);
	const [pendingDocumentRequestTasks, setPendingDocumentRequestTasks] = useState<IPendingDocumentRequest[]>([]);
	const [tempPendingDocumentRequestTasks, setTempPendingDocumentRequestTasks] = useState<IPendingDocumentRequest[]>([]);
	const [pendingTasks_gtr, setPendingTasks_gtr] = useState<IPendingTaxReturn[]>([]);
	const [tempPendingTasks_gtr, setTempPendingTasks_gtr] = useState<IPendingTaxReturn[]>([]);
	const [pendingTasks_org, setPendingTasks_org] = useState<IPendingTaxReturn[]>([]);
	const [tempPendingTasks_org, setTempPendingTasks_org] = useState<IPendingTaxReturn[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { lastLogin, completedTasksCount, pendingTasksCount, userName } = dashboardOverview;
	const { collapsedMenu, userDetail, companyProducts } = useContext(AuthContext);
	const [isExchangeSubscribedAndEnabled, setIsExchangeSubscribedAndEnabled] = useState(false);
	const [showCompletedTasksModal, setShowCompletedTasksModal] = useState<boolean>(false);
	const [completedTasks, setCompletedTasks] = useState<ICompletedTaxReturn[]>([]);
	const { isClientView } = useContext(AuthContext);
	const [isRecoveryPopup, setIsRecoveryPopup] = useState(false);

	useEffect(() => {
		fetchDashboardData();
		fetchPendingSignatureTasks();
		fetchEXTPendingTasks();
		fetchSSEDocumentRequest();
		fetchCompanyProducts();
		productsToBeFetched.forEach((product: ProductType) => fetchPendingTasks(product));
	}, []);

	useEffect(() => {
		const uteLoggedInUserFlag = localStorage.getItem(RECOVERY_CONSTANTS.UTE_LOGGEDIN_USER_FLAG);
		if (uteLoggedInUserFlag && uteLoggedInUserFlag === 'true') {
			showRecoveryPopup(
				(response: boolean) => {
					setIsRecoveryPopup(response);
					localStorage.removeItem(RECOVERY_CONSTANTS.UTE_LOGGEDIN_USER_FLAG);
				},
				() => {
					UTENotifier.Error(RECOVERY_CONSTANTS.ERROR_GET_RECOVERY);
				},
			);
		}
	}, []);

	const fetchDashboardData = () => {
		setIsLoading(true);
		getDashboardDetails((dashboardOverviewResponse: IDashboardOverviewResponse) => {
			setIsLoading(false);
			if (dashboardOverviewResponse.error) {
				UTENotifier.Error(DASHBOARD_CONSTANTS.ERROR_FETCH_DASHBOARD_OVERVIEW);
			} else {
				setDashboardOverview(dashboardOverviewResponse.dashboardDetails);
			}
		});
	};

	const fetchCompanyProducts = () => {
		if (companyProducts.length === 0) {
			setIsLoading(true);
			getCompanyProducts((response: ICompanyProductsResponse) => {
				setIsLoading(false);
				if (response.error) {
					UTENotifier.Error(LOGGED_IN_LAYOUT_CONSTANTS.ERROR_FETCHING_COMPANY_PRODUCTS);
				} else {
					setExchangeSubscribedAndEnabled(response.products);
				}
			});
		} else {
			setExchangeSubscribedAndEnabled(companyProducts);
		}
	};

	const setExchangeSubscribedAndEnabled = (products: ICompanyProduct[]) => {
		if (products.length > 0) {
			const exchangeProduct = products.find((product) => product.productId === ProductType.Exchange);
			if (exchangeProduct && exchangeProduct.isSubscribed && exchangeProduct.productStatus === ProductStatus.Enabled) {
				setIsExchangeSubscribedAndEnabled(true);
			}
		}
	};

	const fetchEXTPendingTasks = () => {
		setIsLoading(true);
		getEXTPendingTasks((pendingTasksResponse: IPendingTaxReturnResponse) => {
			setIsLoading(false);
			if (pendingTasksResponse.error) {
				UTENotifier.Error(DASHBOARD_CONSTANTS.ERROR_FETCH_PENDING_TASKS);
			} else {
				setPendingTasks_ext(pendingTasksResponse.pendingTaxReturns);
				setTempPendingTasks_ext(pendingTasksResponse.pendingTaxReturns.slice(0, 3));
			}
		});
	};

	const fetchSSEDocumentRequest = () => {
		setIsLoading(true);
		getSSEPendingTasks((documentRequestResponse: IPendingDocumentRequestResponse) => {
			setIsLoading(false);
			if (documentRequestResponse.error) {
				UTENotifier.Error(DASHBOARD_CONSTANTS.ERROR_FETCH_PENDING_TASKS);
			} else {
				setPendingDocumentRequestTasks(documentRequestResponse.pendingDocumentRequests);
				setTempPendingDocumentRequestTasks(documentRequestResponse.pendingDocumentRequests.slice(0, 3));
			}
		});
	};

	const fetchPendingSignatureTasks = () => {
		setIsLoading(true);
		getPendingSignatureTasks((pendingSignatureTasksResponse: IPendingSignatureReportResponse) => {
			setIsLoading(false);
			if (pendingSignatureTasksResponse.error) {
				UTENotifier.Error(DASHBOARD_CONSTANTS.ERROR_FETCH_PENDING_TASKS);
			} else {
				setPendingSignatureTasks(pendingSignatureTasksResponse.pendingSignatureReports);
				setTempPendingSignatureTasks(pendingSignatureTasksResponse.pendingSignatureReports.slice(0, 3));
			}
		});
	};

	const fetchPendingTasks = (product: ProductType) => {
		setIsLoading(true);
		getPendingTasks(product, (pendingTasksResponse: IPendingTaxReturnResponse) => {
			setIsLoading(false);
			if (pendingTasksResponse.error) {
				UTENotifier.Error(DASHBOARD_CONSTANTS.ERROR_FETCH_PENDING_TASKS);
			} else {
				if (product === ProductType.Returns) {
					setPendingTasks_ssr(pendingTasksResponse.pendingTaxReturns);
					setTempPendingTasks_ssr(pendingTasksResponse.pendingTaxReturns.slice(0, 3));
				} else if (product === ProductType.Gather) {
					setPendingTasks_gtr(pendingTasksResponse.pendingTaxReturns);
					setTempPendingTasks_gtr(pendingTasksResponse.pendingTaxReturns.slice(0, 3));
				} else if (product === ProductType.Organizers) {
					setPendingTasks_org(pendingTasksResponse.pendingTaxReturns);
					setTempPendingTasks_org(pendingTasksResponse.pendingTaxReturns.slice(0, 3));
				}
			}
		});
	};

	const isLastLoginDateValid = () => {
		if (convertISODateToLocalDate(lastLogin) === DASHBOARD_CONSTANTS.FIRST_LOGIN_DATE) {
			return false;
		}
		return true;
	};

	const handleOpenTaxreturn_ext = (document: IPendingTaxReturn) => {
		if (!isClientView) {
			if (
				document.delegateeClientGuid !== null &&
				document.delegateeClientGuid !== Guid.empty &&
				document.isDelegated == false
			)
				generateExtTaxpayerRedirectionAPIURL(
					document.delegateeClientGuid,
					TaxpayerRedirectionSourcePage.DelegateeInitialize,
					ClientTypes.Delegatee,
				);
			else
				generateExtTaxpayerRedirectionAPIURL(
					document.clientGuid,
					TaxpayerRedirectionSourcePage.TaxpayerFlow,
					ClientTypes.Taxpayer,
					userDetail.emailAddress,
				);
			let userActivity: IUserActivity = {
				eventId: document.isVisited ? OneHubEventType.Continue : OneHubEventType.GetStarted,
				eventData: {
					documentId: document.documentId,
					fileName: `${document.name} ${document.taxyear} Tax Return Extensions.pdf`,
					signerType: document.signerType,
				},
				productType: ProductType.Extensions,
			};
			saveUserActivity(userActivity);
		} else {
			generateEXTTaxpayerClientViewAPIURL(
				document.clientGuid,
				ProductType.Extensions,
				ClientTypes.Taxpayer,
				TaxpayerRedirectionSourcePage.PreviewFlow,
			);
		}
	};

	const handleOpenSignatureReport = (signatureReport: IPendingSignatureReport) => {
		generateSignatureTaxpayerAPIURL(signatureReport.clientGuid.toString());
		if (!isClientView) {
			let userActivity: IUserActivity = {
				eventId: signatureReport.isDocumentAccessed ? OneHubEventType.Continue : OneHubEventType.GetStarted,
				eventData: {
					documentId: signatureReport.signingId,
					signingId: signatureReport.signingId,
					fileName: `${signatureReport.name} Signature Request ${getFormattedDate(new Date(signatureReport.sentOn))}`,
					signerType: ClientTypes.Taxpayer,
					clientGuid: signatureReport.clientGuid.toString(),
				},
				productType: ProductType.SignaturesOneOff,
			};
			saveUserActivity(userActivity);
		}
	};

	const handleOpenTaxreturn = (document: IPendingTaxReturn, product: ProductType) => {
		if (!isClientView) {
			if (
				document.delegateeClientGuid !== null &&
				document.delegateeClientGuid !== Guid.empty &&
				document.isDelegated == false
			)
				generateTaxpayerRedirectionAPIURL(
					document.delegateeClientGuid,
					product,
					TaxpayerRedirectionSourcePage.DelegateeInitialize,
					ClientTypes.Delegatee,
				);
			else
				generateTaxpayerRedirectionAPIURL(
					document.clientGuid,
					product,
					TaxpayerRedirectionSourcePage.TaxpayerFlow,
					ClientTypes.Taxpayer,
					userDetail.emailAddress,
				);
			let userActivity: IUserActivity = {
				eventId: document.isVisited ? OneHubEventType.Continue : OneHubEventType.GetStarted,
				eventData: {
					documentId: document.documentId,
					fileName: `${document.spouseName ?? document.name} ${document.taxyear} Tax Return.pdf`,
					signerType: document.signerType,
					userName: document.spouseName ?? document.name,
					gatherFileData: {
						taxYear: document.taxyear,
					},
				},
				productType: product,
			};
			saveUserActivity(userActivity);
		} else {
			generateTaxpayerClientViewAPIURL(
				document.clientGuid,
				product,
				ClientTypes.Taxpayer,
				TaxpayerRedirectionSourcePage.PreviewFlow,
			);
		}
	};

	const handleOpenDocumentRequest = (documentRequest: IPendingDocumentRequest) => {
		generateExchangeTaxpayerAPIURL(documentRequest.documentRequestGuid);
		let userActivity: IUserActivity = {
			eventId: documentRequest.isAccessed ? OneHubEventType.Continue : OneHubEventType.GetStarted,
			eventData: {
				documentId: documentRequest.documentRequestId,
				fileName: documentRequest.subject,
				signerType: ClientTypes.Undefined,
				exchangeFileType: ExchangeFileType.None,
				clientGuid: documentRequest.documentRequestGuid,
			},
			productType: ProductType.Exchange,
		};
		saveUserActivity(userActivity);
	};

	const openCompletedTasksModal = () => {
		setShowCompletedTasksModal(true);
	};

	const closeCompletedTasksModal = () => {
		setShowCompletedTasksModal(false);
	};

	const fetchCompletedTasks = () => {
		if (completedTasks.length > 0) {
			openCompletedTasksModal();
			return;
		} else {
			setIsLoading(true);
			getCompletedTasks((completedTasksResponse: ICompletedTaxReturnResponse) => {
				setIsLoading(false);
				if (completedTasksResponse.error) {
					UTENotifier.Error(DASHBOARD_CONSTANTS.ERROR_FETCH_COMPLETED_TASKS);
				} else {
					setCompletedTasks(completedTasksResponse.completedTasks);
					openCompletedTasksModal();
				}
			});
		}
	};

	return (
		<main className={`${styles.dashboard} ${collapsedMenu ? styles.collapsedWidth : styles.expandWidth}`}>
			<LoadingOverlay
				active={isLoading}
				spinner={<Loader />}
				text=''>
				<article className={styles.topSection}>
					<div className={styles.leftContainer}>
						{isLastLoginDateValid() ? (
							<div className={styles.lastLoginDate}>
								Last Login {lastLogin ? convertISODateToLocalDate(lastLogin) : ''}
							</div>
						) : (
							<></>
						)}
						<div className={`${styles.greeting} ${!isLastLoginDateValid() && styles.increaseMargin}`}>
							Hello, <span className={styles.greetingName}>{userName}</span>!
						</div>
					</div>
				</article>
				<article className={styles.mainContent}>
					<section
						className={styles.leftContainer}
						id='section-block'>
						<div className={styles.header}>Overview</div>
						<div className={styles.cardsContainer}>
							<div className={styles.card}>
								<ClockIcon />
								<span className={styles.count}>{pendingTasksCount}</span>
								<span className={styles.taskCategoryName}>
									Pending <br />
									Tasks
								</span>
							</div>
							<div
								className={`${styles.card} ${completedTasksCount ? styles.cursorPointer : ''}`}
								onClick={completedTasksCount ? fetchCompletedTasks : () => {}}>
								<CheckListIcon />
								<span className={styles.count}>{completedTasksCount}</span>
								<span className={styles.taskCategoryName}>
									Completed <br />
									Tasks
								</span>
							</div>
						</div>
						<div className={styles.actionCategories}>
							<div className={styles.actionCategoryHeader}>Waiting for Action</div>
							{/* For Signatures */}
							{Array.isArray(pendingSignatureTasks) && pendingSignatureTasks.length !== 0 ? (
								<SignaturePendingTasks
									pendingSignatureTasks={pendingSignatureTasks}
									tempPendingSignatureTasks={tempPendingSignatureTasks}
									setTempPendingSignatureTasks={setTempPendingSignatureTasks}
									handleOpenSignatureReport={handleOpenSignatureReport}
								/>
							) : (
								<></>
							)}

							{/* For SSR */}
							{Array.isArray(pendingTasks_ssr) && pendingTasks_ssr.length !== 0 ? (
								<ReviewPendingTasks
									productType={ProductType.Returns}
									pendingTasks={pendingTasks_ssr}
									tempPendingTasks={tempPendingTasks_ssr}
									setTempPendingTasks={setTempPendingTasks_ssr}
									handleOpenTaxreturn={(task: IPendingTaxReturn) => handleOpenTaxreturn(task, ProductType.Returns)}
								/>
							) : (
								<></>
							)}

							{/* For Extensions */}
							{Array.isArray(pendingTasks_ext) && pendingTasks_ext.length !== 0 ? (
								<ReviewPendingTasks
									productType={ProductType.Extensions}
									pendingTasks={pendingTasks_ext}
									tempPendingTasks={tempPendingTasks_ext}
									setTempPendingTasks={setTempPendingTasks_ext}
									handleOpenTaxreturn={handleOpenTaxreturn_ext}
								/>
							) : (
								<></>
							)}
							{/* For Gather */}
							{Array.isArray(pendingTasks_gtr) && pendingTasks_gtr.length !== 0 ? (
								<ReviewPendingTasks
									productType={ProductType.Gather}
									pendingTasks={pendingTasks_gtr}
									tempPendingTasks={tempPendingTasks_gtr}
									setTempPendingTasks={setTempPendingTasks_gtr}
									handleOpenTaxreturn={(task: IPendingTaxReturn) => handleOpenTaxreturn(task, ProductType.Gather)}
								/>
							) : (
								<></>
							)}

							{Array.isArray(pendingTasks_gtr) && pendingTasks_org.length !== 0 ? (
								<ReviewPendingTasks
									productType={ProductType.Organizers}
									pendingTasks={pendingTasks_org}
									tempPendingTasks={tempPendingTasks_org}
									setTempPendingTasks={setTempPendingTasks_org}
									handleOpenTaxreturn={(task: IPendingTaxReturn) => handleOpenTaxreturn(task, ProductType.Organizers)}
								/>
							) : (
								<></>
							)}

							{/* For Exchange Document Request */}
							{Array.isArray(pendingDocumentRequestTasks) && pendingDocumentRequestTasks.length !== 0 ? (
								<DocumentRequestTask
									pendingDocumentRequestTasks={pendingDocumentRequestTasks}
									tempPendingDocumentRequestTasks={tempPendingDocumentRequestTasks}
									setTempPendingDocumentRequestTasks={setTempPendingDocumentRequestTasks}
									handleOpenDocumentRequest={handleOpenDocumentRequest}
								/>
							) : (
								<></>
							)}
						</div>
					</section>
					<section className={styles.rightContainer}>
						<RecentActivity />
						{isExchangeSubscribedAndEnabled && (
							<UTEClientViewWrapper dataTestAuto='58b6cab0-86aa-4f03-98d9-9dfbaf80ec63'>
								<SendFiles />
							</UTEClientViewWrapper>
						)}
						<ContactInformation />
					</section>
				</article>
			</LoadingOverlay>

			{showCompletedTasksModal && (
				<UTEModalLayout
					width='750'
					theme='light'
					title='Completed Tasks'
					className={styles.completedTasksModal}
					hide={closeCompletedTasksModal}
					onClickOutside={closeCompletedTasksModal}>
					<CompletedTasksModal
						completedTasks={completedTasks}
						closeCompletedTasksModal={closeCompletedTasksModal}
					/>
				</UTEModalLayout>
			)}
			{isRecoveryPopup && !isClientView && (
				<UTEModalLayout
					width='402'
					theme='lightblue'
					title='Recovery Email or Phone Number'
					hide={() => {
						setIsRecoveryPopup(false);
					}}
					onClickOutside={() => {}}>
					<RecoveryPopup
						onCancel={() => {
							setIsRecoveryPopup(false);
						}}
						setShowRecoveryPopup={setIsRecoveryPopup}
						fromUpdateSetting={false}
						recoveryModel={initialRecoveryModelResponse}
					/>
				</UTEModalLayout>
			)}
		</main>
	);
};

export default Dashboard;
