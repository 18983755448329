import { NotificationMailEventType } from 'src/context/ute-notification-context/notification-provider-types';

export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const SSRTP_API_URL = process.env.REACT_APP_SSRTP_API_URL;
export const EXTTP_API_URL = process.env.REACT_APP_EXTTP_API_URL;
export const CLIENT_PENDO_API_KEY = process.env.REACT_APP_CLIENT_PENDO_API_KEY;
export const CAPTCHA_PUBLIC_KEY = process.env.REACT_APP_CAPTCHA_PUBLIC_KEY || '';
export const DEFAULT_SESSION_TIMEOUT = 1800;
export const KNOWN_EXCEPTION_MESSAGES = [
	'ResizeObserver loop limit exceeded',
	'ResizeObserver loop completed with undelivered notifications.',
	'ErrorEvent:',
	'ErrorEvent: Script error.',
];
export const ACCESS_TOKEN_STORAGE_NAME = 'ClientHubUI_AccessToken';
export const REFRESH_TOKEN_STORAGE_NAME = 'ClientHubUI_RefreshToken';
export const USER_DETAILS_STORAGE_NAME = 'ClientHubUI_UserDetails';
export const LOGIN_CONSTANTS = {
	ERROR_FETCH_USER: 'Error while fetching user details.',
	ERROR_CAPTCHA_VALIDATION: 'Captcha validation failed.',
	INVALID_EMAIL_ID: 'Email ID is invalid.',
	INVALID_EMAIL_ID_FORMAT: 'Invalid Email ID format.',
	ERROR_VERIFY_PIN: 'Error while verifying pin.',
	INVALID_PIN: 'PIN is invalid.',
	ERROR_SENDING_OTP: 'Error while sending OTP.',
	OTP_SENT: 'A One-Time Access Code was sent.',
	ERROR_VERIFY_OTP: 'Error while verifying otp.',
	OTP_EXPIRED: 'Your One-Time Access Code has expired.',
	INVALID_OTP: 'OTP is invalid.',
	ERROR_FETCH_COMPANY_SETTING: 'Error while fetching company setting.',
	ERROR_RESET_PIN: 'Error while resetting pin.',
	SUCCESS_RESET_PIN: 'PIN has been successfully reset.',
	OTP_LOCKED: 'Your account has been locked due to maximum access code retries.',
};
export const RESET_EMAILID_CONSTANTS = {
	ERROR_VALIDATING_MAIL: 'Error while validating recovery email ID with existing email ID.',
	ERROR_VALIDATING_PHONE_NUMBER: 'Error while validating recovery phone number with existing email ID.',
	RECOVERY_DETAIL_EXISTING_EMAIL_UNMATCH: 'The recovery email or number and\nexisting email ID are not connected.',
	ERROR_VALIDATING_NEW_MAIL: 'Error while validating new email ID.',
	SUCCESS_RESET_EMAILID: 'Email ID has been successfully reset. Please log in with your new email ID.',
};
export const ERROR_COMPANY_NAME_LOGO = 'Error fetching company name and logo details.';
export const RIGISTER_CONSTANTS = {
	ERROR_FETCH_USER: 'Error while fetching user details.',
	INVALID_EMAIL_ID: 'Please fill valid Email ID.',
	ERROR_USER_EXIST: 'Email ID already in use. Please select a different one.',
	ERROR_MAX_RETRY_OTP:
		'The code is no longer valid due to multiple failed attempts. Click “Resend Code” to request a new one.',
	ERROR_ACCOUNT_CREATION: 'Error creating user account.',
	SUCCESS_ACCOUNT_CREATION: 'PIN has been successfully created.',
};

export const MERGE_EMAIL_CONSTANTS = {
	ERROR_FETCH_MERGE_EMAIL: 'Error while fetching list of merged emails.',
	ERROR_VALIDATE_ACCESS_CODE: 'Error while validating access code.',
	INVALID_ACCESS_CODE: 'Access code is invalid.',
	EXPIRED_ACCESS_CODE: 'Access code has expired.',
	SUCCESS_VALIDATE_ACCESS_CODE: 'Access code verified successfully',
	ERROR_CANCEL_MERGE: 'Error while removing access code entry.',
	ERROR_VALIDATE_EMAIL: 'Error while validating email.',
	ERROR_MAX_EMAIL_REACHED: 'Cannot add more than 15 emails.',
	ERROR_EMAIL_ALREADY_LINKED: 'Cannot add this email ID as it is linked to another Client Portal account.',
	ERROR_DELETE_EMAIL: 'Error while deleting email.',
	SUCCESS_DELETE_EMAIL: 'Merged email deleted.',
};

export const TAX_DOCUMENTS_CONSTANTS = {
	ERROR_FETCH_TAX_DOCUMENTS: 'Error while fetching list of documents.',
	ERROR_SEARCH_TAX_DOCUMENTS: 'Error while searching documents.',
	ERROR_SORT_TAX_DOCUMENTS: 'Error while sorting documents.',
	ERROR_FILTER_TAX_DOCUMENTS: 'Error while filtering documents.',
	ERROR_FILTER_FROM_DATE_EMPTY: 'From Date cannot be empty.',
	ERROR_FILTER_TO_DATE_EMPTY: 'To Date cannot be empty.',
	ERROR_FILTER_FROM_DATE_GREATER_THAN_TO_DATE: 'To Date cannot be less than From Date.',
};

export const DISTRIBUTE_K1_CONSTANTS = {
	ERROR_FETCH_K1_DETAILS: 'Error while fetching K1 details.',
	ERROR_CHECKING_K1_DETAILS_PRESENT_OR_NOT: 'Error while checking K1 details present or not.',
};

export const UPDATE_EMAIL_CONSTANTS = {
	EMAIL_UPDATE_SUCCESS: 'Your email ID has been updated. Please log in with your new email ID.',
	EMAIL_UPDATE_ERROR: 'Error while updating email ID.',
	INVALID_EMAIL_ID: 'Please fill in a valid Email ID.',
	ERROR_VALIDATE_ACCESS_CODE: 'Error while validating access code.',
	INVALID_ACCESS_CODE: 'Access code is invalid.',
	EXPIRED_ACCESS_CODE: 'Access code has expired.',
};

export const RECOVERY_CONSTANTS = {
	UTE_LOGGEDIN_USER_FLAG: 'UTE_LOGGEDIN_USER_FLAG',
	INVALID_EMAIL_ID: 'Please enter a valid email ID.',
	INVALID_PHONE_NUMBER_WARNING: 'Please enter a valid mobile number matching the selected country code.',
	SAME_RECOVERY_EMAILID_VALIDATION: 'The recovery email cannot be the same as the primary email ID.',
	MAXCOUNT_RECOVERY_EMAILID_VALIDATION:
		'A recovery email can only be linked to a maximum of five different Client Portal accounts.',
	RECOVERY_EMAILID_SUCCESS: 'The recovery option has been saved successfully.',
	ERROR_UPDATE_RECOVERY: 'An error occurred while updating the recovery option.',
	ERROR_GET_RECOVERY: 'An error occurred while retrieving the recovery information.',
};

export const UPDATE_PIN_CONSTANTS = {
	PIN_UPDATE_VALIDATIONMESSAGE: 'Invalid PIN!',
	PIN_UPDATE_SUCCESS: 'Your PIN has been updated. Please log in with your new PIN.',
	PIN_UPDATE_ERROR: 'Error while updating PIN.',
};

export const DASHBOARD_CONSTANTS = {
	ERROR_FETCH_DASHBOARD_OVERVIEW: 'Error while fetching dashboard details.',
	ERROR_FETCH_PENDING_TASKS: 'Error while fetching pending tasks.',
	FIRST_LOGIN_DATE: '01/01/0001',
	ERROR_FETCH_CONTACT_INFORMATION: 'Error while fetching contact information.',
	ERROR_FETCH_COMPLETED_TASKS: 'Error while fetching completed tasks.',
	NO_RECORDS_FOUND: 'No records found.',
};

export const NOTIFICATION_CONSTANTS = {
	ERROR_FETCH_CONTACT_INFORMATION: 'Error while fetching contact information.',
	ERROR_FETCH_NOTIFICATIONS: 'Error while fetching notifications.',
	ERROR_FETCH_NOTIFICATION: 'Error while fetching notification.',
	ERROR_CLEAR_NOTIFICATIONS: 'Error while clearing notifications.',
	ERROR_REDIRECTION_NOTIFICATIONS: 'Error while redirecting.',
	ERROR_UPDATE_NOTIFICATIONS: 'Error while updating notification status.',
};

export const RECENT_ACTIVITY_CONSTANTS = {
	ERROR_FETCH_RECENT_ACTIVITY: 'Error while fetching list of recent activities.',
	ERROR_FETCH_SSR_DOCUMENT_INFO: 'Error while fetching the SSR document info',
};

export const NOTIFICATION_AND_REMINDER_CONSTANTS = {
	[NotificationMailEventType.InitialEmail]: 'Review and sign your tax returns.',
	[NotificationMailEventType.VoucherReminderEmail]: 'Your taxes are due in #NUMBEROFDAYS# days.',
	[NotificationMailEventType.SigningReminderEmail]: 'Reminder to sign your tax returns.',
	[NotificationMailEventType.InvoicePaymentEmail]: 'Your invoice payment was received.',
	[NotificationMailEventType.RecallNotificationEmail]: 'Your return was recalled.',
	[NotificationMailEventType.DownloadESignedFormsEmail]: 'Find your signed tax return in the Documents tab.',
	[NotificationMailEventType.UploadEstimatesEmail]: 'Your estimated tax payments have been updated.',
	[NotificationMailEventType.SignatureDelegateeEmail]: 'Review and sign your tax returns.',
	[NotificationMailEventType.DelegationCancelledEmail]: 'The delegator has canceled your signing delegation.',
	[NotificationMailEventType.DownloadTaxReturnEmail]: 'Find your signed tax return in the Documents tab.',
	[NotificationMailEventType.DelegateeDeclinedEmail]: 'Your signature request was declined by the delegatee.',
	[NotificationMailEventType.DraftInitialEmail]: 'Review your draft tax returns.',
};

export const EXTENSION_NOTIFICATION_AND_REMINDER_CONSTANTS = {
	[NotificationMailEventType.InitialEmail]: 'Review and sign your tax extensions.',
	[NotificationMailEventType.VoucherReminderEmail]: 'Your taxes are due in #NUMBEROFDAYS# days.',
	[NotificationMailEventType.SigningReminderEmail]: 'Reminder to sign your tax extensions.',
	[NotificationMailEventType.InvoicePaymentEmail]: '',
	[NotificationMailEventType.RecallNotificationEmail]: 'Your tax extension was recalled.',
	[NotificationMailEventType.DownloadESignedFormsEmail]: 'Find your signed tax extensions in the Documents tab',
	[NotificationMailEventType.UploadEstimatesEmail]: '',
	[NotificationMailEventType.SignatureDelegateeEmail]: 'Review and sign your tax extensions.',
	[NotificationMailEventType.DelegationCancelledEmail]: 'The delegator has canceled your signing delegation.',
	[NotificationMailEventType.DownloadTaxReturnEmail]: 'Find your signed tax extensions in the Documents tab',
	[NotificationMailEventType.DelegateeDeclinedEmail]: 'Your signature request was declined by the delegatee.',
};

export const SIGNATURE_NOTIFICATION_AND_REMINDER_CONSTANTS = {
	[NotificationMailEventType.InitialEmail]: 'Review and sign your Documents.',
	[NotificationMailEventType.SigningReminderEmail]: 'Reminder to sign your Documents.',
	[NotificationMailEventType.SignatureCancelledEmail]: 'Your signature request was canceled.',
	[NotificationMailEventType.DelegateeDeclinedEmail]: 'Your signature request was declined by the delegatee.',
	[NotificationMailEventType.SignatureSignedEmail]: 'Find your signed document(s) in the Documents tab.',
	[NotificationMailEventType.DelegationCancelledEmail]: 'The delegator has canceled your signing delegation.',
	[NotificationMailEventType.SignatureDeclinedEmail]: 'Your signature request was declined.',
};

export const GATHER_NOTIFICATION_AND_REMINDER_CONSTANTS = {
	[NotificationMailEventType.InitialEmail]: 'Complete your gather request.',
	[NotificationMailEventType.DownloadESignedFormsEmail]: 'Find your signed engagement letter in the Documents tab.',
	[NotificationMailEventType.SpouseReviewMail]: 'Review and confirm your organizer.',
	[NotificationMailEventType.OrganizerCompleted]: 'Find your completed organizer in the Documents tab.',
	[NotificationMailEventType.ReopenedGather]: 'CPA has reopened your gather request.',
	[NotificationMailEventType.SigningReminderEmail]: 'Reminder to sign your engagement letter.',
	[NotificationMailEventType.OrganizerReminderEmail]: 'Reminder to complete your organizer.',
	[NotificationMailEventType.SourceDocumentReminderMail]: 'Reminder to finish uploading your source documents.',
	[NotificationMailEventType.SourceDocumentMarkedCompletedToCpa]: 'Find your source documents in the Documents tab.',
	[NotificationMailEventType.SourceDocumentUploadReopened]: 'CPA has reopened your  Document Upload portal.',
};

export const ORGANIZER_NOTIFICATION_AND_REMINDER_CONSTANTS = {
	[NotificationMailEventType.InitialEmail]: 'Complete your organizer request.',
	[NotificationMailEventType.DownloadESignedFormsEmail]: 'Find your signed engagement letter in the Documents tab.',
	[NotificationMailEventType.SpouseReviewMail]: 'Review and confirm your organizer.',
	[NotificationMailEventType.OrganizerCompleted]: 'Find your completed organizer in the Documents tab.',
	[NotificationMailEventType.ReopenedOrganizer]: 'CPA has reopened your organizer request.',
	[NotificationMailEventType.SigningReminderEmail]: 'Reminder to sign your engagement letter.',
	[NotificationMailEventType.OrganizerReminderEmail]: 'Reminder to complete your organizer.',
	[NotificationMailEventType.SourceDocumentReminderMail]: 'Reminder to finish uploading your source documents.',
	[NotificationMailEventType.SourceDocumentMarkedCompletedToCpa]: 'Find your source documents in the Documents tab.',
	[NotificationMailEventType.SourceDocumentUploadReopened]: 'CPA has reopened your  Document Upload portal.',
};

export const EXCHANGE_NOTIFICATION_PLACEHOLDER_CONSTANTS = {
	SENTDATE: 'sentDate',
};

export const EXCHANGE_NOTIFICATION_AND_REMINDER_CONSTANTS = {
	[NotificationMailEventType.InitialMessageEmail]: `You have received new documents on ${EXCHANGE_NOTIFICATION_PLACEHOLDER_CONSTANTS.SENTDATE}`,
	[NotificationMailEventType.MessageExpiryReminder]: 'Files Expiring. Please download your files.',
	[NotificationMailEventType.InitialDocumentRequestEmail]: 'Request to upload documents.',
	[NotificationMailEventType.DocumentRequestReminder]: 'Reminder to upload the requested documents.',
	[NotificationMailEventType.DocumentRequestDocumentReminder]: 'Reminder to upload the requested document.',
	[NotificationMailEventType.DocumentRequestMore]: 'Request to upload additional documents.',
	[NotificationMailEventType.DocumentRequestReopen]: 'Document request is reopened: Upload documents.',
};

export const FORWARD_TAXDOCUMENT_CONSTANTS = {
	DESCRIPTION:
		'Please provide the email addresses for sharing tax documents. ' +
		'These documents will originate from <span class="ssr_default_email">noreply@safesendreturns.com</span>. ' +
		'Receivers must input an Access Code, which will be dispatched via a follow-up email or through text message confirmation.',
	NOTE: '<b>Note</b>: Recipients will only be able to download copies of tax documents. They will not be able to sign forms or manage payments.',
	FORWARD_TAX_DOCUMENT_TEXT: 'Choose the document(s) to download',
	EMTPY_RECIPIENT_WARNING: 'Please make sure you have entered recipient email address',
	DOCUMENTS_SELECTION_EMPTY_WARNING: 'Please make sure you have selected atleast one document to forward',
	SUCCESS_MESSAGE: 'Email Sent Successfully.',
	ERROR_MESSAGE: 'Error occured while forwarding documents to cc recipient(s).',
	ERROR_FETCH_DOCUMENT: 'Error occured while fetching the documents of cc recipient(s).',
	MODAL_HEADER_TITLE: 'Forward A Copy',
	INVALID_EMAIL_ADDRESS_WARNING: 'Please make sure you have entered valid email address.',
	EMPTY_DOCUMENT_SELECTION: 'Please select atleast one.',
	BLANK_EMAIL_ADDRESS_WARNING: 'Email address cannot be blank.',
};

export const VALIDATION_CONSTANTS = {
	EMPTY_PHONE_NUMBER_WARNING: 'Please enter mobile number.',
	INVALID_PHONE_NUMBER_LENGTH_WARNING: 'Please enter a 10 digit mobile number.',
	COUNTRY_CODE_WARNING: 'Please select country code',
	MOBILE_NUMBER_LENGTH_WARNING: 'Please enter a valid mobile number, according to the country code selected.',
};

export const MAX_NUMBER_OF_ATTEMPTS = 3;
export const DEFAULT_PAYMENTLIST_PER_PAGE = 10;

export const TAXPAYER_REDIRECTIONPATH_CONSTANTS = {
	SUMMARYPAGE: 'TaxpayerFlow',
	PAYPAGE: 'pay',
	FINAL_SUMMARYPAGE: 'signcompleted',
	DOWNLOAD_PAGE: 'download',
};

export enum ProductStatus {
	None = 0,
	Enabled = 1,
	Disabled = 2,
}

// for Extensions only
export enum SignatureStatus {
	None,
	ESigned = 1,
	ManuallySigned,
	Uploaded,
	AwaitingESign,
	AwaitingUpload,
	Locked,
	MailOrFax,
	SignedAndESigned,
	DeliveredToTaxCaddy = 10,
	TaxCaddyDeliveryFailed = 16,
	AlternateDelivery = 17,
	DownloadingFailed = 18,
	AlternateAndUploaded = 19,
	Reviewed = 20,
	AwaitingReview = 21,
	PartiallySigned = 22,
	PartiallyReviewed = 23,
}

export const SSR_DOCUMENTS_PREVIEW_CONSTANTS = {
	ERROR_FETCH_DOCUMENTS: 'Error while fetching list of documents.',
	ERROR_DOWNLOAD_K1_DOCUMENT: 'Error while downloading the K1 document.',
};

export const TAXPAYEMENT_VIEW_CONSTANTS = {
	NORECORDS_FOUND_MESSAGE: 'No payments to display at this time',
};

export const TAX_RETURN_FOLDER_NAME = 'Tax Year - ';

export const DASHBOARD_LISTING_CONSTANTS = {
	SHOW_MORE: 'Show More',
	SHOW_LESS: 'Show Less',
	CONTINUE: 'Continue',
	GET_STARTED: 'Get Started',
	BACK_TO_TOP: 'Back to Top',
};

export const SSE_DOCUMENT_CONSTANTS = {
	ERROR_DOWNLOAD_DOCUMENT: 'Error while downloading the document.',
	ERROR_FETCH_MESSAGE: 'Error while fetching CPA message.',
};

export const LOGGED_IN_LAYOUT_CONSTANTS = {
	ERROR_FETCHING_COMPANY_PRODUCTS: 'Error while fetching company product details.',
};

export const EXCHANGE_FILE_TYPES = ['pdf', 'doc', 'png', 'bmp', 'jpg', 'jpeg', 'jfif', 'docx'];

export const NULL_DATE = '0001-01-01T00:00:00';

export const SESSION_EXPIRED_WARNING =
	'Your current session has expired, and you have been logged out of Client Portal.';

export const CLIENT_VIEW_ACTION_NOT_PERMITTED = 'This action is not permitted in Client View.';
export const MAX_FAILED_ATTEMPT_COUNT = 3;

export const Preview_SupportedFileExtensions: Array<string> = [
	'doc',
	'docx',
	'jpg',
	'jpeg',
	'png',
	'ppt',
	'pptx',
	'txt',
	'xls',
	'xlsx',
];

export const UnsupportedFileMessage: string =
	'Unable to view the file? No problem! Click the button above to download the file and open it on your';
