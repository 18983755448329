import React from 'react';
import SafeSendSuiteLogo from 'src/assets/svg/safesend-suite';
import LoginLayout from 'src/modules/layout/login-layout';
import styles from './onehub-domain-page.module.scss';

const OneHubDomainPage = () => {
	window.document.title = 'SSClientPortal';
	return (
		<LoginLayout>
			<section className={styles.contentContainer}>
				<SafeSendSuiteLogo />
				<header>Client Portal</header>
				<p className={styles.description1}>
					The centralized location to access all your
					<br />
					tax related tasks and historical documents.
				</p>
				<p className={styles.description2}>Please get in touch with your accountant for access.</p>
			</section>
		</LoginLayout>
	);
};

export default OneHubDomainPage;
