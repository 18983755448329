import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from 'src/pages/login';
import Disabled from '../../../pages/disabled';
import { AxiosInterceptorNavigate } from 'src/modules/axios-interceptor/axios-interceptor-navigate';
import UserAccess from 'src/pages/user-access';
import ClientView from 'src/pages/client-view';

const LoggedOutUsers = () => {
	return (
		<Router>
			<AxiosInterceptorNavigate />
			<Routes>
				<Route
					path='/disabled'
					element={<Disabled />}
				/>
				<Route
					path='/:companyId'
					element={<Login />}
				/>
				<Route
					path='/*'
					element={<Login />}
				/>
				<Route
					path='/access/:productType/:companyId'
					element={<UserAccess />}
				/>
				<Route
					path='/clientview/:productType/:companyId/:cpaUserId'
					element={<ClientView />}
				/>
			</Routes>
		</Router>
	);
};

export default LoggedOutUsers;
