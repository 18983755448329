import React from 'react';
import styles from './logged-in-footer.module.scss';

const LoggedInFooter = () => {
	return (
		<>
			<span>Copyright &copy; 2024 cPaperless LLC</span>
			<span>
				<a
					href='https://safesend.com/legal-policies/terms-of-use/'
					target='_blank'
					className={styles.links}>
					Terms of service
				</a>
				<span>&nbsp;-&nbsp;</span>
				<a
					href='https://safesend.com/legal-policies/privacy-statement/'
					target='_blank'
					className={styles.links}>
					Privacy Policy
				</a>
			</span>
		</>
	);
};

export default LoggedInFooter;
