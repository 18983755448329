import React, { useContext, useEffect, useState } from 'react';
import styles from './recovery-popup.module.scss';
import UTEButton from 'src/components/ute-button';
import { Modal, Col, Row } from 'react-bootstrap';
import { isValidEmailAddress } from 'src/helper/utils';
import { IRecoveryModel, IRecoveryPopUpModel } from './recovery-popup-types';
import { getRecoveryDetails, onSubmitRecovery } from './recovery-popup-api';
import { CLIENT_VIEW_ACTION_NOT_PERMITTED, RECOVERY_CONSTANTS } from 'src/helper/constants';
import { isValidMobileNumber } from '@sssuite-js-packages/mobile-number-validator';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';
import { UTENotifier } from '../notification/ute-notifier';
import { CountryData } from '../cc-recipient/country-data';
import PhoneInput from '@sssuite-component-ui/react-phone-input-2';

type RecoveryPopupProps = {
	onCancel: () => void;
	setShowRecoveryPopup: (isRecoveryPopup: boolean) => void;
	fromUpdateSetting: boolean;
	recoveryModel: IRecoveryModel;
	setRecoveryModel: (recoveryModel: IRecoveryModel) => void;
};

type IRecoveryOptions = {
	label: string;
	value: string;
};

const InitialRecoveryOptions: IRecoveryOptions[] = [
	{ label: 'Email Address', value: 'emailAddress' },
	{ label: 'Phone Number', value: 'phoneNumber' },
];

const RecoveryPopup = ({
	onCancel,
	setShowRecoveryPopup,
	fromUpdateSetting,
	recoveryModel,
	setRecoveryModel,
}: RecoveryPopupProps) => {
	const { isClientView, userDetail } = useContext(AuthContext);
	const [selectedOption, setSelectedOption] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [recoveryEmail, setRecoveryEmail] = useState('');
	const [recoveryPhoneNumber, setRecoveryPhoneNumber] = useState('');
	const [recoveryCountryCode, setRecoveryCountryCode] = useState('');

	useEffect(() => {
		if (fromUpdateSetting) {
			if (recoveryModel?.emailAddress) {
				setSelectedOption('emailAddress');
				setRecoveryEmail(recoveryModel?.emailAddress);
			} else if (recoveryModel?.phoneNumber) {
				setSelectedOption('phoneNumber');
				setRecoveryPhoneNumber(recoveryModel?.phoneNumber);
				setRecoveryCountryCode(recoveryModel?.countryCode);
			}
		}
	}, [recoveryModel]);

	const handleRecoveryOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedOption(event.target.value);
		setErrorMessage('');
		if (event.target.value === 'emailAddress') {
			setRecoveryPhoneNumber('');
			setRecoveryCountryCode('');
		} else {
			setRecoveryEmail('');
		}
	};

	const handlePhoneNumberChange = (fullValue: string, countryObject: CountryData) => {
		if (fullValue.length === 0) {
			setRecoveryPhoneNumber('');
			setRecoveryCountryCode('');
		} else {
			setRecoveryCountryCode(`+${countryObject.dialCode}`);
			setRecoveryPhoneNumber(fullValue.slice(countryObject.dialCode?.length));
		}
	};

	const handleRecoveryEmailIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRecoveryEmail(event.target.value);
	};

	const handleSubmit = () => {
		if (isClientView) {
			UTENotifier.Warning(CLIENT_VIEW_ACTION_NOT_PERMITTED);
			return;
		}

		let updateRecoveryModel: IRecoveryPopUpModel = {
			countryCode: recoveryCountryCode,
			phoneNumber: recoveryPhoneNumber,
			recoveryEmail: recoveryEmail,
		};
		if (
			selectedOption === 'emailAddress' &&
			recoveryEmail?.length > 0 &&
			userDetail.emailAddress.trim() === recoveryEmail?.trim()
		) {
			setErrorMessage(RECOVERY_CONSTANTS.SAME_RECOVERY_EMAILID_VALIDATION);
		} else if (
			selectedOption === 'emailAddress' &&
			(recoveryEmail?.length === 0 || !isValidEmailAddress(recoveryEmail))
		) {
			setErrorMessage(RECOVERY_CONSTANTS.INVALID_EMAIL_ID);
		} else if (selectedOption === 'phoneNumber' && !isValidMobileNumber(recoveryCountryCode, recoveryPhoneNumber)) {
			setErrorMessage(RECOVERY_CONSTANTS.INVALID_PHONE_NUMBER_WARNING);
		} else {
			onSubmitRecovery(
				updateRecoveryModel,
				(response: boolean) => {
					if (response === true) {
						UTENotifier.Success(RECOVERY_CONSTANTS.RECOVERY_EMAILID_SUCCESS);
						getRecoveryDetails(
							(response: IRecoveryModel) => {
								setRecoveryModel(response);
							},
							() => {
								UTENotifier.Error(RECOVERY_CONSTANTS.ERROR_GET_RECOVERY);
							},
						);
						setShowRecoveryPopup(false);
					} else {
						UTENotifier.Error(RECOVERY_CONSTANTS.MAXCOUNT_RECOVERY_EMAILID_VALIDATION);
					}
				},
				() => {
					UTENotifier.Error(RECOVERY_CONSTANTS.ERROR_UPDATE_RECOVERY);
				},
			);
		}
	};

	return (
		<div className={styles.updateRecoveryPopupModal}>
			<Modal.Body>
				<Row data-test-auto='8ce0a6ee-91aa-40ee-9534-a11dbc579c42'>
					<Row className={styles.recoveryEmailPhonNumberTitle}>
						<Col lg={12}>
							<span className={styles.infoText}>
								{fromUpdateSetting
									? 'Update your recovery email or cell phone number to recover your account if it is needed.'
									: 'Set up a recovery email or cell phone number to recover your account if it is needed.'}
							</span>
						</Col>
					</Row>
					<Col lg={12}>
						<div className={styles.recoveryInputContainer}>
							{InitialRecoveryOptions.map((recoveryOption: IRecoveryOptions) => {
								return (
									<label
										key={recoveryOption.value}
										style={{ display: 'block' }}>
										<input
											type='radio'
											name='recoveryType'
											value={recoveryOption.value}
											checked={selectedOption === recoveryOption.value}
											onChange={handleRecoveryOptionChange}
										/>
										<span style={{ marginLeft: '8px' }}>{recoveryOption.label}</span>
									</label>
								);
							})}
							{selectedOption === 'emailAddress' && (
								<>
									<div className={styles.recoveryOptionLabel}>Recovery Email</div>
									<input
										placeholder='Enter your recovery email'
										maxLength={320}
										type='text'
										name='recoveryEmail'
										id='recoveryEmail'
										className={`${styles.recoveryEmail} ${errorMessage.length > 0 ? `${styles.error}` : ''}`}
										value={recoveryEmail}
										data-test-auto='afe63f31-4fb3-4e94-8c3b-3cb9fb50c293'
										onChange={(event) => {
											handleRecoveryEmailIdChange(event);
										}}
									/>
									{errorMessage.length > 0 && <div className={styles.errorContainer}>{errorMessage}</div>}
								</>
							)}
							{selectedOption === 'phoneNumber' && (
								<>
									<div className={styles.recoveryOptionLabel}>Phone Number</div>
									<PhoneInput
										containerClass={`${styles.inputRecoveryPhoneNumber} ${
											errorMessage.length > 0 ? `${styles.error}` : ''
										}`}
										autoFormat={true}
										country={recoveryCountryCode ? recoveryCountryCode : 'us'}
										value={`${recoveryCountryCode}${recoveryPhoneNumber}`}
										onChange={handlePhoneNumberChange}
									/>
									{errorMessage.length > 0 && <div className={styles.errorContainer}>{errorMessage}</div>}
								</>
							)}
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				{!fromUpdateSetting && (
					<UTEButton
						dataTestAuto='140a85b0-e218-40ee-a05b-a4d8cc0f38ae'
						onClick={onCancel}
						className='btn btn-skip'
						disabled={false}
						text='Skip'
					/>
				)}

				<UTEButton
					dataTestAuto='2af0fb3e-cd11-4e2b-8f32-ce35a483289a'
					onClick={handleSubmit}
					className='btn btn-primary'
					disabled={
						selectedOption === '' ||
						(selectedOption === 'emailAddress' && recoveryEmail?.length === 0) ||
						(selectedOption === 'phoneNumber' && recoveryPhoneNumber.length === 0)
					}
					text={fromUpdateSetting ? 'Update' : 'Submit'}
				/>
			</Modal.Footer>
		</div>
	);
};

export default RecoveryPopup;
