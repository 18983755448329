import { ICompanyProduct } from 'src/modules/common/common-types';

export type IUserDetail = {
	id: number;
	name: string;
	emailAddress: string;
	otpAttemptCount: number;
	pinAttemptCount: number;
};

export type IAuthContextValue = {
	userDetail: IUserDetail;
	authToken: string;
	refreshToken: string;
	companyId: number;
	companyNameAndLogo: ICompanyNameAndLogo;
	collapsedMenu: boolean;
	sessionTimeInSeconds: number;
	isSessionTimedOut: boolean;
	companyProducts: ICompanyProduct[];
	isClientView: boolean;
	updateUserDetails: (userDetail: IUserDetail) => void;
	updateAuthToken: (authToken: string) => void;
	updateRefreshToken: (authToken: string) => void;
	updateCompanyId: (companyId: number) => void;
	updateCompanyNameAndLogo: (companyNameAndLogo: ICompanyNameAndLogo) => void;
	updateCollapsedMenu: (collapsedMenu: boolean) => void;
	updateSessionTimeInSeconds: (time: number) => void;
	updateIsSessionTimedOut: (isSessionTimedOut: boolean) => void;
	updateCompanyProducts: (companyProducts: ICompanyProduct[]) => void;
	updateIsClientView: (isClientView: boolean) => void;
};

export const emptyUser: IUserDetail = {
	id: -1,
	name: '',
	emailAddress: '',
	otpAttemptCount: 0,
	pinAttemptCount: 0,
};

export type ICompanyNameAndLogo = {
	name: string;
	logoPath: string;
	whiteLogoPath: string;
};

export const emptyCompanyNameAndLogo: ICompanyNameAndLogo = {
	name: '',
	logoPath: '',
	whiteLogoPath: '',
};
