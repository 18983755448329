import React, { useContext, useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import Loader from 'src/components/loader';
import styles from './distribute-k1s.module.scss';
import { getK1Details } from './distribute-k1-api';
import {
	IK1Details,
	IK1DistributeModel,
	IK1DistributeResponse,
	K1EmailStatus,
	initialK1Details,
} from './distribute-k1-types';
import { UTENotifier } from '../notification/ute-notifier';
import { DEFAULT_PAYMENTLIST_PER_PAGE, DISTRIBUTE_K1_CONSTANTS } from 'src/helper/constants';
import UTEPagination from 'src/components/ute-pagination';
import { generateTaxpayerRedirectionAPIURL, generateTaxpayerClientViewAPIURL } from '../common/common-api';
import { ClientTypes, ProductType, TaxpayerRedirectionSourcePage } from '../common/common-types';
import { AuthContext } from '../../context/ute-auth-context/auth-provider';

const DistributeK1s = () => {
	const [isLoading, setisLoading] = useState(false);
	const [k1Details, setK1Details] = useState<IK1Details>(initialK1Details);
	const [page, setPage] = useState({ pageIndex: 1, pageSize: DEFAULT_PAYMENTLIST_PER_PAGE });
	const { userDetail, isClientView } = useContext(AuthContext);

	useEffect(() => {
		fetchK1Details(page.pageIndex, page.pageSize);
	}, []);

	const fetchK1Details = (pageNumber: number, pageSize: number) => {
		setisLoading(true);
		getK1Details(pageNumber, pageSize, (k1DistributeResponse: IK1DistributeResponse) => {
			setisLoading(false);
			if (k1DistributeResponse.error) {
				UTENotifier.Error(DISTRIBUTE_K1_CONSTANTS.ERROR_FETCH_K1_DETAILS);
			} else {
				setK1Details(k1DistributeResponse.k1Details);
			}
		});
	};

	const showStatus = (k1EmailStatus: K1EmailStatus) => {
		if (k1EmailStatus === K1EmailStatus.NotSent) {
			return <span className={styles.notSent}>Not Sent</span>;
		} else if (k1EmailStatus === K1EmailStatus.PartiallySent) {
			return <span className={styles.partiallySent}>Partially&nbsp;Sent</span>;
		} else {
			return <span className={styles.sent}>Sent</span>;
		}
	};

	const onPageChange = (newPageIndex: number, newPageSize: number) => {
		const totalPages = Math.ceil(k1Details.count / page.pageSize);
		if (
			(newPageIndex !== page.pageIndex && newPageIndex <= totalPages && newPageIndex >= 1) ||
			newPageSize !== page.pageSize
		) {
			const newPage = { ...page };
			newPage.pageIndex = newPageIndex;
			newPage.pageSize = newPageSize;
			setPage(newPage);
			fetchK1Details(newPageIndex, newPageSize);
		}
	};

	const openK1DistributePage = (clientId: string, isClientView: boolean) => {
		if (!isClientView) {
			generateTaxpayerRedirectionAPIURL(
				clientId,
				ProductType.Returns,
				TaxpayerRedirectionSourcePage.DistributeK1,
				ClientTypes.Undefined,
				userDetail.emailAddress,
			);
		} else {
			generateTaxpayerClientViewAPIURL(
				clientId,
				ProductType.Returns,
				ClientTypes.Undefined,
				TaxpayerRedirectionSourcePage.DistributeK1,
			);
		}
	};

	return (
		<section className='pageModuleContainer'>
			<LoadingOverlay
				active={isLoading}
				spinner={<Loader />}
				text=''>
				<h2 className='pageModuleHeader'>Distribute K-1s Electronically or By Mail</h2>
				<p className='pageModuleDescription'>Select which company to distribute K-1 documents</p>
				<section className={styles.k1Container}>
					<table>
						<thead>
							<th className={styles.nameHeader}>Name</th>
							<th className={styles.statusHeader}>Status</th>
							<th className={styles.actionHeader}>Action</th>
						</thead>
						<tbody>
							{k1Details.items.map((eachK1Detail: IK1DistributeModel, index: number) => (
								<tr
									key={index}
									className={styles.k1detailRow}>
									<td className={styles.nameData}>
										{eachK1Detail.taxpayerName} {eachK1Detail.taxYear} Tax Return
									</td>
									<td className={styles.statusData}>{showStatus(eachK1Detail.k1EmailStatus)}</td>
									<td className={styles.actionData}>
										<span
											onClick={() => {
												openK1DistributePage(eachK1Detail.clientGuid, isClientView);
											}}>
											Distribute
										</span>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</section>
				<UTEPagination
					count={k1Details.count ?? 0}
					page={page}
					goToPage={onPageChange}
				/>
			</LoadingOverlay>
		</section>
	);
};

export default DistributeK1s;
