import React from 'react';
import styles from './verify-email.module.scss';
import CloseIcon from 'src/assets/svg/close-icon';
import UTEButton from 'src/components/ute-button';

type VerifyEmailProps = {
	emailID: string;
	onClose: () => void;
	handleAddEmail: () => void;
	verifyCodeInput: string;
	isVerifyCodeDisabled: boolean;
	isCodeError: boolean;
	handleVerifyCodeChange: (code: string) => void;
	handleVerifyCodeSubmit: () => void;
	isCloseVerifyDisabled: boolean;
};
const VerifyEmail = ({
	emailID,
	onClose,
	handleAddEmail,
	verifyCodeInput,
	isVerifyCodeDisabled,
	isCodeError,
	handleVerifyCodeChange,
	handleVerifyCodeSubmit,
	isCloseVerifyDisabled,
}: VerifyEmailProps) => {
	return (
		<section className={styles.veryEmailContainer}>
			<header className={styles.header}>
				<span className={styles.headerTitle}>Verify Email</span>
				<span
					className={isCloseVerifyDisabled ? styles.headerCloseDisabled : styles.headerClose}
					onClick={onClose}>
					<CloseIcon />
				</span>
			</header>
			<div className={styles.verifyDescription}>
				Please enter the verification code sent to <span>{emailID}</span>
			</div>
			<div className={styles.codeContainer}>
				<input
					type='text'
					name='verifyCodeInput'
					id='verifyCodeInput'
					className={`${styles.verifyCodeInput} ${isCodeError ? styles.errorBorder : ''}`}
					value={verifyCodeInput}
					onChange={(e) => {
						handleVerifyCodeChange(e.target.value);
					}}
					placeholder='Access Code'
					maxLength={8}
				/>
				<UTEButton
					text='Verify Code'
					className={styles.verifyCodeButton}
					onClick={handleVerifyCodeSubmit}
					disabled={isVerifyCodeDisabled}
					dataTestAuto='502874fe-d919-4db4-aa08-a235e1ad9ec1'
				/>
			</div>
			<div className={styles.resend}>
				Didn’t receive the code? <span onClick={handleAddEmail}>Resend</span>
			</div>
		</section>
	);
};

export default VerifyEmail;
