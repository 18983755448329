import React from 'react';
import Header from 'awesome-pdf-viewer/dist/layout/Header';
import LeftPanel from 'awesome-pdf-viewer/dist/layout/LeftPanel';
import Main from 'awesome-pdf-viewer/dist/layout/Main';
import ViewPanel from 'awesome-pdf-viewer/dist/layout/ViewPanel';
import Toolbar from 'awesome-pdf-viewer/dist/toolbar/Toolbar';
import Pagination from 'awesome-pdf-viewer/dist/toolbar/Pagination';
import Zoom from 'awesome-pdf-viewer/dist/toolbar/Zoom';
import { PdfSource, ViewerMode } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import PdfViewer from 'awesome-pdf-viewer';
import PreviewLeftPanel from './left-panel';
import { IFileGroup } from './left-panel/left-panel-types';
import UTEButton from '../ute-button';
import './pdf-preview.style.scss';
import { DownloadIcon } from 'src/assets/svg/download-icon';
import '../../import';
import UTEClientViewWrapper from '../ute-client-view-wrapper';

type PdfPreviewProps = {
	fileGroups: IFileGroup[];
	pdfSource: PdfSource;
	onFileSelect: (url: string, data: any) => void;
	onFileDownload: () => void;
	fileExtension?: string;
	tooltip?: string;
	hideLeftPanel?: boolean;
};

export class PdfPreview extends React.Component<PdfPreviewProps, {}> {
	private _viewPanel: any;
	private _toolbar: any;
	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		this.setReferences();
	}

	setReferences() {
		this._toolbar && this._toolbar.setViewerReference(this._viewPanel);
		this._viewPanel && this._viewPanel.setToolbarReference(this._toolbar);
	}

	componentDidUpdate() {
		this.setReferences();
	}

	public render() {
		return (
			<>
				<PdfViewer id={'awesome-pdf-viewer'}>
					<Header>
						<Toolbar
							ref={(ref: any) => (this._toolbar = ref)}
							hideRightPanel={true}
							onViewModeChange={(mode: ViewerMode) => {}}>
							{this.props.fileExtension && this.props.fileExtension.toLowerCase() !== '.pdf' ? <></> : <Pagination />}
							{this.props.fileExtension && this.props.fileExtension.toLowerCase() !== '.pdf' ? <></> : <Zoom />}
							<UTEClientViewWrapper dataTestAuto='19d3e87e-8d97-4530-a126-c630694e2298'>
								<UTEButton
									className='download-btn'
									dataTestAuto='F48A59BC-957B-4DB5-8E8B-08071C2A207F'
									disabled={false}
									text='Download'
									title={this.props.tooltip ?? 'Download your tax documents.'}
									icon={<DownloadIcon />}
									onClick={() => {
										this.props.onFileDownload();
									}}
								/>
							</UTEClientViewWrapper>
						</Toolbar>
					</Header>
					<Main>
						{!this.props.hideLeftPanel && (
							<LeftPanel>
								<PreviewLeftPanel
									onFileSelect={this.props.onFileSelect}
									fileGroups={this.props.fileGroups}></PreviewLeftPanel>
							</LeftPanel>
						)}
						{this.props.fileExtension && this.props.fileExtension.toLowerCase() !== '.pdf' ? (
							<>
								<div className='page-viewer'>
									<div className='non-supported-file-container'>
										Unable to view the file? No problem! Click the button above to download the file and open it on your
										device.
									</div>
								</div>
							</>
						) : (
							<ViewPanel
								ref={(ref: any) => (this._viewPanel = ref)}
								pdfSource={this.props.pdfSource}></ViewPanel>
						)}
					</Main>
				</PdfViewer>
			</>
		);
	}
}

export default PdfPreview;
