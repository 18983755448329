import React, { useState } from 'react';
import styles from './reset-pin-email.module.scss';
import { isValidEmailAddress } from 'src/helper/utils';
import { ExclamationError } from 'src/assets/svg/exclamation-error';
import UTEButton from 'src/components/ute-button';

type ResetPinEmailProps = {
	emailID: string;
	setEmailID: (emailID: string) => void;
	handleEmailIDSubmit: (callback: () => void) => void;
	usernotfound: boolean;
};
const ResetPinEmail = ({ emailID, setEmailID, handleEmailIDSubmit, usernotfound }: ResetPinEmailProps) => {
	const [isEmailIDDisabled, setIsEmailIDDisabled] = useState(true);

	const handleEmailIDChange = (emailIDValue: string) => {
		setEmailID(emailIDValue);
		if (emailIDValue.length > 0 && isValidEmailAddress(emailIDValue)) {
			setIsEmailIDDisabled(false);
		} else {
			setIsEmailIDDisabled(true);
		}
	};

	const onClickContinue = () => {
		setIsEmailIDDisabled(true);
		handleEmailIDSubmit(() => {
			setIsEmailIDDisabled(false);
		});
	};

	return (
		<>
			<div className='uteLoginHeader'>Reset&nbsp;your&nbsp;PIN</div>
			<div className='uteLoginSubHeader'>Verify your identity to reset your PIN.</div>
			<div className='formInputHeader'>Email ID</div>
			<div className={`${styles.emailIdContainer} ${usernotfound && styles.error}`}>
				<input
					type='text'
					name='emailID'
					id='emailID'
					maxLength={320}
					className={`${styles.emailID}`}
					placeholder='Enter your email ID'
					value={emailID}
					onChange={(e) => {
						handleEmailIDChange(e.target.value);
					}}
					data-test-auto='A2E37D00-BBAA-4CCE-AD35-CB9A1A79C1BC'
				/>
				<span className={styles.errorIconContainer}>{usernotfound && <ExclamationError />}</span>
			</div>
			{usernotfound && (
				<div className={styles.errorContainer}>
					<span>Email ID not found. Please enter the email ID associated with your account.</span>
				</div>
			)}
			<div className={styles.continueContainer}>
				<UTEButton
					text='Continue'
					className={styles.continue}
					onClick={onClickContinue}
					disabled={emailID.length > 0 ? false : isEmailIDDisabled}
					dataTestAuto='17C0CC29-00D6-41F5-AE28-4DF67C302964'
				/>
			</div>
		</>
	);
};

export default ResetPinEmail;
