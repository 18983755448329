import React from 'react';
import LoggedInLayout from 'src/modules/layout/logged-in-layout';
import MergeEmails from 'src/modules/merge-emails';
import UpdateEmail from 'src/modules/update-email';
import UpdatePIN from 'src/modules/update-pin';
import UpdateRecoverySection from 'src/modules/update-recovery';

const SecuritySettingsContainer = () => {
	return (
		<LoggedInLayout>
			<main className='pageMainContent'>
				<h1 className='pageMainHeader'>Security Settings</h1>
				<MergeEmails />
				<hr />
				<UpdatePIN />
				<hr />
				<UpdateEmail />
				<hr />
				<UpdateRecoverySection />
				<hr />
			</main>
		</LoggedInLayout>
	);
};

export default SecuritySettingsContainer;
