import React, { useEffect, useState } from 'react';
import styles from './create-pin.module.scss';
import { EyeClose, EyeOpen } from 'src/assets/svg/eye-icons';
import UTEButton from 'src/components/ute-button';
import { isValidNumber } from 'src/helper/utils';

type CreatePINProps = {
	pin: string;
	setPIN: (pin: string) => void;
	handlePINSubmit: () => void;
};
const CreatePIN = ({ pin, setPIN, handlePINSubmit }: CreatePINProps) => {
	const [inputPINType, setInputPINType] = useState('password');
	const [inputConfirmPINType, setInputConfirmPINType] = useState('password');
	const [confirmPIN, setConfirmPIN] = useState('');
	const [isPINDisabled, setIsPINDisabled] = useState(true);
	const [isError, setIsError] = useState(false);

	useEffect(() => {
		if (pin.length < 6 || confirmPIN.length < 6) {
			setIsError(false);
			setIsPINDisabled(true);
		} else {
			setIsPINDisabled(false);
		}
	}, [pin, confirmPIN]);

	const handlePINChange = (pinValue: string) => {
		if (pinValue === '' || isValidNumber(pinValue)) setPIN(pinValue);
	};

	const handleConfirmPINChange = (confirmPINValue: string) => {
		if (confirmPINValue === '' || isValidNumber(confirmPINValue)) setConfirmPIN(confirmPINValue);
	};

	const handlePINEyeClick = () => {
		setInputPINType(inputPINType === 'password' ? 'text' : 'password');
	};

	const handleConfirmPINEyeClick = () => {
		setInputConfirmPINType(inputConfirmPINType === 'password' ? 'text' : 'password');
	};

	const onCreateAccountContinue = () => {
		if (pin === confirmPIN) {
			setIsError(false);
			setIsPINDisabled(true);
			handlePINSubmit();
		} else {
			setIsError(true);
		}
	};

	return (
		<>
			<div className='uteLoginHeader'>Create&nbsp;your&nbsp;PIN</div>
			<div className='uteLoginSubHeader'>
				Final step! Now that your email is verified,
				<br />
				create a 6-digit PIN.
			</div>
			<div className='formInputHeader'>Create PIN</div>
			<div className={`${styles.pinContainer} ${isError && styles.error}`}>
				<input
					type={inputPINType}
					name='pin'
					id='pin'
					className={styles.pin}
					placeholder='Create your 6-digit PIN'
					value={pin}
					onChange={(e) => {
						handlePINChange(e.target.value);
					}}
					maxLength={6}
					data-test-auto='1d81cc4b-22fd-42bc-8fea-d74da31b7df6'
				/>
				<span
					className={styles.eyeContainer}
					onClick={handlePINEyeClick}>
					{inputPINType === 'password' ? <EyeClose /> : <EyeOpen />}
				</span>
			</div>
			<div className={`formInputHeader ${styles.confirmPadding}`}>Confirm PIN</div>
			<div className={`${styles.pinContainer} ${isError && styles.error}`}>
				<input
					type={inputConfirmPINType}
					name='confirmPIN'
					id='confirmPIN'
					className={styles.pin}
					placeholder='Confirm 6-digit PIN'
					value={confirmPIN}
					onChange={(e) => {
						handleConfirmPINChange(e.target.value);
					}}
					maxLength={6}
					data-test-auto='802b66ab-b33c-44aa-be69-aebf73e5066f'
				/>
				<span
					className={styles.eyeContainer}
					onClick={handleConfirmPINEyeClick}>
					{inputConfirmPINType === 'password' ? <EyeClose /> : <EyeOpen />}
				</span>
			</div>
			{isError && <div className={styles.errorContainer}>PINs do not match. Please ensure they are identical.</div>}
			<div className={styles.createAccountContainer}>
				<UTEButton
					text='Create Account'
					className={styles.createAccount}
					onClick={onCreateAccountContinue}
					disabled={isPINDisabled}
					dataTestAuto='6c05cc92-c10c-44d4-b4dd-9e961dd4a505'
				/>
			</div>
		</>
	);
};

export default CreatePIN;
