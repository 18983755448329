import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from 'src/helper/constants';
import {
	IGenerateOTPResponse,	
	IValidOTPDetail,
	IValidPINDetail,
	IValidateOTPResponse,
	IValidatePINResponse,	
	validOTPDetailDefault,
	validPINDetailDefault,
} from './login-component-types';
import { apiCatchHandler } from 'src/helper/utils';

export function validatePIN(
	companyId: number,
	registrationId: number,
	pin: string,
	emailAddress: string,
	callback: (validatePINResponse: IValidatePINResponse) => void,
) {
	const validatePINResponse: IValidatePINResponse = {
		validPINDetail: validPINDetailDefault,
		error: false,
	};
	axios
		.post(`${API_BASE_URL}/api/pin/validate/${companyId}?registrationId=${registrationId}&pin=${pin}&emailAddress=${emailAddress}`, '', {
			withCredentials: true,
		})
		.then((response: AxiosResponse<IValidPINDetail>) => {
			const { data } = response;
			validatePINResponse.validPINDetail = data;
			callback(validatePINResponse);
		})
		.catch((error) => {
			validatePINResponse.error = true;			
			apiCatchHandler(error,validatePINResponse, callback);
		});
}

export function generateOTP(
	companyId: number,
	emailID: string,
	registrationId: number,
	callback: (generateOTPResponse: IGenerateOTPResponse) => void,
) {
	const generateOTPResponse: IGenerateOTPResponse = {
		error: false,
	};
	axios
		.get(`${API_BASE_URL}/api/otp/generate/${companyId}?email=${emailID}&registrationId=${registrationId}`, {
			withCredentials: true,
		})
		.then((response: AxiosResponse) => {
			const { data } = response;
			generateOTPResponse.error = false;
			callback(generateOTPResponse);
		})
		.catch((error) => {
			generateOTPResponse.error = true;			
			apiCatchHandler(error,generateOTPResponse, callback);
		});
}

export function validateOTP(
	companyId: number,
	emailId: string,
	otp: string,
	registrationId: number,
	trackSuccessEvent: boolean,
	callback: (validateOTPResponse: IValidateOTPResponse) => void,
) {
	const validateOTPResponse: IValidateOTPResponse = {
		validOTPDetail: validOTPDetailDefault,
		error: false,
	};
	axios
		.post(
			`${API_BASE_URL}/api/otp/login/validate/${companyId}?emailId=${emailId}&otp=${otp}&registrationId=${registrationId}&trackSuccessEvent=${trackSuccessEvent}`,
			'',
			{ withCredentials: true },
		)
		.then((response: AxiosResponse<IValidOTPDetail>) => {
			const { data } = response;
			validateOTPResponse.validOTPDetail = data;
			callback(validateOTPResponse);
		})
		.catch((error) => {
			validateOTPResponse.error = true;
			if (error.response && error.response.data) {
				validateOTPResponse.validOTPDetail.errorDescription = error.response.data.errorDescription;
			}			
			apiCatchHandler(error,validateOTPResponse, callback);
		});
}
