import { Guid } from 'src/helper/utils';
import { ProductType } from '../common/common-types';

const NO_INDEX: number = -1;

export interface IFormBase {
	formName: string;
	bookmark: string;
	pageNo: number[];
}

export enum PaymentType {
	DUE = 0,
	REFUND = 1,
}

export enum VoucherTypes {
	None = 0,
	PaymentVoucher = 1,
	EstimatedVoucher,
}

export enum VoucherMode {
	None = 0,
	Recognized = 1,
	CustomVoucher = 2,
}

export interface IRefund extends IFormBase {
	authorityID: number;
	overPayment: number;
	overPaymentApplied: number;
	groupId?: number;
	isMultipart: boolean;
}

export interface IRefundInfo {
	id: number;
	documentId: number;
	authorityID: number;
	overPayment: number;
	overPaymentApplied: number;
	customRefundGUID?: Guid;
}

export enum VoucherStatus {
	None = 0,
	Existing = 1,
	ExistingUpdated = 2,
	New = 3,
}

export enum VoucherNo {
	None = 0,
	PaymentVoucher,
	Q1,
	Q2,
	Q3,
	Q4,
}

export enum CustomType {
	None = 0,
	Select,
	DoNotPayScheduledAutomaticWithdrawal,
	OnlinePaymentRequired,
	AddCustom,
}

export interface ITaxingAuthority {
	id: number;
	authorityName: string;
}

export const initialTaxingAuthority: ITaxingAuthority = {
	id: 0,
	authorityName: '',
};

export interface SummaryPageItem {
	name: string;
	value: number;
}

export interface SummaryPageGroupedItem {
	groupName: string;
	items: SummaryPageItem[];
	total: number;
}

export interface IVoucher extends IFormBase {
	authorityID: number;
	dueDate: Date | undefined;
	amount: number;
	voucherNo: VoucherNo;
	paymentType: VoucherTypes;
	customType: CustomType;
	onlinePaymentUri: string;
	customText: string;
	authorityImage: string;
	authorityName: string;
	groupId?: number;
	isMasterPaymentUrl: boolean;
	vocherMode: VoucherMode;
	amountPaid: number;
	checkNumber: string;
	paidDate: Date;
	isPaid: boolean;
	isPaymentCompleted?: boolean;
	voucherStatus?: VoucherStatus;
}

export interface IPaymentDetailResponseViewModel {
	documentId: number;
	taxYear: number;
	clientName: string;
	vouchers: IVoucher[];
	refunds: IRefund[];
	addedRefunds: IRefundInfo[];
	ProductType: ProductType;
}

export const initialPaymentDetailResponseViewModel: IPaymentDetailResponseViewModel = {
	documentId: 0,
	taxYear: 0,
	clientName: '',
	vouchers: [],
	refunds: [],
	addedRefunds: [],
	ProductType: ProductType.None,
};

export interface IDocumentPaymentInfoViewModel {
	documentId: number;
	taxYear: number;
	name: string;
	dueDate: string;
	clientGuid: string;
}

export interface IPaymentData {
	isLoading: boolean;
	documents: IDocumentPaymentInfoViewModel[];
	count: number;
	productType: ProductType;
}

export interface IPaymentTab {
	tabId: ProductType;
	tabHeading: string;
	tabDescription: string;
	visibility: boolean;
	active: boolean;
}

export const initialDocumentPaymentInfoViewModel: IDocumentPaymentInfoViewModel = {
	documentId: 0,
	taxYear: 0,
	name: '',
	dueDate: '',
	clientGuid: '',
};

export const initialIPaymentData: IPaymentData = {
	isLoading: false,
	documents: [],
	count: 0,
	productType: ProductType.None,
};

export function getPaymentDue(vouchers: IVoucher[], taxingAuthorities: ITaxingAuthority[]): SummaryPageGroupedItem {
	let groupedItem: SummaryPageGroupedItem = {
		groupName: 'Payments Due',
		items: [],
		total: 0,
	};
	const paymentVouchers = vouchers.filter((x) => x.paymentType === VoucherTypes.PaymentVoucher);
	let total: number = 0;
	let items: SummaryPageItem[] = [];

	if (paymentVouchers && paymentVouchers.length > 0) {
		paymentVouchers.forEach((value) => {
			if (value.amount > 0) {
				let authority = taxingAuthorities.find((x) => x.id === value.authorityID);
				items.push({
					name: authority ? authority.authorityName : '',
					value: value.amount,
				});
				total += value.amount;
			}
		});
	}

	groupedItem.items = summaryPageGroupedItemSort(items);
	groupedItem.total = total;

	return groupedItem;
}

export function getRefunds(
	refunds: IRefund[],
	addedRefunds: IRefundInfo[],
	taxingAuthorities: ITaxingAuthority[],
): SummaryPageGroupedItem {
	let groupedItem: SummaryPageGroupedItem = {
		groupName: 'Refunds',
		items: [],
		total: 0,
	};

	let total: number = 0;
	let items: SummaryPageItem[] = [];

	if (refunds && refunds.length > 0) {
		let nonMultipartItems = ([] = refunds.filter((refund: IRefund) => {
			return refund.isMultipart === false;
		}));
		let multipartItems = ([] = refunds.filter((refund: IRefund) => {
			return refund.isMultipart === true;
		}));
		if (multipartItems.length > 0) {
			let uniqueItems: IRefund[] = multipartItems.filter(
				(v, i, a) =>
					a.findIndex(
						(t: IRefund) =>
							t.authorityID === v.authorityID &&
							t.formName === v.formName &&
							t.overPayment === v.overPayment &&
							t.overPaymentApplied === v.overPaymentApplied,
					) === i,
			);
			nonMultipartItems = [...nonMultipartItems, ...uniqueItems];
		}
		if (nonMultipartItems && nonMultipartItems.length > 0) {
			nonMultipartItems.forEach((value: IRefund) => {
				if (value.overPayment > 0) {
					let authority = taxingAuthorities.find((x) => x.id === value.authorityID);
					items.push({
						name: authority ? authority.authorityName : '',
						value: value.overPayment - value.overPaymentApplied,
					});
					total += value.overPayment - value.overPaymentApplied;
				}
			});
		}
	}

	if (addedRefunds && addedRefunds.length > 0) {
		addedRefunds.forEach((value, index) => {
			if (value.overPayment > 0) {
				let authority = taxingAuthorities.find((x) => x.id === value.authorityID);
				items.push({
					name: authority ? authority.authorityName : '',
					value: value.overPayment - value.overPaymentApplied,
				});
				total += value.overPayment - value.overPaymentApplied;
			}
		});
	}

	groupedItem.items = summaryPageGroupedItemSort(items);
	groupedItem.total = total;

	return groupedItem;
}

export function getOverPaymentApplied(
	refunds: IRefund[],
	addedRefunds: IRefundInfo[],
	taxingAuthorities: ITaxingAuthority[],
): SummaryPageGroupedItem {
	let groupedItem: SummaryPageGroupedItem = {
		groupName: 'Overpayments Applied',
		items: [],
		total: 0,
	};

	let total: number = 0;
	let items: SummaryPageItem[] = [];

	if (refunds && refunds.length > 0) {
		let nonMultipartItems = ([] = refunds.filter((refund: IRefund) => {
			return refund.isMultipart === false;
		}));
		let multipartItems = ([] = refunds.filter((refund: IRefund) => {
			return refund.isMultipart === true;
		}));
		if (multipartItems.length > 0) {
			let uniqueItems: IRefund[] = multipartItems.filter(
				(v, i, a) =>
					a.findIndex(
						(t: IRefund) =>
							t.authorityID === v.authorityID &&
							t.formName === v.formName &&
							t.overPayment === v.overPayment &&
							t.overPaymentApplied === v.overPaymentApplied,
					) === i,
			);
			nonMultipartItems = [...nonMultipartItems, ...uniqueItems];
		}
		if (nonMultipartItems && nonMultipartItems.length > 0) {
			nonMultipartItems.forEach((value, index) => {
				if (value.overPaymentApplied > 0) {
					let authority = taxingAuthorities.find((x) => x.id === value.authorityID);
					items.push({
						name: authority ? authority.authorityName : '',
						value: value.overPaymentApplied,
					});
					total += value.overPaymentApplied;
				}
			});
		}
	}

	if (addedRefunds && addedRefunds.length > 0) {
		addedRefunds.forEach((value, index) => {
			if (value.overPaymentApplied > 0) {
				let authority = taxingAuthorities.find((x) => x.id === value.authorityID);
				items.push({
					name: authority ? authority.authorityName : '',
					value: value.overPaymentApplied,
				});
				total += value.overPaymentApplied;
			}
		});
	}

	groupedItem.items = summaryPageGroupedItemSort(items);
	groupedItem.total = total;

	return groupedItem;
}

export function getEstimatedVouchers(
	vouchers: IVoucher[],
	taxingAuthorities: ITaxingAuthority[],
): SummaryPageGroupedItem[] {
	let groupedItemList: SummaryPageGroupedItem[] = [];

	const voucherNoList = vouchers
		.filter(
			(x) =>
				x.paymentType === VoucherTypes.EstimatedVoucher &&
				(x.voucherNo == VoucherNo.Q1 ||
					x.voucherNo == VoucherNo.Q2 ||
					x.voucherNo == VoucherNo.Q3 ||
					x.voucherNo == VoucherNo.Q4),
		)
		.map((x) => x.voucherNo);
	const distVoucherNoList = voucherNoList.filter(distinct);

	if (distVoucherNoList) {
		distVoucherNoList.forEach((voucherNo, index) => {
			const estimatedVouchers = vouchers.filter((x) => x.voucherNo === voucherNo);

			let groupedItem: SummaryPageGroupedItem = {
				groupName: VoucherNo[voucherNo]?.toString(),
				items: [],
				total: 0,
			};

			let total: number = 0;
			let items: SummaryPageItem[] = [];

			if (estimatedVouchers && estimatedVouchers.length > 0) {
				estimatedVouchers.forEach((value) => {
					if (value.amount > 0) {
						let authority = taxingAuthorities.find((x) => x.id === value.authorityID);
						items.push({
							name: authority ? authority.authorityName : '',
							value: value.amount,
						});
						total += value.amount;
					}
				});
			}

			groupedItem.items = summaryPageGroupedItemSort(items);
			groupedItem.total = total;

			groupedItemList.push(groupedItem);
		});
	}

	return summaryPageGroupSort(groupedItemList);
}

export function summaryPageGroupSort(items: SummaryPageGroupedItem[]): SummaryPageGroupedItem[] {
	items = items.sort(function (a, b) {
		var nameA = a.groupName?.toLowerCase(),
			nameB = b.groupName?.toLowerCase();
		if (nameA < nameB)
			//sort string ascending
			return -1;
		if (nameA > nameB) return 1;
		return 0;
	});

	return items;
}

export function summaryPageGroupedItemSort(items: SummaryPageItem[]): SummaryPageItem[] {
	items = items.sort(function (a, b) {
		var nameA = a.name?.toLowerCase(),
			nameB = b.name?.toLowerCase();
		if (nameA < nameB)
			//sort string ascending
			return -1;
		if (nameA > nameB) return 1;
		return 0;
	});

	const indexes: number[] = [];
	let i = -1;
	const names: string[] = items.map(function (item) {
		return item.name;
	});
	while ((i = names.indexOf('Federal', i + 1)) != NO_INDEX) {
		indexes.push(i);
	}

	indexes.map(function (i) {
		let federalItem = items.splice(i, 1);
		items.unshift(federalItem[0]);
	});

	return items;
}

function distinct(value: any, index: number, self: any) {
	return self.indexOf(value) === index;
}
