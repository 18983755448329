import { useContext, useEffect } from 'react';
import { AuthContext } from '../../../context/ute-auth-context/auth-provider';
import styles from './disabled.module.scss';
import { DisabledIcon } from '../../../assets/svg/disabled-icon';
import { fetchCompanyNameAndLogo } from 'src/modules/common/common-api';
import { ICompanyNameAndLogoResponse } from 'src/modules/common/common-types';
import { UTENotifier } from 'src/modules/notification/ute-notifier';
import { ERROR_COMPANY_NAME_LOGO } from 'src/helper/constants';

const DisabledContainer = () => {
	const { updateCompanyId, updateCompanyNameAndLogo, companyId,companyNameAndLogo } = useContext(AuthContext);

	useEffect(() => {
		updateCompanyId(Number(companyId));
		fetchCompanyNameAndLogo(Number(companyId), (companyNameAndLogoResponse: ICompanyNameAndLogoResponse) => {
			if (companyNameAndLogoResponse.error) {
				UTENotifier.Error(ERROR_COMPANY_NAME_LOGO);
			} else {
				updateCompanyNameAndLogo(companyNameAndLogoResponse.companyNameAndLogo);
				document.title = companyNameAndLogoResponse.companyNameAndLogo?.name + ' : ssportal.com';
			}
		});
	}, []);

	return (
		<div>
			<div>
				<DisabledIcon />
			</div>
			<div className={styles.accessDenied}>Access Denied</div>
			<div className={styles.disabledTextContainer}>
				<p className={styles.disabledText}>
					{companyNameAndLogo.name} has turned off Client Portal capability. Please get in touch with your representative at{' '}
					{companyNameAndLogo.name} for assistance.
				</p>
			</div>
		</div>
	);
};

export default DisabledContainer;
