import { OTPValidationStatus } from 'src/modules/common/common-types';

export type IGenerateOTPResponse = {
	error: boolean;
};

export type IValidOTPDetail = {
	status: OTPValidationStatus;
	retryCount: number;
	accessToken: string;
};

export type IValidateOTPResponse = {
	validOTPDetail: IValidOTPDetail;
	error: boolean;
};

export const validOTPDetailDefault: IValidOTPDetail = {
	status: OTPValidationStatus.Success,
	retryCount: 0,
	accessToken: '',
};

export type IAccountCreatedResponse = {
	error: boolean;
	accountCreated: boolean;
};

export const accountCreatedResponseDefault: IAccountCreatedResponse = {
	error: false,
	accountCreated: false,
};
