import React from 'react';

const BellIcon: React.FC = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='17'
			height='16'
			fill='none'
			viewBox='0 0 17 16'>
			<g clipPath='url(#clip0_4201_7883)'>
				<path
					fill='#AB7F2D'
					d='M8.5 16a2 2 0 002-2h-4a2 2 0 002 2zm0-14.082l-.797.16A4.002 4.002 0 004.5 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.634 8.197 12.5 6.628 12.5 6a4.002 4.002 0 00-3.203-3.92L8.5 1.917zM14.72 12c.223.447.481.8.78 1h-14c.299-.2.557-.553.78-1 .9-1.8 1.22-5.12 1.22-6 0-2.42 1.72-4.44 4.005-4.901a1 1 0 111.99 0 5.002 5.002 0 014.005 4.9c0 .88.32 4.2 1.22 6z'></path>
			</g>
			<defs>
				<clipPath id='clip0_4201_7883'>
					<path
						fill='#fff'
						d='M0 0H16V16H0z'
						transform='translate(.5)'></path>
				</clipPath>
			</defs>
		</svg>
	);
};

export default BellIcon;
