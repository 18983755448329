export class DisplayDownloadFile {
	public showFile = (blob: Blob, fileName: string) => {
		var newBlob = new Blob([blob], { type: blob.type });

		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob, fileName);
			return;
		}

		const data = window.URL.createObjectURL(newBlob);
		var link = document.createElement('a');
		link.href = data;
		link.download = fileName;
		link.target = '_blank';
		document.body.appendChild(link);
		link.click();
		setTimeout(function () {
			window.URL.revokeObjectURL(data);
			document.body.removeChild(link);
		}, 100);
	};

	public directDownload = (url: string, fileName?: string) => {
		var link = document.createElement('a');
		link.href = url;
		link.target = '_blank';
		if (fileName) {
			link.download = fileName;
		}
		document.body.appendChild(link);
		link.click();
		setTimeout(function () {
			document.body.removeChild(link);
		}, 100);
	};

	public directDownloadWithFileName = async (url: string, fileName?: string) => {
		const response = await fetch(url); // Fetch the file from the URL
		const blob = await response.blob(); // Convert to blob
	
		const blobUrl = window.URL.createObjectURL(blob); // Create a Blob URL
	
		const link = document.createElement('a');
		link.href = blobUrl;
	
		// Use the provided filename or fallback to 'downloaded-file'
		link.download = fileName || 'downloaded-file';
	
		document.body.appendChild(link);
		link.click();
	
		// Clean up
		document.body.removeChild(link);
		window.URL.revokeObjectURL(blobUrl); // Revoke the Blob URL to free memory
	};
	
}
