import React from 'react';
import { IFilter, IGatherDocumentModel, IOrganizerDocumentModel, ITaxDocument, ITaxRecord } from '../documents-types';
import { getNumberOfFilesAndFoldersCount } from 'src/helper/utils';
import TreeNode from '../tree-node';
import styles from './documents-tree.module.scss';

type DocumentsTreeProps = {
	records: ITaxRecord[];
	isLoading: boolean;
	displayCCRecipientModal: (documentReceived: ITaxDocument) => void;
	onshowPreviewModal: (documentReceived: ITaxDocument, name: string) => void;
	fetchTaxDocuments: (taxYear: number) => void;
	expandLevelIds: string;
	handleTaxYearClick: (taxYear: number) => void;
	filter: IFilter;
	downloadExchangeFiles: (file: ITaxDocument) => void;
	showExchangePreviewModal: (file: ITaxDocument) => void;
	showCpaMessageModal: (file: ITaxDocument) => void;
	onShowGTRPreviewModal: (document: IGatherDocumentModel) => void;
	onShowORGPreviewModal: (document: IOrganizerDocumentModel) => void;
};
const DocumentsTree = ({
	records,
	isLoading,
	displayCCRecipientModal,
	onshowPreviewModal,
	fetchTaxDocuments,
	expandLevelIds,
	handleTaxYearClick,
	filter,
	downloadExchangeFiles,
	showExchangePreviewModal,
	showCpaMessageModal,
	onShowGTRPreviewModal,
	onShowORGPreviewModal,
}: DocumentsTreeProps) => {
	return (
		<tbody>
			{records.length === 0 && !isLoading ? (
				<td
					className={styles.messageRow}
					colSpan={5}>
					{(filter.fromDate && filter.toDate) || filter.filterStatus || filter.filterFileType
						? 'No search results found.'
						: 'No documents to display at this time.'}
				</td>
			) : (
				records.map(
					(node: any, index: number) =>
						(getNumberOfFilesAndFoldersCount(node) > 0 || node.documentCount > 0) && (
							<TreeNode
								key={node.name + node.id + index}
								node={node}
								level={0}
								showCCRecipientModal={displayCCRecipientModal}
								showPreviewModal={onshowPreviewModal}
								fetchTaxDocuments={fetchTaxDocuments}
								parentId=''
								expandLevelIds={expandLevelIds}
								handleTaxYearClick={handleTaxYearClick}
								downloadExchangeFiles={downloadExchangeFiles}
								showExchangePreviewModal={showExchangePreviewModal}
								showCpaMessageModal={showCpaMessageModal}
								onShowGTRPreviewModal={onShowGTRPreviewModal}
								onShowORGPreviewModal={onShowORGPreviewModal}
							/>
						),
				)
			)}
		</tbody>
	);
};

export default DocumentsTree;
