import React from 'react';
import { Row } from 'react-bootstrap';
import { ITaxDocument } from 'src/modules/documents/documents-types';
import SSRDocumentPreview from '../ssr-document-preview';
import UTEModalLayout from 'src/components/ute-modal-layout';
import SIGDocumentPreview from '../sig-document-preview';
import SSEDocumentPreview from '../sse-document-preview';
import { ClientTypes, ProductType } from 'src/modules/common/common-types';
import GTRDocumentPreview from './gtr-document-preview/gtr-preview-modal';
import { IFileName, IRecentActivity } from 'src/modules/recent-activity/recent-activity-types';
import UTEClientViewWrapper from 'src/components/ute-client-view-wrapper';

type PreviewPopupProps = {
	showModal: boolean;
	id: number;
	name: string;
	onCancel: () => void;
	clientType: ClientTypes;
	productType: ProductType;
	exchangeFile?: ITaxDocument;
	recentActivity?: IRecentActivity;
};

const PreviewPopupModal = ({
	showModal,
	onCancel,
	id,
	name,
	clientType,
	productType,
	exchangeFile,
	recentActivity,
}: PreviewPopupProps) => {
	const getPreview = () => {
		switch (productType) {
			case ProductType.Returns:
			case ProductType.Extensions:
				return (
					<SSRDocumentPreview
						documentId={id}
						clientType={clientType}
						productType={productType}
					/>
				);
			case ProductType.SignaturesOneOff:
				return <SIGDocumentPreview documentId={id} />;
			case ProductType.Exchange:
				return (
					<UTEClientViewWrapper dataTestAuto={'ec464f6b-f807-4ec3-9496-abf616d70797'}>
						<SSEDocumentPreview file={exchangeFile as ITaxDocument} />
					</UTEClientViewWrapper>
				);
			case ProductType.Gather:
			case ProductType.Organizers:
				return (
					<GTRDocumentPreview
						fileData={recentActivity?.eventData as IFileName}
						productType={productType}
					/>
				);
			default:
				return null;
		}
	};

	return (
		showModal && (
			<UTEModalLayout
				width='1100'
				theme='light'
				title={name}
				hide={onCancel}
				onClickOutside={onCancel}>
				<Row data-test-auto='F75380E8-5D19-48A3-9A32-5A792F893CA7'>{getPreview()}</Row>
			</UTEModalLayout>
		)
	);
};

export default PreviewPopupModal;
