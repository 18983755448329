import React from 'react';

const HammerSettingsIcon = () => {
	return (
		<svg
			width='122'
			height='110'
			viewBox='0 0 122 110'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M59.2406 43.4545L42.584 26.8023C44.0546 23.5498 44.4994 19.9266 43.8591 16.415C43.2189 12.9034 41.5241 9.67026 39.0003 7.14604C36.4766 4.62182 33.2437 2.92636 29.7323 2.28547C26.2208 1.64458 22.5975 2.08867 19.3447 3.55859L28.7687 12.9782V21.761H19.9859L10.5619 12.3414C9.09137 15.5939 8.6466 19.2171 9.28683 22.7287C9.92706 26.2403 11.6219 29.4734 14.1456 31.9977C16.6694 34.5219 19.9023 36.2173 23.4137 36.8582C26.9251 37.4991 30.5485 37.055 33.8012 35.5851L50.4578 52.2373'
				fill='#B5D5EA'
			/>
			<path
				d='M59.2406 43.4545L42.584 26.8023C44.0546 23.5498 44.4994 19.9266 43.8591 16.415C43.2189 12.9034 41.524 9.67026 39.0003 7.14604C36.4766 4.62182 33.2437 2.92636 29.7323 2.28547C26.2208 1.64458 22.5975 2.08867 19.3447 3.55859L28.7687 12.9782V21.761H19.9859L10.5619 12.3414C9.09137 15.5939 8.6466 19.2171 9.28683 22.7287C9.92706 26.2403 11.6219 29.4734 14.1456 31.9977C16.6694 34.5219 19.9023 36.2173 23.4137 36.8582C26.9251 37.4991 30.5485 37.055 33.8012 35.5851L50.4578 52.2373'
				stroke='#B5D5EA'
				strokeWidth='3.76875'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M59.7734 61.5572L76.43 78.2138C74.9595 81.4663 74.5147 85.0896 75.155 88.6011C75.7952 92.1127 77.49 95.3459 80.0138 97.8701C82.5375 100.394 85.7704 102.09 89.2818 102.731C92.7933 103.372 96.4166 102.927 99.6694 101.458L90.2454 92.0248V83.242H99.0282L108.452 92.6703C109.923 89.4179 110.367 85.7946 109.727 82.283C109.087 78.7715 107.392 75.5383 104.868 73.0141C102.345 70.4899 99.1118 68.7944 95.6004 68.1535C92.089 67.5126 88.4656 67.9567 85.2129 69.4266L68.5562 52.77'
				fill='#B5D5EA'
			/>
			<path
				d='M59.7734 61.5572L76.43 78.2138C74.9595 81.4663 74.5147 85.0896 75.155 88.6011C75.7952 92.1127 77.49 95.3459 80.0138 97.8701C82.5375 100.394 85.7704 102.09 89.2818 102.731C92.7933 103.372 96.4166 102.927 99.6694 101.458L90.2454 92.0248V83.242H99.0282L108.452 92.6703C109.923 89.4179 110.367 85.7946 109.727 82.283C109.087 78.7715 107.392 75.5383 104.868 73.0141C102.345 70.4899 99.1118 68.7944 95.6004 68.1535C92.089 67.5126 88.4656 67.9567 85.2129 69.4266L68.5562 52.77'
				stroke='#B5D5EA'
				strokeWidth='3.76875'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M93.087 12.9214C88.6584 17.0541 80.3983 16.6607 73.2609 10L67 15.8388L100.391 47L115 33.367L93.087 12.9214Z'
				fill='#B5D5EA'
				stroke='#B5D5EA'
				strokeWidth='3.76875'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M94.4463 5.29355C89.787 9.95284 81.0964 9.50931 73.5871 2L67 8.58272L102.131 43.714L117.501 28.344L94.4463 5.29355Z'
				stroke='#0973BA'
				strokeWidth='3.76875'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M55.2406 47.4545L38.584 30.8023C40.0546 27.5498 40.4994 23.9266 39.8591 20.415C39.2189 16.9034 37.524 13.6703 35.0003 11.146C32.4766 8.62182 29.2437 6.92636 25.7323 6.28547C22.2208 5.64458 18.5975 6.08867 15.3447 7.55859L24.7687 16.9782V25.761H15.9859L6.56191 16.3414C5.09137 19.5939 4.6466 23.2171 5.28683 26.7287C5.92706 30.2403 7.6219 33.4734 10.1456 35.9977C12.6694 38.5219 15.9023 40.2173 19.4137 40.8582C22.9251 41.4991 26.5485 41.055 29.8012 39.5851L46.4578 56.2373'
				stroke='#0973BA'
				strokeWidth='3.76875'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M55.7734 65.5572L72.43 82.2138C70.9595 85.4663 70.5147 89.0896 71.155 92.6011C71.7952 96.1127 73.49 99.3459 76.0138 101.87C78.5375 104.394 81.7704 106.09 85.2818 106.731C88.7933 107.372 92.4166 106.927 95.6694 105.458L86.2454 96.0248V87.242H95.0282L104.452 96.6703C105.923 93.4179 106.367 89.7946 105.727 86.283C105.087 82.7715 103.392 79.5383 100.868 77.0141C98.3447 74.4899 95.1118 72.7944 91.6004 72.1535C88.089 71.5126 84.4656 71.9567 81.2129 73.4266L64.5562 56.77'
				stroke='#0973BA'
				strokeWidth='3.76875'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M78.5526 23L7.16373 94.3889C5.77832 95.7743 5 97.6533 5 99.6126C5 101.572 5.77832 103.451 7.16373 104.836C8.54914 106.222 10.4282 107 12.3874 107C14.3467 107 16.2257 106.222 17.6111 104.836L89 33.4474'
				fill='#B5D5EA'
			/>
			<path
				d='M78.5526 23L7.16373 94.3889C5.77832 95.7743 5 97.6533 5 99.6126C5 101.572 5.77832 103.451 7.16373 104.836C8.54914 106.222 10.4282 107 12.3874 107C14.3467 107 16.2257 106.222 17.6111 104.836L89 33.4474'
				stroke='#B5D5EA'
				strokeWidth='3.76875'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M78.5526 23L7.16373 94.3889C5.77832 95.7743 5 97.6533 5 99.6126C5 101.572 5.77832 103.451 7.16373 104.836C8.54914 106.222 10.4282 107 12.3874 107C14.3467 107 16.2257 106.222 17.6111 104.836L89 33.4474'
				stroke='#0973BA'
				strokeWidth='3.76875'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default HammerSettingsIcon;
