import React from 'react';
import Documents from 'src/modules/documents';
import LoggedInLayout from 'src/modules/layout/logged-in-layout';

const DocumentsContainer = () => {
	return (
		<LoggedInLayout>
			<main className='pageMainContent'>
				<h1 className='pageMainHeader'>Documents</h1>
				<Documents />
			</main>
		</LoggedInLayout>
	);
};

export default DocumentsContainer;
