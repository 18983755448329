import React from 'react';

type SVGProps = {
	color?: string;
};
const CloseIcon = ({ color }: SVGProps) => {
	return (
		<svg
			width='8'
			height='9'
			viewBox='0 0 8 9'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.65833 0.840344C7.68743 0.869372 7.71052 0.903857 7.72628 0.941823C7.74203 0.979788 7.75014 1.02049 7.75014 1.06159C7.75014 1.1027 7.74203 1.1434 7.72628 1.18136C7.71052 1.21933 7.68743 1.25382 7.65833 1.28284L0.783332 8.15784C0.724653 8.21652 0.645067 8.24949 0.562082 8.24949C0.479097 8.24949 0.399511 8.21652 0.340832 8.15784C0.282153 8.09916 0.249187 8.01958 0.249187 7.93659C0.249187 7.85361 0.282153 7.77402 0.340832 7.71534L7.21583 0.840344C7.24486 0.811242 7.27935 0.788153 7.31731 0.772398C7.35528 0.756644 7.39598 0.748535 7.43708 0.748535C7.47819 0.748535 7.51889 0.756644 7.55685 0.772398C7.59482 0.788153 7.6293 0.811242 7.65833 0.840344Z'
				fill={color ?? '#565A5E'}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0.340832 0.840344C0.31173 0.869372 0.288641 0.903857 0.272887 0.941823C0.257133 0.979788 0.249023 1.02049 0.249023 1.06159C0.249023 1.1027 0.257133 1.1434 0.272887 1.18136C0.288641 1.21933 0.31173 1.25382 0.340832 1.28284L7.21583 8.15784C7.27451 8.21652 7.3541 8.24949 7.43708 8.24949C7.52007 8.24949 7.59965 8.21652 7.65833 8.15784C7.71701 8.09916 7.74998 8.01958 7.74998 7.93659C7.74998 7.85361 7.71701 7.77402 7.65833 7.71534L0.783332 0.840344C0.754304 0.811242 0.719819 0.788153 0.681853 0.772398C0.643887 0.756644 0.603187 0.748535 0.562082 0.748535C0.520978 0.748535 0.480277 0.756644 0.442311 0.772398C0.404345 0.788153 0.369861 0.811242 0.340832 0.840344Z'
				fill={color ?? '#565A5E'}
			/>
		</svg>
	);
};

export default CloseIcon;
