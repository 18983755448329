import React, { ReactNode, useState } from 'react';
import UTEButton from 'src/components/ute-button';
import OtpInputControl from 'src/components/otp-input-control';
import { IOTPValidity, OTPValidityDefault } from './ute-otp-input-types';
import styles from './ute-otp-input.module.scss';

type UTEOTPInputProps = {
	headerText?: ReactNode;
	subHeaderText?: ReactNode;
	expiryText?: ReactNode;
	codeNotReceivedText?: ReactNode;
	resendCodeText?: ReactNode;
	otpSubmitText?: ReactNode;
	otpLength: number;
	otp: string;
	setOTP: (otp: string) => void;
	handleOTPSubmit: (callback: () => void) => void;
	otpValidity: IOTPValidity;
	setOTPValidity: (otpValidity: IOTPValidity) => void;
	handleResendCode: () => void;
};

const UTEOTPInput = ({
	headerText,
	subHeaderText,
	expiryText,
	codeNotReceivedText,
	resendCodeText,
	otpSubmitText,
	otpLength,
	otp,
	setOTP,
	handleOTPSubmit,
	otpValidity,
	setOTPValidity,
	handleResendCode,
}: UTEOTPInputProps) => {
	const [isOTPDisabled, setIsOTPDisabled] = useState(true);

	const handleOTPChange = (otpValue: any) => {
		setOTP(otpValue);
		setOTPValidity(OTPValidityDefault);
		if (otpValue.length === otpLength) setIsOTPDisabled(false);
		else setIsOTPDisabled(true);
	};

	const onClickConfirmOTP = () => {
		setIsOTPDisabled(true);
		handleOTPSubmit(() => {
			setIsOTPDisabled(false);
		});
	};

	const onClickResendCode = () => {
		handleResendCode();
	};

	return (
		<>
			<div className='uteLoginHeader'>{headerText ?? <>Verify&nbsp;your&nbsp;identity</>}</div>
			<div className='uteLoginSubHeader'>
				{subHeaderText ?? (
					<>
						A One-Time Access Code has been sent to
						<br />
						your email address; it{' '}
					</>
				)}
				<span className={styles.otpExpiryMessage}>{expiryText ?? <>expires in 20 minutes.</>}</span>
			</div>

			<div className={styles.otpContainer}>
				<OtpInputControl
					otp={otp}
					setOtp={handleOTPChange}
					isInvalid={!otpValidity.isValid}
					errorText={otpValidity.message}
					numInputs={otpLength}
				/>
			</div>
			<div className={styles.resendOTPContainer}>
				{codeNotReceivedText ?? <>If you haven’t received the code yet, please check your spam/junk folder.</>}
				<br />
				<span onClick={onClickResendCode}>{resendCodeText ?? <>Resend Code</>}</span>
			</div>
			<div className={styles.confirmContainer}>
				<UTEButton
					text={otpSubmitText ?? 'Confirm'}
					className={styles.confirm}
					onClick={onClickConfirmOTP}
					disabled={isOTPDisabled}
					dataTestAuto='0a515d4e-c4d9-46a9-ae9c-c95a905d96f7'
				/>
			</div>
		</>
	);
};

export default UTEOTPInput;
