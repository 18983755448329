import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DashboardContainer from 'src/pages/dashboard';
import DistributeK1sContainer from 'src/pages/distribute-k1s';
import DocumentsContainer from 'src/pages/documents';
import SecuritySettingsContainer from 'src/pages/security-settings';
import TaxPaymentsContainer from 'src/pages/tax-payments';
import UserAccess from 'src/pages/user-access';
import ClientView from 'src/pages/client-view';

const LoggedInUsers = () => {
	return (
		<Router>
			<></>
			<Routes>
				<Route
					path='/'
					element={<DashboardContainer />}
				/>
				<Route
					path='/documents'
					element={<DocumentsContainer />}
				/>
				<Route
					path='/tax-payments'
					element={<TaxPaymentsContainer />}
				/>
				<Route
					path='/distribute-k1s'
					element={<DistributeK1sContainer />}
				/>
				<Route
					path='/security-settings'
					element={<SecuritySettingsContainer />}
				/>
				<Route
					path='/access/:productType/:companyId'
					element={<UserAccess />}
				/>
				<Route
					path='/clientview/:productType/:companyId/:cpaUserId'
					element={<ClientView />}
				/>
			</Routes>
		</Router>
	);
};

export default LoggedInUsers;
