import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from 'src/helper/constants';
import {
	IK1DocumentsPresentResponse,
	IK1Details,
	IK1DistributeResponse,
	initialK1Details,
} from './distribute-k1-types';
import { apiCatchHandler } from 'src/helper/utils';

export const getK1Details = (
	pageNumber: number,
	pageSize: number,
	callback: (k1DistributeResponse: IK1DistributeResponse) => void,
) => {
	const k1DistributeResponse: IK1DistributeResponse = {
		k1Details: initialK1Details,
		error: false,
	};
	axios
		.get(`${API_BASE_URL}/api/dashboard/get-k1-distribute-details?pageNumber=${pageNumber}&pageSize=${pageSize}`)
		.then((response: AxiosResponse<IK1Details>) => {
			const { data } = response;
			k1DistributeResponse.k1Details = data;
			callback(k1DistributeResponse);
		})
		.catch((error) => {
			k1DistributeResponse.error = true;
			apiCatchHandler(error, k1DistributeResponse, callback);
		});
};

export const getIsK1DocumentsPresent = (
	callback: (k1DocumentsPresentResponse: IK1DocumentsPresentResponse) => void,
) => {
	const k1DocumentsPresentResponse: IK1DocumentsPresentResponse = {
		isPresent: false,
		error: false,
	};
	axios
		.get(`${API_BASE_URL}/api/dashboard/is-k1-distribute-documents`)
		.then((response: AxiosResponse<boolean>) => {
			const { data } = response;
			k1DocumentsPresentResponse.isPresent = data;
			callback(k1DocumentsPresentResponse);
		})
		.catch((error) => {
			k1DocumentsPresentResponse.error = true;
			apiCatchHandler(error, k1DocumentsPresentResponse, callback);
		});
};
