import React from 'react';

interface ISvgProps {
	width?: string;
	height?: string;
	className?: string;
	onClick?: () => void;
}

const DelegatedIcon: React.FC<ISvgProps> = ({ className }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			fill='none'
			viewBox='0 0 16 16'
			className={className}>
			<path
				fill='#355ABF'
				d='M5 8a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm1.667-2.5a1.667 1.667 0 11-3.334 0 1.667 1.667 0 013.334 0zM10 12.167C10 13 9.167 13 9.167 13H.833S0 13 0 12.167c0-.834.833-3.334 5-3.334s5 2.5 5 3.334zm-.833-.004c-.001-.205-.129-.821-.694-1.386-.543-.544-1.566-1.11-3.473-1.11-1.908 0-2.93.566-3.473 1.11-.565.565-.692 1.181-.694 1.386h8.334z'></path>
			<path
				fill='#355ABF'
				fillRule='evenodd'
				d='M9 5c0-.114.046-.223.128-.303a.442.442 0 01.31-.125h5.068l-1.879-1.84a.425.425 0 010-.606.443.443 0 01.62 0l2.624 2.57A.43.43 0 0116 5a.422.422 0 01-.129.303l-2.624 2.571a.443.443 0 01-.62 0 .425.425 0 010-.606l1.879-1.84H9.437a.442.442 0 01-.309-.125A.424.424 0 019 5z'
				clipRule='evenodd'></path>
		</svg>
	);
};

export default DelegatedIcon;
