import React from 'react';

const CalendarIcon = () => {
	return (
		<svg
			width='16'
			height='17'
			viewBox='0 0 16 17'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<rect
				width='16'
				height='16'
				transform='translate(0 0.5)'
				fill='white'
				fillOpacity='0.01'
			/>
			<g clipPath='url(#clip0_11684_70640)'>
				<rect
					width='14'
					height='14'
					transform='translate(1 1.5)'
					fill='white'
					fillOpacity='0.01'
				/>
				<g clipPath='url(#clip1_11684_70640)'>
					<path
						d='M10.4968 7.75177C10.5375 7.79241 10.5698 7.84069 10.5919 7.89384C10.6139 7.947 10.6253 8.00398 10.6253 8.06152C10.6253 8.11907 10.6139 8.17605 10.5919 8.2292C10.5698 8.28235 10.5375 8.33063 10.4968 8.37127L7.87176 10.9963C7.83112 11.037 7.78284 11.0693 7.72969 11.0914C7.67654 11.1135 7.61956 11.1248 7.56201 11.1248C7.50447 11.1248 7.44748 11.1135 7.39433 11.0914C7.34118 11.0693 7.2929 11.037 7.25226 10.9963L5.93976 9.68377C5.89908 9.6431 5.86682 9.59481 5.8448 9.54166C5.82279 9.48851 5.81146 9.43155 5.81146 9.37402C5.81146 9.3165 5.82279 9.25953 5.8448 9.20639C5.86682 9.15324 5.89908 9.10495 5.93976 9.06427C6.02191 8.98212 6.13333 8.93597 6.24951 8.93597C6.30704 8.93597 6.364 8.9473 6.41715 8.96932C6.47029 8.99133 6.51858 9.0236 6.55926 9.06427L7.56201 10.0679L9.87726 7.75177C9.9179 7.71103 9.96618 7.67871 10.0193 7.65665C10.0725 7.63459 10.1295 7.62324 10.187 7.62324C10.2446 7.62324 10.3015 7.63459 10.3547 7.65665C10.4078 7.67871 10.4561 7.71103 10.4968 7.75177Z'
						fill='#565A5E'
					/>
					<path
						d='M4.06201 1.49902C4.17804 1.49902 4.28932 1.54512 4.37137 1.62716C4.45342 1.70921 4.49951 1.82049 4.49951 1.93652V2.37402H11.4995V1.93652C11.4995 1.82049 11.5456 1.70921 11.6277 1.62716C11.7097 1.54512 11.821 1.49902 11.937 1.49902C12.053 1.49902 12.1643 1.54512 12.2464 1.62716C12.3284 1.70921 12.3745 1.82049 12.3745 1.93652V2.37402H13.2495C13.7136 2.37402 14.1588 2.5584 14.4869 2.88659C14.8151 3.21478 14.9995 3.65989 14.9995 4.12402V13.749C14.9995 14.2132 14.8151 14.6583 14.4869 14.9865C14.1588 15.3146 13.7136 15.499 13.2495 15.499H2.74951C2.28538 15.499 1.84026 15.3146 1.51207 14.9865C1.18389 14.6583 0.999512 14.2132 0.999512 13.749V4.12402C0.999512 3.65989 1.18389 3.21478 1.51207 2.88659C1.84026 2.5584 2.28538 2.37402 2.74951 2.37402H3.62451V1.93652C3.62451 1.82049 3.67061 1.70921 3.75265 1.62716C3.8347 1.54512 3.94598 1.49902 4.06201 1.49902ZM1.87451 4.99902V13.749C1.87451 13.9811 1.9667 14.2036 2.13079 14.3677C2.29489 14.5318 2.51745 14.624 2.74951 14.624H13.2495C13.4816 14.624 13.7041 14.5318 13.8682 14.3677C14.0323 14.2036 14.1245 13.9811 14.1245 13.749V4.99902H1.87451Z'
						fill='#565A5E'
					/>
				</g>
			</g>
			<defs>
				<clipPath id='clip0_11684_70640'>
					<rect
						width='14'
						height='14'
						fill='white'
						transform='translate(1 1.5)'
					/>
				</clipPath>
				<clipPath id='clip1_11684_70640'>
					<rect
						width='14'
						height='14'
						fill='white'
						transform='translate(1 1.5)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default CalendarIcon;
