import React, { useContext } from 'react';
import styles from './locked-otp.module.scss';
import { LockedIcon } from 'src/assets/svg/locked-icon';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';

const LockedOTP = () => {
	const { companyNameAndLogo } = useContext(AuthContext);
	return (
		<>
			<div className={styles.lockedIcon}>
				<LockedIcon />
			</div>

			<div className='uteLoginHeader'>Account Locked</div>
			<div className='uteLoginSubHeader'>
				Your account has been locked
				<br />
				Please contact <span className={styles.companyName}>{companyNameAndLogo.name}</span>
				<br />
				for further assistance.
			</div>
		</>
	);
};

export default LockedOTP;
