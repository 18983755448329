import React, { useEffect, useState, useContext } from 'react';
import styles from './documents.module.scss';
import { UTENotifier } from '../notification/ute-notifier';
import {
	DEFAULT_PAYMENTLIST_PER_PAGE,
	LOGGED_IN_LAYOUT_CONSTANTS,
	SSE_DOCUMENT_CONSTANTS,
	TAX_DOCUMENTS_CONSTANTS,
	TAX_RETURN_FOLDER_NAME,
} from 'src/helper/constants';
import LoadingOverlay from 'react-loading-overlay';
import Loader from 'src/components/loader';
import {
	getExchangeSasUrl,
	getFilteredTaxDocuments,
	getPaginatedTaxDocuments,
	getTaxYearFolders,
} from './documents-api';
import {
	DocumentsDisplay,
	DocumentsSort,
	FilterType,
	IFilter,
	IGatherDocumentModel,
	IOrganizerDocumentModel,
	INodeSelected,
	IPaginatedNode,
	IPaginatedTaxDataResponse,
	ITaxDocument,
	ITaxRecord,
	ITaxRecordsResponse,
	initialFilter,
	initialNodeSelected,
	initialTaxDocument,
	initialGatherDocument,
	initialOrganizerDocument,
} from './documents-types';
import ForwardTaxDocumentContainer from '../cc-recipient';
import { getCCRecipientDownloadableTaxDocuments } from '../cc-recipient/forward-taxdocument-api';
import {
	ICCRecipientDownloadableDocuments,
	IDownloadableDocumentsResponse,
} from '../cc-recipient/forward-taxdocument-types';
import { generateTaxpayerEncodedClientId, getCompanyProducts } from '../common/common-api';
import { ICompanyProductsResponse, ICompanyProduct, ProductType, ClientTypes } from '../common/common-types';
import { saveUserActivity } from '../recent-activity/recent-activity-api';
import { IUserActivity, OneHubEventType } from '../recent-activity/recent-activity-types';
import PreviewPopupModal from '../document-preview/preview-pop-up';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';
import DocumentsHead from './documents-head';
import DocumentsTree from './documents-tree';
import DocumentsGrid from './documents-grid';
import UTEPagination from 'src/components/ute-pagination';
import UTEBreadcrumb from 'src/components/ute-breadcrumb';
import { getTaxYearFromName } from 'src/helper/utils';
import UTEModalLayout from 'src/components/ute-modal-layout';
import FilterDocuments from './filter-documents';
import FilterList from './filter-list';
import { logger } from 'src/context/ute-auth-context/auth-provider';
import { DisplayDownloadFile } from 'src/helper/display-download-file';
import { CpaMessageModal } from '../document-preview/cpa-message';
import { getCPAMessage } from '../document-preview/cpa-message/cpa-message-api';
import { ProductStatus } from 'src/helper/constants';
import GTRDocumentPreviewModal from '../document-preview/preview-pop-up/gtr-document-preview';
import { useLocation } from 'react-router-dom';

const Documents = () => {
	const [records, setRecords] = useState<ITaxRecord[]>([]);
	const [paginatedRecords, setPaginatedRecords] = useState<IPaginatedNode[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isDocumentLoading, setIsDocumentLoading] = useState(false);
	const [showCCRecipientModal, setShowCCRecipientModal] = useState(false);
	const [showPreviewModal, setShowPreviewModal] = useState(false);
	const [taxDocument, setDocument] = useState<ITaxDocument>(initialTaxDocument);
	const [downloadableDocuments, setDownloadableDocuments] = useState<ICCRecipientDownloadableDocuments[]>([]);
	const [encodedClientId, setEncodedClientId] = useState<string>('');
	const [selectedDocumentName, setSelectedDocumentName] = useState('');
	const [openTreeID, setOpenTreeID] = useState('');
	const [searchText, setSearchText] = useState('');
	const [searchTextWithResults, setSearchTextWithResults] = useState('');
	const [showClearButton, setShowClearButton] = useState(false);
	const [display, setDisplay] = useState(DocumentsDisplay.TREE);
	const [sortOrder, setSortOrder] = useState(DocumentsSort.NONE);
	const [filter, setFilter] = useState<IFilter>(initialFilter);
	const [totalCount, setTotalCount] = useState(0);
	const [page, setPage] = useState({ pageIndex: 1, pageSize: DEFAULT_PAYMENTLIST_PER_PAGE });
	const [nodeSelected, setNodeSelected] = useState<INodeSelected>(initialNodeSelected);
	const [expandLevelIds, setExpandLevelIds] = useState('');
	const { companyProducts, isClientView } = useContext(AuthContext);
	const [showFilterModal, setShowFilterModal] = useState(false);
	const [exchangeDocument, setExchangeDocument] = useState<ITaxDocument>(initialTaxDocument);
	const [showExchangePreviewModal, setShowExchangePreviewModal] = useState(false);
	const [showCpaMessageModal, setShowCpaMessageModal] = useState(false);
	const [cpaMessage, setCpaMessage] = useState('');
	const [isExchangeSubscribedAndEnabled, setIsExchangeSubscribedAndEnabled] = useState(false);
	const [showGTRPreviewModal, setShowGTRPreviewModal] = useState(false);
	const [selectedGTRDocument, setSelectedGTRDocument] = useState<IGatherDocumentModel>(initialGatherDocument);
	const [selectedORGDocument, setSelectedORGDocument] = useState<IOrganizerDocumentModel>(initialOrganizerDocument);

	const location = useLocation();
	const locationData = location.state;

	useEffect(() => {
		fetchTaxDocuments(0);
		fetchCompanyProducts();
	}, []);

	useEffect(() => {
		if (locationData && locationData.expandLevelIds) {
			if (locationData.expandLevelIds !== expandLevelIds) {
				setExpandLevelIds(locationData.expandLevelIds);
			} else {
				const isExpanded = document.getElementById(openTreeID)?.getAttribute('data-expanded');
				isExpanded === 'no' && document.getElementById(openTreeID)?.click();
			}
		}
	}, [locationData]); // Run when location state changes

	useEffect(() => {
		const isExpanded = document.getElementById(openTreeID)?.getAttribute('data-expanded');
		isExpanded === 'no' && document.getElementById(openTreeID)?.click();
	}, [openTreeID]);

	useEffect(() => {
		if (expandLevelIds.length > 0) {
			setIsLoading(true);
			setShowClearButton(false);
			setSearchText('');
			setSearchTextWithResults('');
			setSortOrder(DocumentsSort.NONE);
			setNodeSelected(initialNodeSelected);
			setDisplay(DocumentsDisplay.TREE);
			if (!isFilterEmpty(filter)) {
				getFilteredTaxDocuments(
					buildQueryStringForFilter(filter.filterStatus, filter.filterFileType, filter.fromDate, filter.toDate),
					(taxRecordsResponse: ITaxRecordsResponse) => {
						setIsLoading(false);
						if (taxRecordsResponse.error) {
							logger && logger.trackWarning(TAX_DOCUMENTS_CONSTANTS.ERROR_FILTER_TAX_DOCUMENTS);
							UTENotifier.Error(TAX_DOCUMENTS_CONSTANTS.ERROR_FILTER_TAX_DOCUMENTS);
						} else {
							setRecords(taxRecordsResponse.taxRecords);
						}
					},
				);
			} else {
				getTaxYearFolders(0, (allTaxYearsResponse: ITaxRecordsResponse) => {
					if (allTaxYearsResponse.error) {
						logger && logger.trackWarning(TAX_DOCUMENTS_CONSTANTS.ERROR_FETCH_TAX_DOCUMENTS);
						UTENotifier.Error(TAX_DOCUMENTS_CONSTANTS.ERROR_FETCH_TAX_DOCUMENTS);
						setIsLoading(false);
					} else {
						const tempRecords = JSON.parse(JSON.stringify(allTaxYearsResponse.taxRecords));
						const indexOfSeparator = expandLevelIds.indexOf(':::');
						const taxYear =
							indexOfSeparator > 0
								? getTaxYearFromName(expandLevelIds.substring(0, indexOfSeparator))
								: getTaxYearFromName(expandLevelIds);
						getTaxYearFolders(taxYear, (taxRecordsResponse: ITaxRecordsResponse) => {
							setIsLoading(false);
							if (taxRecordsResponse.error) {
								logger && logger.trackWarning(TAX_DOCUMENTS_CONSTANTS.ERROR_FETCH_TAX_DOCUMENTS);
								UTENotifier.Error(TAX_DOCUMENTS_CONSTANTS.ERROR_FETCH_TAX_DOCUMENTS);
							} else {
								const taxYearName = `${TAX_RETURN_FOLDER_NAME}${taxYear}`;
								const taxYearIndex = tempRecords.findIndex((eachRecord: ITaxRecord) => {
									return eachRecord.name === taxYearName;
								});
								tempRecords[taxYearIndex] = taxRecordsResponse.taxRecords[0];
								setRecords(tempRecords);
								setOpenTreeID(taxYearName);
							}
						});
					}
				});
			}
		}
	}, [expandLevelIds]);

	const fetchCompanyProducts = () => {
		if (companyProducts.length === 0) {
			setIsLoading(true);
			getCompanyProducts((response: ICompanyProductsResponse) => {
				setIsLoading(false);
				if (response.error) {
					UTENotifier.Error(LOGGED_IN_LAYOUT_CONSTANTS.ERROR_FETCHING_COMPANY_PRODUCTS);
				} else {
					setExchangeSubscribedAndEnabled(response.products);
				}
			});
		} else {
			setExchangeSubscribedAndEnabled(companyProducts);
		}
	};

	const setExchangeSubscribedAndEnabled = (products: ICompanyProduct[]) => {
		if (products.length > 0) {
			const exchangeProduct = products.find((product) => product.productId === ProductType.Exchange);
			if (exchangeProduct && exchangeProduct.isSubscribed && exchangeProduct.productStatus === ProductStatus.Enabled) {
				setIsExchangeSubscribedAndEnabled(true);
			}
		}
	};

	const fetchTaxDocuments = (taxYear: number) => {
		setIsLoading(true);
		!(locationData && locationData.expandLevelIds) && setExpandLevelIds('');
		getTaxYearFolders(taxYear, (taxRecordsResponse: ITaxRecordsResponse) => {
			setIsLoading(false);
			if (taxRecordsResponse.error) {
				logger && logger.trackWarning(TAX_DOCUMENTS_CONSTANTS.ERROR_FETCH_TAX_DOCUMENTS);
				UTENotifier.Error(TAX_DOCUMENTS_CONSTANTS.ERROR_FETCH_TAX_DOCUMENTS);
			} else {
				if (taxYear === 0) {
					if (checkFilesPresent(taxRecordsResponse.taxRecords)) {
						setRecords(taxRecordsResponse.taxRecords);
					} else {
						setRecords([]);
					}
				} else {
					const tempRecords = JSON.parse(JSON.stringify(records));
					const taxYearName = `${TAX_RETURN_FOLDER_NAME}${taxYear}`;
					const taxYearIndex = tempRecords.findIndex((eachRecord: ITaxRecord) => {
						return eachRecord.name === taxYearName;
					});
					tempRecords[taxYearIndex] = taxRecordsResponse.taxRecords[0];
					setRecords(tempRecords);
					setOpenTreeID(taxYearName);
				}
			}
		});
	};

	const fetchPaginatedTaxDocuments = (queryString: string, pageSizeReceived?: number) => {
		let pageSize = pageSizeReceived ?? page.pageSize;
		setIsLoading(true);
		getPaginatedTaxDocuments(queryString, (paginatedTaxDataResponse: IPaginatedTaxDataResponse) => {
			setIsLoading(false);
			if (paginatedTaxDataResponse.error) {
				logger && logger.trackWarning(TAX_DOCUMENTS_CONSTANTS.ERROR_SEARCH_TAX_DOCUMENTS);
				UTENotifier.Error(TAX_DOCUMENTS_CONSTANTS.ERROR_SEARCH_TAX_DOCUMENTS);
			} else {
				const { count, items } = paginatedTaxDataResponse.paginatedTaxData;
				let paginatedNodes: IPaginatedNode[] = [];
				if (items.taxReturnDetails.length === pageSize) {
					paginatedNodes = items.taxReturnDetails;
					setPaginatedRecords(paginatedNodes);
					setTotalCount(count);
				} else if (items.taxReturnDetails.length > 0 && items.taxReturnDetails.length < pageSize) {
					const filesLength = items.taxReturnDetails.length;
					const foldersArray = items.folderDetails.slice(0, pageSize - filesLength);
					paginatedNodes = items.taxReturnDetails.concat(foldersArray);
					setPaginatedRecords(paginatedNodes);
					setTotalCount(count);
				} else if (items.taxReturnDetails.length === 0 && items.folderDetails.length > 0) {
					if (page.pageIndex == 1 && count <= page.pageSize) {
						setPaginatedRecords(items.folderDetails);
					} else {
						let numberOfRemainingFolders =
							items.folderDetails.length - (pageSize - ((totalCount - items.folderDetails.length) % pageSize));
						paginatedNodes = items.folderDetails.slice(0 - numberOfRemainingFolders);
						setPaginatedRecords(paginatedNodes);
					}
				} else if (items.taxReturnDetails.length === 0 && items.folderDetails.length === 0) {
					setPaginatedRecords([]);
					setTotalCount(0);
				}
				setDisplay(DocumentsDisplay.GRID);
				setOpenTreeID('');
			}
		});
	};

	const fetchFilteredTaxDocuments = (queryString: string) => {
		setIsLoading(true);
		getFilteredTaxDocuments(queryString, (taxRecordsResponse: ITaxRecordsResponse) => {
			setIsLoading(false);
			if (taxRecordsResponse.error) {
				logger && logger.trackWarning(TAX_DOCUMENTS_CONSTANTS.ERROR_FILTER_TAX_DOCUMENTS);
				UTENotifier.Error(TAX_DOCUMENTS_CONSTANTS.ERROR_FILTER_TAX_DOCUMENTS);
			} else {
				setRecords(taxRecordsResponse.taxRecords);
				setDisplay(DocumentsDisplay.TREE);
			}
		});
	};

	const checkFilesPresent = (taxRecords: ITaxRecord[]) => {
		let filesPresent = false;
		taxRecords.forEach((taxRecord: ITaxRecord) => {
			if (taxRecord.documentCount > 0) filesPresent = true;
		});
		return filesPresent;
	};

	const onshowPreviewModal = (documentReceived: ITaxDocument, name: string) => {
		setDocument(documentReceived);
		setShowPreviewModal(true);
		setSelectedDocumentName(name);
		if (!isClientView) {
			const userActivity: IUserActivity = {
				eventId: OneHubEventType.View,
				eventData: {
					documentId: documentReceived.documentId,
					fileName: documentReceived.efileFormName,
					signerType: documentReceived.signerType,
					clientGuid: documentReceived.clientGuid,
					fileId: documentReceived.fileId,
					fileGuid: documentReceived.documentGuid,
					exchangeFileType: documentReceived.exchangeFileType,
				},
				productType: documentReceived.productType,
			};
			if (documentReceived.signingId) {
				userActivity.eventData.signingId = documentReceived.signingId;
			}
			saveUserActivity(userActivity);
		}
	};

	const onPreviewModalCancel = () => {
		setDocument(initialTaxDocument);
		setShowPreviewModal(false);
		setSelectedDocumentName('');
	};

	const onGTRPreviewModalCancel = () => {
		setShowGTRPreviewModal(false);
		setSelectedGTRDocument(initialGatherDocument);
		setSelectedORGDocument(initialOrganizerDocument);
	};

	const displayCCRecipientModal = (documentReceived: ITaxDocument) => {
		if (!isClientView) {
			let userActivity: IUserActivity = {
				eventId: OneHubEventType.Forward,
				eventData: {
					documentId: documentReceived.documentId,
					fileName: documentReceived.efileFormName,
					signerType: documentReceived.signerType,
				},
				productType: documentReceived.productType,
			};
			saveUserActivity(userActivity);
		}
		setDocument(documentReceived);
		fetchDownloadableDocuments(documentReceived);
		setShowCCRecipientModal(true);
	};

	const fetchDownloadableDocuments = (documentReceived: ITaxDocument) => {
		setIsDocumentLoading(true);
		generateTaxpayerEncodedClientId(documentReceived.clientGuid, (encodedClientId) => {
			setEncodedClientId(encodedClientId);
			getCCRecipientDownloadableTaxDocuments(
				encodedClientId,
				documentReceived.documentId,
				documentReceived.documentStatus,
				documentReceived.productType,
				(downloadableDocumentsResponse: IDownloadableDocumentsResponse) => {
					setDownloadableDocuments(downloadableDocumentsResponse.downloadableDocuments);
					setIsDocumentLoading(false);
				},
			);
		});
	};

	const hideCCRecipientModal = () => {
		setShowCCRecipientModal(false);
		setDocument(initialTaxDocument);
		setDownloadableDocuments([]);
	};

	const handleTaxYearClick = (taxYear: number) => {
		if (isFilterEmpty(filter)) {
			fetchTaxDocuments(taxYear);
		}
	};

	const handleSearchTextChange = (newSearchText: string) => {
		const disallowedChars = /[\\/:*?"<>|]/g;
		const sanitizedValue = newSearchText.replace(disallowedChars, '');
		setSearchText(sanitizedValue);
	};

	const handleSearch = () => {
		setSearchTextWithResults(searchText);
		const queryString = buildQueryStringForSearchSortFilter(
			1,
			page.pageSize,
			sortOrder,
			searchText,
			filter.filterStatus,
			filter.filterFileType,
			filter.fromDate,
			filter.toDate,
		);
		fetchPaginatedTaxDocuments(queryString);
		setShowClearButton(true);
		setPage({ ...page, pageIndex: 1 });
		setNodeSelected(initialNodeSelected);
		setOpenTreeID('');
		setExpandLevelIds('');
	};

	const handleClearSearch = () => {
		setShowClearButton(false);
		setSearchText('');
		setSearchTextWithResults('');
		setPage({ ...page, pageIndex: 1 });
		setNodeSelected(initialNodeSelected);
		setOpenTreeID('');
		setExpandLevelIds('');
		if (sortOrder === DocumentsSort.NONE) {
			if (!isFilterEmpty(filter)) {
				const queryString = buildQueryStringForFilter(
					filter.filterStatus,
					filter.filterFileType,
					filter.fromDate,
					filter.toDate,
				);
				fetchFilteredTaxDocuments(queryString);
			} else {
				fetchTaxDocuments(0);
			}
			setTotalCount(0);
			setDisplay(DocumentsDisplay.TREE);
		} else {
			const queryString = buildQueryStringForSearchSortFilter(
				1,
				page.pageSize,
				sortOrder,
				'',
				filter.filterStatus,
				filter.filterFileType,
				filter.fromDate,
				filter.toDate,
			);
			fetchPaginatedTaxDocuments(queryString);
			setDisplay(DocumentsDisplay.GRID);
		}
	};

	const handleSort = (sortOrderReceived: DocumentsSort) => {
		setSortOrder(sortOrderReceived);
		const queryString = buildQueryStringForSearchSortFilter(
			1,
			page.pageSize,
			sortOrderReceived,
			searchTextWithResults,
			filter.filterStatus,
			filter.filterFileType,
			filter.fromDate,
			filter.toDate,
		);
		fetchPaginatedTaxDocuments(queryString);
		setPage({ ...page, pageIndex: 1 });
		setNodeSelected(initialNodeSelected);
		setOpenTreeID('');
		setExpandLevelIds('');
	};

	const handleClearSort = () => {
		setSortOrder(DocumentsSort.NONE);
		setPage({ ...page, pageIndex: 1 });
		setNodeSelected(initialNodeSelected);
		setOpenTreeID('');
		setExpandLevelIds('');
		if (searchTextWithResults.length === 0) {
			if (!isFilterEmpty(filter)) {
				const queryString = buildQueryStringForFilter(
					filter.filterStatus,
					filter.filterFileType,
					filter.fromDate,
					filter.toDate,
				);
				fetchFilteredTaxDocuments(queryString);
			} else {
				fetchTaxDocuments(0);
			}
			setDisplay(DocumentsDisplay.TREE);
		} else {
			const queryString = buildQueryStringForSearchSortFilter(
				1,
				page.pageSize,
				DocumentsSort.NONE,
				searchTextWithResults,
				filter.filterStatus,
				filter.filterFileType,
				filter.fromDate,
				filter.toDate,
			);
			fetchPaginatedTaxDocuments(queryString);
			setDisplay(DocumentsDisplay.GRID);
		}
	};

	const buildQueryStringForSearchSortFilter = (
		pageNo: number,
		pageSize: number,
		sortOrder?: string,
		searchText?: string,
		filterStatus?: string,
		filterFileType?: string,
		fromDate?: string,
		toDate?: string,
	): string => {
		let queryString = `?pageNo=${pageNo}&pageSize=${pageSize}`;
		if (sortOrder !== DocumentsSort.NONE) {
			queryString += `&sortOrder=${sortOrder}`;
		}
		if (searchText && searchText.length >= 2) {
			const newSearchText = encodeURIComponent(searchText);
			queryString += `&searchText=${newSearchText}`;
		}
		if (filterStatus && filterStatus.length > 0) {
			queryString += `&filterStatus=${filterStatus}`;
		}
		if (filterFileType && filterFileType.length > 0) {
			queryString += `&filterFileType=${filterFileType}`;
		}
		if (fromDate) {
			queryString += `&fromDate=${fromDate}`;
		}
		if (toDate) {
			queryString += `&toDate=${toDate}`;
		}
		return queryString;
	};

	const buildQueryStringForFilter = (
		filterStatus?: string,
		filterFileType?: string,
		fromDate?: string,
		toDate?: string,
	): string => {
		let queryString = '';
		if (filterStatus && filterStatus.length > 0) {
			queryString += `?filterStatus=${filterStatus}`;
		}
		if (filterFileType && filterFileType.length > 0) {
			if (queryString.length === 0) {
				queryString += `?filterFileType=${filterFileType}`;
			} else {
				queryString += `&filterFileType=${filterFileType}`;
			}
		}
		if (fromDate) {
			if (queryString.length === 0) {
				queryString += `?fromDate=${fromDate}`;
			} else {
				queryString += `&fromDate=${fromDate}`;
			}
		}
		if (toDate) {
			if (queryString.length === 0) {
				queryString += `?toDate=${toDate}`;
			} else {
				queryString += `&toDate=${toDate}`;
			}
		}
		return queryString;
	};

	const onPageChange = (newPageIndex: number, newPageSize: number) => {
		const totalPages = Math.ceil(totalCount / page.pageSize);
		if (
			(newPageIndex !== page.pageIndex && newPageIndex <= totalPages && newPageIndex >= 1) ||
			newPageSize !== page.pageSize
		) {
			const newPage = { ...page };
			newPage.pageIndex = newPageIndex;
			newPage.pageSize = newPageSize;
			setPage(newPage);
			const queryString = buildQueryStringForSearchSortFilter(
				newPageIndex,
				newPageSize,
				sortOrder,
				searchText,
				filter.filterStatus,
				filter.filterFileType,
				filter.fromDate,
				filter.toDate,
			);
			fetchPaginatedTaxDocuments(queryString, newPageSize);
		}
	};

	const onNodeClick = (index: number, node: IPaginatedNode) => {
		setNodeSelected({ index, node });
	};

	const onClickLevel = (levelIds: string) => {
		setExpandLevelIds(levelIds);
	};

	const displayFilterModal = () => {
		setShowFilterModal(true);
	};

	const hideFilterModal = () => {
		setShowFilterModal(false);
	};

	const handleFilter = (filterReceived: IFilter) => {
		const { filterStatus, filterFileType, fromDate, toDate } = filterReceived;
		if (searchTextWithResults.length > 0 || sortOrder !== DocumentsSort.NONE) {
			const queryString = buildQueryStringForSearchSortFilter(
				1,
				page.pageSize,
				sortOrder,
				searchTextWithResults,
				filterStatus,
				filterFileType,
				fromDate,
				toDate,
			);
			fetchPaginatedTaxDocuments(queryString);
			setPage({ ...page, pageIndex: 1 });
			setNodeSelected(initialNodeSelected);
			setOpenTreeID('');
			setExpandLevelIds('');
		} else {
			const queryString = buildQueryStringForFilter(filterStatus, filterFileType, fromDate, toDate);
			fetchFilteredTaxDocuments(queryString);
		}
		setFilter(filterReceived);
		setShowFilterModal(false);
		if (searchText !== searchTextWithResults) {
			setSearchText(searchTextWithResults);
		}
	};

	const removeFilter = (filterValue?: string, filterType?: FilterType) => {
		if (filterValue) {
			let newFilter = { ...filter };
			if (filterValue === 'date') {
				newFilter.fromDate = '';
				newFilter.toDate = '';
			} else if (filterType === FilterType.STATUS) {
				const statusArray = filter.filterStatus.split(',');
				const index = statusArray.indexOf(filterValue);
				statusArray.splice(index, 1);
				newFilter.filterStatus = statusArray.join(',');
			} else if (filterType === FilterType.FILETYPE) {
				const fileTypeArray = filter.filterFileType.split(',');
				const index = fileTypeArray.indexOf(filterValue);
				fileTypeArray.splice(index, 1);
				newFilter.filterFileType = fileTypeArray.join(',');
			}
			if (isFilterEmpty(newFilter)) {
				if (searchTextWithResults.length > 0 || sortOrder !== DocumentsSort.NONE) {
					const queryString = buildQueryStringForSearchSortFilter(1, page.pageSize, sortOrder, searchTextWithResults);
					fetchPaginatedTaxDocuments(queryString);
					setPage({ ...page, pageIndex: 1 });
					setNodeSelected(initialNodeSelected);
					setOpenTreeID('');
					setExpandLevelIds('');
				} else {
					fetchTaxDocuments(0);
				}
			} else {
				const { filterStatus, filterFileType, fromDate, toDate } = newFilter;
				if (searchTextWithResults.length > 0 || sortOrder !== DocumentsSort.NONE) {
					const queryString = buildQueryStringForSearchSortFilter(
						1,
						page.pageSize,
						sortOrder,
						searchTextWithResults,
						filterStatus,
						filterFileType,
						fromDate,
						toDate,
					);
					fetchPaginatedTaxDocuments(queryString);
					setPage({ ...page, pageIndex: 1 });
					setNodeSelected(initialNodeSelected);
					setOpenTreeID('');
					setExpandLevelIds('');
				} else {
					const queryString = buildQueryStringForFilter(filterStatus, filterFileType, fromDate, toDate);
					fetchFilteredTaxDocuments(queryString);
				}
			}
			setFilter(newFilter);
		} else {
			setFilter(initialFilter);
			if (searchTextWithResults.length > 0 || sortOrder !== DocumentsSort.NONE) {
				const queryString = buildQueryStringForSearchSortFilter(1, page.pageSize, sortOrder, searchTextWithResults);
				fetchPaginatedTaxDocuments(queryString);
				setPage({ ...page, pageIndex: 1 });
				setNodeSelected(initialNodeSelected);
				setOpenTreeID('');
				setExpandLevelIds('');
			} else {
				fetchTaxDocuments(0);
			}
		}
	};

	const isFilterEmpty = (filterReceived: IFilter): boolean => {
		const { filterStatus, filterFileType, fromDate, toDate } = filterReceived;
		if (!filterStatus && !filterFileType && !fromDate && !toDate) {
			return true;
		}
		return false;
	};

	const onShowGTRPreviewModal = (document: IGatherDocumentModel) => {
		setShowGTRPreviewModal(true);
		setSelectedGTRDocument(document);
	};

	const onShowORGPreviewModal = (document: IOrganizerDocumentModel) => {
		setShowGTRPreviewModal(true);
		setSelectedORGDocument(document);
	};

	const downloadExchangeFiles = (file: ITaxDocument) => {
		setIsLoading(true);
		getExchangeSasUrl(
			file,
			(sasUrl: string) => {
				saveUserActivity({
					eventId: OneHubEventType.Download,
					eventData: {
						documentId: file.documentId,
						fileName: file.efileFormName,
						signerType: ClientTypes.Undefined,
						fileId: file.fileId,
						fileGuid: file.documentGuid,
						exchangeFileType: file.fileType,
						exchangeFileData: {
							fileGuid: file.fileGuid,
							isMessagePresent: file.isMessagePresent,
							messageGuid: file.messageGuid,
						},
					},
					productType: ProductType.Exchange,
				});

				setIsLoading(false);
				let displayDownloadFile = new DisplayDownloadFile();
				displayDownloadFile.directDownload(sasUrl);
			},
			() => {
				setIsLoading(false);
				UTENotifier.Error(SSE_DOCUMENT_CONSTANTS.ERROR_DOWNLOAD_DOCUMENT);
			},
		);
	};

	const onShowExchangePreviewModal = (file: ITaxDocument) => {
		setExchangeDocument(file);
		setShowExchangePreviewModal(true);
		setSelectedDocumentName(file.efileFormName);
		if (!isClientView) {
			saveUserActivity({
				eventId: OneHubEventType.View,
				eventData: {
					documentId: file.documentId,
					fileName: file.efileFormName,
					signerType: ClientTypes.Undefined,
					fileId: file.fileId,
					fileGuid: file.documentGuid,
					exchangeFileType: file.fileType,
					exchangeFileData: {
						fileGuid: file.fileGuid,
						isMessagePresent: file.isMessagePresent,
						messageGuid: file.messageGuid,
					},
				},
				productType: ProductType.Exchange,
			});
		}
	};

	const onExchangePreviewModalCancel = () => {
		setExchangeDocument(initialTaxDocument);
		setShowExchangePreviewModal(false);
		setSelectedDocumentName('');
	};

	const onshowCpaMessageModal = (file: ITaxDocument) => {
		setExchangeDocument(file);
		setShowCpaMessageModal(true);
		setSelectedDocumentName(file.efileFormName);
		fetchMessage(file);
		// add user actvity
	};

	const onCpaMessageModalCancel = () => {
		setExchangeDocument(initialTaxDocument);
		setShowCpaMessageModal(false);
		setSelectedDocumentName('');
		setCpaMessage('');
	};
	const fetchMessage = (file: ITaxDocument) => {
		setIsLoading(true);
		getCPAMessage(
			file,
			(str: string) => {
				setCpaMessage(str);
				setIsLoading(false);
			},
			() => {
				setIsLoading(false);
				onCpaMessageModalCancel();
				UTENotifier.Error(SSE_DOCUMENT_CONSTANTS.ERROR_FETCH_MESSAGE);
			},
		);
	};
	return (
		<section className='pageModuleContainer'>
			<LoadingOverlay
				active={isLoading}
				spinner={<Loader />}
				text=''>
				<h2 className='pageModuleHeader'>Your Folders</h2>
				<p className='pageModuleDescription'>Access your tax documents and files</p>
				<DocumentsHead
					searchText={searchText}
					showClearButton={showClearButton}
					onChangeSearchText={handleSearchTextChange}
					onSearch={handleSearch}
					onClearSearch={handleClearSearch}
					onClickFilter={displayFilterModal}
					filter={filter}
					sortOrder={sortOrder}
					onSort={handleSort}
					onClearSort={handleClearSort}
					showSendFiles={isExchangeSubscribedAndEnabled}
				/>
				{!isFilterEmpty(filter) && (
					<div className={styles.filterListContainer}>
						<FilterList
							filter={filter}
							removeFilter={removeFilter}
						/>
					</div>
				)}
				{nodeSelected.index !== -1 && (
					<div className={styles.breadcrumbContainer}>
						<UTEBreadcrumb
							path={nodeSelected.node?.path}
							onClickLevel={onClickLevel}
						/>
					</div>
				)}
				<section className={styles.documentsContainer}>
					<table>
						<thead>
							<th className={styles.nameHeader}>Name</th>
							<th className={styles.containsHeader}>Contains</th>
							<th className={styles.dateHeader}>Date</th>
							<th className={styles.statusHeader}>Status</th>
							<th className={styles.actionsHeader}>Actions</th>
						</thead>
						{display === DocumentsDisplay.TREE ? (
							<DocumentsTree
								records={records}
								isLoading={isLoading}
								displayCCRecipientModal={displayCCRecipientModal}
								onshowPreviewModal={onshowPreviewModal}
								fetchTaxDocuments={fetchTaxDocuments}
								expandLevelIds={expandLevelIds}
								handleTaxYearClick={handleTaxYearClick}
								filter={filter}
								onShowGTRPreviewModal={onShowGTRPreviewModal}
								onShowORGPreviewModal={onShowORGPreviewModal}
								downloadExchangeFiles={downloadExchangeFiles}
								showExchangePreviewModal={onShowExchangePreviewModal}
								showCpaMessageModal={onshowCpaMessageModal}
							/>
						) : (
							<DocumentsGrid
								paginatedRecords={paginatedRecords}
								isLoading={isLoading}
								displayCCRecipientModal={displayCCRecipientModal}
								onshowPreviewModal={onshowPreviewModal}
								onShowGTRPreviewModal={onShowGTRPreviewModal}
								onShowORGPreviewModal={onShowORGPreviewModal}
								fetchTaxDocuments={fetchTaxDocuments}
								onNodeClick={onNodeClick}
								nodeSelected={nodeSelected}
								searchText={searchTextWithResults}
								showExchangePreviewModal={onShowExchangePreviewModal}
								downloadExchangeFiles={downloadExchangeFiles}
								showCpaMessageModal={onshowCpaMessageModal}
							/>
						)}
					</table>

					{display === DocumentsDisplay.GRID && (
						<UTEPagination
							page={page}
							count={totalCount}
							goToPage={onPageChange}
						/>
					)}
					{showCCRecipientModal && (
						<ForwardTaxDocumentContainer
							downloadableDocuments={downloadableDocuments}
							encodedClientId={encodedClientId}
							taxDocument={taxDocument}
							closeCCRecipientModal={hideCCRecipientModal}
							show={showCCRecipientModal}
							isDocumentLoading={isDocumentLoading}
						/>
					)}
					{showPreviewModal && taxDocument && (
						<PreviewPopupModal
							clientType={taxDocument.signerType}
							id={taxDocument.documentId}
							name={selectedDocumentName}
							onCancel={onPreviewModalCancel}
							productType={taxDocument.productType}
							showModal={showPreviewModal}
						/>
					)}
					{showFilterModal && (
						<UTEModalLayout
							width='500'
							theme='light'
							title='Filter'
							className='filterDocumentsModal'
							hide={hideFilterModal}
							onClickOutside={() => {}}>
							<FilterDocuments
								filter={filter}
								handleFilter={handleFilter}
							/>
						</UTEModalLayout>
					)}
					<GTRDocumentPreviewModal
						show={showGTRPreviewModal}
						onCancel={onGTRPreviewModalCancel}
						document={selectedGTRDocument}
						organizerDocument={selectedORGDocument}
					/>
					{/* for exchange actions */}
					{showExchangePreviewModal && exchangeDocument && (
						<PreviewPopupModal
							clientType={ClientTypes.Undefined}
							id={exchangeDocument.documentId}
							name={selectedDocumentName}
							onCancel={onExchangePreviewModalCancel}
							productType={ProductType.Exchange}
							showModal={showExchangePreviewModal}
							exchangeFile={exchangeDocument}
						/>
					)}

					{showCpaMessageModal && exchangeDocument.messageGuid !== '' && cpaMessage !== '' && (
						<UTEModalLayout
							width='698'
							theme='light'
							title='Message from CPA'
							hide={onCpaMessageModalCancel}
							onClickOutside={onCpaMessageModalCancel}
							className='cpaMessageModal'>
							<CpaMessageModal
								show={showCpaMessageModal}
								onModalClose={onCpaMessageModalCancel}
								message={cpaMessage}
							/>
						</UTEModalLayout>
					)}
				</section>
			</LoadingOverlay>
		</section>
	);
};

export default Documents;
