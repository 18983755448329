import React, { useContext, useEffect, useState } from 'react';
import PdfPreview from 'src/components/pdf-preview';
import {
	DocumentGroupType,
	initialSSRDocumentPreview,
	initialSSRDocumentPreviewFile,
	ISSRDocumentPreview,
	ISSRDocumentPreviewFile,
	K1ZipDownloadRequest,
} from './ssr-document-preview.types';
import { PdfSource } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import LoadingOverlay from 'react-loading-overlay';
import { getDocumentsForPreview, getK1ZipFileDownloadUri } from './ssr-document-preview-api';
import { UTENotifier } from 'src/modules/notification/ute-notifier';
import { SSR_DOCUMENTS_PREVIEW_CONSTANTS } from 'src/helper/constants';
import { IFileGroup } from 'src/components/pdf-preview/left-panel/left-panel-types';
import { DisplayDownloadFile } from '../../../helper/display-download-file';
import { ClientHubEvents, IClientTracking, ProductType } from '../../common/common-types';
import { ClientTypes } from 'src/modules/common/common-types';
import { AuthContext } from '../../../context/ute-auth-context/auth-provider';
import { saveClientTrackingEvent } from '../../common/common-api';

type SSRDocumentPreviewProps = {
	documentId: number;
	clientType: ClientTypes;
	productType: ProductType;
};

const SSRDocumentPreview = ({ documentId, clientType, productType }: SSRDocumentPreviewProps) => {
	const [documents, setDocuments] = useState<ISSRDocumentPreview>(initialSSRDocumentPreview);
	const [isLoading, setisLoading] = useState(false);
	const [selectedDocumentSource, setSelectedDocumentSource] = useState<PdfSource>({ base64String: '', url: '' });
	const [selectedDocument, setSelectedDocument] = useState<ISSRDocumentPreviewFile>(initialSSRDocumentPreviewFile);
	const { userDetail } = useContext(AuthContext);
	useEffect(() => {
		fetchDocuments();
	}, [documentId, clientType]);

	const fetchDocuments = () => {
		setisLoading(true);

		getDocumentsForPreview(
			documentId,
			clientType,
			productType,
			(data: ISSRDocumentPreview) => {
				setisLoading(false);
				setDocuments(data);
				let pdfSource: PdfSource = {
					base64String: '',
					url: data.fileGroups[0].files[0].sasUrl,
				};
				setSelectedDocumentSource(pdfSource);
				setSelectedDocument(data.fileGroups[0].files[0]);
			},
			() => {
				setisLoading(false);
				UTENotifier.Error(SSR_DOCUMENTS_PREVIEW_CONSTANTS.ERROR_FETCH_DOCUMENTS);
			},
		);
	};

	const convertToPdfViewerModel = () => {
		let fileGroups: IFileGroup[] = [];
		if (documents.fileGroups && documents.fileGroups.length > 0) {
			documents.fileGroups.forEach((fileGoup) => {
				let group: IFileGroup = {} as IFileGroup;
				group.name = fileGoup.groupName;
				group.files = [];
				fileGoup.files.forEach((file) => {
					group.files.push({
						data: file,
						name: file.friendlyFileName,
						url: file.sasUrl,
					});
				});
				fileGroups.push(group);
			});
		}
		return fileGroups;
	};

	const onFileSelect = (url: string, data: any) => {
		setSelectedDocumentSource({
			base64String: '',
			url: url,
		});
		let file = data as ISSRDocumentPreviewFile;
		setSelectedDocument(file);
	};

	const onFileDownload = () => {
		let displayDownloadFile = new DisplayDownloadFile();
		if (selectedDocument.groupId === DocumentGroupType.K1) {
			let k1ZipFileDownloadRequest: K1ZipDownloadRequest = {
				clientName: documents.documentInfo.clientName,
				companyId: documents.documentInfo.companyId,
				documentGuid: documents.documentInfo.documentGuid,
				engagementType: documents.documentInfo.engagementType,
				taxYear: documents.documentInfo.taxYear,
			};
			getK1ZipFileDownloadUri(
				k1ZipFileDownloadRequest,
				(uri) => {
					displayDownloadFile.directDownload(uri);
				},
				() => {
					UTENotifier.Error(SSR_DOCUMENTS_PREVIEW_CONSTANTS.ERROR_DOWNLOAD_K1_DOCUMENT);
				},
			);
		} else {
			displayDownloadFile.directDownload(selectedDocument.sasUrl);
			let clientTracking: IClientTracking = {
				eventId: ClientHubEvents.DocumentDownloadedFromView,
				eventData: {
					emailId: userDetail.emailAddress,
					fileName: selectedDocument.friendlyFileName,
					newEmailId: '',
					oldEmailId: '',
				},
				actedBy: userDetail.emailAddress,
				registrationId: userDetail.id,
			};
			saveClientTrackingEvent(clientTracking);
		}
	};

	return (
		<>
			<LoadingOverlay
				active={isLoading}
				spinner={''}
				text=''>
				<PdfPreview
					fileGroups={convertToPdfViewerModel()}
					pdfSource={selectedDocumentSource}
					onFileSelect={onFileSelect}
					onFileDownload={onFileDownload}
					fileExtension={selectedDocument.fileExtension}
				/>
			</LoadingOverlay>
		</>
	);
};

export default SSRDocumentPreview;
