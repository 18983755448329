import React, { ReactElement } from 'react';
import styles from './ute-search-box.module.scss';
import UTEButton from '../ute-button';
import SearchIcon from 'src/assets/svg/search-icon';
import CloseIcon from 'src/assets/svg/close-icon';

type UTESearchBoxProps = {
	id?: string;
	name?: string;
	searchText: string;
	placeholderText?: string;
	searchIcon?: ReactElement<any, any>;
	disabled: boolean;
	searchOnClick?: boolean;
	showClearButton?: boolean;
	totalWidth?: string;
	height?: string;
	maxLength?: number;
	className?: string;
	onChangeSearchText: (searchText: string) => void;
	onSearch: () => void;
	onClearSearch: () => void;
};
const UTESearchBox = ({
	id,
	name,
	searchText,
	placeholderText,
	searchIcon,
	disabled,
	searchOnClick,
	showClearButton,
	totalWidth,
	height,
	maxLength,
	className,
	onChangeSearchText,
	onSearch,
	onClearSearch,
}: UTESearchBoxProps) => {
	return (
		<div
			className={`${styles.searchContainer} ${className ?? ''}`}
			style={{ width: totalWidth ?? '350px', height: height ?? '38px' }}>
			<input
				type='text'
				id={id ?? 'searchText'}
				name={name ?? 'searchText'}
				placeholder={placeholderText ?? 'Search'}
				maxLength={maxLength ?? 300}
				onChange={(e) => {
					onChangeSearchText(e.target.value);
				}}
				value={searchText}
			/>
			<UTEButton
				className={`${styles.searchIcon} ${searchOnClick ? '' : styles.noSearchOnClick}`}
				text=''
				icon={searchIcon ?? <SearchIcon />}
				onClick={() => {
					searchOnClick && onSearch();
				}}
				disabled={searchOnClick ? disabled ?? false : false}
				dataTestAuto='f2478cb0-8d27-4b62-aedb-70311afd90da'
			/>
			{showClearButton && (
				<div
					className={styles.clearSearch}
					onClick={onClearSearch}>
					<CloseIcon />
				</div>
			)}
		</div>
	);
};

export default UTESearchBox;
