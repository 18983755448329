import React from 'react';
import styles from './enter-new-email-id.module.scss';
import UTEButton from 'src/components/ute-button';
import { isValidEmailAddress } from 'src/helper/utils';

type EnterNewEmailIdProps = {
	existingEmail: string;
	newEmail: string;
	setNewEmail: (newEmail: string) => void;
	newEmailError: boolean;
	setNewEmailError: (newEmailError: boolean) => void;
	handleNewEmailSubmit: () => void;
	actionInProgress: boolean;
};

const EnterNewEmailId = ({
	existingEmail,
	newEmail,
	setNewEmail,
	newEmailError,
	setNewEmailError,
	handleNewEmailSubmit,
	actionInProgress,
}: EnterNewEmailIdProps) => {
	return (
		<>
			<div className='uteLoginHeader'>Reset your email ID</div>
			<div className='uteLoginSubHeader'>
				Almost Done! Set up the new email ID you
				<br />
				would like and verify your access to it.
			</div>
			<div
				className='formInputHeader'
				style={{ paddingTop: '1rem' }}>
				Existing Email ID
			</div>
			<input
				maxLength={320}
				type='text'
				name='existingEmail'
				id='existingEmail'
				className={styles.emailID}
				value={existingEmail}
				data-test-auto='0da146a4-a6a2-411a-bae0-a8ec95c1bfd3'
				disabled={true}
			/>
			<div
				className='formInputHeader'
				style={{ paddingTop: '1rem' }}>
				New Email ID
			</div>
			<input
				maxLength={320}
				type='text'
				name='newEmail'
				id='newEmail'
				className={`${styles.emailID} ${newEmailError ? styles.newEmailError : ''}`}
				placeholder='Enter your new email ID'
				value={newEmail}
				onChange={(e) => {
					setNewEmail(e.target.value);
					setNewEmailError(false);
				}}
				data-test-auto='75ae2ea8-b721-4aae-87ec-f91de98f80a1'
			/>

			<div className={styles.continueContainer}>
				<UTEButton
					text='Continue'
					className={styles.continue}
					onClick={handleNewEmailSubmit}
					disabled={
						!newEmail.trim() || (newEmail.trim().length > 0 && !isValidEmailAddress(newEmail)) || actionInProgress
					}
					dataTestAuto='9c9ac1ef-900f-4082-a061-559900898cfb'
				/>
			</div>
		</>
	);
};

export default EnterNewEmailId;
