import React, { useState, useContext } from 'react';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';
import styles from './update-email.module.scss';
import UTEButton from 'src/components/ute-button';
import EmailUpdatePopup from './pop-up';
import { checkUserExists } from '../common/common-api';
import { IUserExistsResponse, OTPValidationStatus } from '../common/common-types';
import { LOGIN_CONSTANTS, RIGISTER_CONSTANTS, UPDATE_EMAIL_CONSTANTS } from 'src/helper/constants';
import { generateOTP, verifyAndUpdateEmailID } from './update-email-api';
import { UTENotifier } from 'src/modules/notification/ute-notifier';
import { IGenerateOTPResponse } from '../register/register-component/register-component-types';
import { logger } from 'src/context/ute-auth-context/auth-provider';

const UpdateEmail = () => {
	const { userDetail, companyId, updateAuthToken, updateRefreshToken } = useContext(AuthContext);
	const [showModal, setShowModal] = useState(false);

	const handleEmailIDSubmit = (
		emailId: string,
		successCallback: () => void,
		errorCallback: (message: string) => void,
	) => {
		checkUserExists(companyId, emailId, (userExistsResponse: IUserExistsResponse) => {
			if (userExistsResponse.error) {
				errorCallback(RIGISTER_CONSTANTS.ERROR_FETCH_USER);
			} else {
				if (userExistsResponse.isExists) {
					errorCallback(RIGISTER_CONSTANTS.ERROR_USER_EXIST);
				} else {
					generateOTP(companyId, emailId, (generateOTPResponse: IGenerateOTPResponse) => {
						if (generateOTPResponse.error) UTENotifier.Error(LOGIN_CONSTANTS.ERROR_SENDING_OTP);
						else successCallback();
					});
				}
			}
		});
	};

	const handleResendCode = (emailId: string) => {
		generateOTP(companyId, emailId, (generateOTPResponse: IGenerateOTPResponse) => {
			if (generateOTPResponse.error) {
				UTENotifier.Error(LOGIN_CONSTANTS.ERROR_SENDING_OTP);
			} else {
				UTENotifier.Success(LOGIN_CONSTANTS.OTP_SENT);
			}
		});
	};

	const onVerifyOtp = (emailId: string, otp: string, errorCallback: () => void) => {
		verifyAndUpdateEmailID(
			emailId,
			otp,
			(status: OTPValidationStatus) => {
				if (status === OTPValidationStatus.InvalidOTP) {
					logger && logger.trackWarning(UPDATE_EMAIL_CONSTANTS.INVALID_ACCESS_CODE);
					UTENotifier.Error(UPDATE_EMAIL_CONSTANTS.INVALID_ACCESS_CODE);
					errorCallback();
				} else if (status === OTPValidationStatus.OTPExpired) {
					logger && logger.trackWarning(UPDATE_EMAIL_CONSTANTS.EXPIRED_ACCESS_CODE);
					UTENotifier.Error(UPDATE_EMAIL_CONSTANTS.EXPIRED_ACCESS_CODE);
					errorCallback();
				} else if (status === OTPValidationStatus.Success) {
					setShowModal(false);
					UTENotifier.Success(UPDATE_EMAIL_CONSTANTS.EMAIL_UPDATE_SUCCESS);
					setInterval(() => {
						updateAuthToken('');
						updateRefreshToken('');
						window.location.href = '/';
					}, 5000);
				}
			},
			() => {},
		);
	};

	return (
		<>
			{' '}
			<section
				id={styles.updateEmailSection}
				className='pageModuleContainer'>
				<h2 className='pageModuleHeader'>Update Email ID</h2>
				<div>
					<p className='pageModuleDescription'>Update your existing Email ID</p>
				</div>
				<div>
					<span className={styles.emailText}>{userDetail.emailAddress}</span>
				</div>
				<div>
					<UTEButton
						className={styles.btnDefault}
						dataTestAuto='a8d264e2-dddf-4808-b166-10ddcef4cab0'
						text='Update'
						onClick={() => {
							setShowModal(true);
						}}
						disabled={false}
					/>
				</div>
			</section>
			{showModal && (
				<EmailUpdatePopup
					showModal={showModal}
					onCancel={() => {
						setShowModal(false);
					}}
					oldEmail={userDetail.emailAddress}
					onUpdate={handleEmailIDSubmit}
					handleResendCode={handleResendCode}
					onVerifyOtp={onVerifyOtp}></EmailUpdatePopup>
			)}
		</>
	);
};

export default UpdateEmail;
