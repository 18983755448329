import React, { useState } from 'react';
import UTEModalLayout from '../../../components/ute-modal-layout';
import { Modal } from 'react-bootstrap';
import { WelcomeIcon } from '../../../assets/svg/welcome-icon';
import styles from './account-setup-wizard.module.scss';
import { saveAccountRegistrationConsentCookie } from '../../../helper/utils';

type PopupProps = {
	setShowAccountCreationPopup: (value: boolean) => void;
	emailID: string;
};

const AccountSetupWizard = ({ setShowAccountCreationPopup, emailID }: PopupProps) => {
	const [doNotShowConsent, setDoNotShowConsent] = useState<boolean>(false);

	const closeModal = () => {
		setShowAccountCreationPopup(false);
		saveAccountRegistrationConsentCookie(emailID, doNotShowConsent.toString());
	};

	return (
		<UTEModalLayout
			className=''
			width='600'
			theme='light'
			title={'Welcome!'}
			hide={closeModal}>
			<div>
				<Modal.Body className={styles.accountSetupWizardModalBody}>
					<div className={styles.iconSection}>
						<div className={styles.welcomeMessage}>
							Welcome to <br /> Client Portal
						</div>
						<div className={styles.icon}>
							<WelcomeIcon />
						</div>
					</div>
					<div className={styles.messageContainer}>
						<div className={styles.messageTitle}>
							<p>Getting started is quick and easy</p>
						</div>
						<div className={styles.messageDescription}>
							<p>Follow these simple steps to access your account:</p>
						</div>
						<div className={styles.messageSteps}>
							<ol>
								<li>
									Click <span className={styles.textHighlight}>“Sign Up”</span> to create your account.
								</li>
								<li>Enter your details and complete the identity verification.</li>
								<li>
									Set up a secure <span className={styles.textHighlight}>6-digit PIN.</span>
								</li>
								<li>Once done, log in and start exploring your portal.</li>
							</ol>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className={styles.accountSetupWizardModalFooter}>
					<input
						type='checkbox'
						name={'signup-consent'}
						id={'signup-consent'}
						checked={doNotShowConsent}
						onChange={(e) => {
							setDoNotShowConsent(e.target.checked);
						}}
						className={styles.checkBoxConsent}
					/>
					<label
						htmlFor='modal-consent'
						className={styles.checkBoxLabel}>
						Don’t show me this again
					</label>
				</Modal.Footer>
			</div>
		</UTEModalLayout>
	);
};

export default AccountSetupWizard;
