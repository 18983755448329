import React, { useEffect, useState } from 'react';
import {
	IAuthContextValue,
	ICompanyNameAndLogo,
	IUserDetail,
	emptyCompanyNameAndLogo,
	emptyUser,
} from './auth-provider-types';
import { ACCESS_TOKEN_STORAGE_NAME, REFRESH_TOKEN_STORAGE_NAME, USER_DETAILS_STORAGE_NAME } from 'src/helper/constants';
import { ILogger } from 'src/logger/logger';
import { TelemetryLogger } from 'src/logger/telemetry-logger';
import { ICompanyProduct, emptyCompanyProducts } from 'src/modules/common/common-types';

export let logger: ILogger;

export const AuthContext = React.createContext({
	userDetail: emptyUser,
	authToken: '',
	refreshToken: '',
	companyId: -1,
	companyNameAndLogo: emptyCompanyNameAndLogo,
	collapsedMenu: false,
	sessionTimeInSeconds: 0,
	isSessionTimedOut: false,
	companyProducts: emptyCompanyProducts,
	isClientView: false,
	updateUserDetails: (userDetail: IUserDetail) => {},
	updateAuthToken: (authToken: string, userDetail?: IUserDetail) => {},
	updateRefreshToken: (authToken: string) => {},
	updateCompanyId: (companyId: number) => {},
	updateCompanyNameAndLogo: (companyNameAndLogo: ICompanyNameAndLogo) => {},
	updateCollapsedMenu: (collapsedMenu: boolean) => {},
	updateSessionTimeInSeconds: (time: number) => {},
	updateIsSessionTimedOut: (isSessionTimedOut: boolean) => {},
	updateCompanyProducts: (companyProducts: ICompanyProduct[]) => {},
	updateIsClientView: (isClientView: boolean) => {},
});

const AuthProvider = ({ children }: any) => {
	const accessTokenInLocalStorage = localStorage.getItem(ACCESS_TOKEN_STORAGE_NAME);
	const userDetailsString = localStorage.getItem(USER_DETAILS_STORAGE_NAME) ?? '';
	const refreshTokenInLocalStorage = localStorage.getItem(REFRESH_TOKEN_STORAGE_NAME);
	const [userDetail, setUserDetail] = useState(userDetailsString ? JSON.parse(userDetailsString) : emptyUser);
	const [companyNameAndLogo, setCompanyNameAndLogo] = useState<ICompanyNameAndLogo>(emptyCompanyNameAndLogo);
	const [companyProducts, setCompanyProducts] = useState<ICompanyProduct[]>([]);
	const [authToken, setAuthToken] = useState(accessTokenInLocalStorage ?? '');
	const [companyId, setCompanyId] = useState(-1);
	const [collapsedMenu, setCollapsedMenu] = useState(false);
	const [refreshToken, setRefreshToken] = useState(refreshTokenInLocalStorage ?? '');
	const [sessionTimeInSeconds, setSessionTimeInSeconds] = useState(0);
	const [isSessionTimedOut, setIsSessionTimedOut] = useState(false);
	const [isClientView, setIsClientView] = useState(false);

	useEffect(() => {
		const accessTokenInLocalStorage = localStorage.getItem(ACCESS_TOKEN_STORAGE_NAME);
		setAuthToken(accessTokenInLocalStorage ?? '');
		const userDetailsString = localStorage.getItem(USER_DETAILS_STORAGE_NAME) ?? '';
		userDetailsString && setUserDetail(JSON.parse(userDetailsString));
		const refreshTokenInLocalStorage = localStorage.getItem(REFRESH_TOKEN_STORAGE_NAME);
		setRefreshToken(refreshTokenInLocalStorage ?? '');
	}, []);

	useEffect(() => {
		if (authToken) logger = TelemetryLogger.getInstance();
	}, [authToken]);

	const updateUserDetails = (newUserDetail: IUserDetail): void => {
		setUserDetail(newUserDetail);
	};
	const updateCompanyNameAndLogo = (newCompanyNameAndLogo: ICompanyNameAndLogo): void => {
		setCompanyNameAndLogo(newCompanyNameAndLogo);
	};
	const updateAuthToken = (newAuthToken: string, newUserDetail?: IUserDetail): void => {
		localStorage.setItem(ACCESS_TOKEN_STORAGE_NAME, newAuthToken);
		localStorage.setItem(USER_DETAILS_STORAGE_NAME, JSON.stringify(newUserDetail ?? userDetail));
		setAuthToken(newAuthToken);
	};
	const updateRefreshToken = (refreshToken: string): void => {
		localStorage.setItem(REFRESH_TOKEN_STORAGE_NAME, refreshToken);
		setRefreshToken(refreshToken);
	};
	const updateCompanyId = (newCompanyId: number): void => {
		setCompanyId(newCompanyId);
	};
	const updateCollapsedMenu = (newCollapsedMenu: boolean): void => {
		setCollapsedMenu(newCollapsedMenu);
	};
	const updateSessionTimeInSeconds = (time: number): void => {
		setSessionTimeInSeconds(time);
	};
	const updateIsSessionTimedOut = (isSessionTimedOut: boolean): void => {
		setIsSessionTimedOut(isSessionTimedOut);
	};

	const updateCompanyProducts = (companyProducts: ICompanyProduct[]): void => {
		setCompanyProducts(companyProducts);
	};

	const updateIsClientView = (isClientView: boolean): void => {
		setIsClientView(isClientView);
	};

	const value: IAuthContextValue = {
		userDetail,
		authToken,
		refreshToken,
		companyId,
		companyNameAndLogo,
		collapsedMenu,
		sessionTimeInSeconds,
		isSessionTimedOut,
		companyProducts,
		isClientView,
		updateUserDetails,
		updateAuthToken,
		updateRefreshToken,
		updateCompanyId,
		updateCompanyNameAndLogo,
		updateCollapsedMenu,
		updateSessionTimeInSeconds,
		updateIsSessionTimedOut,
		updateCompanyProducts,
		updateIsClientView,
	};
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
