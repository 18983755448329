import React from 'react';

const Folder = () => {
	return (
		<svg
			width='14'
			height='12'
			viewBox='0 0 14 12'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M0 1.5C0 1.10218 0.158035 0.720644 0.43934 0.43934C0.720644 0.158035 1.10218 0 1.5 0H4.264C5.222 0 6.024 0.56 6.575 1.184C6.985 1.648 7.48 2 8 2H12.5C12.8978 2 13.2794 2.15804 13.5607 2.43934C13.842 2.72064 14 3.10218 14 3.5V10.5C14 10.8978 13.842 11.2794 13.5607 11.5607C13.2794 11.842 12.8978 12 12.5 12H1.5C1.10218 12 0.720644 11.842 0.43934 11.5607C0.158035 11.2794 0 10.8978 0 10.5V1.5ZM1.5 1C1.36739 1 1.24021 1.05268 1.14645 1.14645C1.05268 1.24021 1 1.36739 1 1.5V4H13V3.5C13 3.36739 12.9473 3.24021 12.8536 3.14645C12.7598 3.05268 12.6326 3 12.5 3H8C7.036 3 6.29 2.371 5.826 1.846C5.374 1.334 4.82 1 4.264 1H1.5ZM13 5H1V10.5C1 10.6326 1.05268 10.7598 1.14645 10.8536C1.24021 10.9473 1.36739 11 1.5 11H12.5C12.6326 11 12.7598 10.9473 12.8536 10.8536C12.9473 10.7598 13 10.6326 13 10.5V5Z'
				fill='#05386B'
			/>
		</svg>
	);
};

export default Folder;
