import React, { useContext, useEffect, useRef, useState } from 'react';
import NotificationIcon from 'src/assets/svg/notification-icon';
import { getNotifications } from 'src/modules/user-notifications/user-notification-api';
import styles from './user-notification.module.scss';
import { UTENotifier } from '../notification/ute-notifier';
import { INotification, INotificationResponse } from 'src/context/ute-notification-context/notification-provider-types';
import { NOTIFICATION_CONSTANTS } from 'src/helper/constants';
import NotificationPopover from './notification-popover';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';
import { NotificationContext } from 'src/context/ute-notification-context/notification-provider';

const UserNotification: React.FC = () => {
	const [showNotification, setShowNotification] = useState<boolean>(false);
	const target = useRef(null);
	const { userNotifications, updateNotifications } = useContext(NotificationContext);
	const {
		userDetail: { id: registrationId },
	} = useContext(AuthContext);

	useEffect(() => {
		fetchNotifications();
	}, []);

	const fetchNotifications = () => {
		getNotifications((notificationsResponse: INotificationResponse) => {			
				setNotifications(notificationsResponse.notifications);			
		},()=>{
			UTENotifier.Error(NOTIFICATION_CONSTANTS.ERROR_FETCH_NOTIFICATIONS);
		});
	};

	const setNotifications = (notifications: INotification[]) => {
		updateNotifications(notifications);
	};

	const getUnreadNotifications = () => {
		return userNotifications.filter((x) => !x.isAccessed);
	};

	const unreadNotifications = getUnreadNotifications();

	return (
		<div className={styles.notification}>
			<NotificationIcon
				className={styles.notificationIcon}
				assignRef={target}
				onClick={() => setShowNotification(true)}
			/>
			{unreadNotifications.length > 0 && (
				<div className={`${styles.notificationDot} ${unreadNotifications.length > 9 ? styles.extraPadding : ''}`}>
					{unreadNotifications.length > 9 ? '9+' : unreadNotifications.length}
				</div>
			)}
			{showNotification && (
				<NotificationPopover
					target={target}
					showNotification={showNotification}
					setShowNotification={setShowNotification}
					notifications={userNotifications}
					setNotifications={setNotifications}
					registrationId={registrationId}
				/>
			)}
		</div>
	);
};

export default UserNotification;
