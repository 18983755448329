import { ClientTypes, EngagementTypes, ProductType } from '../common/common-types';

export type ITaxRecordsResponse = {
	taxRecords: ITaxRecord[];
	error: boolean;
};

export type ITaxRecord = {
	id: number;
	type: FileSystemType;
	name: string;
	documentCount: number;
	documents: ITaxDocument;
	gatherDocuments: IGatherDocumentModel;
	sourceDocuments: IGatherDocumentModel;
	gatherSignedDocuments: IGatherDocumentModel;
	organizerDocuments: IOrganizerDocumentModel;
	organizerSourceDocuments: IOrganizerDocumentModel;
	organizerSignedDocuments: IOrganizerDocumentModel;
	children: ITaxRecord[];
};

export enum QuickInfo {
	None = 0,
	Signed = 1,
	Received,
	Uploaded,
}

export enum FileSystemType {
	None = 0,
	Folder = 1,
	File = 2,
}

export enum DocumentType {
	None = 0,
	Organizer = 1,
	Engagement = 2,
	Removed = 3,
	CustomQuestion = 4,
	AdditionalEFile = 5,
	OrganizerWithSignature = 6,
	OrganizerRemoved = 7,
}

export enum SignatureStatus {
	Esigned = 'ESigned',
	Reviewed = 'Reviewed',
	ManuallySigned = 'ManuallySigned',
	PartiallySigned = 'PartiallySigned',
	PartiallyReviewed = 'PartiallyReviewed',
	Downloaded = 'EngagementLetterDownloaded',
}

export type ITaxDocument = {
	fileType: ExchangeFileType;
	documentId: number;
	clientId: string;
	documentGuid: string;
	taxYear: number;
	documentStatus: number;
	signatureStatus: number;
	returnType: EngagementTypes;
	clientGuid: string;
	signerName: string;
	signerType: ClientTypes;
	signedOn: string;
	efileFormName: string;
	toolTip: QuickInfo;
	productType: ProductType;
	signingId?: number;
	sourceDocumentId?: number;
	sourceDocumentStatus?: number;
	sourceDocumentFileName?: string;
	documentType?: number;
	fileGuid?: string;
	isMessagePresent: boolean;
	messageGuid: string;
	fileId: number;
	expiryDate?: string;
	isShowExpiration?: boolean;
};

export interface IGatherDocumentModel {
	clientGuid: string;
	clientId: string;
	efileFormName: string;
	esignStatus: SignatureStatus;
	gatherGuid: string;
	gatherId: number;
	organizerStatus: string;
	productType: ProductType;
	returnType: string;
	signedOn: string;
	signerName: string;
	signerType: number;
	sourceDocumentStatus: string;
	taxYear: number;
	toolTip: number;
	fileGuid?: string;
	fileName?: string;
	documentType?: DocumentType;
	sourceDocumentFileName?: string;
	sourceDocumentId?: number;
	updatedOn: string;
	expiryDate?: string;
	isShowExpiration?: boolean;
}

export interface IOrganizerDocumentModel {
	clientGuid: string;
	clientId: string;
	efileFormName: string;
	esignStatus: SignatureStatus;
	documentGuid: string;
	documentId: number;
	organizerStatus: string;
	productType: ProductType;
	returnType: string;
	signedOn: string;
	signerName: string;
	signerType: number;
	sourceDocumentStatus: string;
	taxYear: number;
	toolTip: number;
	fileGuid?: string;
	fileName?: string;
	documentType?: DocumentType;
	sourceDocumentFileName?: string;
	sourceDocumentId?: number;
	updatedOn: string;
	expiryDate?: string;
	isShowExpiration?: boolean;
}

export interface ICaptchaResponse {
	response: boolean
}


export enum OrganizerStatus {
	Completed = 'Completed',
	ManuallyCompleted = 'ManuallyCompleted',
	QuestionnaireCompleted = 'QuestionnaireCompleted',
	PartiallyCompleted = 'PartiallyCompleted',
	ClosedByFirm = 'ClosedByFirm',
	OrganizerDownloaded = 'OrganizerDownloaded',
}

export enum SourceDocumentStatus {
	Uploaded = 'Uploaded'
}



export enum SSRSignatureStatus {
	None,
	ESigned = 1,
	ManuallySigned,
	Uploaded,
	AwaitingESign,
	AwaitingUpload,
	Locked,
	MailOrFax,
	SignedAndESigned,
	DeliveredToTaxCaddy = 10,
	TaxCaddyDeliveryFailed = 16,
	AlternateDelivery = 17,
	DownloadingFailed = 18,
	AlternateAndUploaded = 19,
	PartiallySigned = 20,
	SignatureStampingFailed = 21,
	ESignedInOffice = 22,
	AwaitingDraftReview = 23,
	DraftApproved = 24,
	DraftRejected = 25,
	FinalReturnCopyCreated = 26
}

export enum EXTSignatureStatus {
	None,
	ESigned = 1,
	ManuallySigned,
	Uploaded,
	AwaitingESign,
	AwaitingUpload,
	Locked,
	MailOrFax,
	SignedAndESigned,
	DeliveredToTaxCaddy = 10,
	TaxCaddyDeliveryFailed = 16,
	AlternateDelivery = 17,
	DownloadingFailed = 18,
	AlternateAndUploaded = 19,
	Reviewed = 20,
	AwaitingReview = 21,
	PartiallySigned = 22,
	PartiallyReviewed = 23,
}


export enum GTRDocumentStatus {
	None = 0,

	// Source Document
	Uploaded = 2,

	// Organizer & CustomQuestion
	Completed = 3,
	ManuallyCompleted = 6,
	ClosedByFirm = 7,
	QuestionnaireCompleted = 8,
	OrganizerDownloaded = 4,

	// EngagementLetters
	PartiallySigned = 22,
	ESigned = 23,
	ELDownloaded = 24,
	Reviewed = 26,
	ManuallySigned = 28,
	PartiallyReviewed = 29,
}

export const enum SIGDocumentStatus {
	None,
	Active = 1,
	Archived = 2,
	Recycled = 3,
	Deleted = 4,
	Draft = 5,
}

export const enum SIGSignatureStatus {
	None = 0,
	Processing = 1,
	OutForDelivery = 2,
	ESigned = 3,
	Declined = 4,
	Cancelled = 5,
	PartiallySigned = 6,
	SignatureStampingFailed = 7,
	DeliveryFailed = 8,
}
export const enum SIGClientType {
	None,
	Signer = 1,
	CC = 2,
	Sender = 3,
	Delegatee = 4,
}
export const enum ExchangeFileType {
	None = 0,
	ReceivedMessage = 1,
	SentMessage,
	DocumentRequest,
}

export const enum DocumentStatus {
	None,
	UPLOADED = 1,
	ERROR = 2,
	READY = 3,
	PROCESSING = 4,
	REVIEW = 5,
	USERSIGNED = 6,
	DELIVERED = 7,
	APPROVEDFORDELIVERY = 8,
	PREPARINGFORDELIVERY = 9,
	DELIVERYFAILED = 10,
	NONSUPPORTED = 11,
	RECALLED = 12,
	DELETED = 13,
	REPROCESSING = 14,
	DELIVEREDTOTAXCADDY = 15,
	TAXCADDYDELIVERYFAILED = 16,
	DUPLICATE = 17,
	DOWNLOADPDF = 18,
	DOWNLOADINGFAILED = 19,
	PREPARINGFORPDFDOWNLOAD = 20,
	TOBEREPROCESSED = 21,
	READYFORDELIVERY = 22,
	SIGNATURESTAMPINGFAILED = 23,
}

export const initialTaxDocument: ITaxDocument = {
	documentId: 0,
	clientGuid: '',
	clientId: '',
	documentGuid: '',
	documentStatus: DocumentStatus.None,
	efileFormName: '',
	returnType: EngagementTypes.None,
	signatureStatus: -1,
	signedOn: '',
	signerName: '',
	signerType: ClientTypes.Undefined,
	taxYear: 0,
	toolTip: 0,
	productType: ProductType.None,

	isMessagePresent: false,
	messageGuid: '',
	fileType: ExchangeFileType.None,
	exchangeFileType: 0,
	fileId: 0,

};

export const initialGatherDocument: IGatherDocumentModel = {
	clientGuid: '',
	clientId: '',
	efileFormName: '',
	esignStatus: SignatureStatus.Esigned,
	gatherGuid: '',
	gatherId: 0,
	organizerStatus: '',
	productType: ProductType.Gather,
	returnType: '',
	signedOn: '',
	signerName: '',
	signerType: 2,
	sourceDocumentStatus: '',
	taxYear: 2023,
	toolTip: 3,
	documentType: DocumentType.None,
	updatedOn: ''
};

export const initialOrganizerDocument: IOrganizerDocumentModel = {
	clientGuid: '',
	clientId: '',
	efileFormName: '',
	esignStatus: SignatureStatus.Esigned,
	documentGuid: '',
	documentId: 0,
	organizerStatus: '',
	productType: ProductType.Gather,
	returnType: '',
	signedOn: '',
	signerName: '',
	signerType: 2,
	sourceDocumentStatus: '',
	taxYear: 2023,
	toolTip: 3,
	documentType: DocumentType.None,
	updatedOn: ''
};

export const enum DocumentsDisplay {
	TREE = 1,
	GRID = 2,
}

export const enum DocumentsSort {
	NONE = 'none',
	DESC = 'desc',
	ASC = 'asc',
}

export type IFolderDetails = {
	folderName: string;
	documentCount: number;
	path: string;
};

export interface ITaxReturnDetails extends ITaxDocument {
	path: string;
}

export type IPaginatedTaxDocuments = {
	folderDetails: IFolderDetails[];
	taxReturnDetails: ITaxReturnDetails[];
};

export type IPaginatedTaxData = {
	count: number;
	items: IPaginatedTaxDocuments;
};

export const initialPaginatedTaxData = {
	count: 0,
	items: { folderDetails: [], taxReturnDetails: [] },
};

export type IPaginatedTaxDataResponse = {
	paginatedTaxData: IPaginatedTaxData;
	error: boolean;
};

export type IPaginatedNode = IFolderDetails | ITaxReturnDetails;

export type IFilter = {
	fromDate: string;
	toDate: string;
	filterStatus: string;
	filterFileType: string;
};

export const initialFilter: IFilter = {
	fromDate: '',
	toDate: '',
	filterStatus: '',
	filterFileType: '',
};

export type INodeSelected = {
	index: number;
	node: IPaginatedNode | undefined;
};

export const initialNodeSelected: INodeSelected = {
	index: -1,
	node: undefined,
};

export const enum FilterType {
	FROMDATE = 0,
	TODATE,
	STATUS,
	FILETYPE,
}

export type IOptionsType = {
	name: string;
	value: string;
};

export const FILTER_STATUS_OPTIONS: IOptionsType[] = [
	{
		name: 'E-Signed',
		value: 'ESigned',
	},
	{
		name: 'Manually Signed',
		value: 'ManuallySigned',
	},
	{
		name: 'Partially Signed',
		value: 'PartiallySigned',
	},
	{
		name: 'In-Office E-Signed',
		value: 'ESignedInOffice',
	},
	{
		name: 'Partially Reviewed',
		value: 'PartiallyReviewed',
	},
	{
		name: 'Reviewed',
		value: 'Reviewed',
	},
	{
		name: 'Completed',
		value: 'Completed',
	},
	{
		name: 'Manually Completed',
		value: 'ManuallyCompleted',
	},
	{
		name: 'Questionnaire Completed',
		value: 'QuestionnaireCompleted',
	},
	{
		name: 'Uploaded',
		value: 'Uploaded',
	},
	{
		name: 'Closed By Firm',
		value: 'ClosedByFirm',
	},
];

export const FILTER_FILETYPE_OPTIONS: IOptionsType[] = [
	{
		name: 'PDF',
		value: 'PDF',
	},
	{
		name: 'DOC/DOCX',
		value: 'DOC/DOCX',
	},
	{
		name: 'XLS/XLSX',
		value: 'XLS/XLSX',
	},
	{
		name: 'PNG/JPEG/JPG',
		value: 'PNG/JPEG/JPG',
	},
	{
		name: 'QBB',
		value: 'QBB',
	},
	{
		name: 'QBM',
		value: 'QBM',
	},
	{
		name: 'QBW',
		value: 'QBW',
	},
	{
		name: 'QBX',
		value: 'QBX',
	},
	{
		name: 'Others',
		value: 'Others',
	},
];
