export const WelcomeIcon: React.FC = () => {
	return (
		<svg
			width='211'
			height='166'
			viewBox='0 0 211 166'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M47.1647 125.003C47.1647 125.492 47.3467 125.97 47.6877 126.377C48.0286 126.783 48.5132 127.1 49.0802 127.287C49.6472 127.474 50.2711 127.523 50.873 127.428C51.4749 127.332 52.0278 127.097 52.4618 126.751C52.8957 126.406 53.1913 125.965 53.311 125.486C53.4307 125.006 53.3693 124.509 53.1344 124.058C52.8996 123.606 52.5019 123.22 51.9916 122.948C51.4813 122.677 50.8814 122.532 50.2677 122.532C49.4449 122.532 48.656 122.793 48.0742 123.256C47.4924 123.72 47.1653 124.348 47.1647 125.003Z'
				fill='#212529'
			/>
			<path
				d='M57.0941 125.003C57.0941 125.492 57.2761 125.97 57.6171 126.377C57.958 126.783 58.4426 127.1 59.0096 127.287C59.5766 127.474 60.2005 127.523 60.8024 127.428C61.4043 127.332 61.9572 127.097 62.3912 126.751C62.8251 126.406 63.1206 125.965 63.2404 125.486C63.3601 125.006 63.2987 124.509 63.0638 124.058C62.8289 123.606 62.4312 123.22 61.921 122.948C61.4107 122.677 60.8108 122.532 60.1971 122.532C59.3743 122.532 58.5853 122.793 58.0035 123.256C57.4217 123.72 57.0946 124.348 57.0941 125.003Z'
				fill='#212529'
			/>
			<path
				d='M68.2647 125.003C68.2647 125.492 68.4103 125.97 68.6831 126.377C68.9558 126.783 69.3435 127.1 69.7971 127.287C70.2507 127.474 70.7498 127.523 71.2313 127.428C71.7129 127.332 72.1552 127.097 72.5024 126.751C72.8495 126.406 73.0859 125.965 73.1817 125.486C73.2775 125.006 73.2283 124.509 73.0405 124.058C72.8526 123.606 72.5344 123.22 72.1262 122.948C71.718 122.677 71.238 122.532 70.7471 122.532C70.089 122.533 69.4581 122.793 68.9927 123.257C68.5274 123.72 68.2656 124.348 68.2647 125.003Z'
				fill='#212529'
			/>
			<path
				d='M78.1941 125.003C78.1941 125.492 78.3761 125.97 78.717 126.377C79.058 126.783 79.5426 127.1 80.1096 127.287C80.6766 127.474 81.3005 127.523 81.9024 127.428C82.5043 127.332 83.0572 127.097 83.4911 126.751C83.9251 126.406 84.2206 125.965 84.3403 125.486C84.4601 125.006 84.3986 124.509 84.1638 124.058C83.9289 123.606 83.5312 123.22 83.0209 122.948C82.5107 122.677 81.9107 122.532 81.297 122.532C80.4744 122.533 79.6858 122.793 79.1041 123.257C78.5224 123.72 78.1951 124.348 78.1941 125.003Z'
				fill='#212529'
			/>
			<path
				d='M88.1235 125.003C88.1235 125.492 88.3055 125.97 88.6465 126.377C88.9874 126.783 89.472 127.1 90.039 127.287C90.606 127.474 91.2299 127.523 91.8318 127.428C92.4337 127.332 92.9866 127.097 93.4206 126.751C93.8545 126.406 94.1501 125.965 94.2698 125.486C94.3895 125.006 94.3281 124.509 94.0932 124.058C93.8584 123.606 93.4606 123.22 92.9504 122.948C92.4401 122.677 91.8402 122.532 91.2265 122.532C90.4037 122.532 89.6147 122.793 89.0329 123.256C88.4511 123.72 88.1241 124.348 88.1235 125.003Z'
				fill='#212529'
			/>
			<path
				d='M99.2941 125.003C99.2941 125.492 99.4397 125.97 99.7125 126.377C99.9852 126.783 100.373 127.1 100.827 127.287C101.28 127.474 101.779 127.523 102.261 127.428C102.742 127.332 103.185 127.097 103.532 126.751C103.879 126.406 104.115 125.965 104.211 125.486C104.307 125.006 104.258 124.509 104.07 124.058C103.882 123.606 103.564 123.22 103.156 122.948C102.747 122.677 102.267 122.532 101.776 122.532C101.118 122.532 100.487 122.793 100.022 123.256C99.5562 123.72 99.2946 124.348 99.2941 125.003Z'
				fill='#212529'
			/>
			<path
				d='M109.224 125.003C109.224 125.492 109.369 125.97 109.642 126.377C109.915 126.783 110.302 127.1 110.756 127.287C111.21 127.474 111.709 127.523 112.19 127.428C112.672 127.332 113.114 127.097 113.461 126.751C113.808 126.406 114.045 125.965 114.141 125.486C114.236 125.006 114.187 124.509 113.999 124.058C113.811 123.606 113.493 123.22 113.085 122.948C112.677 122.677 112.197 122.532 111.706 122.532C111.048 122.532 110.416 122.793 109.951 123.256C109.486 123.72 109.224 124.348 109.224 125.003Z'
				fill='#212529'
			/>
			<path
				d='M119.153 125.003C119.153 125.492 119.335 125.97 119.676 126.377C120.017 126.783 120.501 127.1 121.068 127.287C121.635 127.474 122.259 127.523 122.861 127.428C123.463 127.332 124.016 127.097 124.45 126.751C124.884 126.406 125.179 125.965 125.299 125.486C125.419 125.006 125.357 124.509 125.123 124.058C124.888 123.606 124.49 123.22 123.98 122.948C123.47 122.677 122.87 122.532 122.256 122.532C121.433 122.532 120.644 122.793 120.062 123.256C119.481 123.72 119.153 124.348 119.153 125.003Z'
				fill='#212529'
			/>
			<path
				d='M129.082 125.003C129.082 125.492 129.264 125.97 129.605 126.377C129.946 126.783 130.431 127.1 130.998 127.287C131.565 127.474 132.189 127.523 132.791 127.428C133.393 127.332 133.945 127.097 134.379 126.751C134.813 126.406 135.109 125.965 135.229 125.486C135.348 125.006 135.287 124.509 135.052 124.058C134.817 123.606 134.419 123.22 133.909 122.948C133.399 122.677 132.799 122.532 132.185 122.532C131.362 122.532 130.574 122.793 129.992 123.256C129.41 123.72 129.083 124.348 129.082 125.003Z'
				fill='#212529'
			/>
			<path
				d='M202.229 21.8147C201.901 18.4089 200.673 15.1502 198.671 12.3695C196.988 10.0415 194.834 8.09079 192.347 6.64326C189.86 5.19574 187.096 4.28361 184.233 3.96577C182.972 3.87628 195.188 3.89574 29.5296 3.89574C27.5528 3.85226 25.5797 4.08627 23.6685 4.59086C19.8615 5.58113 16.425 7.65024 13.776 10.5471C11.1269 13.444 9.37917 17.0442 8.74479 20.9107C8.58093 21.8518 8.48776 22.8038 8.46606 23.7587C8.46606 24.245 8.94277 135.85 9.02352 137.587C9.14726 140.226 9.30355 141.754 9.51195 140.156C10.3038 134.083 10.2791 56.9185 10.2791 29.33C15.5424 30.0783 200.391 28.9734 200.974 28.8282C200.974 29.1757 200.813 135.868 200.841 137.606C200.899 141.318 201.702 141.367 201.883 137.587C202.23 130.338 202.494 23.9273 202.229 21.8147ZM10.2101 28.2731V24.4538C10.1734 20.7475 11.2492 17.1148 13.2997 14.0214C15.3502 10.928 18.2818 8.51478 21.7187 7.09124C23.3524 6.45087 25.0646 6.03043 26.81 5.84105C28.9396 5.84105 5.53551 5.77102 183.187 5.77102C187.792 5.97036 192.16 7.86308 195.445 11.0831C198.73 14.3032 200.699 18.6217 200.971 23.2036V28.1564C191.997 26.4549 63.5397 28.2731 10.2101 28.2731Z'
				fill='#212529'
			/>
			<path
				d='M24.5788 18.1331C24.5788 19.3118 25.0491 20.4423 25.8861 21.2758C26.7232 22.1093 27.8585 22.5775 29.0424 22.5775C30.2262 22.5775 31.3615 22.1093 32.1986 21.2758C33.0357 20.4423 33.5059 19.3118 33.5059 18.1331C33.5059 16.9544 33.0357 15.8239 32.1986 14.9904C31.3615 14.157 30.2262 13.6887 29.0424 13.6887C27.8585 13.6887 26.7232 14.157 25.8861 14.9904C25.0491 15.8239 24.5788 16.9544 24.5788 18.1331Z'
				fill='#38A1FF'
			/>
			<path
				d='M31.9014 13.5498C30.808 12.8839 29.5119 12.6283 28.2461 12.8288C26.9803 13.0293 25.8279 13.6729 24.996 14.6437C24.1641 15.6146 23.7073 16.8491 23.7078 18.1253C23.7082 19.4014 24.1658 20.6356 24.9983 21.6059C26.672 23.5111 29.5023 23.2919 28.9735 22.5138C28.8041 22.1779 27.7817 22.091 26.741 21.0561C26.2725 20.594 25.9349 20.0169 25.7623 19.3831C25.5898 18.7494 25.5884 18.0815 25.7584 17.4471C25.9284 16.8126 26.2637 16.2342 26.7304 15.7703C27.1971 15.3063 27.7786 14.9734 28.416 14.8051C29.1071 14.6582 29.8264 14.7176 30.4837 14.976C31.1411 15.2344 31.7072 15.6802 32.1111 16.2576C32.4118 16.7439 32.6022 17.2897 32.6691 17.8568C32.736 18.424 32.6778 18.9988 32.4984 19.5412C32.3191 20.0836 32.0229 20.5805 31.6307 20.9972C31.2386 21.4138 30.7597 21.7402 30.2277 21.9535L29.3902 22.3698C29.1819 22.5086 29.0425 22.6473 29.1819 22.8561C29.2509 22.9949 29.4606 23.0649 29.6703 23.1337C30.5823 23.2301 31.5013 23.0326 32.2922 22.5701C33.083 22.1076 33.7039 21.4046 34.0635 20.5645C34.2182 20.183 34.3351 19.7873 34.4126 19.3831C34.5819 18.2743 34.4365 17.1405 33.9928 16.1097C33.549 15.0789 32.8246 14.1922 31.9014 13.5498Z'
				fill='#212529'
			/>
			<path
				d='M45.9915 13.5499C44.898 12.8862 43.6028 12.6323 42.3383 12.8336C41.0737 13.0349 39.9226 13.6783 39.0914 14.6483C38.2603 15.6184 37.8034 16.8515 37.8029 18.1266C37.8023 19.4017 38.2581 20.6353 39.0884 21.606C40.7634 23.5112 43.5937 23.292 43.0649 22.5139C42.8955 22.178 41.8731 22.0911 40.8324 21.0562C40.3639 20.5942 40.0262 20.0172 39.8536 19.3835C39.681 18.7498 39.6795 18.082 39.8494 17.4476C40.0192 16.8132 40.3544 16.2347 40.8209 15.7707C41.2874 15.3067 41.8688 14.9737 42.5061 14.8052C43.1972 14.6585 43.9165 14.718 44.5738 14.9764C45.231 15.2347 45.7972 15.6805 46.2012 16.2577C46.5017 16.7441 46.6919 17.2898 46.7587 17.8569C46.8255 18.424 46.7672 18.9988 46.5878 19.5411C46.4085 20.0835 46.1125 20.5803 45.7204 20.997C45.3283 21.4136 44.8497 21.7401 44.3178 21.9536L43.4804 22.3699C43.2707 22.5087 43.1313 22.6474 43.2707 22.8562C43.341 22.995 43.5507 23.065 43.7591 23.1338C44.6712 23.2301 45.5904 23.0325 46.3814 22.5701C47.1724 22.1076 47.7936 21.4047 48.1536 20.5647C48.3074 20.1828 48.4243 19.7872 48.5027 19.3832C48.672 18.2744 48.5266 17.1406 48.0829 16.1098C47.6391 15.079 46.9147 14.1923 45.9915 13.5499Z'
				fill='#212529'
			/>
			<path
				d='M60.0804 13.5498C58.9869 12.8839 57.6908 12.6283 56.4251 12.8288C55.1593 13.0293 54.0069 13.6729 53.175 14.6437C52.3431 15.6146 51.8863 16.8491 51.8867 18.1253C51.8871 19.4014 52.3447 20.6356 53.1773 21.6059C54.8522 23.5111 57.6825 23.2919 57.1524 22.5138C56.9844 22.1779 55.962 22.091 54.9213 21.0561C54.4527 20.5941 54.1151 20.0171 53.9424 19.3834C53.7698 18.7497 53.7684 18.0819 53.9382 17.4475C54.1081 16.8131 54.4432 16.2346 54.9097 15.7706C55.3763 15.3066 55.9576 14.9736 56.595 14.8051C57.2861 14.6582 58.0054 14.7176 58.6627 14.976C59.32 15.2344 59.8861 15.6802 60.2901 16.2576C60.5907 16.7439 60.7812 17.2897 60.8481 17.8568C60.915 18.424 60.8567 18.9988 60.6774 19.5412C60.498 20.0836 60.2019 20.5805 59.8097 20.9972C59.4175 21.4138 58.9387 21.7402 58.4067 21.9535L57.5705 22.3698C57.3608 22.5086 57.2214 22.6473 57.3608 22.8561C57.4311 22.9949 57.6395 23.0649 57.8492 23.1337C58.7614 23.23 59.6805 23.0324 60.4716 22.57C61.2626 22.1075 61.8837 21.4046 62.2438 20.5645C62.3974 20.1827 62.5138 19.7871 62.5915 19.3831C62.7609 18.2743 62.6155 17.1405 62.1717 16.1097C61.728 15.0789 61.0036 14.1922 60.0804 13.5498Z'
				fill='#212529'
			/>
			<path
				d='M169.59 108.938H43.8938C42.4607 108.924 41.09 108.341 40.0768 107.312C39.0636 106.283 38.4889 104.891 38.4764 103.436V97.1372C38.4889 95.6824 39.0636 94.2907 40.0768 93.2618C41.09 92.2328 42.4607 91.6489 43.8938 91.6357H169.59C171.023 91.6494 172.393 92.2335 173.406 93.2624C174.419 94.2913 174.993 95.6827 175.006 97.1372V103.436C174.993 104.891 174.419 106.282 173.406 107.311C172.393 108.34 171.023 108.924 169.59 108.938Z'
				fill='#38A1FF'
			/>
			<path
				d='M172.193 109.164C172.809 109.111 173.389 108.843 173.835 108.405C174.281 107.967 174.567 107.386 174.644 106.758C174.644 106.571 175.193 94.147 174.939 93.4244C174.779 92.829 174.482 92.2814 174.073 91.8276C173.664 91.3738 173.154 91.0271 172.587 90.8164C171.597 90.4794 41.2922 90.3521 40.3789 90.4157C39.7853 90.5397 39.228 90.8035 38.7515 91.1864C38.2749 91.5693 37.8922 92.0605 37.6338 92.6212C37.5954 92.741 37.2422 93.7988 37.2422 93.9317C37.2422 107.481 37.1964 106.569 37.341 106.865C37.4823 107.794 37.9465 108.641 38.6484 109.248C39.3503 109.856 40.2428 110.184 41.1622 110.173C158.735 109.851 175.749 109.675 171.116 108.675C166.54 107.679 124.307 107.573 116.626 107.573C35.6298 107.573 40.6937 107.794 40.0843 107.17C39.5353 106.608 39.6927 107.26 39.6927 94.1377C39.7637 93.9015 39.8901 93.6867 40.0607 93.5118C40.2313 93.337 40.4411 93.2073 40.6718 93.1342C40.8255 93.0574 167.583 92.9339 167.882 92.9339C171.037 92.9339 169.273 92.8328 171.908 92.8328C172.195 92.9057 172.463 93.0432 172.691 93.2353C172.908 93.4253 173.076 93.6662 173.182 93.9374C173.259 94.0551 173.293 94.197 173.279 94.338C173.279 97.8091 173.476 96.8131 173.476 102.95C173.476 105.933 174.086 107.546 172.594 108.567C172.343 108.721 172.08 108.855 171.809 108.967C171.613 109.069 171.515 109.069 171.515 109.168C171.705 109.065 171.901 109.164 172.193 109.164Z'
				fill='#212529'
			/>
			<path
				d='M172.193 133.881C172.809 133.827 173.389 133.559 173.835 133.122C174.281 132.684 174.567 132.102 174.644 131.475C174.644 131.288 175.193 118.864 174.939 118.141C174.779 117.546 174.482 116.998 174.073 116.544C173.664 116.091 173.154 115.744 172.587 115.533C171.597 115.196 41.2922 115.069 40.3789 115.133C39.7853 115.256 39.228 115.52 38.7515 115.903C38.2749 116.286 37.8922 116.777 37.6338 117.338C37.5954 117.458 37.2422 118.516 37.2422 118.649C37.2422 132.198 37.1964 131.286 37.341 131.582C37.4823 132.511 37.9465 133.357 38.6484 133.965C39.3503 134.573 40.2428 134.901 41.1622 134.89C158.735 134.568 175.749 134.392 171.116 133.392C166.54 132.396 124.307 132.289 116.626 132.289C35.6298 132.289 40.6937 132.51 40.0843 131.887C39.5353 131.325 39.6927 131.977 39.6927 118.854C39.7637 118.618 39.8901 118.404 40.0607 118.229C40.2313 118.054 40.4411 117.924 40.6718 117.851C40.8255 117.774 167.583 117.651 167.882 117.651C171.037 117.651 169.273 117.55 171.908 117.55C172.195 117.622 172.463 117.76 172.691 117.952C172.908 118.142 173.076 118.383 173.182 118.654C173.259 118.772 173.293 118.914 173.279 119.055C173.279 122.526 173.476 121.53 173.476 127.667C173.476 130.649 174.086 132.263 172.594 133.284C172.343 133.437 172.08 133.571 171.809 133.684C171.613 133.785 171.515 133.785 171.515 133.885C171.705 133.782 171.901 133.881 172.193 133.881Z'
				fill='#212529'
			/>
			<path
				d='M81.5675 59.5037C81.5675 62.6331 82.1865 65.7319 83.3893 68.623C84.592 71.5142 86.3549 74.1412 88.5772 76.354C90.7996 78.5668 93.4379 80.3221 96.3416 81.5197C99.2452 82.7173 102.357 83.3336 105.5 83.3336C108.643 83.3336 111.755 82.7173 114.659 81.5197C117.562 80.3221 120.201 78.5668 122.423 76.354C124.646 74.1412 126.408 71.5142 127.611 68.623C128.814 65.7319 129.433 62.6331 129.433 59.5037C129.433 56.3743 128.814 53.2756 127.611 50.3844C126.408 47.4932 124.646 44.8663 122.423 42.6534C120.201 40.4406 117.562 38.6853 114.659 37.4878C111.755 36.2902 108.643 35.6738 105.5 35.6738C102.357 35.6738 99.2452 36.2902 96.3416 37.4878C93.4379 38.6853 90.7996 40.4406 88.5772 42.6534C86.3549 44.8663 84.592 47.4932 83.3893 50.3844C82.1865 53.2756 81.5675 56.3743 81.5675 59.5037Z'
				stroke='#212529'
				stroke-width='1.58194'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M88.7657 76.5389C92.3193 74.4872 96.0995 72.8517 100.031 71.6652C101.773 71.0249 101.962 67.0463 100.713 65.6787C98.9111 63.7039 97.3835 61.3893 97.3835 55.8027C97.2786 54.6897 97.412 53.5673 97.7751 52.5095C98.1382 51.4516 98.7227 50.4826 99.4899 49.6665C100.257 48.8503 101.189 48.2055 102.225 47.7748C103.262 47.3441 104.378 47.1373 105.5 47.168C106.622 47.1373 107.738 47.3441 108.774 47.7748C109.81 48.2055 110.743 48.8503 111.51 49.6665C112.277 50.4826 112.861 51.4516 113.225 52.5095C113.588 53.5673 113.721 54.6897 113.616 55.8027C113.616 61.3976 112.089 63.7039 110.286 65.6787C109.038 67.0463 109.227 71.0249 110.969 71.6652C114.9 72.8517 118.68 74.4872 122.234 76.5389'
				stroke='#212529'
				stroke-width='1.58194'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	);
};
