import React, { useContext, useEffect, useState } from 'react';
import LeftNavigation from 'src/modules/left-navigation';
import LoggedInFooter from 'src/modules/logged-in-footer';
import styles from './logged-in-layout.module.scss';
import IdleTimer from 'react-idle-timer';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';
import SessionTimeout from '../../session-timeout/index';
import LoggedOut from '../../logged-out/index';
import {
	fetchCompanyNameAndLogo,
	getPendoMetadata,
	getSessionTimeout,
	getUserRole,
	markLogout,
	validateRefreshToken,
} from '../../common/common-api';
import {

	RefreshTokenResponseVM,
	ICompanyProductsResponse,
	ProductType,
	ICompanyNameAndLogoResponse,
	IUserRoleResponse,
	UserRole,
	IPendoMetadata,
} from 'src/modules/common/common-types';
import { getCompanyProducts } from 'src/modules/common/common-api';
import { getIsK1DocumentsPresent } from 'src/modules/distribute-k1s/distribute-k1-api';
import { IK1DocumentsPresentResponse } from 'src/modules/distribute-k1s/distribute-k1-types';
import { UTENotifier } from 'src/modules/notification/ute-notifier';
import {
	DISTRIBUTE_K1_CONSTANTS,
	ERROR_COMPANY_NAME_LOGO,
	LOGGED_IN_LAYOUT_CONSTANTS,
	ProductStatus,
} from 'src/helper/constants';
import usePendo from 'src/hooks/usePendo';
import { useNavigate } from 'react-router-dom';
import AxiosInterceptorsSetup from 'src/modules/axios-interceptor';
import SignalRWebSocket from 'src/modules/signalr';
import UserNotification from 'src/modules/user-notifications';

declare global {
	interface Window {
		pendo: any;
	}
}

const LoggedInLayout = ({ children }: React.PropsWithChildren) => {
	const {
		authToken,
		updateAuthToken,
		refreshToken,
		updateRefreshToken,
		updateSessionTimeInSeconds,
		userDetail,
		companyProducts,
		updateIsSessionTimedOut,
		updateCompanyProducts,
		companyId,
		companyNameAndLogo,
		updateCompanyNameAndLogo,
		updateIsClientView,
	} = useContext(AuthContext);

	const WarningVisibleMinutes = 1;
	let idleTimer: any;
	const [showSessionTimeOut, setShowSessionTimeOut] = useState(false);
	const [showLoggedOut, setShowLoggedOut] = useState(false);
	const [sessionTimeInSeconds, setSessionTimeInSeconds] = useState(0);
	const [showDistributeK1Menu, setShowDistributeK1Menu] = useState(false);
	const [showTaxpaymentMenu, setShowTaxPaymentMenu] = useState(false);
	const navigate = useNavigate();
	const [pendoMetadata, setPendoMetadata] = useState<IPendoMetadata>({ isTestCompany: false, subscription: '' });

	usePendo({
		companyId,
		companyName: companyNameAndLogo.name,
		userId: userDetail.id,
		name: userDetail.name,
		email: userDetail.emailAddress,
		isTestCompany: pendoMetadata?.isTestCompany ?? false,
		subscription: pendoMetadata?.subscription ?? "",
	});

	useEffect(() => {
		fetchUserRole();
	}, []);

	useEffect(() => {
		getIsK1DocumentsPresent((k1DocumentsPresentResponse: IK1DocumentsPresentResponse) => {
			if (k1DocumentsPresentResponse.error) {
				UTENotifier.Error(DISTRIBUTE_K1_CONSTANTS.ERROR_CHECKING_K1_DETAILS_PRESENT_OR_NOT);
			} else {
				setShowDistributeK1Menu(k1DocumentsPresentResponse.isPresent);
			}
		});

		if (companyProducts.length === 0) {
			updateCompanyProductsInfo();
		}

		setShowTaxPaymentMenu(showPaymentMenu());

		if (sessionTimeInSeconds === 0) {
			getSessionTimeout((sessionTimeout: number) => {
				updateSessionTimeInSeconds(sessionTimeout);
				setSessionTimeInSeconds(sessionTimeout);
			});
		}
	}, []);

	useEffect(() => {
		fetchCompanyNameAndLogo(Number(companyId), (companyNameAndLogoResponse: ICompanyNameAndLogoResponse) => {
			if (companyNameAndLogoResponse.error) {
				UTENotifier.Error(ERROR_COMPANY_NAME_LOGO);
			} else {
				updateCompanyNameAndLogo(companyNameAndLogoResponse.companyNameAndLogo);
				document.title = companyNameAndLogoResponse.companyNameAndLogo?.name + ' : ssportal.com';
			}
		});
		getPendoMetadata(updatePendoMetadata);
	}, []);

	AxiosInterceptorsSetup(authToken, navigate);

	const fetchUserRole = () => {
		getUserRole((userRoleResponse: IUserRoleResponse) => {
			if (userRoleResponse.error) {
				UTENotifier.Error('Error while fetching user role');
			} else {
				const userRole = userRoleResponse.role;
				if (userRole === UserRole.CPA) {
					updateIsClientView(true);
				} else {
					updateIsClientView(false);
				}
			}
		});
	};

	const updatePendoMetadata = (data: IPendoMetadata) => {
		setPendoMetadata(data);
	};


	const onIdle = (e: any) => {
		setShowSessionTimeOut(true);
	};

	const updateCompanyProductsInfo = () => {
		getCompanyProducts((companyProductsResponse: ICompanyProductsResponse) => {
			if (companyProductsResponse.error) {
				UTENotifier.Error(LOGGED_IN_LAYOUT_CONSTANTS.ERROR_FETCHING_COMPANY_PRODUCTS);
			} else {
				updateCompanyProducts(companyProductsResponse.products);
			}
		});
	};

	const showPaymentMenu = (): boolean => {
		if (companyProducts && companyProducts.length > 0) {
			const ssrProduct = companyProducts.find((product) => product.productId === ProductType.Returns);
			const extProduct = companyProducts.find((product) => product.productId === ProductType.Extensions);
			if (ssrProduct && ssrProduct.isSubscribed && ssrProduct.productStatus === ProductStatus.Enabled) {
				return true;
			} else if (extProduct && extProduct.isSubscribed && extProduct.productStatus === ProductStatus.Enabled) {
				return true;
			}
		}
		return false;
	};

	const onStayAlive = () => {
		validateRefreshToken(
			{ accessToken: authToken, refreshToken: refreshToken, registrationId: userDetail.id },
			(response: RefreshTokenResponseVM) => {
				updateAuthToken(response.accessToken);
				updateRefreshToken(response.refreshToken);
				setShowSessionTimeOut(false);
			},
			() => {
				logout();
			},
		);
	};

	const logout = () => {
		markLogout(() => {
			updateAuthToken('');
			updateRefreshToken('');
			window.location.href = '/';
		});
	};

	return (
		<>
			<SignalRWebSocket />
			<UserNotification />
			{!showSessionTimeOut && sessionTimeInSeconds > 0 && (
				<IdleTimer
					key='idleTimer'
					ref={idleTimer}
					onActive={() => setShowSessionTimeOut(false)}
					element={document}
					onIdle={onIdle}
					stopOnIdle={true}
					debounce={250}
					timeout={1000 * 60 * (sessionTimeInSeconds / 60 - WarningVisibleMinutes)}
				/>
			)}
			<div className={styles.loggedInLayout}>
				<div className={styles.content}>
					<LeftNavigation
						logout={logout}
						showDistributeK1Menu={showDistributeK1Menu}
						showTaxpaymentMenu={!showTaxpaymentMenu ? showPaymentMenu() : showTaxpaymentMenu}
					/>
					{children}
				</div>
				<footer className={styles.footer}>
					<LoggedInFooter />
				</footer>
			</div>

			{sessionTimeInSeconds > 0 && (
				<SessionTimeout
					countDownMinutes={WarningVisibleMinutes}
					showModal={showSessionTimeOut}
					logout={() => {
						setShowSessionTimeOut(false);
						setShowLoggedOut(true);
					}}
					onStayAlive={onStayAlive}></SessionTimeout>
			)}

			<LoggedOut
				logout={() => {
					updateIsSessionTimedOut(true);
					logout();
				}}
				showModal={showLoggedOut}></LoggedOut>
		</>
	);
};

export default LoggedInLayout;
