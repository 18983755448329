import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';
import LoggedInUsers from '../logged-in-users';
import LoggedOutUsers from '../logged-out-users';
import { Toaster } from 'src/modules/notification/ute-notifier';
import { ACCESS_TOKEN_STORAGE_NAME } from 'src/helper/constants';
import { validateSubDomain } from 'src/modules/common/common-api';
import { IUTESubDomainResponse } from 'src/modules/common/common-types';
import OneHubDomainPage from '../onehub-domain-page';
import LoadingOverlay from 'react-loading-overlay';
import Loader from 'src/components/loader';
import { TelemetryLogger } from 'src/logger/telemetry-logger';
import { ILogger } from 'src/logger/logger';

export let logger: ILogger;

const UTEApplication = () => {
	const {
		authToken,
		updateCompanyId,
		companyId,
		userDetail: { id: registrationId },
	} = useContext(AuthContext);
	const subDomainLink = '-subdomainlink';
	const tokenInLocalStorage = localStorage.getItem(ACCESS_TOKEN_STORAGE_NAME);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const url = window.location.origin;
		validateSubDomain(
			url,
			(companyDomainInfo: IUTESubDomainResponse) => {
				const newSubDomainLink = localStorage.getItem(companyId + subDomainLink);
				if (
					companyDomainInfo.companyId > 0 &&
					newSubDomainLink === null &&
					companyDomainInfo.url &&
					companyDomainInfo.url != ''
				) {
					localStorage.setItem(companyId + subDomainLink, companyDomainInfo.url);
					window.location.href = companyDomainInfo.url;
					return;
				}
				localStorage.removeItem(companyId + subDomainLink);
				updateCompanyId(companyDomainInfo.companyId);
				setIsLoading(false);
			},
			() => {
				setIsLoading(false);
			},
		);
	}, []);

	useEffect(() => {
		if (authToken) logger = TelemetryLogger.getInstance();
	}, [authToken]);

	return (
		<>
			{isLoading ? (
				<>
					<LoadingOverlay
						active={isLoading}
						spinner={<Loader />}
						text=''>
						<div style={{ width: '100vw', height: '100vh' }}></div>
					</LoadingOverlay>
				</>
			) : companyId > 0 ? (
				authToken || tokenInLocalStorage ? (
					<LoggedInUsers />
				) : (
					<LoggedOutUsers />
				)
			) : (
				<OneHubDomainPage />
			)}

			<Toaster />
		</>
	);
};

export default UTEApplication;
