export const DownloadIcon: React.FC = () => {
	return (
		<svg
			width='16'
			height='15'
			viewBox='0 0 16 15'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M0.499649 9.39902C0.632257 9.39902 0.759434 9.4517 0.853202 9.54547C0.946971 9.63924 0.999649 9.76641 0.999649 9.89902V12.399C0.999649 12.6642 1.10501 12.9186 1.29254 13.1061C1.48008 13.2937 1.73443 13.399 1.99965 13.399H13.9996C14.2649 13.399 14.5192 13.2937 14.7068 13.1061C14.8943 12.9186 14.9996 12.6642 14.9996 12.399V9.89902C14.9996 9.76641 15.0523 9.63924 15.1461 9.54547C15.2399 9.4517 15.367 9.39902 15.4996 9.39902C15.6323 9.39902 15.7594 9.4517 15.8532 9.54547C15.947 9.63924 15.9996 9.76641 15.9996 9.89902V12.399C15.9996 12.9295 15.7889 13.4382 15.4139 13.8132C15.0388 14.1883 14.5301 14.399 13.9996 14.399H1.99965C1.46922 14.399 0.960508 14.1883 0.585436 13.8132C0.210363 13.4382 -0.000350952 12.9295 -0.000350952 12.399V9.89902C-0.000350952 9.76641 0.0523275 9.63924 0.146096 9.54547C0.239864 9.4517 0.367041 9.39902 0.499649 9.39902Z'
				fill='#0973BA'
			/>
			<path
				d='M7.64565 11.353C7.69209 11.3996 7.74727 11.4365 7.80802 11.4617C7.86876 11.4869 7.93388 11.4999 7.99965 11.4999C8.06542 11.4999 8.13054 11.4869 8.19128 11.4617C8.25203 11.4365 8.3072 11.3996 8.35365 11.353L11.3536 8.35302C11.4475 8.25914 11.5003 8.1318 11.5003 7.99902C11.5003 7.86625 11.4475 7.73891 11.3536 7.64502C11.2598 7.55114 11.1324 7.49839 10.9996 7.49839C10.8669 7.49839 10.7395 7.55114 10.6456 7.64502L8.49965 9.79202V0.999023C8.49965 0.866415 8.44697 0.739238 8.3532 0.64547C8.25943 0.551702 8.13226 0.499023 7.99965 0.499023C7.86704 0.499023 7.73986 0.551702 7.6461 0.64547C7.55233 0.739238 7.49965 0.866415 7.49965 0.999023V9.79202L5.35365 7.64502C5.25976 7.55114 5.13242 7.49839 4.99965 7.49839C4.86687 7.49839 4.73954 7.55114 4.64565 7.64502C4.55176 7.73891 4.49902 7.86625 4.49902 7.99902C4.49902 8.1318 4.55176 8.25914 4.64565 8.35302L7.64565 11.353Z'
				fill='#0973BA'
			/>
		</svg>
	);
};
