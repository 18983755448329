import React from 'react';

const ClipboardIcon: React.FC = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='17'
			height='16'
			fill='none'
			viewBox='0 0 17 16'>
			<path
				fill='#075C95'
				d='M10.354.146A.5.5 0 0110.5.5a.5.5 0 00.5.5.5.5 0 01.5.5V2a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5v-.5A.5.5 0 016 1a.5.5 0 00.5-.5A.5.5 0 017 0h3a.5.5 0 01.354.146z'></path>
			<path
				fill='#075C95'
				d='M3.646 2.146A.5.5 0 014 2h.5a.5.5 0 100-1H4a1.5 1.5 0 00-1.5 1.5v12A1.5 1.5 0 004 16h9a1.5 1.5 0 001.5-1.5v-12A1.5 1.5 0 0013 1h-.5a.5.5 0 000 1h.5a.5.5 0 01.5.5v12a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5v-12a.5.5 0 01.146-.354z'></path>
		</svg>
	);
};

export default ClipboardIcon;
