import { useContext, useState } from 'react';
import { AuthContext } from '../../../context/ute-auth-context/auth-provider';
import { UTENotifier } from '../../notification/ute-notifier';
import { LOGIN_CONSTANTS } from '../../../helper/constants';
import ResetPinEmail from '../reset-pin-email';
import { resetAccountPin } from './reset-pin-api';
import { IResetAccountPinResponse } from './reset-pin-types';
import ResetPIN from '../reset-pin';
import { IGenerateOTPResponse, IValidateOTPResponse } from '../../login/login-component/login-component-types';
import { generateOTP, validateOTP } from '../../login/login-component/login-component-api';
import LockedOTP from '../../login/locked-otp';
import { getUserDetail } from '../../common/common-api';
import { IUserDetailResponse, OTPValidationStatus, emptyUserDetail } from '../../common/common-types';
import UTEOTPInput from 'src/components/ute-otp-input';
import { IOTPValidity, OTPValidityDefault } from 'src/components/ute-otp-input/ute-otp-input-types';

type ResetPinComponentProps = {
	setComponent: (component: string) => void;
	setResetEmail: (component: string) => void;
};
const ResetPinComponent = ({ setComponent, setResetEmail }: ResetPinComponentProps) => {
	const [emailID, setEmailID] = useState('');
	const [userDetail, setUserDetail] = useState(emptyUserDetail);
	const [emailValid, setEmailValid] = useState(false);
	const [otpValid, setOtpValid] = useState(false);
	const [usernotfound, setUserNotFound] = useState(false);
	const [otp, setOTP] = useState('');
	const [pin, setPIN] = useState('');
	const [otpValidity, setOTPValidity] = useState<IOTPValidity>(OTPValidityDefault);
	const { companyId, updateUserDetails } = useContext(AuthContext);
	const [islocked, setIsLocked] = useState<boolean>(false);
	const [isValidResetPin, setIsValidResetPin] = useState(true);

	const handleEmailIDSubmit = (callback: () => void) => {
		getUserDetail(companyId, emailID, true, (userDetailResponse: IUserDetailResponse) => {
			if (userDetailResponse.error) {
				UTENotifier.Error(LOGIN_CONSTANTS.ERROR_FETCH_USER);
				callback();
			} else {
				if (userDetailResponse.userDetail.id) {
					setUserDetail(userDetailResponse.userDetail);
					updateUserDetails(userDetailResponse.userDetail);
					generateOTPCall(companyId, emailID, userDetailResponse.userDetail.id);
					setEmailValid(true);
				} else {
					setUserNotFound(true);
					callback();
				}
			}
		});
	};
	const generateOTPCall = (companyId: number, emailID: string, userDetailId: number) => {
		generateOTP(companyId, emailID, userDetailId, (generateOTPResponse: IGenerateOTPResponse) => {
			if (generateOTPResponse.error) UTENotifier.Error(LOGIN_CONSTANTS.ERROR_SENDING_OTP);
			else UTENotifier.Success(LOGIN_CONSTANTS.OTP_SENT);
		});
	};
	const handleOTPSubmit = (callback: () => void) => {
		validateOTP(companyId, emailID, otp, userDetail.id, false, (validateOTPResponse: IValidateOTPResponse) => {
			if (validateOTPResponse.validOTPDetail.status === OTPValidationStatus.LockedByOTP) {
				setIsLocked(true);
				setOTP('');
				callback();
			} else {
				if (validateOTPResponse.validOTPDetail.status === OTPValidationStatus.Success) {
					setOtpValid(true);
					updateUserDetails(userDetail);
				} else if (validateOTPResponse.validOTPDetail.status === OTPValidationStatus.OTPExpired) {
					UTENotifier.Error(LOGIN_CONSTANTS.OTP_EXPIRED);
					setOTP('');
					callback();
				} else if (validateOTPResponse.validOTPDetail.status === OTPValidationStatus.InvalidOTP) {
					setOTPValidity({
						isValid: false,
						message: validateOTPResponse.validOTPDetail.errorDescription,
					});
					setOTP('');
					callback();
				}
			}
		});
	};
	const handleResendCode = () => {
		generateOTP(companyId, emailID, userDetail.id, (generateOTPResponse: IGenerateOTPResponse) => {
			if (generateOTPResponse.error) {
				UTENotifier.Error(LOGIN_CONSTANTS.ERROR_SENDING_OTP);
			} else {
				UTENotifier.Success(LOGIN_CONSTANTS.OTP_SENT);
			}
		});
	};
	const handlePINSubmit = () => {
		resetAccountPin(
			companyId,
			userDetail.id,
			userDetail.emailAddress,
			pin,
			(pinResetResponse: IResetAccountPinResponse) => {
				if (pinResetResponse.error) {
					UTENotifier.Error(LOGIN_CONSTANTS.ERROR_RESET_PIN);
				} else {
					if (pinResetResponse.isPinResetted) {
						setResetEmail(userDetail.emailAddress);
						setComponent('login');
						UTENotifier.Success(LOGIN_CONSTANTS.SUCCESS_RESET_PIN);
					} else if (!pinResetResponse.isPinResetted && !pinResetResponse?.isValidPIN) {
						setIsValidResetPin(false);
					} else {
						UTENotifier.Error(LOGIN_CONSTANTS.ERROR_RESET_PIN);
					}
				}
			},
		);
	};
	return (
		<>
			{islocked ? (
				<LockedOTP />
			) : (
				<>
					{userDetail.id === -1 && !emailValid && !otpValid && (
						<ResetPinEmail
							emailID={emailID}
							setEmailID={setEmailID}
							handleEmailIDSubmit={handleEmailIDSubmit}
							usernotfound={usernotfound}
						/>
					)}
					{userDetail.id !== -1 && emailValid && !otpValid && (
						<UTEOTPInput
							otpLength={8}
							otp={otp}
							setOTP={setOTP}
							otpValidity={otpValidity}
							setOTPValidity={setOTPValidity}
							handleOTPSubmit={handleOTPSubmit}
							handleResendCode={handleResendCode}
						/>
					)}
					{userDetail.id !== -1 && emailValid && otpValid && (
						<ResetPIN
							pin={pin}
							setPIN={setPIN}
							handlePINSubmit={handlePINSubmit}
							isValidResetPin={isValidResetPin}
							setIsValidResetPin={setIsValidResetPin}
						/>
					)}
				</>
			)}
		</>
	);
};

export default ResetPinComponent;
