import * as React from 'react';
import { useEffect } from 'react';
import PdfViewer from 'awesome-pdf-viewer';
import Main from 'awesome-pdf-viewer/dist/layout/Main';
import Zoom from 'awesome-pdf-viewer/dist/toolbar/Zoom';
import Header from 'awesome-pdf-viewer/dist/layout/Header';
import Toolbar from 'awesome-pdf-viewer/dist/toolbar/Toolbar';
import ViewPanel from 'awesome-pdf-viewer/dist/layout/ViewPanel';
import Pagination from 'awesome-pdf-viewer/dist/toolbar/Pagination';
import { PdfSource } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import DocViewer, {
	BMPRenderer,
	JPGRenderer,
	MSDocRenderer,
	PNGRenderer,
} from '@cyntler/react-doc-viewer';
import '../../../../components/pdf-preview/pdf-preview.style.scss';
import { getFileExtension } from 'src/helper/utils';
import { Preview_SupportedFileExtensions, UnsupportedFileMessage } from 'src/helper/constants';
import { DownloadIcon } from 'src/assets/svg/download-icon';
import UTEClientViewWrapper from 'src/components/ute-client-view-wrapper';
import UTEButton from 'src/components/ute-button';
import { DisplayDownloadFile } from 'src/helper/display-download-file';
import { ClientHubEvents, IClientTracking } from 'src/modules/common/common-types';
import { saveClientTrackingEvent } from 'src/modules/common/common-api';
import { DownloadComponent } from './gtr-preview-modal';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';

interface FileViewerProps {
	url: string;
	fileName: string;
}

export const FileViewer: React.FC<FileViewerProps> = (props) => {
	let _viewPanel: any;
	let _toolbar: any;

	const { userDetail } = React.useContext(AuthContext);

	useEffect(() => {
		setToolbarReferences();
	}, [props.url]);

	const setToolbarReferences = () => {
		_toolbar && _toolbar.setViewerReference(_viewPanel);
		_viewPanel && _viewPanel.setToolbarReference(_toolbar);
	};
	const isSupportedFile = (fileName: string): boolean => {
		const ext = getFileExtension(fileName);
		return Preview_SupportedFileExtensions.includes(ext);
	};
	const NoRenderer = ({ }: any) => {
		return <div className='p-3'>No Renderer for: {getFileExtension(props.fileName)}</div>;
	};
	const onFileDownload = () => {
		let displayDownloadFile = new DisplayDownloadFile();
		displayDownloadFile.directDownloadWithFileName(props.url, props.fileName);
		let clientTracking: IClientTracking = {
			eventId: ClientHubEvents.DocumentDownloadedFromView,
			eventData: {
				emailId: userDetail.emailAddress,
				fileName: props.fileName,
				newEmailId: '',
				oldEmailId: '',
			},
			actedBy: userDetail.emailAddress,
			registrationId: userDetail.id,
		};
		saveClientTrackingEvent(clientTracking);
	};

	const DownloadButton = () => {
		return <UTEClientViewWrapper dataTestAuto='19d3e87e-8d97-4530-a126-c630694e2290'>
			<UTEButton
				className='download-btn'
				dataTestAuto='F48A59BC-957B-4DB5-8E8B-08071C2A207F'
				disabled={false}
				text='Download'
				title='Download'
				icon={<DownloadIcon />}
				onClick={() => {
					onFileDownload();
				}}
			/>
		</UTEClientViewWrapper>
	}
	// @ts-ignore
	return !isSupportedFile(props.fileName) ? (
		// @ts-ignore
		<PdfViewer>
			<Header>
				{/* @ts-ignore */}
				<Toolbar
					ref={(ref: any) => (_toolbar = ref)}
					hideLeftPanel
					hideRightPanel>
					<Pagination />
					<p>
						<i className='arrow-down'></i>
					</p>
					<Zoom />
					<DownloadButton />
				</Toolbar>
			</Header>
			<Main>
				{/* @ts-ignore */}
				{getFileExtension(props.fileName).toLowerCase() !== 'pdf' ? (
					<>
						<div className='page-viewer'>
							<div className='non-supported-file-container'>
								{UnsupportedFileMessage}
							</div>
						</div>
					</>
				) : (
					<ViewPanel
						ref={(ref: any) => (_viewPanel = ref)}
						pdfSource={PdfSource.createFromUrl(props.url)}></ViewPanel>
				)}
			</Main>
		</PdfViewer>
	) : (
		props.url !== '' && <DocViewer
			pluginRenderers={[MSDocRenderer, JPGRenderer, PNGRenderer, BMPRenderer]}
			documents={[
				{
					uri: props.url,
					fileName: props.fileName,
					fileType: getFileExtension(props.fileName),
				},
			]}
			config={{
				header: {
					overrideComponent: DownloadComponent,
				},
				noRenderer: {
					overrideComponent: NoRenderer,
				},
			}}
			style={{ height: 500 }}
		/>
	);
};

export default FileViewer;