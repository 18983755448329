import LoginLayout from '../../modules/layout/login-layout';
import DisabledContainer from '../../modules/login/disabled';

const Disabled = () => {
	return (
		<LoginLayout>
			<DisabledContainer />
		</LoginLayout>
	);
};

export default Disabled;
