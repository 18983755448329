import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import './ute-notifier.scss';
import { DangerIcon, SuccessIcon, ToasterCloseIcon, WarningIcon } from 'src/assets/svg/ute-notifier-icons';

export class UTENotifier extends React.Component {
	static Error = (message: string) =>
		toast.error(
			<div className='toaster-icon-message'>
				<DangerIcon />
				<div className='toaster-message'>{message}</div>
			</div>,
			{
				position: toast.POSITION.TOP_RIGHT,
				className: 'toaster-error',
				closeButton: <ToasterCloseIcon />,
			},
		);

	static Warning = (message: string) =>
		toast.warn(
			<div className='toaster-icon-message'>
				<WarningIcon />
				<div className='toaster-message'>{message}</div>
			</div>,
			{
				position: toast.POSITION.TOP_RIGHT,
				className: 'toaster-warning',
				closeButton: <ToasterCloseIcon />,
			},
		);

	static Success = (message: string) =>
		toast.success(
			<div className='toaster-icon-message'>
				<SuccessIcon />
				<div className='toaster-message'>{message}</div>
			</div>,
			{
				position: toast.POSITION.TOP_RIGHT,
				className: 'toaster-success',
				closeButton: <ToasterCloseIcon />,
			},
		);
}

export const Toaster: React.FC = () => {
	return (
		<div className='toaster_container'>
			<ToastContainer
				autoClose={5000}
				className='toaster'
				position='top-right'
				hideProgressBar={true}
				newestOnTop={true}
				closeOnClick={true}
				rtl={false}
				pauseOnHover
			/>
		</div>
	);
};
