import React from 'react';
import styles from '../dashboard.module.scss';
import MFJIcon from 'src/assets/svg/mfj-icon';
import DelegatedIcon from 'src/assets/svg/delegated-icon';
import ClockHistoryIcon from 'src/assets/svg/clock-history-icon';
import { ButtonComponent } from 'cp-common-ui-components';
import { IPendingTaxReturn } from '../dashboard-types';
import { getIconWidth } from 'src/helper/utils';
import { DASHBOARD_LISTING_CONSTANTS, SignatureStatus } from 'src/helper/constants';
import { WaitingForActionHeaders } from '../utils/constants';
import { ProductType } from 'src/modules/common/common-types';
import { SSRSignatureStatus } from 'src/modules/documents/documents-types';

interface IReviewPendingTasks {
	productType: ProductType;
	pendingTasks: IPendingTaxReturn[];
	tempPendingTasks: IPendingTaxReturn[];
	setTempPendingTasks: (tasks: IPendingTaxReturn[]) => void;
	handleOpenTaxreturn: (task: IPendingTaxReturn) => void;
}

export const ReviewPendingTasks: React.FC<IReviewPendingTasks> = ({
	productType,
	pendingTasks,
	tempPendingTasks,
	setTempPendingTasks,
	handleOpenTaxreturn,
}) => {
	const showMoreTasks = () => {
		setTempPendingTasks(pendingTasks);
	};

	const showLessTasks = () => {
		setTempPendingTasks(pendingTasks.slice(0, 3));
	};

	const isShowMoreActive = () => {
		return pendingTasks.length === tempPendingTasks.length;
	};

	const getTitleForMFJ = (task: IPendingTaxReturn) => {
		if (productType === ProductType.Returns) {
			const title = task.signatureStatus == SSRSignatureStatus.AwaitingDraftReview 
    		? "review your draft tax return." 
    		: "sign your tax return.";
			return task.spouseName !== null
				? `${task.spouseName}, ${title}`
				: `${task.name}, ${title}`;
		} else if (productType === ProductType.Extensions) {
			const { ESigned, AwaitingReview, PartiallyReviewed } = SignatureStatus;

			const name = task.spouseName !== null ? task.spouseName : task.name;
			return [ESigned, AwaitingReview, PartiallyReviewed].includes(task.signatureStatus)
				? `${name}, review your tax return extension.`
				: `${name}, sign your tax return extension.`;
		} else if (productType === ProductType.Gather) {
			return `${task.spouseName ?? task.name}, please complete your gather request.`;
		} else if (productType === ProductType.Organizers) {
			return `${task.spouseName ?? task.name}, please complete your organizer request.`;
		}
	};

	const getTaskName = (task: IPendingTaxReturn) => {
		if (productType === ProductType.Returns) {
			return `${task.name} ${task.taxyear} Tax Return`;
		} else if (productType === ProductType.Extensions) {
			return `${task.name} ${task.taxyear} Tax Return Extensions`;
		} else if (productType === ProductType.Gather) {
			return `${task.spouseName ?? task.name} ${task.taxyear} Gather Request`;
		} else if (productType === ProductType.Organizers) {
			return `${task.spouseName ?? task.name} ${task.taxyear} Tax Organizer`;
		}
	};

	const backToTop = () => {
		const sectionBlock = document.getElementById('section-block');
		if (sectionBlock) {
			sectionBlock.scrollTop = 0;
		}
	};

	return (
		<div className={styles.actionSubCategory}>
			<div className={styles.actionSubCategoryHeader}>
				<span>{WaitingForActionHeaders[ProductType[productType]]}</span>
				<span className={styles.count}>{pendingTasks.length}</span>
			</div>
			<div className={styles.actionSubCategoryTable}>
				<table className={`table table-striped table-hover ${styles.customTableStyles}`}>
					<tbody>
						{tempPendingTasks.map((task: IPendingTaxReturn, index: number) => {
							return (
								<tr key={task.clientGuid}>
									<td className={styles.ellipsisText}>
										<span
											style={{ maxWidth: `calc(100% - ${getIconWidth(task)}px)` }}
											className={`${styles.text} ${styles.ellipsis}`}
											title={getTaskName(task)}>
											{getTaskName(task)}
										</span>
										{task.isMFJ && (
											<div className={styles.mfjWrapper}>
												<MFJIcon
													hoverText={getTitleForMFJ(task)}
													className={styles.mfjIcon}
												/>
											</div>
										)}
										{task.isDelegated && (
											<span className={styles.pillShapeDelegation}>
												<DelegatedIcon className={styles.delegatedIcon} /> Delegated
											</span>
										)}
										{task.isReminderSent && (
											<span className={styles.pillShapeReminder}>
												<ClockHistoryIcon className={styles.dueSoon} /> Due Soon
											</span>
										)}
									</td>
									<td>
										<ButtonComponent
											data-test-auto={`2e08330c-5b5c-4cb3-a90e-548e1fb4e${index.toString().padStart(3, '0')}`}
											onClick={() => handleOpenTaxreturn(task)}
											variant={'outline-primary'}
											size={'small'}
											buttonClassName={styles.pendingTasksBtn}>
											{task.isVisited ? 'Continue' : 'Get Started'}
										</ButtonComponent>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				{pendingTasks.length > 3 ? (
					<>
						<div
							className={styles.seeMore}
							onClick={isShowMoreActive() ? showLessTasks : showMoreTasks}>
							{isShowMoreActive() ? 'See less' : 'See more'}
						</div>
						{isShowMoreActive() ? (
							<div className={styles.backToTopContainer}>
								<div
									className={styles.backToTop}
									onClick={backToTop}>
									{DASHBOARD_LISTING_CONSTANTS.BACK_TO_TOP}
								</div>
								<span className={styles.upperArrow}>&uarr;</span>
							</div>
						) : (
							<></>
						)}
					</>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};
