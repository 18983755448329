import React from 'react';

const ClockIcon: React.FC = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='40'
			height='40'
			fill='none'
			viewBox='0 0 40 40'>
			<g
				stroke='#A6A9AC'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.466'
				clipPath='url(#clip0_4206_19713)'>
				<path d='M4.83 21.6a15.17 15.17 0 1030.34 0 15.17 15.17 0 00-30.34 0zM11.46 34.13l-4.236 4.235M28.536 34.127l4.24 4.238'></path>
				<path d='M14.411 22.398H20.8v-9.581M13.345 4.498a6.376 6.376 0 10-9.484 8.335M26.656 4.497a6.378 6.378 0 119.484 8.336'></path>
			</g>
			<defs>
				<clipPath id='clip0_4206_19713'>
					<path
						fill='#fff'
						d='M0 0H40V40H0z'></path>
				</clipPath>
			</defs>
		</svg>
	);
};

export default ClockIcon;
