import React, { useEffect, useState } from 'react';
import AuthProvider from './context/ute-auth-context/auth-provider';
import NotificationProvider from './context/ute-notification-context/notification-provider';
import UTEApplication from './modules/routes/ute-application';
import MobileDefaultPage from './pages/mobile-default-page';
import { isMobile, isTablet } from 'react-device-detect';
import './app-common.scss';

function App() {
	const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

	const checkDeviceType = () => {
		const screenWidth = window.innerWidth;
		setIsMobileOrTablet(screenWidth <= 820);
	};

	useEffect(() => {
		checkDeviceType();
		window.addEventListener('resize', checkDeviceType);

		return () => {
			window.removeEventListener('resize', checkDeviceType);
		};
	}, []);

	const application = (
		<AuthProvider>
			<NotificationProvider>
				<UTEApplication />
			</NotificationProvider>
		</AuthProvider>
	);
	if (isTablet || isMobile || isMobileOrTablet) {
		return <MobileDefaultPage />;
	} else return application;
}

export default App;
