export const DisabledIcon: React.FC = () => {
	return (
		<svg
			width='92'
			height='86'
			viewBox='0 0 92 86'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M15.8857 11.2422C15.4116 11.2422 15.0273 10.8579 15.0273 10.3838C15.0273 9.9098 15.4116 9.52551 15.8857 9.52551'
				stroke='#B5D5EA'
				strokeWidth='3.60125'
			/>
			<path
				d='M15.8857 11.2422C16.3598 11.2422 16.7441 10.8579 16.7441 10.3838C16.7441 9.9098 16.3598 9.52551 15.8857 9.52551'
				stroke='#B5D5EA'
				strokeWidth='3.60125'
			/>
			<path
				d='M22.7524 11.2422C22.2783 11.2422 21.894 10.8579 21.894 10.3838C21.894 9.9098 22.2783 9.52551 22.7524 9.52551'
				stroke='#B5D5EA'
				strokeWidth='3.60125'
			/>
			<path
				d='M22.7524 11.2422C23.2265 11.2422 23.6108 10.8579 23.6108 10.3838C23.6108 9.9098 23.2265 9.52551 22.7524 9.52551'
				stroke='#B5D5EA'
				strokeWidth='3.60125'
			/>
			<path
				d='M29.6191 11.2422C29.145 11.2422 28.7607 10.8579 28.7607 10.3838C28.7607 9.9098 29.145 9.52551 29.6191 9.52551'
				stroke='#B5D5EA'
				strokeWidth='3.60125'
			/>
			<path
				d='M29.6191 11.2422C30.0932 11.2422 30.4775 10.8579 30.4775 10.3838C30.4775 9.9098 30.0932 9.52551 29.6191 9.52551'
				stroke='#B5D5EA'
				strokeWidth='3.60125'
			/>
			<path
				d='M88.4828 61.883C88.4828 65.9573 87.2746 69.9399 85.0111 73.3275C82.7475 76.7151 79.5305 79.3557 75.7663 80.9147C72.002 82.4738 67.8601 82.8817 63.8644 82.0868C59.8684 81.292 56.1979 79.3303 53.3171 76.4491C50.4359 73.5682 48.4741 69.8977 47.6793 65.9017C46.8845 61.906 47.2923 57.7641 48.8514 53.9999C50.4108 50.2356 53.051 47.0186 56.4386 44.7551C59.8262 42.4915 63.8088 41.2833 67.8831 41.2833C73.3465 41.2833 78.586 43.4535 82.4491 47.317C86.3126 51.1801 88.4828 56.4196 88.4828 61.883Z'
				fill='#B5D5EA'
				stroke='#B5D5EA'
				strokeWidth='3.60125'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M82.4828 61.883C82.4828 65.9573 81.2746 69.9399 79.0111 73.3275C76.7475 76.7151 73.5305 79.3557 69.7663 80.9147C66.002 82.4738 61.8601 82.8817 57.8644 82.0868C53.8684 81.292 50.1979 79.3303 47.3171 76.4491C44.4359 73.5682 42.4741 69.8977 41.6793 65.9017C40.8845 61.906 41.2923 57.7641 42.8514 53.9999C44.4108 50.2356 47.051 47.0186 50.4386 44.7551C53.8262 42.4915 57.8088 41.2833 61.8831 41.2833C67.3465 41.2833 72.586 43.4535 76.4491 47.317C80.3126 51.1801 82.4828 56.4196 82.4828 61.883Z'
				stroke='#0973BA'
				strokeWidth='3.60125'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M69.1616 54.6041L54.6045 69.1956'
				stroke='#0973BA'
				strokeWidth='3.60125'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M69.1616 69.1956L54.6045 54.6041'
				stroke='#0973BA'
				strokeWidth='3.60125'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M3.58594 17.2504H72.2516'
				stroke='#0973BA'
				strokeWidth='3.60125'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M31.0522 58.4497H10.4525C8.63139 58.4497 6.88484 57.7263 5.59712 56.4385C4.30938 55.1507 3.58594 53.4042 3.58594 51.5831V10.3838C3.58594 8.56266 4.30938 6.81611 5.59712 5.52839C6.88484 4.24065 8.63139 3.51721 10.4525 3.51721H65.385C67.206 3.51721 68.9525 4.24065 70.2403 5.52839C71.5282 6.81611 72.2516 8.56266 72.2516 10.3838V30.9835'
				stroke='#0973BA'
				strokeWidth='3.60125'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M13.8857 11.2422C13.4116 11.2422 13.0273 10.8579 13.0273 10.3838C13.0273 9.9098 13.4116 9.52551 13.8857 9.52551'
				stroke='#0973BA'
				strokeWidth='3.60125'
			/>
			<path
				d='M13.8857 11.2422C14.3598 11.2422 14.7441 10.8579 14.7441 10.3838C14.7441 9.9098 14.3598 9.52551 13.8857 9.52551'
				stroke='#0973BA'
				strokeWidth='3.60125'
			/>
			<path
				d='M20.7524 11.2422C20.2783 11.2422 19.894 10.8579 19.894 10.3838C19.894 9.9098 20.2783 9.52551 20.7524 9.52551'
				stroke='#0973BA'
				strokeWidth='3.60125'
			/>
			<path
				d='M20.7524 11.2422C21.2265 11.2422 21.6108 10.8579 21.6108 10.3838C21.6108 9.9098 21.2265 9.52551 20.7524 9.52551'
				stroke='#0973BA'
				strokeWidth='3.60125'
			/>
			<path
				d='M27.6191 11.2422C27.145 11.2422 26.7607 10.8579 26.7607 10.3838C26.7607 9.9098 27.145 9.52551 27.6191 9.52551'
				stroke='#0973BA'
				strokeWidth='3.60125'
			/>
			<path
				d='M27.6191 11.2422C28.0932 11.2422 28.4775 10.8579 28.4775 10.3838C28.4775 9.9098 28.0932 9.52551 27.6191 9.52551'
				stroke='#0973BA'
				strokeWidth='3.60125'
			/>
		</svg>
	);
};