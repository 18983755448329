import axios, { AxiosResponse } from 'axios';
import { IResetAccountPinResponse } from './reset-pin-types';
import { API_BASE_URL } from '../../../helper/constants';
import { apiCatchHandler } from 'src/helper/utils';

export function resetAccountPin(
	companyId: number,
	registrationId: number,
	emailId: string,
	pin: string,
	callback: (pinResetResponse: IResetAccountPinResponse) => void,
) {
	const pinResetResponse: IResetAccountPinResponse = {
		error: false,
		isPinResetted: false,
		isValidPIN: false,
	};
	axios
		.post(
			`${API_BASE_URL}/api/pin/reset/${companyId}?registrationId=${registrationId}&emailId=${emailId}&pin=${pin}`,
			'',
			{
				withCredentials: true,
			},
		)
		.then((response: AxiosResponse<IResetAccountPinResponse>) => {
			callback(response.data);
		})
		.catch((error) => {
			pinResetResponse.error = true;
			apiCatchHandler(error, pinResetResponse, callback);
		});
}
