import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from 'src/helper/constants';
import { IRecentActivity, IRecentActivityResponse, IUserActivity } from './recent-activity-types';
import { apiCatchHandler } from 'src/helper/utils';

export const getRecentActivityList = (callback: (recentActivityResponse: IRecentActivityResponse) => void) => {
	const recentActivityResponse: IRecentActivityResponse = {
		recentActivityList: [],
		error: false,
	};
	axios
		.get(`${API_BASE_URL}/api/activitylog/get-user-activities`)
		.then((response: AxiosResponse<IRecentActivity[]>) => {
			const { data } = response;
			recentActivityResponse.recentActivityList = data;
			callback(recentActivityResponse);
		})
		.catch((error) => {
			recentActivityResponse.error = true;
			apiCatchHandler(error, recentActivityResponse, callback);
		});
};

export const saveUserActivity = (userActivity: IUserActivity) => {
	axios
		.post(`${API_BASE_URL}/api/activitylog/save-user-activities`, userActivity)
		.then((response: AxiosResponse<boolean>) => {
			
		})
		.catch((error) => {
			
		});
};