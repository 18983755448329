import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal, Overlay, Popover } from 'react-bootstrap';
import './forward-taxdocument.scss';
import PhoneInput from '@sssuite-component-ui/react-phone-input-2';
import {
	CCRecipientDocumentGroups,
	ICCRecipientDownloadableDocuments,
	ICCRecipientModel,
	IRecipient,
} from './forward-taxdocument-types';
import { FORWARD_TAXDOCUMENT_CONSTANTS } from '../../helper/constants';
import { isValidEmailAddress, nullAndEmptyCheck, validateCountryCode, validatePhone } from '../../helper/utils';
import AddIcon from '../../assets/svg/add-icon';
import DeleteIcon from '../../assets/svg/delete-icon';
import { UTENotifier } from '../notification/ute-notifier';
import { Guid } from '../../helper/guid';
import { AuthContext, logger } from '../../context/ute-auth-context/auth-provider';
import { forwardDocumentsToRecipients } from './forward-taxdocument-api';
import MessageNote from '../message-note/message-note';
import { NotificationType } from '../common/common-types';
import CloseIcon from '../../assets/svg/close-icon';
import '@sssuite-component-ui/react-phone-input-2/lib/style.css';
import { ButtonComponent } from 'cp-common-ui-components';
import { ITaxDocument } from '../documents/documents-types';
import LoadingOverlay from 'react-loading-overlay';
import Loader from 'src/components/loader';
import UTEClientViewWrapper from 'src/components/ute-client-view-wrapper';
import { CountryData } from './country-data';
var htmlencode = require('htmlencode');

type ForwardTaxDocumentProps = {
	closeCCRecipientModal: () => void;
	downloadableDocuments: ICCRecipientDownloadableDocuments[];
	encodedClientId: string;
	taxDocument: ITaxDocument;
	show: boolean;
	isDocumentLoading: boolean;
};

const ForwardTaxDocumentContainer = ({
	closeCCRecipientModal,
	downloadableDocuments,
	taxDocument,
	show,
	encodedClientId,
	isDocumentLoading,
}: ForwardTaxDocumentProps) => {
	const [ccRecipients, setRecipients] = useState<IRecipient[]>([
		{ clientGuid: Guid.newGuid(), email: '', mobileNumber: '', countryCode: '' },
	]);
	const [documents, setDocuments] = useState<number[]>([]);
	const [selectAll, setSelectAll] = useState<boolean>(false);
	const [showPopOver, setShowPopOver] = useState<boolean>(false);
	const popoverTarget = useRef<HTMLElement>(null);
	const { userDetail } = useContext(AuthContext);
	let attachAddPopoverRef: any;

	useEffect(() => {}, [ccRecipients, show, selectAll]);

	useEffect(() => {}, [showPopOver, isDocumentLoading]);

	const onEmailChange = (e: any, index: number) => {
		const recipientEmail = e.target.value;
		setRecipients((prevRecipients) => {
			const updatedRecipients = [...prevRecipients];
			updatedRecipients[index] = {
				...updatedRecipients[index],
				email: recipientEmail,
			};
			return updatedRecipients;
		});
	};

	const onChangeCountryCode = (value: string, countryObject: CountryData, index: number) => {
		const recipientCountryCode = `+${countryObject.dialCode}`;
		setRecipients((prevRecipients) => {
			const updatedRecipients = [...prevRecipients];
			updatedRecipients[index] = {
				...updatedRecipients[index],
				countryCode: recipientCountryCode,
				mobileNumber: value.slice(countryObject.dialCode.length),
			};
			return updatedRecipients;
		});
	};

	const onEmailInputKeyDown = (e: any, idx: number) => {
		if (e.keyCode === 13) {
			e.preventDefault();
			if (nullAndEmptyCheck(ccRecipients[idx].email)) {
				if (!isValidEmailAddress(ccRecipients[idx].email)) {
					UTENotifier.Warning(FORWARD_TAXDOCUMENT_CONSTANTS.INVALID_EMAIL_ADDRESS_WARNING);
					return;
				}
				getCountryCodeInputFocus(idx);
			} else {
				UTENotifier.Warning(FORWARD_TAXDOCUMENT_CONSTANTS.BLANK_EMAIL_ADDRESS_WARNING);
				return;
			}
		}
	};

	const handleDelete = (idx: number) => {
		if (idx > 0) {
			setRecipients(ccRecipients.filter((input, index) => idx !== index));
		} else {
			let recipients = ccRecipients;
			recipients[idx].mobileNumber = '';
			recipients[idx].email = '';
			recipients[idx].countryCode = '+1';
			setRecipients((prevRecipients) => {
				const updatedRecipients = [...prevRecipients];
				updatedRecipients[idx] = recipients[idx];
				return updatedRecipients;
			});
		}
	};

	const handleCancel = () => {
		clearSections();
		closeCCRecipientModal();
	};

	const saveDocumentsToShow = (event: any, documentGroup: CCRecipientDocumentGroups) => {
		if (event.target.checked) {
			let documentGroups = [...documents, documentGroup];
			setDocuments(documentGroups);
		} else {
			setDocuments(documents.filter((groupId, index) => groupId !== documentGroup));
		}
	};

	const handleSelectAll = (event: any) => {
		if (event.target.checked) {
			let documentGroups: number[] = [];
			downloadableDocuments &&
				downloadableDocuments.map((document, i) => {
					documentGroups.push(document.documentGroup);
				});
			setDocuments(documentGroups);
			setSelectAll(true);
		} else {
			setDocuments([]);
			setSelectAll(false);
		}
	};

	const mailDocumentsToRecipients = () => {
		if (!ccRecipients.every((x) => x.email != '')) {
			UTENotifier.Warning(FORWARD_TAXDOCUMENT_CONSTANTS.EMTPY_RECIPIENT_WARNING);
			return;
		} else if (documents.length == 0) {
			UTENotifier.Warning(FORWARD_TAXDOCUMENT_CONSTANTS.DOCUMENTS_SELECTION_EMPTY_WARNING);
			return;
		}

		if (
			!ccRecipients
				.map((recipient, index) => {
					return isValidEmailAddress(recipient.email);
				})
				.every((x: boolean) => x === true)
		) {
			UTENotifier.Warning(FORWARD_TAXDOCUMENT_CONSTANTS.INVALID_EMAIL_ADDRESS_WARNING);
			return;
		}
		if (
			!ccRecipients
				.map((recipient, index) => {
					if (recipient.mobileNumber.trim() !== '') {
						if (!validateCountryCode(recipient.countryCode) || !validatePhone(recipient.mobileNumber)) {
							return false;
						}
					}
					return true;
				})
				.every((x: boolean) => x === true)
		) {
			return;
		}

		let recipientInfo: ICCRecipientModel = { documentId: 0, downloadableDocuments: [], recipients: [] };
		recipientInfo.documentId = taxDocument.documentId;
		recipientInfo.recipients = ccRecipients;
		recipientInfo.downloadableDocuments = documents;
		forwardDocumentsToRecipients(
			encodedClientId,
			recipientInfo,
			taxDocument.signerName,
			taxDocument.taxYear,
			taxDocument.productType,
			userDetail.emailAddress,
			() => {
				onSuccess();
				logger.trackTrace('Documents forwarded to cc recipient', {
					ClientId: taxDocument.clientId,
					DocumentGroups: documents,
				});
			},
			() => {
				UTENotifier.Error(FORWARD_TAXDOCUMENT_CONSTANTS.ERROR_MESSAGE);
			},
		);
	};

	const onSuccess = () => {
		UTENotifier.Success(FORWARD_TAXDOCUMENT_CONSTANTS.SUCCESS_MESSAGE);
		confirmPopoverClose();
	};

	const getCountryCodeInputFocus = (index: number) => {
		const inputCountryCodeRef: any = document.getElementById('ccRecipientCountryCode' + index);
		const innerHtml: any =
			inputCountryCodeRef && inputCountryCodeRef.children[0].children[0].children[1].children[0].innerHTML;
		const selectorId: string = innerHtml && innerHtml.match(/react-select-\d+-input/)[0];
		const countrySelector = document.getElementById(selectorId);
		countrySelector && countrySelector.focus();
	};

	const clearSections = () => {
		setRecipients([]);
		setDocuments([]);
		setSelectAll(false);
		setRecipients([{ clientGuid: Guid.newGuid(), email: '', mobileNumber: '', countryCode: '' }]);
		setDocuments([]);
		setSelectAll(false);
	};

	const handleKeyDown = (event: any, callback: () => void, key: string = 'Enter') => {
		if (event && event.key === key) {
			event.preventDefault();
			callback();
		}
	};

	const addNewRecipientInputRow = () => {
		const recipients = [...ccRecipients, { clientGuid: Guid.newGuid(), email: '', mobileNumber: '', countryCode: '' }];
		setRecipients(recipients);
	};

	const confirmPopoverClose = () => {
		setShowPopOver(false);
		clearSections();
		closeCCRecipientModal();
	};

	const hidePopover = () => {
		setShowPopOver(false);
	};

	const deleteConfirmation = () => {
		return (
			<Popover
				className='closeCCRecipientPopover'
				placement='left'
				data-test-auto='C4ED4674-B4EA-4649-BD90-47F944980BB6'>
				<div className={'popover_header'}>
					Discard Changes
					<button
						type='button'
						className='close'
						data-test-auto='15613D09-8326-4FE6-B256-F30085B56534'
						onClick={hidePopover}>
						&times;
					</button>
				</div>
				<div className={'popover_body'}>
					<div>Are you sure you want to discard the changes made? </div>
				</div>
				<div className={'modal_footer'}>
					<ButtonComponent
						dataTestAuto='313B8870-6D87-40F8-B2C0-FEE5C2423279'
						onClick={hidePopover}
						variant={'outline-tertiary'}
						buttonClassName={'button-margin-right'}
						size={'medium'}>
						No
					</ButtonComponent>
					<ButtonComponent
						dataTestAuto='1E01E792-06DB-4361-A944-9A010FAB9EB6'
						size={'medium'}
						onClick={confirmPopoverClose}
						variant={'primary'}>
						Yes
					</ButtonComponent>
				</div>
			</Popover>
		);
	};

	const renderDownloadableDocumentDetails = (
		downloadableDocument: ICCRecipientDownloadableDocuments,
		index: number,
	) => {
		return (
			<div key={downloadableDocument.fileName + index}>
				<input
					type='checkbox'
					id={`checkbox-${index}`}
					className={'chkCcRecipientModalSelect'}
					data-test-auto={'18EF199A-D9BE-42A6-ABAB-533174ABD07' + index}
					key={downloadableDocument.fileName + downloadableDocument.documentGroup}
					checked={documents.findIndex((groupId) => groupId == downloadableDocument.documentGroup) > -1 ? true : false}
					onChange={(e: any) => saveDocumentsToShow(e, downloadableDocument.documentGroup)}
					onKeyDown={(e: any) =>
						handleKeyDown(e, () => {
							if (documents.findIndex((groupId) => groupId == downloadableDocument.documentGroup) > -1) {
								saveDocumentsToShow({ target: { checked: false } }, downloadableDocument.documentGroup);
							} else {
								saveDocumentsToShow({ target: { checked: true } }, downloadableDocument.documentGroup);
							}
						})
					}
				/>
				<label
					htmlFor={`checkbox-${index}`}
					className={'ccRecipientModalDownloadableDocument'}>
					{downloadableDocument.documentGroup === CCRecipientDocumentGroups.SignedEFile
						? 'E-file Forms'
						: downloadableDocument.documentGroup === CCRecipientDocumentGroups.TaxReturns
						? 'Tax Return'
						: downloadableDocument.fileName}
				</label>
			</div>
		);
	};

	const handleClose = () => {
		if (
			ccRecipients.some((x) => x.email != '') ||
			ccRecipients.some((x) => x.mobileNumber != '') ||
			ccRecipients.some((x) => x.countryCode != '')
		) {
			setShowPopOver(true);
			return;
		}
		clearSections();
		closeCCRecipientModal();
	};

	return (
		<Modal
			show={show}
			className={'ccRecipientModalPopup'}>
			<Modal.Header data-test-auto='01897DA0-02F5-45C6-9D29-E0C2F0C4E7C9'>
				<Modal.Title>{FORWARD_TAXDOCUMENT_CONSTANTS.MODAL_HEADER_TITLE}</Modal.Title>
				<div
					data-test-auto='75613D09-8326-4FE6-B256-F30085B56534'
					className={'closeIcon'}
					data-dismiss='modal'
					onClick={() => {
						handleClose();
					}}
					ref={attachAddPopoverRef}
					tabIndex={0}
					onKeyDown={(e: any) => handleKeyDown(e, handleClose)}>
					<CloseIcon />
				</div>
				<Overlay
					placement='left'
					data-test-auto='AE380218-167B-4BD2-A179-75BC737BCEF7'
					target={popoverTarget.current}
					show={showPopOver}
					rootClose={true}>
					{deleteConfirmation}
				</Overlay>
			</Modal.Header>
			<Modal.Body>
				<LoadingOverlay
					active={isDocumentLoading}
					spinner={<Loader />}
					text=''>
					<div style={{ paddingLeft: '16px' }}>
						<div data-test-auto='BAD25332-6C0A-46C0-88DA-6FD34DDD0F69'>
							<MessageNote
								note={FORWARD_TAXDOCUMENT_CONSTANTS.NOTE}
								noteType={NotificationType.Warning}
							/>
						</div>
						<br />
						<div
							className={'ccRecipientModalDescription'}
							dangerouslySetInnerHTML={{
								__html: htmlencode.htmlDecode(FORWARD_TAXDOCUMENT_CONSTANTS.DESCRIPTION),
							}}></div>
						<div
							data-test-auto='F366A8F2-401C-4188-9DE3-3CF62F568763'
							className={'section_header_text'}>
							<label>{FORWARD_TAXDOCUMENT_CONSTANTS.FORWARD_TAX_DOCUMENT_TEXT}</label>
						</div>
						<div className={'marTop12'}>
							<input
								type='checkbox'
								id='selectAllCheckbox'
								className={'chkCcRecipientModalSelect'}
								data-test-auto={'83A6BED4-6BF8-4D1D-BBA4-96AB8C868A3A'}
								key={'select-All'}
								checked={selectAll}
								onKeyDown={(e: any) =>
									handleKeyDown(e, () => {
										if (selectAll) {
											handleSelectAll({ target: { checked: false } });
										} else {
											handleSelectAll({ target: { checked: true } });
										}
									})
								}
								onChange={(event: any) => {
									handleSelectAll(event);
								}}
							/>
							<label
								htmlFor='selectAllCheckbox'
								className={'lblCcRecipientModalSelectAll'}>
								{' '}
								Select All
							</label>
						</div>
						<div className={'marTop12'}>
							<div className='row'>
								<div className='col-lg-6 col-md-6 col-sm-6'>
									{downloadableDocuments &&
										downloadableDocuments
											?.sort((a, b) => (a.fileName !== b.fileName ? (a.fileName < b.fileName ? -1 : 1) : 0))
											?.map((document, i) => {
												if (i <= 3) {
													return renderDownloadableDocumentDetails(document, i);
												}
											})}
								</div>

								<div className='col-lg-6 col-md-6 col-sm-6'>
									{downloadableDocuments &&
										downloadableDocuments
											?.sort((a, b) => (a.fileName !== b.fileName ? (a.fileName < b.fileName ? -1 : 1) : 0))
											?.map((document, i) => {
												if (i > 3) {
													return renderDownloadableDocumentDetails(document, i);
												}
											})}
								</div>
							</div>
						</div>
						<div className={'section_header_text'}>
							<label>Add Contact(s)</label>
						</div>
						<div style={{ display: 'flex' }}>
							<div className='labelCCRecipientEmail'>Email</div>
							<div className='labelCCRecipientMobile'>Phone</div>
						</div>
						<div className={'ccRecipientModalBodyContent'}>
							{ccRecipients.length > 0 &&
								ccRecipients.map((recipient, index) => {
									return (
										<div
											key={recipient.clientGuid.toString()}
											style={{ display: 'flex' }}>
											<div>
												<input
													id={'ccEmail' + index}
													className={'inputCCRecipientEmail'}
													aria-label='Enter cc recipient email'
													type='text'
													onChange={(event: any) => {
														onEmailChange(event, index);
													}}
													onKeyDown={(e: any) => {
														onEmailInputKeyDown(e, index);
													}}
													value={ccRecipients[index].email}
													data-lpignore='true'
													data-test-auto='FE0309F3-01BC-4517-A8FD-0F12B2A4BF9F'
													autoComplete='off'
												/>
											</div>

											<div>
												<PhoneInput
													containerClass={'inputCCRecipientMobile'}
													autoFormat={true}
													country={'us'}
													inputProps={{ 'aria-label': 'Enter cc recipient mobile number' }}
													value={`${ccRecipients[index].countryCode} ${ccRecipients[index].mobileNumber}`}
													onChange={(fullValue: string, countryObject: CountryData) => {
														onChangeCountryCode(fullValue, countryObject, index);
													}}
												/>
											</div>
											<UTEClientViewWrapper dataTestAuto='2e0b4948-6de9-4994-bda9-f83f6dec6bed'>
												<div
													tabIndex={0}
													className={'deleteContactContainer'}
													onClick={() => {
														handleDelete(index);
													}}
													onKeyDown={(e: any) =>
														handleKeyDown(e, () => {
															handleDelete(index);
														})
													}
													data-test-auto='6944FE2C-8C70-4334-8049-454A2D7809F8'>
													<DeleteIcon color={'#CC4A43'} />
													<span>Delete</span>
												</div>
											</UTEClientViewWrapper>
										</div>
									);
								})}

							<UTEClientViewWrapper dataTestAuto='46f859e8-ae52-4362-95e8-925b6eac565e'>
								<div
									tabIndex={0}
									className={'btnAddNewCCRecipient'}
									onClick={addNewRecipientInputRow}
									onKeyDown={(e: any) => handleKeyDown(e, addNewRecipientInputRow)}
									data-test-auto='F8A14D37-AAB9-44FE-99F8-43D568E14F83'>
									<AddIcon color={'#05386B'} />
									<span>Add New Contact</span>
								</div>
							</UTEClientViewWrapper>
						</div>
					</div>
				</LoadingOverlay>
			</Modal.Body>
			<Modal.Footer>
				<ButtonComponent
					data-test-auto='2FFE3287-E2C3-4EAC-B75D-EFE26485E105'
					id='btnHideCCRecipientPopUp'
					onClick={handleCancel}
					variant={'outline-tertiary'}
					size={'medium'}
					buttonClassName={'button-margin-right'}>
					Cancel
				</ButtonComponent>
				<UTEClientViewWrapper dataTestAuto='4660129e-7731-490c-b8ff-c58f2b384c45'>
					<ButtonComponent
						data-test-auto='E927BDC4-A00E-4A67-9D5D-F5CA7CC46BBB'
						variant={'primary'}
						onClick={mailDocumentsToRecipients}
						size={'medium'}>
						Send
					</ButtonComponent>
				</UTEClientViewWrapper>
			</Modal.Footer>
		</Modal>
	);
};

export default ForwardTaxDocumentContainer;
