import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';
import axios from "axios";
import * as signalR from '@microsoft/signalr';
import { getSignalRWebsocketURL } from 'src/modules/common/common-api';
import { INotificationAlertMessage} from 'src/context/ute-notification-context/notification-provider-types';
import { NotificationContext } from 'src/context/ute-notification-context/notification-provider';

const bearerPrefix = 'Bearer ';

const SignalRWebSocket = () => {
	const [signalRBaseUrl, setSignalRBaseUrl] = useState<string>("");
	const [user, setUser] = useState<string>("");
	const [group, setGroup] = useState<string>("");
	const { userDetail, companyId, authToken } = useContext(AuthContext);
	const { updateNotificationAlertMessage } = useContext(NotificationContext);
	const { updateInitializationStatus, isConnectedInitialized } = useContext(NotificationContext);
	
	useEffect(() => {
		if (!signalRBaseUrl) {
			getWebSocketUrl();
		}
	}, []);

	useEffect(() => {
		if (!isConnectedInitialized && signalRBaseUrl && userDetail.id > 0) {
			setUser(companyId + '_' + userDetail.id);
			setGroup(getWebSocketGroup());
			signalRConnectionInit();
		}
	}, [signalRBaseUrl, user, group]);

	const getWebSocketGroup = () => {
		const group = "00000000" + companyId;
		return 'suite-ute' + group.substr(group.length - 8);
	}

	const signalRConnectionInit = () => {
		if(!user) return;
		axios.post(`${signalRBaseUrl}/signalr/negotiate`,null, {
			headers: {
				'x-ms-signalr-userid': user,
				'Authorization': bearerPrefix + authToken,
				'ClientType': "UTE"
			}
		})
			.then((response: any) => {
				response.accessToken = response.accessToken || response.accessKey;
				const url = response.data.url || response.data.endpoint;

				const options = {
					accessTokenFactory: () => response.data.accessToken
				};

				const connection = new signalR.HubConnectionBuilder()
					.withUrl(url, options)
					.withAutomaticReconnect()
					.configureLogging(signalR.LogLevel.Information)
					.build();
				console.log(connection);
				connection.on('UTENotificationAlert', notifyEmailNotification);

				connection.onclose(() => {
					console.log('disconnected');
					startConnection(connection);
				});
				startConnection(connection);

			}).catch((reason) => {
				console.log(reason);
			})
	}

	const startConnection = (connection: any) => {
		console.log('connecting...');
		connection.start()
			.then(() => {
				console.log('connected!');
				updateInitializationStatus(true);
				if(user && user.length>0)
				{
					addGroup();
					connection.invoke('getConnectionId');
				}
			})
			.catch((err: any) => {
				console.error(err);
				setTimeout(startConnection, 5000, connection);
			});
	}

	const addGroup = () => {
		axios.post(`${signalRBaseUrl}/signalr/AddToGroup`,{
			recipient: user,
			groupname: group
		}, {
			headers: {
				'x-ms-signalr-userid': user,
				'Authorization': bearerPrefix + authToken,
				'ClientType': "UTE"
			}			
		}).then((resp: any) => {
			if (resp.status == 200) {
				console.log("User added to the group successfully");
			}
		}).catch((error: any) => {
			console.log(error);
		});
	}

	const removeGroup = () => {
		axios.post(`${signalRBaseUrl}/signalr/RemoveFromGroup`,
			{
			recipient: user,
			groupname: group
		}, {
			headers: {
				'x-ms-signalr-userid': user,
				'Authorization': bearerPrefix + authToken,
				'ClientType': "UTE"
			}			
		}).then((resp: any) => {
			if (resp.status == 200) {
				console.log("User removed from group successfully")
			}
		}).catch((error: any) => {
			console.log(error);
		});
	}

	const getWebSocketUrl = () => {
		getSignalRWebsocketURL((socketUrl: string) => {
			setSignalRBaseUrl(socketUrl);
		},
		() => {
			console.log("getSignalRWebsocketURL call failed");
		});
	}

	const notifyEmailNotification = (notificationMessage: INotificationAlertMessage) => {
		if (notificationMessage.users.includes(userDetail.id) && notificationMessage.companyId === companyId){
			updateNotificationAlertMessage(notificationMessage);
		}
	}

	return (<> </>);
};

export default SignalRWebSocket;
