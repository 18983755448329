import React from 'react';
import { DASHBOARD_CONSTANTS, NULL_DATE } from 'src/helper/constants';
import { ICompletedTaxReturn } from '../../dashboard-types';
import { getDueDate } from 'src/helper/utils';
import styles from './completed-tasks-list.module.scss';

interface ICompletedTasksListProps {
	completedTasksList: ICompletedTaxReturn[];
}

const CompletedTasksList: React.FC<ICompletedTasksListProps> = ({ completedTasksList }) => {
	return (
		<div>
			<section className={styles.completedTasksListContainer}>
				<table>
					<thead>
						<tr>
							<th className={styles.nameHeader}>Task Name</th>
							<th className={styles.actionsHeader}>Date Completed</th>
						</tr>
					</thead>
					<tbody>
						{completedTasksList.length === 0 ? (
							<tr style={{ height: '85px' }}>
								<td
									colSpan={2}
									className='text-center'>
									{DASHBOARD_CONSTANTS.NO_RECORDS_FOUND}
								</td>
							</tr>
						) : (
							completedTasksList.map((task, index) => (
								<tr
									className={styles.completedTasksListRow}
									key={index}>
									<td className={styles.nameData}>
										<span
											className={styles.nameContainer}
											title={task.taskName}>
											{task.taskName}
										</span>
									</td>
									<td className={styles.dateContainer}>{task.completionDate == NULL_DATE ? <></> : getDueDate(new Date(task.completionDate))}</td>
								</tr>
							))
						)}
					</tbody>
				</table>
			</section>
		</div>
	);
};

export default CompletedTasksList;
