import React, { FC } from 'react';
import OtpInput from 'react-otp-input';
import { ExclamationError } from 'src/assets/svg/exclamation-error';
import styles from './otp-input-control.module.scss';

interface IOtpInputControlProps {
	otp: string;
	setOtp: (otp: string) => void;
	isInvalid?: boolean;
	errorText?: string;
	numInputs?: number;
}

const OtpInputControl: FC<IOtpInputControlProps> = ({ otp, setOtp, isInvalid, errorText, numInputs }) => {
	return (
		<div className={`${styles.otpInputControlWrapper}${isInvalid && otp.length === 0 ? ` ${styles.error}` : ''}`}>
			<div className={styles.inputOuterContainer}>
				<OtpInput
					value={otp}
					onChange={setOtp}
					numInputs={numInputs}
					inputType={'tel'}
					containerStyle={styles.inputContainer}
					inputStyle={{ width: 34 }}
					renderInput={(props) => <input {...props} />}
				/>
				{isInvalid && <ExclamationError />}
			</div>
			{isInvalid && <>{errorText && <div className={styles.invalidFeedback}>{errorText}</div>}</>}
		</div>
	);
};

export default OtpInputControl;
