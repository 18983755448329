import { useEffect, useState, useContext } from 'react';
import { initialSIGDocumentPreviewModel, ISIGDocumentPreviewModel } from './sig-document-preview.types';
import { PdfSource } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import { CLIENT_VIEW_ACTION_NOT_PERMITTED, SSR_DOCUMENTS_PREVIEW_CONSTANTS } from 'src/helper/constants';
import { UTENotifier } from 'src/modules/notification/ute-notifier';
import { getSignatureDocumentForPreview } from './sig-document-preview-api';
import LoadingOverlay from 'react-loading-overlay';
import PdfPreview from 'src/components/pdf-preview';
import { IFileGroup } from 'src/components/pdf-preview/left-panel/left-panel-types';
import { DisplayDownloadFile } from 'src/helper/display-download-file';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';
import { ClientHubEvents, IClientTracking } from 'src/modules/common/common-types';
import { saveClientTrackingEvent } from 'src/modules/common/common-api';

type SIGDocumentPreviewProps = {
	documentId: number;
};

const SIGDocumentPreview = ({ documentId }: SIGDocumentPreviewProps) => {
	const [document, setDocument] = useState<ISIGDocumentPreviewModel>(initialSIGDocumentPreviewModel);
	const [isLoading, setisLoading] = useState(false);
	const [selectedDocumentSource, setSelectedDocumentSource] = useState<PdfSource>({ base64String: '', url: '' });
	const { isClientView, userDetail } = useContext(AuthContext);

	useEffect(() => {
		fetchDocument();
	}, [documentId]);

	const fetchDocument = () => {
		setisLoading(true);
		getSignatureDocumentForPreview(
			documentId,
			(data: ISIGDocumentPreviewModel) => {
				setisLoading(false);
				setDocument(data);
				let pdfSource: PdfSource = {
					base64String: '',
					url: data.sasUrl,
				};
				setSelectedDocumentSource(pdfSource);
			},
			() => {
				setisLoading(false);
				UTENotifier.Error(SSR_DOCUMENTS_PREVIEW_CONSTANTS.ERROR_FETCH_DOCUMENTS);
			},
		);
	};

	const convertToPdfViewerModel = () => {
		let fileGroups: IFileGroup[] = [];
		let group: IFileGroup = {} as IFileGroup;
		group.name = 'Document';
		group.files = [];
		group.files.push({
			data: document,
			name: document.documentInfo.documentName,
			url: document.sasUrl,
		});
		fileGroups.push(group);
		return fileGroups;
	};

	const onFileDownload = () => {
		if (isClientView) {
			UTENotifier.Warning(CLIENT_VIEW_ACTION_NOT_PERMITTED);
			return;
		}
		let displayDownloadFile = new DisplayDownloadFile();
		let clientTracking: IClientTracking = {
			eventId: ClientHubEvents.DocumentDownloadedFromView,
			eventData: {
				emailId: userDetail.emailAddress,
				fileName: document.documentInfo.documentName,
				newEmailId: '',
				oldEmailId: '',
			},
			actedBy: userDetail.emailAddress,
			registrationId: userDetail.id,
		};
		saveClientTrackingEvent(clientTracking);
		displayDownloadFile.directDownload(document.sasUrl);
	};

	return (
		<>
			<LoadingOverlay
				active={isLoading}
				spinner={''}
				text=''>
				<PdfPreview
					pdfSource={selectedDocumentSource}
					fileGroups={convertToPdfViewerModel()}
					onFileSelect={() => {}}
					onFileDownload={onFileDownload}
				/>
			</LoadingOverlay>
		</>
	);
};

export default SIGDocumentPreview;
