import { OTPValidationStatus } from 'src/modules/common/common-types';

export type IRecoveryInformation = {
	recoveryMatched: boolean;
	recoveryOTPValidated: boolean;
	newEmailVaildated: boolean;
};

export const RecoveryInformationDefault: IRecoveryInformation = {
	recoveryMatched: false,
	recoveryOTPValidated: false,
	newEmailVaildated: false,
};

export type IRecoveryMailResponse = {
	validated: boolean;
	error: boolean;
};

export type IRecoveryPhoneNumberResponse = {
	validated: boolean;
	error: boolean;
};

export type IRecoveryMailOTPResponse = {
	otpResponse: OTPValidationStatus;
	error: boolean;
};

export type IRecoveryPhoneNumberOTPResponse = {
	otpResponse: OTPValidationStatus;
	error: boolean;
};

export type IValidateNewMailResponse = {
	validated: boolean;
	error: boolean;
};

export type IValidateNewMailOTPResponse = {
	otpResponse: OTPValidationStatus;
	error: boolean;
};
