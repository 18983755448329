import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from 'src/helper/constants';
import {
	ITaxRecordsResponse,
	ITaxRecord,
	IPaginatedTaxData,
	IPaginatedTaxDataResponse,
	initialPaginatedTaxData,
	ITaxDocument,
} from './documents-types';
import { apiCatchHandler, apiCatchHandlerNoData } from 'src/helper/utils';

export const getTaxYearFolders = (taxYear: number, callback: (taxRecordsResponse: ITaxRecordsResponse) => void) => {
	const taxRecordsResponse: ITaxRecordsResponse = {
		taxRecords: [],
		error: false,
	};
	axios
		.get(`${API_BASE_URL}/api/dashboard/get-document-folders/${taxYear}`)
		.then((response: AxiosResponse<ITaxRecord[]>) => {
			const { data } = response;
			taxRecordsResponse.taxRecords = data;
			callback(taxRecordsResponse);
		})
		.catch((error) => {
			taxRecordsResponse.error = true;
			apiCatchHandler(error, taxRecordsResponse, callback);
		});
};

export const getPaginatedTaxDocuments = (
	queryString: string,
	callback: (paginatedTaxDataResponse: IPaginatedTaxDataResponse) => void,
) => {
	const paginatedTaxDataResponse: IPaginatedTaxDataResponse = {
		paginatedTaxData: initialPaginatedTaxData,
		error: false,
	};
	axios
		.get(`${API_BASE_URL}/api/dashboard/get-paginated-taxdocuments${queryString}`)
		.then((response: AxiosResponse<IPaginatedTaxData>) => {
			const { data } = response;
			paginatedTaxDataResponse.paginatedTaxData = data;
			callback(paginatedTaxDataResponse);
		})
		.catch((error) => {
			paginatedTaxDataResponse.error = true;
			apiCatchHandler(error, paginatedTaxDataResponse, callback);
		});
};

export const getFilteredTaxDocuments = (
	queryString: string,
	callback: (taxRecordsResponse: ITaxRecordsResponse) => void,
) => {
	const taxRecordsResponse: ITaxRecordsResponse = {
		taxRecords: [],
		error: false,
	};
	axios
		.get(`${API_BASE_URL}/api/dashboard/get-filtered-taxdocuments${queryString}`)
		.then((response: AxiosResponse<ITaxRecord[]>) => {
			const { data } = response;
			taxRecordsResponse.taxRecords = data;
			callback(taxRecordsResponse);
		})
		.catch((error) => {
			taxRecordsResponse.error = true;
			apiCatchHandler(error, taxRecordsResponse, callback);
		});
};

export const getExchangeSasUrl = (
	file: ITaxDocument,
	callback: (sasUrl: string) => void,
	errorCallback: () => void,
) => {
	const fileData = { ...file, clientId: '', signerName: '' };
	axios
		.post(`${API_BASE_URL}/api/document/download`, fileData, { headers: { 'Content-Type': 'application/json' } })
		.then((response) => {
			const url = response.data;
			callback && callback(url);
		})
		.catch((error) => {
			apiCatchHandlerNoData(error, errorCallback);
		});
};
