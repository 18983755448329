import React from 'react';

interface ISvgProps {
	width?: string;
	height?: string;
	className?: string;
	onClick?: () => void;
}

const ClockHistoryIcon: React.FC<ISvgProps> = ({ className }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='12'
			fill='none'
			viewBox='0 0 12 12'
			className={className}>
			<path
				fill='#AE1B6A'
				d='M6.386.764A5.25 5.25 0 006 .75V0a6 6 0 01.441.016l-.055.748zm1.503.338A5.25 5.25 0 007.15.878l.165-.732a6 6 0 01.844.256l-.27.7zm1.028.533a5.256 5.256 0 00-.33-.203l.37-.653a6.001 6.001 0 01.734.49l-.461.592a5.257 5.257 0 00-.313-.226zm1.375 1.342a5.244 5.244 0 00-.49-.597l.543-.517c.204.213.39.441.56.682l-.613.432zm.558 1.014a5.283 5.283 0 00-.16-.351l.67-.338a6.03 6.03 0 01.337.816l-.712.235a5.255 5.255 0 00-.135-.362zm.398 1.88a5.243 5.243 0 00-.075-.769l.739-.128c.05.29.079.584.086.879l-.75.018zm-.099 1.153a5.23 5.23 0 00.062-.381l.744.091a6.008 6.008 0 01-.172.866l-.723-.2c.034-.124.064-.25.09-.376zm-.713 1.785c.137-.218.26-.446.364-.681l.685.303c-.12.27-.258.53-.416.779l-.634-.401zm-.724.903c.092-.091.18-.185.263-.283l.568.49c-.096.111-.196.22-.3.324l-.53-.53z'></path>
			<path
				fill='#AE1B6A'
				d='M6 .75a5.25 5.25 0 103.712 8.962l.53.53A6 6 0 116 0v.75z'></path>
			<path
				fill='#AE1B6A'
				d='M5.625 2.25c.207 0 .375.168.375.375v3.907l2.436 1.392a.375.375 0 11-.372.652l-2.625-1.5a.375.375 0 01-.189-.326V2.625c0-.207.168-.375.375-.375z'></path>
		</svg>
	);
};

export default ClockHistoryIcon;
