import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
	IPaymentDetailResponseViewModel,
	ITaxingAuthority,
	IPaymentData,
	initialPaymentDetailResponseViewModel,
	initialIPaymentData,
	IPaymentTab,
} from './taxpayment-types';
import { ClientTypes, ProductType, TaxpayerRedirectionSourcePage } from 'src/modules/common/common-types';
import {
	generateTaxpayerEncodedClientId,
	generateTaxpayerRedirectionAPIURL,
	generateExtTaxpayerRedirectionAPIURL,
	generateEXTTaxpayerClientViewAPIURL,
	generateTaxpayerClientViewAPIURL,
} from 'src/modules/common/common-api';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';
import {
	getAllTaxingAuthorities,
	getSSRPaymentDetails,
	getAllSSRPaymentDocuments,
	getExtensionPaymentDetails,
	getAllExtensionPaymentDocuments,
	getProductsPaymentDocuments,
} from './taxpayment-api';
import LoadingOverlay from 'react-loading-overlay';
import Loader from 'src/components/loader';
import PaymentList from './taxpayment-list/taxpayment-list';
import { logger } from 'src/context/ute-auth-context/auth-provider';
import { DEFAULT_PAYMENTLIST_PER_PAGE } from 'src/helper/constants';
import TaxPaymentTabs from './taxpayment-tabs/taxpayment-tabs';
import { PaginationPageProps } from 'src/components/ute-pagination/ute-pagination-types';

const PaymentComponent = ({}) => {
	const [isLoading, setisLoading] = useState(false);
	const [paymentDataInfo, setPaymentInfo] = useState<IPaymentDetailResponseViewModel>(
		initialPaymentDetailResponseViewModel,
	);
	const [paymentTabs, setPaymentTab] = useState<IPaymentTab[]>([
		{
			visibility: false,
			tabHeading: 'Tax Returns',
			active: false,
			tabDescription: 'Tax Returns Payments',
			tabId: ProductType.Returns,
		},
		{
			visibility: false,
			tabHeading: 'Tax Extensions',
			active: false,
			tabDescription: 'Tax Return Extension Payments',
			tabId: ProductType.Extensions,
		},
	]);

	const [paymentList, setPaymentDetails] = useState<IPaymentData>(initialIPaymentData);
	const [taxingAuthorities, setTaxingauthorities] = useState<ITaxingAuthority[]>([]);
	const [page, setPage] = useState<PaginationPageProps>({ pageIndex: 1, pageSize: DEFAULT_PAYMENTLIST_PER_PAGE });
	const { userDetail, companyId, companyProducts } = useContext(AuthContext);
	const [taxReturnHeading, setTaxReturnHeading] = useState('Tax Return');
	const [searchParams] = useSearchParams();
	const { isClientView } = useContext(AuthContext);

	useEffect(() => {
		let tab = searchParams.get('tab');
		if (!tab) {
			loadProductsPaymentList();
		} else {
			loadProductsPaymentList(!isNaN(parseInt(tab)) ? parseInt(tab) : ProductType.None);
		}

		loadTaxingAuthorities();
	}, []);

	const loadTaxingAuthorities = () => {
		if (taxingAuthorities.length === 0) {
			getAllTaxingAuthorities(
				companyId,
				(taxingAuthorities: ITaxingAuthority[]) => {
					setTaxingauthorities(taxingAuthorities);
				},
				(error: any) => {
					logger && logger.trackWarning('Error while getAllTaxingAuthorities', { Error: error?.response?.data });
				},
			);
		}
	};

	const onPaymentViewAction = (productType: ProductType, clientId: string, callback: () => void) => {
		generateTaxpayerEncodedClientId(clientId, (taxPayerClientInfo: string) => {
			showPaymentView(taxPayerClientInfo, productType, callback);
		});
	};

	const showTab = (tabId: ProductType) => {
		let selectedTab = ProductType.None;
		paymentTabs.forEach((tab: IPaymentTab, index) => {
			if (tab.tabId === tabId) {
				tab.active = true;
				selectedTab = tab.tabId;
			} else {
				tab.active = false;
			}
		});

		setPaymentTab(paymentTabs);
		setTaxReturnHeading(getTaxReturnColumnHeading(selectedTab));
		loadPaymentList(1, DEFAULT_PAYMENTLIST_PER_PAGE, selectedTab);
	};

	const updateTabs = (tabId: ProductType, visibility: boolean, markAsActive: boolean = false) => {
		const tabs = [...paymentTabs];

		if (tabs.length === 0) {
			return;
		}

		tabs.forEach((tab: IPaymentTab) => {
			if (tab.tabId === tabId) {
				tab.visibility = visibility;
				tab.active = markAsActive ? markAsActive : tab.active;
			}
		});

		setPaymentTab(tabs);
	};

	const getActiveTabs = () => {
		const tabs = [...paymentTabs];

		let activeTabs: IPaymentTab[] = [];

		if (tabs.length === 0) {
			return activeTabs;
		}
		tabs.forEach((tab: IPaymentTab) => {
			if (tab.visibility) {
				activeTabs.push(tab);
			}
		});

		return activeTabs;
	};

	const getTaxReturnColumnHeading = (selectedTab: ProductType) => {
		switch (selectedTab) {
			case ProductType.Extensions:
				return 'Tax Extensions';
			case ProductType.Returns:
			default:
				return 'Tax Return';
		}
	};

	const onPageChange = (newPageIndex: number, newPageSize: number, productType: ProductType) => {
		const totalPages = Math.ceil(paymentList.count / page.pageSize);
		if (
			(newPageIndex !== page.pageIndex && newPageIndex <= totalPages && newPageIndex >= 1) ||
			newPageSize !== page.pageSize
		) {
			const newPage = { ...page };
			newPage.pageIndex = newPageIndex;
			newPage.pageSize = newPageSize;
			setPage(newPage);
			loadPaymentList(newPageIndex, newPageSize, productType);
		}
	};

	const showPaymentView = (clientInfo: string, productType: ProductType, callback: () => void) => {
		setisLoading(true);
		switch (productType) {
			case ProductType.Extensions:
				getExtensionPaymentDetails(
					clientInfo,
					userDetail.emailAddress,
					(paymentDetailResponseViewModel: IPaymentDetailResponseViewModel) => {
						setPaymentInfo(paymentDetailResponseViewModel);
						setisLoading(false);
						callback();
					},
				);
				break;
			case ProductType.Returns:
				getSSRPaymentDetails(clientInfo, (paymentDetailResponseViewModel: IPaymentDetailResponseViewModel) => {
					setPaymentInfo(paymentDetailResponseViewModel);
					setisLoading(false);
					callback();
				});
				break;
		}
	};
	const onPaymentAction = (productType: ProductType, clientId: string) => {
		if (!isClientView) {
			if (productType === ProductType.Returns) {
				generateTaxpayerRedirectionAPIURL(
					clientId,
					ProductType.Returns,
					TaxpayerRedirectionSourcePage.Pay,
					ClientTypes.Undefined,
					userDetail.emailAddress,
				);
			} else if (productType === ProductType.Extensions) {
				generateExtTaxpayerRedirectionAPIURL(
					clientId,
					TaxpayerRedirectionSourcePage.Pay,
					ClientTypes.Undefined,
					userDetail.emailAddress,
				);
			}
		} else {
			if (productType === ProductType.Returns) {
				generateTaxpayerClientViewAPIURL(
					clientId,
					ProductType.Returns,
					ClientTypes.Undefined,
					TaxpayerRedirectionSourcePage.Pay,
				);
			} else if (productType === ProductType.Extensions) {
				generateEXTTaxpayerClientViewAPIURL(
					clientId,
					ProductType.Extensions,
					ClientTypes.Undefined,
					TaxpayerRedirectionSourcePage.Pay,
				);
			}
		}
	};

	const loadPaymentList = (pageNumber: number, pageSize: number, tabId: ProductType = ProductType.None) => {
		switch (tabId) {
			case ProductType.Extensions:
				loadExtensionPaymentList(pageNumber, pageSize);
				break;
			case ProductType.Returns:
			default:
				loadSSRPaymentList(pageNumber, pageSize);
				break;
		}
	};

	const loadProductsPaymentList = (tabId: ProductType = ProductType.None) => {
		setisLoading(true);
		getProductsPaymentDocuments(
			userDetail.emailAddress,
			companyId,
			1,
			page.pageSize,
			(paymentData: IPaymentData[]) => {
				const [paymentData_SSR, paymentData_EXT] = [
					paymentData.filter((data: IPaymentData) => data.productType === ProductType.Returns)[0],
					paymentData.filter((data: IPaymentData) => data.productType === ProductType.Extensions)[0],
				];

				if (
					(!paymentData_SSR || paymentData_SSR.documents.length === 0) &&
					(!paymentData_EXT || paymentData_EXT.documents.length === 0)
				) {
					setisLoading(false);
					return;
				}

				updateTabs(ProductType.Returns, paymentData_SSR.documents.length > 0, paymentData_SSR.documents.length > 0);

				updateTabs(
					ProductType.Extensions,
					paymentData_EXT.documents.length > 0,
					paymentData_SSR.documents.length > 0 ? false : paymentData_EXT.documents.length > 0,
				);

				if (tabId !== ProductType.None) {
					showTab(tabId);
				} else {
					setTaxReturnHeading(
						getTaxReturnColumnHeading(
							paymentData_SSR.documents.length > 0 ? ProductType.Returns : ProductType.Extensions,
						),
					);
				}

				if (paymentData_SSR.documents.length > 0) {
					setPaymentDetails(paymentData_SSR);
				} else if (paymentData_EXT.documents.length > 0) {
					setPaymentDetails(paymentData_EXT);
				}

				setisLoading(false);
			},
			(error: any) => {
				logger && logger.trackWarning('Error while loadSSRPaymentList', { Error: error?.response?.data });
			},
		);
	};

	const loadSSRPaymentList = (pageNumber: number, pageSize: number) => {
		setisLoading(true);
		getAllSSRPaymentDocuments(
			userDetail.emailAddress,
			companyId,
			pageNumber,
			pageSize,
			(paymentData: IPaymentData) => {
				paymentData.productType = ProductType.Returns;
				const newPage = { ...page };
				newPage.pageIndex = pageNumber;
				newPage.pageSize = pageSize;
				setPage(newPage);
				setPaymentDetails(paymentData);
				setisLoading(false);
				updateTabs(ProductType.Returns, paymentData.documents.length > 0, paymentData.documents.length > 0);
			},
			(error: any) => {
				logger && logger.trackWarning('Error while loadSSRPaymentList', { Error: error?.response?.data });
			},
		);
	};

	const loadExtensionPaymentList = (pageNumber: number, pageSize: number) => {
		setisLoading(true);
		getAllExtensionPaymentDocuments(
			userDetail.emailAddress,
			companyId,
			pageNumber,
			pageSize,
			(paymentData: IPaymentData) => {
				paymentData.productType = ProductType.Extensions;
				const newPage = { ...page };
				newPage.pageIndex = pageNumber;
				newPage.pageSize = pageSize;
				setPage(newPage);
				setPaymentDetails(paymentData);
				setisLoading(false);
				updateTabs(ProductType.Extensions, paymentData.documents.length > 0);
			},
			(error: any) => {
				logger && logger.trackWarning('Error while loadExtensionPaymentList', { Error: error?.response?.data });
			},
		);
	};

	return (
		<>
			<section className={`pageModuleContainer`}>
				<LoadingOverlay
					active={isLoading}
					spinner={<Loader />}
					text=''>
					{
						<>
							<h2 className='pageModuleHeader'>Pay your Taxes</h2>
							<p className='pageModuleDescription'>
								View and pay your actual and estimated tax payments for your returns
							</p>
							{getActiveTabs().length > 1 ? (
								<TaxPaymentTabs
									tabs={paymentTabs}
									showTab={showTab}
								/>
							) : (
								''
							)}
							<PaymentList
								taxReturnHeading={taxReturnHeading}
								paymentData={paymentDataInfo}
								onPageChange={onPageChange}
								page={page}
								paymentList={paymentList}
								taxingAuthorities={taxingAuthorities}
								onPayAction={onPaymentAction}
								onPayViewAction={onPaymentViewAction}
							/>
						</>
					}
				</LoadingOverlay>
			</section>
		</>
	);
};

export default PaymentComponent;
