import { useContext, useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import UTEButton from 'src/components/ute-button';
import { isValidNumber } from 'src/helper/utils';
import './pin-update-popup.style.scss';
import { EyeClose, EyeOpen } from 'src/assets/svg/eye-icons';
import { IUpdatePINDetail, IUpdatePINDetailResponse } from '../update-pin-types';
import { updatePIN } from '../update-pin-api';
import { UTENotifier } from 'src/modules/notification/ute-notifier';
import { CLIENT_VIEW_ACTION_NOT_PERMITTED, UPDATE_PIN_CONSTANTS } from 'src/helper/constants';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';

type PINUpdatePopupProps = {
	setShowModal: (showModal: boolean) => void;
	onCancel: () => void;
};
const PINUpdatePopup = ({ setShowModal, onCancel }: PINUpdatePopupProps) => {
	const { updateAuthToken, updateRefreshToken, isClientView } = useContext(AuthContext);
	const [inputCurrentPINType, setInputCurrentPINType] = useState('password');
	const [inputPINType, setInputPINType] = useState('password');
	const [inputConfirmPINType, setInputConfirmPINType] = useState('password');
	const [confirmPIN, setConfirmPIN] = useState('');
	const [isPINDisabled, setIsPINDisabled] = useState(true);
	const [isError, setIsError] = useState(false);
	const [isCurrentPINError, setIsCurrentPINError] = useState(false);
	const [isNewPINError, setIsNewPINError] = useState(false);
	const [isValidation, setIsValidation] = useState(false);
	const [pin, setPIN] = useState('');
	const [currentPIN, setCurrentPIN] = useState('');

	useEffect(() => {
		if (pin.length < 6 || confirmPIN.length < 6 || currentPIN.length < 6) {
			setIsError(false);
			setIsPINDisabled(true);
		} else {
			setIsPINDisabled(false);
		}
	}, [pin, confirmPIN, currentPIN]);

	const handleCurrentPINChange = (currentPinValue: string) => {
		if (currentPinValue === '' || isValidNumber(currentPinValue)) setCurrentPIN(currentPinValue);
	};

	const handlePINChange = (pinValue: string) => {
		if (pinValue === '' || isValidNumber(pinValue)) setPIN(pinValue);
	};

	const handleConfirmPINChange = (confirmPINValue: string) => {
		if (confirmPINValue === '' || isValidNumber(confirmPINValue)) setConfirmPIN(confirmPINValue);
	};

	const handleCurrentPINEyeClick = () => {
		setInputCurrentPINType(inputCurrentPINType === 'password' ? 'text' : 'password');
	};

	const handlePINEyeClick = () => {
		setInputPINType(inputPINType === 'password' ? 'text' : 'password');
	};

	const handleConfirmPINEyeClick = () => {
		setInputConfirmPINType(inputConfirmPINType === 'password' ? 'text' : 'password');
	};

	const onUpdate = () => {
		let updatePINDetail: IUpdatePINDetail = { currentPIN: currentPIN, newPIN: confirmPIN };
		if (isClientView) {
			UTENotifier.Warning(CLIENT_VIEW_ACTION_NOT_PERMITTED);
			return;
		}
		if (pin === confirmPIN) {
			setIsError(false);
			setIsPINDisabled(true);
			updatePIN(
				updatePINDetail,
				(response: IUpdatePINDetailResponse) => {
					if (response && !response?.isValidCurrentPIN) {
						setIsValidation(true);
						setIsCurrentPINError(true);
						setIsNewPINError(false);
					} else if (response && !response?.isValidNewPIN) {
						setIsError(true);
						setIsValidation(false);
						setIsNewPINError(true);
					} else {
						setShowModal(false);
						UTENotifier.Success(UPDATE_PIN_CONSTANTS.PIN_UPDATE_SUCCESS);
						setInterval(() => {
							updateAuthToken('');
							updateRefreshToken('');
							window.location.href = '/';
						}, 5000);
					}
				},
				() => {
					UTENotifier.Error(UPDATE_PIN_CONSTANTS.PIN_UPDATE_ERROR);
				},
			);
		} else {
			setIsError(true);
			setIsNewPINError(false);
		}
	};

	return (
		<div className='pin-update-popup-modal'>
			<Modal.Body>
				<Row data-test-auto='F12E9E3A-5B0D-4463-9329-750FAA9B7D12'>
					<Col lg={12}>
						<div className='formInputHeader'>
							<strong>Current PIN</strong>
						</div>
						<div className={`pinContainer ${isCurrentPINError && 'error'}`}>
							<input
								type={inputCurrentPINType}
								name='currentPIN'
								id='currentPIN'
								className='currentPIN'
								placeholder='Enter current 6-digit PIN'
								value={currentPIN}
								onChange={(e) => {
									handleCurrentPINChange(e.target.value);
								}}
								maxLength={6}
								data-test-auto='DCB47F4A-262E-41CF-9AC7-FA3E0F504FD3'
							/>
							<span
								className='eyeContainer'
								onClick={handleCurrentPINEyeClick}>
								{inputCurrentPINType === 'password' ? <EyeClose /> : <EyeOpen />}
							</span>
						</div>
						{isValidation && <div className='errorContainer'>{UPDATE_PIN_CONSTANTS.PIN_UPDATE_VALIDATIONMESSAGE}</div>}
						<div className='formInputHeader'>
							<strong>New PIN</strong>
						</div>
						<div className={`pinContainer ${isError && 'error'}`}>
							<input
								type={inputPINType}
								name='pin'
								id='pin'
								className='pin'
								placeholder='New 6-digit PIN'
								value={pin}
								onChange={(e) => {
									handlePINChange(e.target.value);
								}}
								maxLength={6}
								data-test-auto='DCB47F4A-262E-41CF-9AC7-FA3E0F504FD3'
							/>
							<span
								className='eyeContainer'
								onClick={handlePINEyeClick}>
								{inputPINType === 'password' ? <EyeClose /> : <EyeOpen />}
							</span>
						</div>
						<div className='formInputHeader'>
							<strong>Confirm PIN</strong>
						</div>
						<div className={`pinContainer ${isError && 'error'}`}>
							<input
								type={inputConfirmPINType}
								name='confirmPIN'
								id='confirmPIN'
								className='confirmPIN'
								placeholder='Confirm 6-digit PIN'
								value={confirmPIN}
								onChange={(e) => {
									handleConfirmPINChange(e.target.value);
								}}
								maxLength={6}
								data-test-auto='38AD24BC-A5E8-416D-8BBF-C61D831D76D3'
							/>
							<span
								className='eyeContainer'
								onClick={handleConfirmPINEyeClick}>
								{inputConfirmPINType === 'password' ? <EyeClose /> : <EyeOpen />}
							</span>
						</div>
					</Col>
				</Row>
				{isError && !isNewPINError && (
					<div className='errorContainer'>PINs do not match. Please ensure they are identical.</div>
				)}
				{isNewPINError && <div className='errorContainer'>Your new PIN cannot be the same as your current PIN.</div>}
			</Modal.Body>
			<Modal.Footer>
				<UTEButton
					dataTestAuto='C0280D93-94DD-438B-9AB9-4F5BC452FE66'
					onClick={() => {
						onCancel();
					}}
					className='btn btn-default'
					disabled={false}
					text='Cancel'></UTEButton>
				<UTEButton
					className='btn btn-primary'
					onClick={() => {
						onUpdate();
					}}
					disabled={isPINDisabled}
					dataTestAuto='68eadccb-6b2f-48cb-99c0-bb6a7bebf258'
					text='Update'></UTEButton>
			</Modal.Footer>
		</div>
	);
};

export default PINUpdatePopup;
