import { PdfSource } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import { useState, useEffect } from 'react';
import PdfPreview from 'src/components/pdf-preview';
import { IFileGroup } from 'src/components/pdf-preview/left-panel/left-panel-types';
import { SSR_DOCUMENTS_PREVIEW_CONSTANTS } from 'src/helper/constants';
import { DisplayDownloadFile } from 'src/helper/display-download-file';
import { UTENotifier } from 'src/modules/notification/ute-notifier';
import { ITaxDocument } from 'src/modules/documents/documents-types';
import { getExchangeSasUrl } from 'src/modules/documents/documents-api';
import LoadingOverlay from 'react-loading-overlay';
import { getFileExtension, isFilePreviewAvailable } from 'src/helper/utils';
import DocViewer, {
	BMPRenderer,
	IHeaderOverride,
	JPGRenderer,
	MSDocRenderer,
	PNGRenderer,
} from '@cyntler/react-doc-viewer';
import { DownloadIcon } from 'src/assets/svg/download-icon';
import UTEButton from 'src/components/ute-button';
import './doc-viewer.scss'; 

type SSEDocumentPreviewProps = {
	file: ITaxDocument;
};

const SSEDocumentPreview = ({ file }: SSEDocumentPreviewProps) => {
	const [documentUrl, setDocumentUrl] = useState<string>('');
	const [isLoading, setIsLoading] = useState(false);
	const [selectedDocumentSource, setSelectedDocumentSource] = useState<PdfSource>({ base64String: '', url: '' });

	useEffect(() => {
		fetchDocument();
	}, []);

	const fetchDocument = () => {
		setIsLoading(true);
		getExchangeSasUrl(
			file,
			(url: string) => {
				setIsLoading(false);
				setDocumentUrl(url);
				let pdfSource: PdfSource = {
					base64String: '',
					url: url,
				};
				setSelectedDocumentSource(pdfSource);
			},
			() => {
				setIsLoading(false);
				UTENotifier.Error(SSR_DOCUMENTS_PREVIEW_CONSTANTS.ERROR_FETCH_DOCUMENTS);
			},
		);
	};

	const convertToPdfViewerModel = () => {
		let fileGroups: IFileGroup[] = [];
		let group: IFileGroup = {} as IFileGroup;
		group.name = 'Document';
		group.files = [];
		group.files.push({
			data: document,
			name: file.efileFormName,
			url: documentUrl,
		});
		fileGroups.push(group);
		return fileGroups;
	};

	const onFileDownload = () => {
		let displayDownloadFile = new DisplayDownloadFile();
		displayDownloadFile.directDownload(documentUrl);
	};

	return (
		<>
			{
				<LoadingOverlay
					active={isFilePreviewAvailable(file) && isLoading}
					spinner={''}
					text=''>
					{getFileExtension(file.efileFormName) === 'pdf' || !isFilePreviewAvailable(file) ? (
						<div className={`sse-pdf-viewer-container ${isFilePreviewAvailable(file) ? '' : 'non-supported-file-view'}`}>
							<PdfPreview
							pdfSource={selectedDocumentSource}
							fileGroups={convertToPdfViewerModel()}
							onFileSelect={() => {}}
							onFileDownload={onFileDownload}
							tooltip='Download'
							fileExtension={`.${getFileExtension(file.efileFormName)}`}
							/>
						</div>						
					) : (
						<DocViewer
							pluginRenderers={[MSDocRenderer, JPGRenderer, PNGRenderer, BMPRenderer]}
							documents={[
								{
									uri: documentUrl,
									fileName: file.efileFormName,
								},
							]}
							config={{
								header: {
									overrideComponent: DownloadComponent,
								},
								noRenderer: {
									overrideComponent: NoRenderer,
								},
							}}
							style={{ height: 500 }}
						/>
					)}
				</LoadingOverlay>
			}
		</>
	);
};

const NoRenderer = ({ document, fileName }: any) => {
	return <div></div>;
};

const DownloadComponent: IHeaderOverride = (state, previousDocument, nextDocument) => {
	if (!state.currentDocument || state.config?.header?.disableFileName) {
		return null;
	}
	const onFileDownload = () => {
		let displayDownloadFile = new DisplayDownloadFile();
		displayDownloadFile.directDownload(state.currentDocument?.uri || '');
	};
	return (
		<>
			{state.currentDocument.uri && (
				<div
					className='sse-doc-viewer'
					style={{ margin: '12px' }}>
					<UTEButton
						className='download-btn'
						dataTestAuto='F48A59BC-957B-4DB5-8E8B-08071C2A207F'
						disabled={false}
						text='Download'
						title='Download'
						icon={<DownloadIcon></DownloadIcon>}
						onClick={() => {
							onFileDownload();
						}}></UTEButton>
				</div>
			)}
		</>
	);
};
export default SSEDocumentPreview;
