import axios, { AxiosResponse } from "axios";
import { API_BASE_URL } from "src/helper/constants";
import { apiCatchHandler, sanitizeFileName } from "src/helper/utils";
import { IGatherDocumentModel, IOrganizerDocumentModel } from "src/modules/documents/documents-types";

const FileGuid = "00000000-0000-0000-0000-000000000000";

export const getGTRDocumentPreview = (
	document: IGatherDocumentModel,
	callback: (response: any) => void,
	errorCallback: () => void,
) => {
    const { gatherId, taxYear, clientId, fileGuid, documentType } = document;
	axios
		.get(`${API_BASE_URL}/api/document-preview/gatherDocumentPreviewAsync/${gatherId}/${taxYear}/${clientId}/${documentType}/${fileGuid ?? FileGuid}`)
		.then((response: AxiosResponse<IGTRDocumentPreviewResponse>) => {
			const { data } = response;
			callback(data);
		})
		.catch((error) => {
			apiCatchHandler(error, errorCallback);
		});
};

export const getGTRSourceDocumentPreview = (
	document: IGatherDocumentModel,
	callback: (response: any) => void,
	errorCallback: () => void,
) => {
    const { gatherId, taxYear, sourceDocumentFileName, sourceDocumentId, fileName } = document;
	axios
		.get(`${API_BASE_URL}/api/document-preview/gatherSourceDocumentPreviewAsync/${gatherId}/${taxYear}/${sourceDocumentId}/${fileName}/${sourceDocumentFileName}`)
		.then((response: AxiosResponse<IGTRDocumentPreviewResponse>) => {
			const { data } = response;
			callback(data);
		})
		.catch((error) => {
			apiCatchHandler(error, errorCallback);
		});
};

export const getORGDocumentPreview = (
	document: IOrganizerDocumentModel,
	callback: (response: any) => void,
	errorCallback: () => void,
) => {
    const { documentGuid, documentId, taxYear, clientId, fileGuid, documentType } = document;
	axios
		.get(`${API_BASE_URL}/api/document-preview/organizer-preview/${documentId}/${documentGuid}/${taxYear}/${sanitizeFileName(clientId)}/${documentType}/${fileGuid ?? FileGuid}`)
		.then((response: AxiosResponse<any>) => {
			const { data } = response;
			callback(data.sasUri);
		})
		.catch((error) => {
			apiCatchHandler(error, errorCallback);
		});
};

export const getORGSourceDocumentPreview = (
	document: IOrganizerDocumentModel,
	callback: (response: any) => void,
	errorCallback: () => void,
) => {
    const { documentGuid, taxYear, sourceDocumentFileName, fileName } = document;
	axios
		.get(`${API_BASE_URL}/api/document-preview/organizer-source-doc-prview/${documentGuid}/${taxYear}/${fileName}/${sourceDocumentFileName}`)
		.then((response: any) => {
			const { data } = response;
			callback(data.sasUri);
		})
		.catch((error) => {
			apiCatchHandler(error, errorCallback);
		});
};