import React, { useContext } from 'react';
import styles from './left-navigation.module.scss';
import { getPageName } from 'src/helper/utils';
import { useNavigate } from 'react-router-dom';
import {
	DistributeK1sIcon,
	DocumentsIcon,
	HomeIcon,
	LeftArrowIcon,
	LogoutIcon,
	RightArrowIcon,
	SecuritySettingsIcon,
	TaxPaymentsIcon,
} from 'src/assets/svg/left-menu-icons';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';
import UTEClientViewWrapper from 'src/components/ute-client-view-wrapper';

type LeftNavigationProps = { logout: () => void; showDistributeK1Menu: boolean; showTaxpaymentMenu: boolean };

const LeftNavigation = ({ logout, showDistributeK1Menu, showTaxpaymentMenu }: LeftNavigationProps) => {
	const { companyId, companyNameAndLogo, collapsedMenu, updateCollapsedMenu } = useContext(AuthContext);
	const navigate = useNavigate();
	const currentPage = getPageName();

	const handleMenuClick = (pageName: string) => {
		navigate(`/${pageName}`, { replace: true });
	};

	return (
		<nav className={`${styles.navigationContainer} ${collapsedMenu ? styles.collapsedWidth : ''}`}>
			<div className={styles.companyLogoContainer}>
				{companyNameAndLogo.whiteLogoPath && !collapsedMenu && (
					<img
						src={companyNameAndLogo.whiteLogoPath}
						alt='Company Logo'
					/>
				)}
			</div>
			<div className={styles.menuItemsContainer}>
				<ul className={styles.menuRouteItems}>
					<li
						className={`${styles.eachMenu} ${
							currentPage === '' || currentPage === companyId.toString() ? styles.selected : ''
						}`}
						onClick={() => {
							handleMenuClick('');
						}}
						title={collapsedMenu ? 'Home' : ''}>
						<HomeIcon />
						<span className={collapsedMenu ? styles.hidePageName : ''}>Home</span>
					</li>
					<li
						className={`${styles.eachMenu} ${currentPage === 'documents' ? styles.selected : ''}`}
						onClick={() => {
							handleMenuClick('documents');
						}}
						title={collapsedMenu ? 'Documents' : ''}>
						<DocumentsIcon />
						<span className={collapsedMenu ? styles.hidePageName : ''}>Documents</span>
					</li>
					{showTaxpaymentMenu && (
						<li
							className={`${styles.eachMenu} ${currentPage === 'tax-payments' ? styles.selected : ''}`}
							onClick={() => {
								handleMenuClick('tax-payments');
							}}
							title={collapsedMenu ? 'Tax Payments' : ''}>
							<TaxPaymentsIcon />
							<span className={collapsedMenu ? styles.hidePageName : ''}>Tax Payments</span>
						</li>
					)}
					{showDistributeK1Menu && (
						<li
							className={`${styles.eachMenu} ${currentPage === 'distribute-k1s' ? styles.selected : ''}`}
							onClick={() => {
								handleMenuClick('distribute-k1s');
							}}
							title={collapsedMenu ? 'Distribute K-1s' : ''}>
							<DistributeK1sIcon />
							<span className={collapsedMenu ? styles.hidePageName : ''}>Distribute K-1s</span>
						</li>
					)}
					<li
						className={`${styles.eachMenu} ${currentPage === 'security-settings' ? styles.selected : ''}`}
						onClick={() => {
							handleMenuClick('security-settings');
						}}
						title={collapsedMenu ? 'Security Settings' : ''}>
						<SecuritySettingsIcon />
						<span className={collapsedMenu ? styles.hidePageName : ''}>Security Settings</span>
					</li>
				</ul>
				<div
					className={`${styles.bottomContainer} ${collapsedMenu ? styles.bottomContainerCollapsed : ''} ${
						collapsedMenu ? styles.collapsedWidth : ''
					}`}>
					<UTEClientViewWrapper dataTestAuto='1b667806-2aa2-4f38-9118-77b0a32bf17a'>
						<div
							className={`${styles.logoutContainer} ${collapsedMenu ? styles.logoutContainerCollapsed : ''}`}
							onClick={() => {
								logout();
							}}
							title={collapsedMenu ? 'Log Out' : ''}>
							<LogoutIcon />
							<span className={collapsedMenu ? styles.hidePageName : ''}>Log Out</span>
						</div>
					</UTEClientViewWrapper>
					<div
						className={styles.collapseContainer}
						onClick={() => {
							updateCollapsedMenu(!collapsedMenu);
						}}>
						{collapsedMenu ? <RightArrowIcon /> : <LeftArrowIcon />}
					</div>
				</div>
			</div>
		</nav>
	);
};

export default LeftNavigation;
