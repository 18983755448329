import { QuestionMarkIcon } from 'src/assets/svg/question-mark-icon';
import styles from './recover-or-reset.module.scss';

type RecoverOrResetProps = {
	copmanyName: string;
	setComponent: (component: string) => void;
};
const RecoverOrReset = ({ copmanyName, setComponent }: RecoverOrResetProps) => {
	return (
		<>
			<div className={styles.questionMarkIcon}>
				<QuestionMarkIcon />
			</div>

			<div className='uteLoginHeader'>Recover&nbsp;or&nbsp;reset&nbsp;your&nbsp;email&nbsp;ID</div>
			<div className={styles.recoverOrResetText}>
				Please get in touch with {copmanyName} to recover your email ID.
				<br />
				<br />
				OR
				<br />
				<br />
				If you need to reset your email ID,
				<br />
				<span
					className={styles.clickHere}
					onClick={() => {
						setComponent('reset-email-id');
					}}>
					please click here.
				</span>
			</div>
		</>
	);
};

export default RecoverOrReset;
