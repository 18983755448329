import React from 'react';
import styles from './filter-list.module.scss';
import { FILTER_FILETYPE_OPTIONS, FILTER_STATUS_OPTIONS, FilterType, IFilter, IOptionsType } from '../documents-types';
import dayjs from 'dayjs';
import CloseIcon from 'src/assets/svg/close-icon-7point5';
import UTEButton from 'src/components/ute-button';

type FilterListProps = {
	filter: IFilter;
	removeFilter: (filterValue?: string, filterType?: FilterType) => void;
};
const FilterList = ({ filter, removeFilter }: FilterListProps) => {
	const { fromDate, toDate, filterStatus, filterFileType } = filter;
	const filterStatusArray = filterStatus.length > 0 ? filterStatus.split(',') : [];
	const filterFileTypeArray = filterFileType.length > 0 ? filterFileType.split(',') : [];

	return (
		<div className={styles.filterList}>
			{filterStatusArray.length > 0 &&
				filterStatusArray.map((eachStatusValue: string) => {
					return (
						<span
							className={styles.statusTag}
							key={eachStatusValue}>
							<span className={styles.name}>
								{FILTER_STATUS_OPTIONS.find((eachOption: IOptionsType) => eachOption.value === eachStatusValue)?.name}
							</span>
							<span
								className={styles.closeIcon}
								onClick={() => {
									removeFilter(eachStatusValue, FilterType.STATUS);
								}}>
								<CloseIcon />
							</span>
						</span>
					);
				})}
			{filterFileTypeArray.length > 0 &&
				filterFileTypeArray.map((eachFileTypeValue: string) => {
					return (
						<span
							className={styles.fileTypeTag}
							key={eachFileTypeValue}>
							<span className={styles.name}>
								{
									FILTER_FILETYPE_OPTIONS.find((eachOption: IOptionsType) => eachOption.value === eachFileTypeValue)
										?.name
								}
							</span>
							<span
								className={styles.closeIcon}
								onClick={() => {
									removeFilter(eachFileTypeValue, FilterType.FILETYPE);
								}}>
								<CloseIcon />
							</span>
						</span>
					);
				})}
			{fromDate && toDate && (
				<span className={styles.dateTag}>
					<span className={styles.name}>
						{dayjs(fromDate).format('MM/DD/YY')}-{dayjs(toDate).format('MM/DD/YY')}
					</span>
					<span
						className={styles.closeIcon}
						onClick={() => {
							removeFilter('date');
						}}>
						<CloseIcon />
					</span>
				</span>
			)}

			<UTEButton
				text='Clear All'
				onClick={() => {
					removeFilter();
				}}
				disabled={false}
				className={styles.clearAll}
				dataTestAuto='d263654f-4a21-47ac-95e2-13e08adb5df7'
			/>
		</div>
	);
};

export default FilterList;
