import React, { useEffect, useState } from 'react';
import {
	INotificationContextValue,
	INotificationResponse,
	initialNotificationResponseData,
	INotificationAlertMessage,
	INotification
} from './notification-provider-types';
import { getNotificationById } from 'src/modules/user-notifications/user-notification-api';
import { UTENotifier } from 'src/modules/notification/ute-notifier';
import { NOTIFICATION_CONSTANTS } from 'src/helper/constants';

export const NotificationContext = React.createContext({
	userNotifications: initialNotificationResponseData.notifications,
	updateNotificationAlertMessage: (notificationInfo: INotificationAlertMessage) => { },
	updateNotifications: (notifications: INotification[]) => { },
	updateInitializationStatus: (value: boolean) => { },
	isConnectedInitialized: false
});

const NotificationProvider = ({ children }: any) => {

	const [userNotifications, setNotifications] = useState<INotification[]>([]);
	const [connectedInitialized, setInitializationStatus] = useState<boolean>(false);

	const updateNotificationAlertMessage = (notificationMessage: INotificationAlertMessage): void => {
		if (notificationMessage.notificationId > 0) {
			getNotificationById(notificationMessage.notificationId, (notificationsResponse: INotificationResponse) => {
				setNotifications(prevState => [notificationsResponse.notifications[0] , ...prevState]);
			},()=>{
				UTENotifier.Error(NOTIFICATION_CONSTANTS.ERROR_FETCH_NOTIFICATION);
			});
		}
	};

	const updateInitializationStatus = (value: boolean): void => {
		setInitializationStatus(value);
	};

	const updateNotifications = (notifications: INotification[]): void => {
		setNotifications(notifications);
	};

	const value: INotificationContextValue = {
		userNotifications,
		updateNotificationAlertMessage,
		updateNotifications,
		updateInitializationStatus,
		isConnectedInitialized: connectedInitialized
	};
	return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export default NotificationProvider;
