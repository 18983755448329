import './logged-out.style.scss';
import UTEButton from 'src/components/ute-button';
import { Modal, Col, Row } from 'react-bootstrap';

type LoggedOutProps = {
	showModal: boolean;
	logout: () => void;
};

const LoggedOut = ({ showModal, logout }: LoggedOutProps) => {
	return (
		<Modal
			className='logged-out-modal'
			show={showModal}
			onHide={() => {
				logout();
			}}>
			<Modal.Header
				closeButton
				data-test-auto='A93CE321-8DF9-497D-BB11-12C83D293B83'>
				<Modal.Title>Logged Out</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row data-test-auto='49E77CA3-CF5A-4D67-A425-625151830BFC'>
					<Col lg={12}>
						<p>Your current session has expired, and you have been logged out of Client Portal.</p>
						<div>Please refresh to login.</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<UTEButton
					dataTestAuto='DFCEB47F-0DAD-4DA9-9C13-BB7BE91608ED'
					onClick={() => {
						logout();
					}}
					className='btn btn-primary'
					disabled={false}
					text='Refresh'></UTEButton>
			</Modal.Footer>
		</Modal>
	);
};

export default LoggedOut;
