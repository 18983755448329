import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';
import AxiosInterceptorsSetup from 'src/modules/axios-interceptor';

type AxiosInterceptorNavigateProps = {
	tokenInLocalStorage?: string | null;
};
export function AxiosInterceptorNavigate({ tokenInLocalStorage }: AxiosInterceptorNavigateProps) {
	const navigate = useNavigate();
	const { authToken } = useContext(AuthContext);
	AxiosInterceptorsSetup(tokenInLocalStorage ?? authToken, navigate);
	return <></>;
}
