import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';
import { ClientViewAccessTokenResponseVM } from 'src/modules/common/common-types';
import { fetchClientView } from 'src/modules/common/common-api';
import { IUserDetail } from 'src/context/ute-auth-context/auth-provider-types';

const ClientView = () => {
	const { updateCompanyId, updateAuthToken, updateUserDetails, updateRefreshToken, updateIsClientView } =
		useContext(AuthContext);
	const [clientViewValidated, setClientView] = useState(false);
	const { companyId, productType, cpaUserId } = useParams();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	useEffect(() => {
		let accessToken = searchParams.get('token');
		if (!accessToken || !companyId) {
			return;
		}
		updateCompanyId(Number(companyId));
		if (!clientViewValidated) {
			fetchClientView(
				Number(companyId),
				Number(productType),
				Number(cpaUserId),
				String(accessToken),
				(clientViewAccessTokenResponseVM: ClientViewAccessTokenResponseVM) => {
					if (clientViewAccessTokenResponseVM.isSuccess) {
						let userDetail: IUserDetail = {
							id: clientViewAccessTokenResponseVM.id,
							emailAddress: clientViewAccessTokenResponseVM.emailAddress,
							name: clientViewAccessTokenResponseVM.name,
							otpAttemptCount: 0,
							pinAttemptCount: 0,
						};
						updateUserDetails(userDetail);
						updateAuthToken(clientViewAccessTokenResponseVM.accessToken, userDetail);
						updateRefreshToken(clientViewAccessTokenResponseVM.refreshToken);
						setClientView(true);
						updateIsClientView(clientViewAccessTokenResponseVM.isClientView);
					}
					navigate('/');
				},
			);
		}
	}, [clientViewValidated]);

	return <></>;
};

export default ClientView;
