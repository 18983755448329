import React from 'react';
import DistributeK1s from 'src/modules/distribute-k1s';
import LoggedInLayout from 'src/modules/layout/logged-in-layout';

const DistributeK1sContainer = () => {
	return (
		<LoggedInLayout>
			<main className='pageMainContent'>
				<h1 className='pageMainHeader'>Distribute K-1s</h1>
				<DistributeK1s />
			</main>
		</LoggedInLayout>
	);
};

export default DistributeK1sContainer;
