import React, { useContext, useState } from 'react';
import CreateUsername from '../create-username';
import { IAccountCreatedResponse, IGenerateOTPResponse, IValidateOTPResponse } from './register-component-types';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';
import { createAccount, generateOTP, validateOTP } from './register-component-api';
import { checkUserExists } from '../../common/common-api';
import { UTENotifier } from 'src/modules/notification/ute-notifier';
import { LOGIN_CONSTANTS, RIGISTER_CONSTANTS } from 'src/helper/constants';
import CreatePIN from '../create-pin';
import { IUserExistsResponse, OTPValidationStatus, emptyUserDetail } from '../../common/common-types';
import { IOTPValidity, OTPValidityDefault } from 'src/components/ute-otp-input/ute-otp-input-types';
import UTEOTPInput from 'src/components/ute-otp-input';

type RegisterComponentProps = {
	setComponent: (component: string) => void;
	setAccountCreatedEmail: (emailID: string) => void;
};
const RegisterComponent = ({ setComponent, setAccountCreatedEmail }: RegisterComponentProps) => {
	const [emailID, setEmailID] = useState('');
	const [userName, setUserName] = useState('');
	const [userDetail, setUserDetail] = useState(emptyUserDetail);
	const [otp, setOTP] = useState('');
	const [otpValidity, setOTPValidity] = useState<IOTPValidity>(OTPValidityDefault);
	const { companyId } = useContext(AuthContext);
	const [isEmailVerified, setIsEmailVerified] = useState(false);
	const [pin, setPIN] = useState('');
	const [otpRetryCount, setOtpRetryCount] = useState(0);
	const [countryCode, setCountryCode] = useState('');
	const [mobileNumber, setMobileNumber] = useState('');

	const handleEmailIDSubmit = (errorCallback: (message: string) => void) => {
		checkUserExists(companyId, emailID, (userExistsResponse: IUserExistsResponse) => {
			if (userExistsResponse.error) {
				errorCallback(RIGISTER_CONSTANTS.ERROR_FETCH_USER);
			} else {
				if (userExistsResponse.isExists) {
					errorCallback(RIGISTER_CONSTANTS.ERROR_USER_EXIST);
				} else {
					generateOTPCall(companyId, emailID);
					setUserDetail({
						emailAddress: emailID,
						id: 0,
						name: userName,
						otpAttemptCount: 0,
						pinAttemptCount: 0,
					});
				}
			}
		});
	};

	const generateOTPCall = (companyId: number, emailID: string) => {
		generateOTP(companyId, emailID, (generateOTPResponse: IGenerateOTPResponse) => {
			if (generateOTPResponse.error) {
				UTENotifier.Error(LOGIN_CONSTANTS.ERROR_SENDING_OTP);
			} else {
				UTENotifier.Success(LOGIN_CONSTANTS.OTP_SENT);
			}
		});
	};

	const handleOTPSubmit = (callback: () => void) => {
		validateOTP(companyId, emailID, otp, (validateOTPResponse: IValidateOTPResponse) => {
			const newOTPValidity: IOTPValidity = { isValid: false, message: '' };
			if (validateOTPResponse.error) {
				UTENotifier.Error(LOGIN_CONSTANTS.ERROR_VERIFY_OTP);
				setOTPValidity(newOTPValidity);
				setOTP('');
				callback();
			} else {
				if (validateOTPResponse.validOTPDetail.status === OTPValidationStatus.Success) {
					newOTPValidity.isValid = true;
					setOTPValidity(newOTPValidity);
					setIsEmailVerified(true);
					setOtpRetryCount(0);
				} else if (validateOTPResponse.validOTPDetail.status === OTPValidationStatus.OTPExpired) {
					UTENotifier.Error(LOGIN_CONSTANTS.OTP_EXPIRED);
					setOtpRetryCount(otpRetryCount + 1);
					newOTPValidity.message = otpRetryCount >= 3 ? RIGISTER_CONSTANTS.ERROR_MAX_RETRY_OTP : '';
					setOTPValidity(newOTPValidity);
					setOTP('');
					callback();
				} else if (validateOTPResponse.validOTPDetail.status === OTPValidationStatus.InvalidOTP) {
					UTENotifier.Error(LOGIN_CONSTANTS.INVALID_OTP);
					setOtpRetryCount(otpRetryCount + 1);
					newOTPValidity.message = otpRetryCount >= 3 ? RIGISTER_CONSTANTS.ERROR_MAX_RETRY_OTP : '';
					setOTPValidity(newOTPValidity);
					setOTP('');
					callback();
				}
			}
		});
	};

	const handleResendCode = () => {
		generateOTP(companyId, emailID, (generateOTPResponse: IGenerateOTPResponse) => {
			if (generateOTPResponse.error) {
				UTENotifier.Error(LOGIN_CONSTANTS.ERROR_SENDING_OTP);
			} else {
				UTENotifier.Success(LOGIN_CONSTANTS.OTP_SENT);
			}
		});
	};

	const handlePINSubmit = () => {
		createAccount(
			companyId,
			userName,
			emailID,
			pin,
			countryCode || '',
			mobileNumber || '',
			(accountCreatedResponse: IAccountCreatedResponse) => {
				if (accountCreatedResponse.error) {
					setComponent('login');
					setAccountCreatedEmail('');
					UTENotifier.Error(RIGISTER_CONSTANTS.ERROR_ACCOUNT_CREATION);
				} else {
					if (accountCreatedResponse.accountCreated) {
						setComponent('login');
						setAccountCreatedEmail(emailID);
						UTENotifier.Success(RIGISTER_CONSTANTS.SUCCESS_ACCOUNT_CREATION);
					} else {
						setComponent('login');
						setAccountCreatedEmail('');
						UTENotifier.Error(RIGISTER_CONSTANTS.ERROR_ACCOUNT_CREATION);
					}
				}
			},
		);
	};

	return (
		<>
			{userDetail.emailAddress.length === 0 && (
				<CreateUsername
					emailID={emailID}
					handleSubmit={handleEmailIDSubmit}
					setEmailID={setEmailID}
					setUserName={setUserName}
					userName={userName}
					countryCode={countryCode}
					mobileNumber={mobileNumber}
					setCountryCode={setCountryCode}
					setMobileNumber={setMobileNumber}
				/>
			)}

			{userDetail.emailAddress.length > 0 && !isEmailVerified && (
				<UTEOTPInput
					otpLength={8}
					otp={otp}
					setOTP={setOTP}
					otpValidity={otpValidity}
					setOTPValidity={setOTPValidity}
					handleOTPSubmit={handleOTPSubmit}
					handleResendCode={handleResendCode}
				/>
			)}

			{isEmailVerified && otpValidity.isValid && (
				<CreatePIN
					pin={pin}
					setPIN={setPIN}
					handlePINSubmit={handlePINSubmit}
				/>
			)}
		</>
	);
};

export default RegisterComponent;
