import React, { useEffect, useState } from 'react';
import styles from './merge-emails.module.scss';
import { addMergeEmail, cancelMerge, deleteEmail, getMergeEmailList, validateOTP } from './merge-emails-api';
import {
	ADD_MERGE_EMAIL_ENUM,
	IAddMergeEmailResponse,
	ICancelMergeResponse,
	IDeleteEmailResponse,
	IMergeEmail,
	IMergeEmailListResponse,
	IValidateOTPResponse,
} from './merge-emails-types';
import { UTENotifier } from '../notification/ute-notifier';
import { MERGE_EMAIL_CONSTANTS } from 'src/helper/constants';
import UTEButton from 'src/components/ute-button';
import AddIcon from 'src/assets/svg/add-icon';
import { isValidEmailAddress, isValidNumber } from 'src/helper/utils';
import VerifyEmail from './verify-email';
import DeleteIcon from 'src/assets/svg/delete-icon';
import UTEModalLayout from 'src/components/ute-modal-layout';
import DeleteEmail from './delete-email';
import { logger } from 'src/context/ute-auth-context/auth-provider';
import LoadingOverlay from 'react-loading-overlay';
import Loader from 'src/components/loader';
import UTEClientViewWrapper from 'src/components/ute-client-view-wrapper';
import { OTPValidationStatus } from '../common/common-types';

const MergeEmails = () => {
	const [mergeEmailList, setMergeEmailList] = useState<IMergeEmail[]>([]);
	const [mergeEmailInput, setMergeEmailInput] = useState('');
	const [mergeEmailRequested, setMergeEmailRequested] = useState(false);
	const [addEmailError, setAddEmailError] = useState(false);
	const [existingEmailError, setExistingEmailError] = useState(false);
	const [isAddEmailDisabled, setIsAddEmailDisabled] = useState(false);
	const [verifyCodeInput, setVerifyCodeInput] = useState('');
	const [isVerifyCodeDisabled, setIsVerifyCodeDisabled] = useState(true);
	const [isCloseVerifyDisabled, setIsCloseVerifyDisabled] = useState(false);
	const [isCodeError, setIsCodeError] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteEmailId, setDeleteEmailId] = useState('');
	const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		fetchMergEmailList();
	}, []);

	const fetchMergEmailList = () => {
		setIsLoading(true);
		getMergeEmailList((mergeEmailListResponse: IMergeEmailListResponse) => {
			setIsLoading(false);
			if (mergeEmailListResponse.error) {
				logger && logger.trackWarning(MERGE_EMAIL_CONSTANTS.ERROR_FETCH_MERGE_EMAIL);
				UTENotifier.Error(MERGE_EMAIL_CONSTANTS.ERROR_FETCH_MERGE_EMAIL);
			} else {
				setMergeEmailList(mergeEmailListResponse.mergeEmailList);
			}
		});
	};

	const handleAddEmail = () => {
		if (mergeEmailInput.length > 0 && isValidEmailAddress(mergeEmailInput)) {
			setIsLoading(true);
			setIsAddEmailDisabled(true);
			addMergeEmail(mergeEmailInput, (addMergeEmailResponse: IAddMergeEmailResponse) => {
				setIsLoading(false);
				if (addMergeEmailResponse.error) {
					logger && logger.trackWarning(MERGE_EMAIL_CONSTANTS.ERROR_VALIDATE_EMAIL);
					UTENotifier.Error(MERGE_EMAIL_CONSTANTS.ERROR_VALIDATE_EMAIL);
					setIsAddEmailDisabled(false);
				} else if (addMergeEmailResponse.mergeEmailDetail === ADD_MERGE_EMAIL_ENUM.Error) {
					logger && logger.trackWarning(MERGE_EMAIL_CONSTANTS.ERROR_VALIDATE_EMAIL);
					UTENotifier.Error(MERGE_EMAIL_CONSTANTS.ERROR_VALIDATE_EMAIL);
					setIsAddEmailDisabled(false);
				} else if (addMergeEmailResponse.mergeEmailDetail === ADD_MERGE_EMAIL_ENUM.MaximumLimitReached) {
					logger && logger.trackWarning(MERGE_EMAIL_CONSTANTS.ERROR_MAX_EMAIL_REACHED);
					UTENotifier.Error(MERGE_EMAIL_CONSTANTS.ERROR_MAX_EMAIL_REACHED);
					setIsAddEmailDisabled(false);
				} else if (addMergeEmailResponse.mergeEmailDetail === ADD_MERGE_EMAIL_ENUM.FailedDueToExistingUTE) {
					logger && logger.trackWarning(MERGE_EMAIL_CONSTANTS.ERROR_EMAIL_ALREADY_LINKED);
					UTENotifier.Error(MERGE_EMAIL_CONSTANTS.ERROR_EMAIL_ALREADY_LINKED);
					setExistingEmailError(true);
					setIsAddEmailDisabled(false);
				} else {
					setIsCloseVerifyDisabled(false);
					setIsVerifyCodeDisabled(true);
					setIsCodeError(false);
					setMergeEmailRequested(true);
					setVerifyCodeInput('');
				}
			});
		} else {
			setAddEmailError(true);
		}
	};

	const handleVerifyCodeChange = (code: string) => {
		if (code === '' || isValidNumber(code)) {
			setVerifyCodeInput(code);
			setIsCodeError(false);
			if (code.length === 8) {
				setIsVerifyCodeDisabled(false);
			} else {
				setIsVerifyCodeDisabled(true);
			}
		}
	};

	const handleVerifyCodeSubmit = () => {
		setIsVerifyCodeDisabled(true);
		validateOTP(verifyCodeInput, mergeEmailInput, (validateOTPResponse: IValidateOTPResponse) => {
			if (validateOTPResponse.error) {
				logger && logger.trackWarning(MERGE_EMAIL_CONSTANTS.ERROR_VALIDATE_ACCESS_CODE);
				UTENotifier.Error(MERGE_EMAIL_CONSTANTS.ERROR_VALIDATE_ACCESS_CODE);
				setIsVerifyCodeDisabled(false);
			} else if (validateOTPResponse.otpDetail === OTPValidationStatus.Failure) {
				logger && logger.trackWarning(MERGE_EMAIL_CONSTANTS.ERROR_VALIDATE_ACCESS_CODE);
				UTENotifier.Error(MERGE_EMAIL_CONSTANTS.ERROR_VALIDATE_ACCESS_CODE);
				setIsVerifyCodeDisabled(false);
			} else if (validateOTPResponse.otpDetail === OTPValidationStatus.InvalidOTP) {
				logger && logger.trackWarning(MERGE_EMAIL_CONSTANTS.INVALID_ACCESS_CODE);
				UTENotifier.Error(MERGE_EMAIL_CONSTANTS.INVALID_ACCESS_CODE);
				setIsCodeError(true);
				setIsVerifyCodeDisabled(false);
			} else if (validateOTPResponse.otpDetail === OTPValidationStatus.OTPExpired) {
				logger && logger.trackWarning(MERGE_EMAIL_CONSTANTS.EXPIRED_ACCESS_CODE);
				UTENotifier.Error(MERGE_EMAIL_CONSTANTS.EXPIRED_ACCESS_CODE);
				setIsCodeError(true);
				setIsVerifyCodeDisabled(false);
			} else if (validateOTPResponse.otpDetail === OTPValidationStatus.Success) {
				logger && logger.trackTrace(MERGE_EMAIL_CONSTANTS.SUCCESS_VALIDATE_ACCESS_CODE);
				UTENotifier.Success(MERGE_EMAIL_CONSTANTS.SUCCESS_VALIDATE_ACCESS_CODE);
				setVerifyCodeInput('');
				setMergeEmailInput('');
				closeVerifyEmailSection();
				fetchMergEmailList();
			} else {
				logger && logger.trackWarning(MERGE_EMAIL_CONSTANTS.ERROR_VALIDATE_ACCESS_CODE);
				UTENotifier.Error(MERGE_EMAIL_CONSTANTS.ERROR_VALIDATE_ACCESS_CODE);
				setIsVerifyCodeDisabled(false);
			}
		});
	};

	const handleCancelMerge = () => {
		setIsCloseVerifyDisabled(true);
		cancelMerge(mergeEmailInput, (cancelMergeResponse: ICancelMergeResponse) => {
			if (cancelMergeResponse.error) {
				logger && logger.trackWarning(MERGE_EMAIL_CONSTANTS.ERROR_CANCEL_MERGE);
				UTENotifier.Error(MERGE_EMAIL_CONSTANTS.ERROR_CANCEL_MERGE);
				setIsCloseVerifyDisabled(false);
			} else if (cancelMergeResponse.isSuccess) {
				setVerifyCodeInput('');
				setIsCodeError(false);
				closeVerifyEmailSection();
			} else {
				logger && logger.trackWarning(MERGE_EMAIL_CONSTANTS.ERROR_CANCEL_MERGE);
				UTENotifier.Error(MERGE_EMAIL_CONSTANTS.ERROR_CANCEL_MERGE);
				setIsCloseVerifyDisabled(false);
			}
		});
	};

	const closeVerifyEmailSection = () => {
		setMergeEmailRequested(false);
		setIsAddEmailDisabled(false);
	};

	const onHideDeleteModal = (): void => {
		setShowDeleteModal(false);
		setDeleteEmailId('');
	};

	const handleDeleteEmailClick = (mergeEmail: IMergeEmail) => {
		if (!mergeEmail.isPrimary) {
			setShowDeleteModal(true);
			setDeleteEmailId(mergeEmail.emailId);
		}
	};

	const deleteMergedEmail = () => {
		setDeleteButtonDisabled(true);
		deleteEmail(deleteEmailId, (deleteEmailResponse: IDeleteEmailResponse) => {
			setDeleteButtonDisabled(false);
			if (deleteEmailResponse.error) {
				logger && logger.trackWarning(MERGE_EMAIL_CONSTANTS.ERROR_DELETE_EMAIL);
				UTENotifier.Error(MERGE_EMAIL_CONSTANTS.ERROR_DELETE_EMAIL);
			} else if (deleteEmailResponse.isSuccess) {
				logger && logger.trackTrace(MERGE_EMAIL_CONSTANTS.SUCCESS_DELETE_EMAIL);
				fetchMergEmailList();
			} else {
				logger && logger.trackWarning(MERGE_EMAIL_CONSTANTS.ERROR_DELETE_EMAIL);
				UTENotifier.Error(MERGE_EMAIL_CONSTANTS.ERROR_DELETE_EMAIL);
			}
			onHideDeleteModal();
		});
	};

	return (
		<section className='pageModuleContainer'>
			<LoadingOverlay
				active={isLoading}
				spinner={<Loader />}
				text=''>
				<h2 className='pageModuleHeader'>Merge Emails</h2>
				<p className='pageModuleDescription'>
					Access all documents from a single Client Portal
					<br />
					To view documents sent to other email IDs, please add them below
				</p>

				<div className={styles.mergeEmailContainer}>
					<div className={styles.emailListHeader}>
						<span className={`${styles.emailHeader} ${styles.emailColumn}`}>Email ID</span>
						<span className={styles.actionHeader}>Action</span>
					</div>
					<ul>
						{mergeEmailList.map((eachMergeEmail: IMergeEmail) => {
							return (
								<li
									key={eachMergeEmail.emailId}
									className={styles.mergeEmailRow}>
									<span
										title={eachMergeEmail.emailId}
										className={`${styles.emailData} ${styles.emailColumn}`}>
										{eachMergeEmail.emailId}
									</span>
									{eachMergeEmail.isPrimary ? (
										<span
											className={styles.actionData}
											style={{
												color: eachMergeEmail.isPrimary ? '#C4C6C8' : '#212529',
												cursor: eachMergeEmail.isPrimary ? 'not-allowed' : 'pointer',
											}}
											onClick={() => {
												handleDeleteEmailClick(eachMergeEmail);
											}}>
											<DeleteIcon color={eachMergeEmail.isPrimary ? '#C4C6C8' : '#CC4A43'} /> Delete
										</span>
									) : (
										<UTEClientViewWrapper dataTestAuto='34856aac-bcbf-46d4-979f-dc96c416d244'>
											<span
												className={styles.actionData}
												style={{
													color: eachMergeEmail.isPrimary ? '#C4C6C8' : '#212529',
													cursor: eachMergeEmail.isPrimary ? 'not-allowed' : 'pointer',
												}}
												onClick={() => {
													handleDeleteEmailClick(eachMergeEmail);
												}}>
												<DeleteIcon color={eachMergeEmail.isPrimary ? '#C4C6C8' : '#CC4A43'} /> Delete
											</span>
										</UTEClientViewWrapper>
									)}
								</li>
							);
						})}
					</ul>
					{mergeEmailList.length < 16 && (
						<div
							className={styles.mergeEmailRow}
							style={{ marginTop: '6px' }}>
							<span className={`${styles.inputEmailContainer} ${styles.emailColumn}`}>
								<input
									type='text'
									name='mergeEmailInput'
									id='mergeEmailInput'
									value={mergeEmailInput}
									onChange={(e) => {
										setMergeEmailInput(e.target.value);
										setAddEmailError(false);
										setExistingEmailError(false);
									}}
									placeholder='Add another email ID'
									className={`${styles.mergeEmailInput} ${
										addEmailError || existingEmailError ? styles.mergeEmailErrorBorder : ''
									}`}
									disabled={mergeEmailRequested}
								/>
							</span>
							<span className={styles.addEmailButtonContainer}>
								<UTEClientViewWrapper dataTestAuto='68cc0cc8-5e01-4473-abec-b2c6221ebb3c'>
									<UTEButton
										text={
											<span>
												<AddIcon color={`${mergeEmailRequested || isAddEmailDisabled ? '#c4c6c8' : '#FFF'}`} />
												Add
											</span>
										}
										className={styles.addEmailButton}
										onClick={handleAddEmail}
										disabled={mergeEmailRequested || isAddEmailDisabled}
										dataTestAuto='b86c2191-e276-4e7f-9779-ef8c21f60335'
									/>
								</UTEClientViewWrapper>
							</span>
						</div>
					)}
					{addEmailError && <div className={`${styles.mergeEmailRow} ${styles.addEmailError}`}>Invalid email.</div>}
				</div>
				{mergeEmailRequested && (
					<VerifyEmail
						emailID={mergeEmailInput}
						onClose={handleCancelMerge}
						handleAddEmail={handleAddEmail}
						verifyCodeInput={verifyCodeInput}
						isVerifyCodeDisabled={isVerifyCodeDisabled}
						isCodeError={isCodeError}
						handleVerifyCodeChange={handleVerifyCodeChange}
						handleVerifyCodeSubmit={handleVerifyCodeSubmit}
						isCloseVerifyDisabled={isCloseVerifyDisabled}
					/>
				)}
				{showDeleteModal && (
					<UTEModalLayout
						width='500'
						theme='light'
						title='Delete Merged Email?'
						hide={onHideDeleteModal}
						onClickOutside={() => {}}>
						<DeleteEmail
							emailId={deleteEmailId}
							hide={onHideDeleteModal}
							deleteMergedEmail={deleteMergedEmail}
							deleteButtonDisabled={deleteButtonDisabled}
						/>
					</UTEModalLayout>
				)}
			</LoadingOverlay>
		</section>
	);
};

export default MergeEmails;
