import axios from 'axios';
import { API_BASE_URL } from 'src/helper/constants';
import { apiCatchHandlerNoData } from 'src/helper/utils';
import { ITaxDocument } from 'src/modules/documents/documents-types';

export const getCPAMessage = (document: ITaxDocument, callback: (str: string) => void, errorCallback: () => void) => {
	const params = {
		messageId: document.messageGuid,
		fileType: document.fileType,
	};
	axios
		.get(`${API_BASE_URL}/api/document/cpa-message`, { params })
		.then((response) => {
			const data = response.data;
			callback(data.message);
		})
		.catch((error) => {
			apiCatchHandlerNoData(error, errorCallback);
		});
};
