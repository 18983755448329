import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from 'src/helper/constants';
import { IUpdatePINDetail, IUpdatePINDetailResponse } from './update-pin-types';

export const updatePIN = (
	updatePINDetail: IUpdatePINDetail,
	successCallback: (response: IUpdatePINDetailResponse) => any,
	errorCallback: () => void,
) => {
	axios
		.post(`${API_BASE_URL}/api/account/update-pin`, JSON.stringify(updatePINDetail), {
			headers: { 'Content-Type': 'application/json-patch+json' },
		})
		.then((response: AxiosResponse<IUpdatePINDetailResponse>) => {
			successCallback(response.data);
		})
		.catch(() => {
			errorCallback();
		});
};
