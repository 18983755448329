import React, { FC } from 'react';
import styles from './taxpayment-tabs.module.scss';
import { IPaymentTab } from '../taxpayment-types';
import { ProductType } from 'src/modules/common/common-types';

interface Props {
	tabs: IPaymentTab[];
	showTab: (tabId: ProductType) => void;
}
const TaxPaymentTabs: FC<Props> = (props) => {
	const handleTabClick = (tabId: ProductType) => {
		props.showTab(tabId);
	};

	return (
		<ul
			id='tabHeader'
			className={styles.tabHeader}>
			{props.tabs
				.filter((x) => x.visibility === true)
				.map((tab: any) => (
					<li
						id={`paymentTab-${tab.tabId}`}
						data-test-auto='1de9ab26-7934-49f7-a612-66514885afa6'
						className={`paymentTabSSR${tab.active ? ` ${styles.tabSelected}` : ''}`}
						onClick={() => {
							handleTabClick(tab.tabId);
						}}>
						{tab.tabHeading}
					</li>
				))}
		</ul>
	);
};

export default TaxPaymentTabs;
