import React from 'react';

const RightArrow = () => {
	return (
		<svg
			width='9'
			height='9'
			viewBox='0 0 9 9'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M0.864 8.456V7.336L8.224 4.088V4.728L0.864 1.464V0.36L8.752 3.896V4.92L0.864 8.456Z'
				fill='#565A5E'
			/>
		</svg>
	);
};

export default RightArrow;
