export type IRecoveryPopUpModel = {
	countryCode: string;
	phoneNumber: string;
	recoveryEmail: string;
};

export type IRecoveryModel = {
	id: number;
	emailAddress: string;
	phoneNumber: string;
	countryCode: string;
	skipCount: number;
	registrationId: number;
};

export const initialRecoveryModelResponse: IRecoveryModel = {
	id: 0,
	emailAddress: '',
	skipCount: 0,
	phoneNumber: '',
	countryCode: '',
	registrationId: 0,
};
