
export interface IContactInformationResponse {
	contactInformation: IContactInformation;
    error: boolean;
}

export type IContactInformation = {
	id: number;
	name: string;
	emailAddress: string;
	countryCode: string;
	mobileNumber: string;
}

export const initialContactInformation: IContactInformation = {
	id: 0,
    name: '',
    emailAddress: '',
    countryCode: '',
    mobileNumber: '',
};