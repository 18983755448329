import React from 'react';

type SVGProps = {
	color?: string;
};
const AddIcon = ({ color }: SVGProps) => {
	return (
		<svg
			width='17'
			height='16'
			viewBox='0 0 17 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_6631_6875)'>
				<path
					d='M8.5 14.9992C6.64348 14.9992 4.86301 14.2617 3.55025 12.9489C2.2375 11.6362 1.5 9.85569 1.5 7.99918C1.5 6.14266 2.2375 4.36218 3.55025 3.04943C4.86301 1.73667 6.64348 0.999176 8.5 0.999176C10.3565 0.999176 12.137 1.73667 13.4497 3.04943C14.7625 4.36218 15.5 6.14266 15.5 7.99918C15.5 9.85569 14.7625 11.6362 13.4497 12.9489C12.137 14.2617 10.3565 14.9992 8.5 14.9992ZM8.5 15.9992C10.6217 15.9992 12.6566 15.1563 14.1569 13.656C15.6571 12.1557 16.5 10.1209 16.5 7.99918C16.5 5.87744 15.6571 3.84261 14.1569 2.34232C12.6566 0.842031 10.6217 -0.000823975 8.5 -0.000823975C6.37827 -0.000823975 4.34344 0.842031 2.84315 2.34232C1.34285 3.84261 0.5 5.87744 0.5 7.99918C0.5 10.1209 1.34285 12.1557 2.84315 13.656C4.34344 15.1563 6.37827 15.9992 8.5 15.9992Z'
					fill={color}
				/>
				<path
					d='M8.5 3.99918C8.63261 3.99918 8.75979 4.05185 8.85355 4.14562C8.94732 4.23939 9 4.36657 9 4.49918V7.49918H12C12.1326 7.49918 12.2598 7.55185 12.3536 7.64562C12.4473 7.73939 12.5 7.86657 12.5 7.99918C12.5 8.13178 12.4473 8.25896 12.3536 8.35273C12.2598 8.4465 12.1326 8.49918 12 8.49918H9V11.4992C9 11.6318 8.94732 11.759 8.85355 11.8527C8.75979 11.9465 8.63261 11.9992 8.5 11.9992C8.36739 11.9992 8.24021 11.9465 8.14645 11.8527C8.05268 11.759 8 11.6318 8 11.4992V8.49918H5C4.86739 8.49918 4.74021 8.4465 4.64645 8.35273C4.55268 8.25896 4.5 8.13178 4.5 7.99918C4.5 7.86657 4.55268 7.73939 4.64645 7.64562C4.74021 7.55185 4.86739 7.49918 5 7.49918H8V4.49918C8 4.36657 8.05268 4.23939 8.14645 4.14562C8.24021 4.05185 8.36739 3.99918 8.5 3.99918Z'
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id='clip0_6631_6875'>
					<rect
						width='16'
						height='16'
						fill={color}
						transform='translate(0.5)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default AddIcon;
