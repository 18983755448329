import { useContext, useEffect, useState } from 'react';
import { useParams,useSearchParams } from 'react-router-dom';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';
import { InternalAccessTokenResponseVM } from 'src/modules/common/common-types';
import LoginLayout from 'src/modules/layout/login-layout';
import { fetchUserAccess } from 'src/modules/common/common-api';
import {
    IUserDetail,
} from 'src/context/ute-auth-context/auth-provider-types';
 
const UserAccess = () => {
    const { updateCompanyId, updateAuthToken, updateUserDetails, updateRefreshToken } =
        useContext(AuthContext);
    const [ userAccessValidated, setUserAccess] = useState(false);
    const { companyId, productType } = useParams();
    const [searchParams] = useSearchParams();
 
    useEffect(() => {
        let accessToken =  searchParams.get("token");
        if(!accessToken || !companyId){
            return;
        }
            updateCompanyId(Number(companyId));
            if(!userAccessValidated){
                fetchUserAccess(Number(companyId), Number(productType), String(searchParams.get("token")), (userAccessTokenResponseVM: InternalAccessTokenResponseVM) => {
                    if(userAccessTokenResponseVM.isSuccess)
                    {
                        updateAuthToken(userAccessTokenResponseVM.accessToken);
                        updateRefreshToken(userAccessTokenResponseVM.refreshToken);
                        let userDetail: IUserDetail = {
                            id: userAccessTokenResponseVM.id,
                            emailAddress: userAccessTokenResponseVM.emailAddress,
                            name: userAccessTokenResponseVM.name,  
                            otpAttemptCount: 0,
                            pinAttemptCount: 0,                   
                        };
                        updateUserDetails(userDetail);
                        setUserAccess(true);
                    }                    
                    window.location.href = "/";
                });
            }
    }, [userAccessValidated]);
 
    return (
        <></>
    );
};
 
export default UserAccess;