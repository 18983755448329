import React from 'react';

const ExpiryAlarmIcon = () => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.00009 15.3494C11.5547 15.3494 14.4363 12.4948 14.4363 8.97347C14.4363 5.45222 11.5547 2.59766 8.00009 2.59766C4.44553 2.59766 1.56396 5.45222 1.56396 8.97347C1.56396 12.4948 4.44553 15.3494 8.00009 15.3494ZM8.00009 5.60847C8.29628 5.60847 8.5364 5.84634 8.5364 6.13978V8.75347L10.1672 10.3689C10.3767 10.5764 10.3767 10.9128 10.1672 11.1203C9.95765 11.3278 9.61809 11.3278 9.40865 11.1203L7.62084 9.34922C7.52028 9.24953 7.46378 9.11447 7.46378 8.97347V6.13978C7.46378 5.84634 7.7039 5.60847 8.00009 5.60847Z'
				fill='#CC4A43'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.23675 0.900211C5.39375 1.14902 5.31738 1.47684 5.06619 1.63234L2.20569 3.4034C1.9545 3.55896 1.62356 3.48334 1.46663 3.23446C1.30963 2.98565 1.38594 2.65784 1.63713 2.50227L4.49769 0.731211C4.74888 0.575711 5.07975 0.651336 5.23675 0.900211Z'
				fill='#CC4A43'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10.7632 0.900211C10.9202 0.651336 11.2511 0.575711 11.5024 0.731211L14.3628 2.50227C14.6141 2.65784 14.6904 2.98565 14.5334 3.23446C14.3764 3.48327 14.0455 3.55896 13.7943 3.4034L10.9338 1.63234C10.6826 1.47684 10.6062 1.14902 10.7632 0.900211Z'
				fill='#CC4A43'
			/>
		</svg>
	);
};

export default ExpiryAlarmIcon;
