import React, { useEffect, useContext, useState } from 'react';
import {
	EXTSignatureStatus,
	ExchangeFileType,
	FileSystemType,
	ITaxDocument,
	ITaxRecord,
	SSRSignatureStatus,
	SIGSignatureStatus,
	SignatureStatus,
	OrganizerStatus,
	IGatherDocumentModel,
	IOrganizerDocumentModel,
	DocumentType,
	ITaxReturnDetails,
	SourceDocumentStatus,
} from '../documents-types';
import Folder from 'src/assets/svg/folder';
import ExpiryAlarmIcon from 'src/assets/svg/expiry-alarm';
import PaperClip from 'src/assets/svg/paper-clip';
import styles from './tree-node.module.scss';
import VerticalDivider from 'src/assets/svg/vertical-divider';
import { ClientTypes, ProductType, SignatureRedirectionSourcePage } from 'src/modules/common/common-types';
import { generateSignatureTaxpayerAPIURL } from '../../common/common-api';
import { IUserActivity, OneHubEventType } from '../../recent-activity/recent-activity-types';
import { saveUserActivity } from '../../recent-activity/recent-activity-api';
import Ellipsis from 'src/assets/svg/ellipsis';
import { DownArrowIcon, RightArrowIcon } from 'src/assets/svg/documents-arrow-icons';
import { getNumberOfFilesAndFoldersCount, getTaxYearFromName, isFilePreviewAvailable } from 'src/helper/utils';
import { ButtonToolbar, DropdownButton, OverlayTrigger, Popover } from 'react-bootstrap';
import { AuthContext } from '../../../context/ute-auth-context/auth-provider';
import {
	getFormattedDate,
	gtrOrgDownloadAction,
	openTaxPayerSummaryPage,
	saveGtrOrgDocumentPreViewAction,
	showDateToolTip,
	showGatherOrganizerDateToolTip,
	showGatherEsignDateToolTip,
	showNumberOfFilesText,
	getFormattedExpiryDate,
} from '../documents-utility-functions';
import UTEClientViewWrapper from 'src/components/ute-client-view-wrapper';

type TreeNodeProps = {
	node: ITaxRecord;
	level: number;
	showCCRecipientModal: (document: ITaxDocument) => void;
	showPreviewModal: (document: ITaxDocument, name: string) => void;
	fetchTaxDocuments: (taxYear: number) => void;
	parentId: string;
	expandLevelIds: string;
	handleTaxYearClick: (taxYear: number) => void;
	downloadExchangeFiles: (file: ITaxDocument) => void;
	showExchangePreviewModal: (file: ITaxDocument) => void;
	showCpaMessageModal: (file: ITaxDocument) => void;
	onShowGTRPreviewModal: (document: IGatherDocumentModel) => void;
	onShowORGPreviewModal: (document: IOrganizerDocumentModel) => void;
};

enum ActionType {
	None = 0,
	MoreActions = 1,
	Download = 2,
}

const TreeNode = ({
	node,
	level,
	showCCRecipientModal,
	showPreviewModal,
	fetchTaxDocuments,
	parentId,
	expandLevelIds,
	handleTaxYearClick,
	downloadExchangeFiles,
	showExchangePreviewModal,
	showCpaMessageModal,
	onShowGTRPreviewModal,
	onShowORGPreviewModal,
}: TreeNodeProps) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const { userDetail, isClientView } = useContext(AuthContext);

	useEffect(() => {
		const id = level === 0 ? node.name : parentId + ':::' + node.name;
		if (expandLevelIds?.indexOf(id) === 0 && !isExpanded) {
			setIsExpanded(true);
		}
	}, []);

	const handleToggle = () => {
		if (!isExpanded && level === 0) {
			const taxYear = getTaxYearFromName(node.name);
			handleTaxYearClick(taxYear);
		}
		setIsExpanded(!isExpanded);
	};

	const getFormattedStatus = (node: ITaxRecord) => {
		let element;
		const signatureStatus = node.documents.signatureStatus;
		const productType = node.documents.productType;
		if (productType === ProductType.Returns) {
			if (signatureStatus === SSRSignatureStatus.Uploaded) {
				element = <span className={styles.manuallySignedStyle}>Manually Signed</span>;
			} else if (signatureStatus === SSRSignatureStatus.PartiallySigned) {
				element = <span className={styles.partiallySignedStyle}>Partially Signed</span>;
			} else if (signatureStatus === SSRSignatureStatus.ESigned) {
				element = <span className={styles.eSignedStyle}>E-Signed</span>;
			} else if (signatureStatus === SSRSignatureStatus.ESignedInOffice) {
				element = <span className={styles.eSignedInOfficeStyle}>E-Signed &#8208; In Office</span>;
			} else if (signatureStatus === SSRSignatureStatus.SignedAndESigned) {
				element = <span className={styles.signedAndESignedStyle}>Signed And E-Signed</span>;
			}
			else if (signatureStatus === SSRSignatureStatus.AwaitingDraftReview) {
				element = <span className={styles.awaitingDraftReviewStyle}>Awaiting Draft Review</span>;
			}
		} else if (productType === ProductType.Extensions) {
			if (signatureStatus === EXTSignatureStatus.Uploaded) {
				element = <span className={styles.manuallySignedStyle}>Manually Signed</span>;
			} else if (signatureStatus === EXTSignatureStatus.PartiallySigned) {
				element = <span className={styles.partiallySignedStyle}>Partially Signed</span>;
			} else if (signatureStatus === EXTSignatureStatus.ESigned) {
				element = <span className={styles.eSignedStyle}>E-Signed</span>;
			} else if (signatureStatus === EXTSignatureStatus.Reviewed) {
				element = <span className={styles.reviewedStyle}>Reviewed</span>;
			} else if (signatureStatus === EXTSignatureStatus.PartiallyReviewed) {
				element = <span className={styles.partiallyReviewedStyle}>Partially Reviewed</span>;
			}
		} else if (productType === ProductType.SignaturesOneOff) {
			if (signatureStatus === SIGSignatureStatus.ESigned) {
				element = <span className={styles.eSignedStyle}>E-Signed</span>;
			} else if (signatureStatus === SIGSignatureStatus.PartiallySigned) {
				element = <span className={styles.partiallySignedStyle}>Partially Signed</span>;
			}
		}
		return element;
	};
	const getFormattedGatherOrganizerSignStatus = (node: ITaxRecord, isGather: boolean = true) => {
		const signatureStatus = isGather
			? node.gatherSignedDocuments.esignStatus
			: node.organizerSignedDocuments.esignStatus;
		let element;
		if (signatureStatus === SignatureStatus.ManuallySigned) {
			element = <span className={styles.manuallySignedStyle}>Manually Signed</span>;
		} else if (signatureStatus === SignatureStatus.PartiallySigned) {
			element = <span className={styles.partiallySignedStyle}>Partially Signed</span>;
		} else if (signatureStatus === SignatureStatus.Esigned) {
			element = <span className={styles.eSignedStyle}>E-Signed</span>;
		} else if (signatureStatus === SignatureStatus.Reviewed) {
			element = <span className={styles.reviewedStyle}>Reviewed</span>;
		} else if (signatureStatus === SignatureStatus.PartiallyReviewed) {
			element = <span className={styles.partiallyReviewedStyle}>Partially Reviewed</span>;
		} else if (signatureStatus === SignatureStatus.Downloaded) {
			element = <span className={styles.partiallyReviewedStyle}>Downloaded</span>;
		}
		return element;
	};

	const getFormattedGatherOrganizerStatus = (node: ITaxRecord, isGather: boolean = true) => {
		const organizerStatus = isGather ? node.gatherDocuments.organizerStatus : node.organizerDocuments.organizerStatus;
		let element;
		if (organizerStatus === OrganizerStatus.Completed || organizerStatus === OrganizerStatus.OrganizerDownloaded) {
			element = <span className={styles.completedStyle}>Completed</span>;
		} else if (organizerStatus === OrganizerStatus.ManuallyCompleted) {
			element = <span className={styles.manuallyCompletedStyle}>Manually Completed</span>;
		} else if (organizerStatus === OrganizerStatus.QuestionnaireCompleted) {
			element = <span className={styles.questionnaireCompletedStyle}>Questionnaire Completed</span>;
		} else if (organizerStatus === OrganizerStatus.PartiallyCompleted) {
			element = <span className={styles.partiallySignedStyle}>Partially Completed</span>;
		} else if (organizerStatus === OrganizerStatus.ClosedByFirm) {
			element = <span className={styles.completedStyle}>Closed By Firm</span>;
		}
		return element;
	};

	const getFormattedSDStatus = (node: ITaxRecord, isGather: boolean = true) => {
		const sourceDocumentStatus = isGather
			? node.sourceDocuments.sourceDocumentStatus
			: node.organizerSourceDocuments?.sourceDocumentStatus;
		let element;
		if (!!sourceDocumentStatus) {
			element = <span className={styles.reviewedStyle}>Uploaded</span>;
		} else {
			element = <></>;
		}
		return element;
	};

	const handleSignatureDownloadAction = (document: ITaxDocument) => {
		generateSignatureTaxpayerAPIURL(document.clientGuid, SignatureRedirectionSourcePage.Download);
		let userActivity: IUserActivity = {
			eventId: OneHubEventType.Download,
			eventData: {
				signingId: document.signingId,
				documentId: document.documentId,
				fileName: document.efileFormName,
				signerType: ClientTypes.Taxpayer,
				clientGuid: document.clientGuid,
			},
			productType: document.productType,
		};
		saveUserActivity(userActivity);
	};

	const handleGtrDownloadAction = (document: IGatherDocumentModel) => {
		const model = {
			clientGuid: document.clientGuid,
			productType: document.productType,
			documentId: document.gatherId,
			efileFormName:
				document.documentType === DocumentType.Organizer || document.documentType === DocumentType.CustomQuestion
					? document.efileFormName
					: document.documentType === DocumentType.Engagement ||
					  document.documentType === DocumentType.AdditionalEFile ||
					  document.documentType === DocumentType.OrganizerWithSignature
					? document.fileName ?? ''
					: document.fileName ?? document.sourceDocumentFileName ?? '',
			signerType: document.signerType,
		};
		gtrOrgDownloadAction(model as ITaxReturnDetails, isClientView);
	};

	const handleGtrPreviewAction = (document: IGatherDocumentModel) => {
		onShowGTRPreviewModal(document);
		if (!isClientView) {
			saveGtrOrgDocumentPreViewAction(document.gatherId, document.gatherGuid, document);
		}
	};

	const handleORGPreviewAction = (document: IOrganizerDocumentModel) => {
		let documentModel = {...document}
		onShowORGPreviewModal(documentModel);
		if (!isClientView) {
			saveGtrOrgDocumentPreViewAction(document.documentId, document.documentGuid, documentModel);
		}
	};

	const showExpiration = (node: ITaxRecord) => {
		return (
			node?.documents?.isShowExpiration ||
			node?.gatherDocuments?.isShowExpiration ||
			node?.gatherSignedDocuments?.isShowExpiration ||
			node?.sourceDocuments?.isShowExpiration ||
			node?.organizerDocuments?.isShowExpiration ||
			node?.organizerSignedDocuments?.isShowExpiration ||
			node?.organizerSourceDocuments?.isShowExpiration
		);
	};

	const handleORGDownloadAction = (document: IOrganizerDocumentModel) => {
		const model = {
			clientGuid: document.clientGuid,
			productType: document.productType,
			documentId: document.documentId,
			efileFormName:
				document.documentType === DocumentType.Organizer || document.documentType === DocumentType.CustomQuestion
					? document.efileFormName
					: document.documentType === DocumentType.Engagement ||
					  document.documentType === DocumentType.AdditionalEFile ||
					  document.documentType === DocumentType.OrganizerWithSignature
					? document.fileName ?? ''
					: document.fileName ?? document.sourceDocumentFileName ?? '',
			signerType: document.signerType,
		};
		gtrOrgDownloadAction(model as ITaxReturnDetails, isClientView);
	};

	const showNumberOfFiles = (node: ITaxRecord) => {
		const fileCount = getNumberOfFilesAndFoldersCount(node) || node.documentCount;
		return showNumberOfFilesText(fileCount);
	};

	const getSSEDateToolTip = (fileType: ExchangeFileType) => {
		return fileType == ExchangeFileType.ReceivedMessage ? 'Received' : 'Uploaded';
	};

	const createActions = (node: ITaxRecord) => {
		const productType = node.documents.productType;
		switch (productType) {
			case ProductType.Returns:
			case ProductType.Extensions:
				return createSSRActions();
			case ProductType.SignaturesOneOff:
				return createSignatureActions();
			case ProductType.Exchange:
				return createExchangeActions();
		}
	};

	const createSSRActions = () => {
		return (
			<ButtonToolbar className='btn-group-vertical'>
				<DropdownButton
					size='sm'
					title={<Ellipsis />}
					id='documentMoreActions'
					data-test-auto='271576c6-b729-4e50-b51a-480831e76dc4'
					className='documentMoreActions'>
					<li
						data-test-auto='b2970083-2474-4fc1-ae4f-080623edd883'
						key='Download'
						onClick={() => {
							openTaxPayerSummaryPage(node.documents, ActionType.Download, userDetail, isClientView);
						}}>
						Download
					</li>
					<li
						data-test-auto='a08f98d1-e6eb-4522-a97f-dcae59bf187a'
						key='Forward'
						onClick={() => {
							showCCRecipientModal(node.documents);
						}}>
						Forward
					</li>
					<li
						data-test-auto='F80C4006-FAA1-4821-8A0F-E5048F06AF68'
						key='More Actions'
						onClick={() => {
							openTaxPayerSummaryPage(node.documents, ActionType.MoreActions, userDetail, isClientView);
						}}>
						More Actions
					</li>
				</DropdownButton>
			</ButtonToolbar>
		);
	};
	const createExchangeActions = () => {
		return (
			<ButtonToolbar className='btn-group-vertical'>
				<DropdownButton
					size='sm'
					title={<Ellipsis />}
					id='sse-documentMoreActions'
					data-test-auto='271576c6-b729-4e50-b51a-480831e76dc4'
					className='documentMoreActions'>
					<UTEClientViewWrapper dataTestAuto='676239cf-fed9-41c9-9743-f8997cf9497e'>
						<li
							data-test-auto='b2970083-2474-4fc1-ae4f-080623edd883'
							key='Download'
							onClick={() => {
								downloadExchangeFiles(node.documents);
							}}>
							Download
						</li>
					</UTEClientViewWrapper>
					{
						/* Message from CPA */
						node.documents && node.documents.isMessagePresent && (
							<li
								onClick={() => {
									showCpaMessageModal(node.documents);
								}}>
								Message From CPA
							</li>
						)
					}
				</DropdownButton>
			</ButtonToolbar>
		);
	};

	const createSignatureActions = () => {
		return (
			<ButtonToolbar className='btn-group-vertical'>
				<DropdownButton
					size='sm'
					title={<Ellipsis />}
					id='documentMoreActions'
					data-test-auto='271576c6-b729-4e50-b51a-480831e76dc4'
					className='documentMoreActions'>
					<UTEClientViewWrapper dataTestAuto={'ef04bc7c-c151-41b0-9b68-df6141f31e7a'}>
						<li
							data-test-auto='b2970083-2474-4fc1-ae4f-080623edd883'
							key='Download'
							onClick={() => {
								handleSignatureDownloadAction(node.documents);
							}}>
							Download
						</li>
					</UTEClientViewWrapper>
				</DropdownButton>
			</ButtonToolbar>
		);
	};

	const createGTRActions = () => {
		return (
			<ButtonToolbar className='btn-group-vertical'>
				<DropdownButton
					size='sm'
					title={<Ellipsis />}
					id='documentMoreActions'
					data-test-auto='271576c6-b729-4e50-b51a-480831e76dc4'
					className='documentMoreActions'>
					<li
						data-test-auto='b2970083-2474-4fc1-ae4f-080623edd844'
						key='Download'
						onClick={() => {
							node.gatherDocuments && handleGtrDownloadAction(node.gatherDocuments);
							node.gatherSignedDocuments && handleGtrDownloadAction(node.gatherSignedDocuments);
							node.sourceDocuments && handleGtrDownloadAction(node.sourceDocuments);
						}}>
						Download
					</li>
				</DropdownButton>
			</ButtonToolbar>
		);
	};

	const createORGActions = () => {
		return (
			<ButtonToolbar className='btn-group-vertical'>
				<DropdownButton
					size='sm'
					title={<Ellipsis />}
					id='documentMoreActions'
					data-test-auto='271576c6-b729-4e50-b51a-480831e76dc4'
					className='documentMoreActions'>
					<li
						data-test-auto='b2970083-2474-4fc1-ae4f-080623edd844'
						key='Download'
						onClick={() => {
							node.organizerDocuments && handleORGDownloadAction(node.organizerDocuments);
							node.organizerSignedDocuments && handleORGDownloadAction(node.organizerSignedDocuments);
							node.organizerSourceDocuments && handleORGDownloadAction(node.organizerSourceDocuments);
						}}>
						Download
					</li>
				</DropdownButton>
			</ButtonToolbar>
		);
	};

	const showPopover = (toolTip: string) => {
		return (
			<Popover
				id='popover-basic'
				className='datePopover'>
				<Popover.Body className='popoverBody'>{toolTip}</Popover.Body>
			</Popover>
		);
	};
	const isExchangeFile = (node: ITaxRecord): boolean => {
		return node.documents && node.documents.productType === ProductType.Exchange;
	};
	return (
		<>
			<tr className={styles.documentRow}>
				<td className={styles.nameData}>
					<span
						id={level === 0 ? node.name : parentId + ':::' + node.name}
						data-expanded={isExpanded ? 'yes' : 'no'}
						className={node.type === FileSystemType.File ? styles.fileNameContainer : styles.nameContainer}
						onClick={handleToggle}
						style={{
							maxWidth: `calc(100% - ${level * 20}px)`,
							marginLeft: level * 20,
							cursor: node.type === FileSystemType.Folder ? 'pointer' : 'default',
						}}>
						{node.type === FileSystemType.Folder ? (
							<>
								{isExpanded ? <DownArrowIcon /> : <RightArrowIcon />} <Folder />
							</>
						) : node.type === FileSystemType.File ? (
							<span className={styles.clipContainer}>
								<PaperClip />
							</span>
						) : (
							''
						)}
						<span className={node.type === FileSystemType.File ? styles.displayName : ''} title={node.name || 'Signed Efile.pdf'}>{node.name || 'Signed Efile.pdf'}</span>
						{node.type === FileSystemType.File && showExpiration(node) ? (
							<OverlayTrigger
								placement='bottom'
								overlay={
									<Popover
										id='popover-basic'
										className='expiry-popover'>
										<Popover.Body>
											<div className='expiry-text-container'>
												<div className='expiry-text'>Access expires on {node && getFormattedExpiryDate(node)}</div>
												<div className='expiry-doc-name'>{node.name}</div>
											</div>
										</Popover.Body>
									</Popover>
								}>
								{({ ref, ...triggerHandler }) => (
									<span
										ref={ref}
										{...triggerHandler}
										className={styles.exipryAlarmIcon}>
										<ExpiryAlarmIcon />
									</span>
								)}
							</OverlayTrigger>
						) : (
							''
						)}
					</span>
				</td>
				<td className={styles.containsData}>{node.type === FileSystemType.Folder && showNumberOfFiles(node)}</td>
				<td className={styles.dateData}>
					{node.type === FileSystemType.File && node.documents && (
						<OverlayTrigger
							placement='bottom-end'
							delay={{ show: 100, hide: 100 }}
							overlay={showPopover(showDateToolTip(node.documents.toolTip))}>
							<span>{getFormattedDate(node.documents.signedOn)}</span>
						</OverlayTrigger>
					)}

					{node.type === FileSystemType.File && node.gatherDocuments && (
						<span title={showGatherOrganizerDateToolTip(node.gatherDocuments.organizerStatus)}>
							{getFormattedDate(node.gatherDocuments.updatedOn)}
						</span>
					)}

					{node.type === FileSystemType.File && node.sourceDocuments && (
						<span title={SourceDocumentStatus.Uploaded}>{getFormattedDate(node.sourceDocuments.updatedOn)}</span>
					)}

					{node.type === FileSystemType.File && node.gatherSignedDocuments && (
						<span title={showGatherEsignDateToolTip(node.gatherSignedDocuments.esignStatus)}>
							{getFormattedDate(node.gatherSignedDocuments.signedOn)}
						</span>
					)}
					{node.type === FileSystemType.File && node.organizerDocuments && (
						<span title={showGatherOrganizerDateToolTip(node.organizerDocuments.organizerStatus)}>
							{getFormattedDate(node.organizerDocuments.updatedOn)}
						</span>
					)}
					{node.type === FileSystemType.File && node.organizerSourceDocuments && (
						<span title={SourceDocumentStatus.Uploaded}>
							{getFormattedDate(node.organizerSourceDocuments.updatedOn)}
						</span>
					)}

					{node.type === FileSystemType.File && node.organizerSignedDocuments && (
						<span title={showGatherEsignDateToolTip(node.organizerSignedDocuments.esignStatus)}>
							{getFormattedDate(node.organizerSignedDocuments.signedOn)}
						</span>
					)}
				</td>
				<td className={styles.statusData}>
					{node.type === FileSystemType.File && node.documents && getFormattedStatus(node)}
					{node.type === FileSystemType.File && node.gatherDocuments && getFormattedGatherOrganizerStatus(node)}
					{node.type === FileSystemType.File &&
						node.gatherSignedDocuments &&
						getFormattedGatherOrganizerSignStatus(node)}
					{node.type === FileSystemType.File && node.sourceDocuments && getFormattedSDStatus(node)}
					{node.type === FileSystemType.File &&
						node.organizerDocuments &&
						getFormattedGatherOrganizerStatus(node, false)}
					{node.type === FileSystemType.File &&
						node.organizerSignedDocuments &&
						getFormattedGatherOrganizerSignStatus(node, false)}
					{node.type === FileSystemType.File && node.organizerSourceDocuments && getFormattedSDStatus(node, false)}
				</td>
				<td className={styles.actionsData}>
					{node.type === FileSystemType.File && node.documents && !isExchangeFile(node) && (
						<div className={styles.actionsContainer}>
							<span
								className={styles.view}
								onClick={() => {
									showPreviewModal(node.documents, node.name);
								}}>
								View
							</span>
							<span className={styles.verticalDivider}>
								<VerticalDivider />
							</span>
							<span>{createActions(node)}</span>
						</div>
					)}
					{node.type === FileSystemType.File && (
						<>
							{node.documents && isExchangeFile(node) && (
								<div className={styles.actionsContainer}>
									<span
										className={isFilePreviewAvailable(node.documents) ? styles.view : styles.hide}
										onClick={() => isFilePreviewAvailable(node.documents) && showExchangePreviewModal(node.documents)}>
										View
									</span>
									<span className={isFilePreviewAvailable(node.documents) ? styles.verticalDivider : styles.hide}>
										<VerticalDivider />
									</span>
									<span className={isFilePreviewAvailable(node.documents) ? '' : styles.spaceForEllipsis}>
										{createExchangeActions()}
									</span>
								</div>
							)}

							{(node.gatherDocuments || node.gatherSignedDocuments || node.sourceDocuments) && (
								<div className={styles.actionsContainer}>
									<span
										className={styles.view}
										onClick={() => {
											node.gatherDocuments && handleGtrPreviewAction(node.gatherDocuments);
											node.gatherSignedDocuments && handleGtrPreviewAction(node.gatherSignedDocuments);
											node.sourceDocuments && handleGtrPreviewAction(node.sourceDocuments);
										}}>
										View
									</span>
									<span className={styles.verticalDivider}>
										<VerticalDivider />
									</span>
									<span>{createGTRActions()}</span>
								</div>
							)}
							{(node.organizerDocuments || node.organizerSignedDocuments || node.organizerSourceDocuments) && (
								<div className={styles.actionsContainer}>
									<span
										className={styles.view}
										onClick={() => {
											node.organizerDocuments && handleORGPreviewAction(node.organizerDocuments);
											node.organizerSignedDocuments && handleORGPreviewAction(node.organizerSignedDocuments);
											node.organizerSourceDocuments && handleORGPreviewAction(node.organizerSourceDocuments);
										}}>
										View
									</span>
									<span className={styles.verticalDivider}>
										<VerticalDivider />
									</span>
									<span>{createORGActions()}</span>
								</div>
							)}
						</>
					)}
				</td>
			</tr>
			{isExpanded &&
				node.children &&
				node.children.map(
					(child: any) =>
						getNumberOfFilesAndFoldersCount(child) > 0 && (
							<TreeNode
								key={child.id}
								node={child}
								level={level + 1}
								showCCRecipientModal={showCCRecipientModal}
								showPreviewModal={showPreviewModal}
								fetchTaxDocuments={fetchTaxDocuments}
								parentId={level === 0 ? node.name : parentId + ':::' + node.name}
								expandLevelIds={expandLevelIds}
								downloadExchangeFiles={downloadExchangeFiles}
								showExchangePreviewModal={showExchangePreviewModal}
								showCpaMessageModal={showCpaMessageModal}
								onShowGTRPreviewModal={onShowGTRPreviewModal}
								onShowORGPreviewModal={onShowORGPreviewModal}
							/>
						),
				)}
		</>
	);
};

export default TreeNode;
