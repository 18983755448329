import React, { useState } from 'react';
import styles from './create-username.module.scss';
import { isValidEmailAddress } from 'src/helper/utils';
import UTEButton from 'src/components/ute-button';
import { RIGISTER_CONSTANTS, VALIDATION_CONSTANTS } from 'src/helper/constants';
import { ErrorInfoIcon, InfoIcon } from 'src/assets/svg/info-icon';
import PhoneInput from '@sssuite-component-ui/react-phone-input-2';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { UTENotifier } from 'src/modules/notification/ute-notifier';
import { isValidMobileNumber } from '@sssuite-js-packages/mobile-number-validator';

type CreateUsernameProps = {
	userName: string;
	setUserName: (emailID: string) => void;
	emailID: string;
	setEmailID: (emailID: string) => void;
	handleSubmit: (errorCallback: (message: string) => void) => void;
	setCountryCode: (countryCode: string) => void;
	setMobileNumber: (mobileNumber: string) => void;
	countryCode: string;
	mobileNumber: string;
};

const CreateUsername = ({
	userName,
	setUserName,
	emailID,
	setEmailID,
	handleSubmit,
	countryCode,
	mobileNumber,
	setCountryCode,
	setMobileNumber,
}: CreateUsernameProps) => {
	const [mobileNumberFullValue, setMobileNumberFullValue] = useState<string>('');
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const handleUserNameChange = (userNameValue: string) => {
		setUserName(userNameValue);
		setIsError(false);
		if (userNameValue.length > 0 && emailID.length > 0) {
			setIsSubmitDisabled(false);
		} else {
			setIsSubmitDisabled(true);
		}
	};

	const handleEmailIDChange = (emailIDValue: string) => {
		setEmailID(emailIDValue);
		setIsError(false);
		if (userName.length > 0 && emailIDValue.length > 0) {
			setIsSubmitDisabled(false);
		} else {
			setIsSubmitDisabled(true);
		}
	};

	const onClickContinue = () => {
		setIsError(false);
		setErrorMessage('');

		if (emailID.length > 0 && isValidEmailAddress(emailID) && userName.length > 0) {
			if (
				!validateMobileNumber(
					mobileNumber,
					countryCode,
					VALIDATION_CONSTANTS.EMPTY_PHONE_NUMBER_WARNING,
					VALIDATION_CONSTANTS.MOBILE_NUMBER_LENGTH_WARNING,
				)
			) {
				return;
			}
			setIsSubmitDisabled(true);
			handleSubmit((message: string) => {
				setIsError(true);
				setErrorMessage(message);
				setIsSubmitDisabled(false);
			});
		} else if (emailID.length > 0 && !isValidEmailAddress(emailID)) {
			setIsError(true);
			setErrorMessage(RIGISTER_CONSTANTS.INVALID_EMAIL_ID);
		} else {
			setIsError(true);
			setErrorMessage('');
		}
	};

	function validateMobileNumber(
		mobileNumber: string,
		countryCode: string,
		warningMessage?: string,
		lengthWarning?: string,
	): boolean {
		let isValid: boolean = true;
		if (mobileNumber && mobileNumber.trim()) {
			if (!countryCode || !countryCode.trim()) {
				isValid = false;
				UTENotifier.Warning(warningMessage ? warningMessage : VALIDATION_CONSTANTS.EMPTY_PHONE_NUMBER_WARNING);
			} else if (!isValidMobileNumber(countryCode, mobileNumber)) {
				isValid = false;
				UTENotifier.Warning(lengthWarning ? lengthWarning : VALIDATION_CONSTANTS.MOBILE_NUMBER_LENGTH_WARNING);
			}
		}
		return isValid;
	}

	const onMobileChange = (fullValue: string, countryObject: any): void => {
		const updatedValue = handleInvalidCountryCodeEntered(fullValue, countryObject);
		const countryCode = `+${countryObject.dialCode}`;
		let extractedMobileNumber = '';
		if (updatedValue.startsWith(countryCode)) {
			extractedMobileNumber = updatedValue.slice(countryCode.length);
		} else {
			extractedMobileNumber = updatedValue.replace(`+${countryObject.dialCode}`, '').replace(/^1/, '');
		}
		setMobileNumber(extractedMobileNumber);
		setCountryCode(countryCode);
		setMobileNumberFullValue(updatedValue);
	};

	const handleInvalidCountryCodeEntered = (value: string, countryObject: any): string => {
		if (value.startsWith(`+${countryObject.dialCode}`)) {
			return value;
		}
		return `+${countryObject.dialCode}${value.replace(/^\+/, '').replace(countryObject.dialCode, '')}`;
	};

	return (
		<>
			<div className='uteLoginHeader'>Register&nbsp;your&nbsp;account</div>
			<div className='uteLoginSubHeader'>Let's get started!</div>
			<div className='formInputHeader'>Name</div>
			<input
				maxLength={500}
				type='text'
				name='userName'
				id='userName'
				className={styles.userName}
				placeholder='Enter your name'
				value={userName}
				onChange={(e) => {
					handleUserNameChange(e.target.value);
				}}
				data-test-auto='afe63f31-4fb3-4e94-8c3b-3cb9fb50c293'
			/>
			<div className='formInputHeader'>Email ID</div>
			<div className={`${styles.emailContainer} ${isError && errorMessage.length > 0 && styles.error}`}>
				<input
					maxLength={320}
					type='text'
					name='emailID'
					id='emailID'
					className={styles.emailID}
					placeholder='Enter your email ID'
					value={emailID}
					onChange={(e) => {
						handleEmailIDChange(e.target.value);
					}}
					data-test-auto='f825b2b9-97f8-4187-8865-c2eeeceb614f'
				/>
				{isError && errorMessage.length > 0 && (
					<span className={styles.errorIconContainer}>
						<ErrorInfoIcon />
					</span>
				)}
			</div>
			{isError && <div className={styles.errorContainer}>{errorMessage}</div>}

			<div className='formInputHeader'>
				<div className={styles.headerContainer}>
					<span>
						<span>Phone Number</span>
						<OverlayTrigger
							trigger={['hover', 'focus']}
							placement='bottom'
							rootClose
							overlay={
								<Tooltip className='clientHubTooltip'>
									Your phone number will be used for authentication if you lose access to your email.
								</Tooltip>
							}>
							{({ ref, ...triggerHandler }) => (
								<span
									className={styles.switchSettingsIcon}
									data-test-auto='d5bf1213-0a4d-45eb-afef-bf4062f04e9a'
									ref={ref}
									{...triggerHandler}>
									<InfoIcon />
								</span>
							)}
						</OverlayTrigger>
					</span>
					<span className={styles.optionalText}>Optional</span>
				</div>
			</div>

			<div className={styles.phoneNumber}>
				<PhoneInput
					containerClass={'inputMobileNo'}
					specialLabel=''
					country={'us'}
					autoFormat={true}
					value={mobileNumberFullValue}
					onChange={onMobileChange}
				/>
			</div>

			<div className={styles.continueContainer}>
				<UTEButton
					text='Verify your email'
					className={styles.continue}
					onClick={onClickContinue}
					disabled={isSubmitDisabled}
					dataTestAuto='849fd46e-3fbf-4c91-ba5e-b81329c95e53'
				/>
			</div>
		</>
	);
};

export default CreateUsername;
