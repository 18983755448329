import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from 'src/helper/constants';
import {
	INotificationResponse,
	INotification,
	INotificationViewModel,
} from 'src/context/ute-notification-context/notification-provider-types';
import { apiCatchHandlerNoData } from 'src/helper/utils';

export const getNotifications = async (
	callback: (notificationsResponse: INotificationResponse) => void,
	errorCallback: () => void,
) => {
	const notificationsResponse: INotificationResponse = {
		notifications: [],
		error: false,
	};

	await axios
		.get(`${API_BASE_URL}/api/Notification`)
		.then((response: AxiosResponse<INotification[]>) => {
			const { data } = response;
			notificationsResponse.notifications = data;
			callback(notificationsResponse);
		})
		.catch((error) => {
			apiCatchHandlerNoData(error, errorCallback);
		});
};

export const getNotificationById = async (
	notificationId: number,
	callback: (notificationsResponse: INotificationResponse) => void,
	errorCallback: () => void,
) => {
	const notificationsResponse: INotificationResponse = {
		notifications: [],
		error: false,
	};

	await axios
		.get(`${API_BASE_URL}/api/Notification/${notificationId}`)
		.then((response: AxiosResponse<INotification>) => {
			const { data } = response;
			notificationsResponse.notifications.push(data);
			callback(notificationsResponse);
		})
		.catch((error) => {
			apiCatchHandlerNoData(error, errorCallback);
		});
};

export const clearNotifications = async (
	callback: (notificationsResponse: INotificationResponse) => void,
) => {
	const notificationsResponse: INotificationResponse = {
		notifications: [],
		error: false,
	};

	try {
		const response: AxiosResponse<INotification[]> = await axios.delete(
			`${API_BASE_URL}/api/Notification`,
		);
		notificationsResponse.notifications = [];
		callback(notificationsResponse);
	} catch (error) {
		notificationsResponse.error = true;
		callback(notificationsResponse);
	}
};

export const getClientGuId = async (
	notificationId: number,
	callback: (notificationModel: INotificationViewModel) => void,
	erroCallback: () => void,
) => {
	axios
		.get(`${API_BASE_URL}/api/Notification/get-clientGuid?notificationId=${notificationId}`)
		.then((response: AxiosResponse) => {
			callback(response.data);
		})
		.catch((error) => {
			apiCatchHandlerNoData(error, erroCallback);
		});
};

export const updateNotificationInfo = async (
	notificationId: number,
	callback: () => void,
	erroCallback: () => void,
) => {
	axios
		.post(`${API_BASE_URL}/api/Notification/update-notification?notificationId=${notificationId}`)
		.then(() => {
			callback();
		})
		.catch((error) => {
			apiCatchHandlerNoData(error, erroCallback);
		});
};
