import React, { useContext, useState } from 'react';
import './email-update-popup.style.scss';
import UTEButton from 'src/components/ute-button';
import { Modal, Col, Row } from 'react-bootstrap';
import { isValidEmailAddress, isValidNumber } from 'src/helper/utils';
import { CLIENT_VIEW_ACTION_NOT_PERMITTED, UPDATE_EMAIL_CONSTANTS } from 'src/helper/constants';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';
import { UTENotifier } from 'src/modules/notification/ute-notifier';

type EmailUpdatePopupProps = {
	showModal: boolean;
	onCancel: () => void;
	oldEmail: string;
	onUpdate: (email: string, successCallback: () => void, errorCallback: (errorMessage: string) => void) => void;
	handleResendCode: (emailId: string) => void;
	onVerifyOtp: (emailId: string, otp: string, errorCallback: () => void) => void;
};

const EmailUpdatePopup = ({
	showModal,
	onCancel,
	oldEmail,
	onUpdate,
	handleResendCode,
	onVerifyOtp,
}: EmailUpdatePopupProps) => {
	const [newEmail, setNewEmail] = useState('');
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showOTPVerification, setShowOTPVerification] = useState(false);
	const [isCodeError, setIsCodeError] = useState(false);
	const [verifyCodeInput, setVerifyCodeInput] = useState('');
	const [isVerifyCodeDisabled, setIsVerifyCodeDisabled] = useState(true);
	const { isClientView } = useContext(AuthContext);

	const handleEmailIDChange = (emailIDValue: string) => {
		setNewEmail(emailIDValue);
		setIsError(false);
		if (emailIDValue.length > 0) {
			setIsSubmitDisabled(false);
		} else {
			setIsSubmitDisabled(true);
		}
	};

	const onUpdateError = (errorMessage: string) => {
		setIsError(true);
		setIsSubmitDisabled(false);
		setErrorMessage(errorMessage);
	};

	const handleUpdateEmail = () => {
		if (isClientView) {
			UTENotifier.Warning(CLIENT_VIEW_ACTION_NOT_PERMITTED);
			return;
		}
		if (newEmail.length > 0 && isValidEmailAddress(newEmail)) {
			setIsSubmitDisabled(true);
			onUpdate(
				newEmail,
				() => {
					setShowOTPVerification(true);
					setIsSubmitDisabled(false);
				},
				onUpdateError,
			);
		} else if (newEmail.length === 0 || (newEmail.length > 0 && !isValidEmailAddress(newEmail))) {
			setIsError(true);
			setErrorMessage(UPDATE_EMAIL_CONSTANTS.INVALID_EMAIL_ID);
		} else {
			setIsError(true);
			setErrorMessage('');
		}
	};

	const onBackBtnClick = () => {
		setShowOTPVerification(false);
	};

	const handleVerifyCodeChange = (code: string) => {
		if (code === '' || isValidNumber(code)) {
			setVerifyCodeInput(code);
			setIsCodeError(false);
			if (code.length === 8) {
				setIsVerifyCodeDisabled(false);
			} else {
				setIsVerifyCodeDisabled(true);
			}
		}
	};

	const handleVerify = () => {
		setIsVerifyCodeDisabled(true);
		onVerifyOtp(newEmail, verifyCodeInput, () => {
			setIsCodeError(true);
			setIsVerifyCodeDisabled(false);
		});
	};

	return (
		<Modal
			className='email-update-popup-modal'
			show={showModal}
			onHide={() => {
				onCancel();
			}}>
			<Modal.Header
				closeButton
				data-test-auto='60334535-F01C-41D3-AC34-CA9BC7B804DD'>
				<Modal.Title>Update Email ID</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{!showOTPVerification && (
					<Row data-test-auto='F12E9E3A-5B0D-4463-9329-750FAA9B7D12'>
						<Row className='otpVerification'>
							<Col lg={12}>
								<span>Note: Verification of your new email ID is required to update current email ID.</span>
							</Col>
						</Row>
						<Col lg={12}>
							<div className='formInputHeader'>
								<strong>Current Email ID</strong>
							</div>
							<input
								maxLength={320}
								type='text'
								name='oldEmail'
								id='oldEmail'
								className='oldEmail'
								value={oldEmail}
								disabled={true}
								data-test-auto='afe63f31-4fb3-4e94-8c3b-3cb9fb50c293'
							/>

							<div className='formInputHeader'>
								<strong>New Email ID</strong>
							</div>
							<input
								placeholder='New Email ID'
								maxLength={320}
								type='text'
								name='newEmail'
								id='newEmail'
								className={`newEmail ${isError && 'error'}`}
								value={newEmail}
								data-test-auto='afe63f31-4fb3-4e94-8c3b-3cb9fb50c293'
								onChange={(e) => {
									handleEmailIDChange(e.target.value);
								}}
							/>
							{isError && <div className='errorContainer'>{errorMessage}</div>}
						</Col>
					</Row>
				)}
				{showOTPVerification && (
					<>
						<Row className='otpVerification'>
							<div>
								<span>Note: Verification of your new email ID is required to update current email ID.</span>
							</div>
						</Row>
						<div className='verifyMainContainer'>
							<section className='veryEmailContainer'>
								<header className='veryEmailHeader'>
									<span className='headerTitle'>Verify Email</span>
								</header>
								<div className='verifyDescription'>
									Please enter the verification code sent to <span>{newEmail}</span>
								</div>
								<div className='codeContainer'>
									<input
										type='text'
										name='verifyCodeInput'
										id='verifyCodeInput'
										className={`verifyCodeInput ${isCodeError ? 'errorBorder' : ''}`}
										value={verifyCodeInput}
										onChange={(e) => {
											handleVerifyCodeChange(e.target.value);
										}}
										placeholder='Access Code'
										maxLength={8}
									/>
								</div>
								<div className='resend'>
									Didn’t receive the code?{' '}
									<span
										onClick={() => {
											handleResendCode(newEmail);
										}}>
										Resend
									</span>
								</div>
							</section>
						</div>
					</>
				)}
			</Modal.Body>
			<Modal.Footer>
				{showOTPVerification && (
					<UTEButton
						dataTestAuto='B49E0BA5-C858-4E22-940C-4A8880C05D37'
						onClick={() => {
							onBackBtnClick();
						}}
						className='btn btn-back'
						disabled={false}
						text='Back'
					/>
				)}

				<UTEButton
					dataTestAuto='8559D534-3D2F-4B11-B47C-A67EF17DCBDC'
					onClick={() => {
						onCancel();
					}}
					className='btn btn-default'
					disabled={false}
					text='Cancel'
				/>
				{showOTPVerification ? (
					<UTEButton
						dataTestAuto='E881346E-DFE9-44CB-B962-BD65D6A90AE4'
						onClick={handleVerify}
						className='btn btn-primary'
						disabled={isVerifyCodeDisabled}
						text='Verify'
					/>
				) : (
					<UTEButton
						dataTestAuto='C0280D93-94DD-438B-9AB9-4F5BC452FE66'
						onClick={handleUpdateEmail}
						className='btn btn-primary'
						disabled={isSubmitDisabled}
						text='Update'
					/>
				)}
			</Modal.Footer>
		</Modal>
	);
};

export default EmailUpdatePopup;
