import React, { useState } from 'react';
import { IPaymentDetailResponseViewModel, ITaxingAuthority, IPaymentData } from '../taxpayment-types';
import UTEModalLayout from 'src/components/ute-modal-layout';
import styles from './taxpayment-list.module.scss';
import VerticalDivider from 'src/assets/svg/vertical-divider';
import UTEPagination from '../../../components/ute-pagination';
import PaymentInfoModal from '../taxpayment-modal';
import { EyeOpen } from 'src/assets/svg/eye-icons';
import PayIcon from 'src/assets/svg/pay-icon';
import { PaginationPageProps } from 'src/components/ute-pagination/ute-pagination-types';
import { TAXPAYEMENT_VIEW_CONSTANTS } from 'src/helper/constants';
import { ProductType } from 'src/modules/common/common-types';

type PaymentListProps = {
	taxingAuthorities: ITaxingAuthority[];
	paymentData: IPaymentDetailResponseViewModel;
	paymentList: IPaymentData;
	onPayAction: (productType: ProductType, clientId: string) => void;
	onPayViewAction: (productType: ProductType, clientId: string, callback: () => void) => void;
	taxReturnHeading: string;
	onPageChange: (newPageIndex: number, newPageSize: number, productType: ProductType) => void;
	page: PaginationPageProps;
};

const PaymentList = ({
	paymentList,
	paymentData,
	onPayViewAction,
	onPayAction,
	taxingAuthorities,
	taxReturnHeading,
	onPageChange,
	page,
}: PaymentListProps) => {
	const [clientGuid, setClientGuid] = useState('');
	const [showPaymentInfoModal, setPaymentInfoModal] = useState<boolean>(false);

	const onPaymentViewAction = (clientId: string) => {
		setClientGuid(clientId);
		onPayViewAction(paymentList.productType, clientId, () => {
			setPaymentInfoModal(true);
		});
	};

	const onPaymentAction = (clientId?: string) => {
		onPayAction(paymentList.productType, clientId ?? clientGuid);
	};

	const onPageSelection = (newPageIndex: number, newPageSize: number) => {
		onPageChange(newPageIndex, newPageSize, paymentList.productType);
	};

	const onHidePaymentInfoModal = (): void => {
		setPaymentInfoModal(false);
	};

	const getFormattedDate = (dateString: string) => {
		const tempString = dateString.substring(0, 10);
		const date = new Date(tempString);
		return `${date.toString().substring(4, 7)} ${date.getDate()}, ${date.getFullYear()}`;
	};

	return (
		<div>
			<section className={styles.taxPaymentsContainer}>
				<table>
					<thead>
						<th className={styles.nameHeader}>{taxReturnHeading}</th>
						<th className={styles.dateHeader}>Due&nbsp;Dates</th>
						<th className={styles.actionsHeader}>Actions</th>
					</thead>
					<tbody>
						{paymentList.documents && paymentList.documents.length === 0 ? (
							<tr style={{ height: '85px' }}>
								<td
									colSpan={3}
									className='text-center'>
									{TAXPAYEMENT_VIEW_CONSTANTS.NORECORDS_FOUND_MESSAGE}
								</td>
							</tr>
						) : (
							paymentList.documents &&
							paymentList.documents.map((document) => {
								return (
									<tr className={styles.taxPaymentRow}>
										<td className={styles.nameData}>
											<span
												className={styles.nameContainer}
												title={document.name}>
												{`${document.name} ${document.taxYear} ${'Tax Return'}`}
											</span>
										</td>
										<td className={styles.dateData}>{getFormattedDate(document.dueDate)}</td>
										<td className={styles.actionsData}>
											{
												<div className={styles.actionsContainer}>
													<span onClick={() => onPaymentViewAction(document.clientGuid)}>
														<EyeOpen /> View
													</span>
													<span className={styles.verticalDivider}>
														<VerticalDivider />
													</span>
													<span onClick={() => onPaymentAction(document.clientGuid)}>
														<PayIcon /> Pay
													</span>
												</div>
											}
										</td>
									</tr>
								);
							})
						)}
					</tbody>
				</table>
			</section>
			<UTEPagination
				count={paymentList.count}
				page={page}
				goToPage={onPageSelection}
			/>

			{showPaymentInfoModal && (
				<UTEModalLayout
					width='600'
					theme='light'
					className={styles.paymentInfoModal}
					title='Tax Payment View'
					hide={onHidePaymentInfoModal}>
					<PaymentInfoModal
						hide={onHidePaymentInfoModal}
						gotoPayment={onPaymentAction}
						paymentDetailResponseViewModel={paymentData}
						taxingAuthorities={taxingAuthorities}
					/>
				</UTEModalLayout>
			)}
		</div>
	);
};

export default PaymentList;
