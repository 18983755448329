import React from 'react';
import { ButtonToolbar, DropdownButton } from 'react-bootstrap';
import { DocumentsSort } from '../documents-types';
import CloseIconUTEModal from '../../../assets/svg/close-icon-ute-modal';
import BottomArrowUTEModal from '../../../assets/svg/bottom-arrow';
import TickIconModel from '../../../assets/svg/tick-icon';
import './documents-sort.style.scss';
type DocumentsSortComponentProps = {
	sortOrder: DocumentsSort;
	onSort: (sortOrderReceived: DocumentsSort) => void;
	onClearSort: () => void;
};

const DocumentsSortComponent = ({ sortOrder, onSort, onClearSort }: DocumentsSortComponentProps) => {
	return (
		<>
			<ButtonToolbar className='btn-group-vertical documentsSortClass'>
				<DropdownButton
					size='sm'
					title={
						<div className='titleName'>
							<span>
								Sort By
								{sortOrder === DocumentsSort.DESC ? (
									<span className='sortDataType'>&nbsp;Newest First</span>
								) : sortOrder === DocumentsSort.ASC ? (
									<span className='sortDataType'>&nbsp;Oldest First</span>
								) : (
									''
								)}
							</span>
							<span className='icons'>
								{sortOrder !== DocumentsSort.NONE && (
									<span
										data-test-auto=''
										className='cancelIcon'
										onClick={(e) => {
											e.stopPropagation();
											onClearSort();
										}}>
										<CloseIconUTEModal />
									</span>
								)}
								<span className='arrowIcon'>
									<BottomArrowUTEModal />
								</span>
							</span>
						</div>
					}
					id='sortByDropdown'
					data-test-auto='db607c8a-0a92-4395-bfcc-b908358de4de'
					className={`sortByDropdown${sortOrder !== DocumentsSort.NONE ? ' bigButton' : ''}`}>
					<li
						className={sortOrder === DocumentsSort.NONE ? 'lessWidth' : ''}
						data-test-auto='35c494b3-aa25-4490-ba81-c062c3f87491'
						key='Newest First'
						onClick={() => {
							onSort(DocumentsSort.DESC);
						}}>
						Newest First
						{<div className='tickIcon'>{sortOrder === DocumentsSort.DESC && <TickIconModel />}</div>}
					</li>
					<li
						className={sortOrder === DocumentsSort.NONE ? 'lessWidth' : ''}
						data-test-auto='a12c96a3-7790-43c1-aff7-ed865d800c6b'
						key='Oldest First'
						onClick={() => {
							onSort(DocumentsSort.ASC);
						}}>
						Oldest First
						{<div className='tickIcon'>{sortOrder === DocumentsSort.ASC && <TickIconModel />}</div>}
					</li>
				</DropdownButton>
			</ButtonToolbar>
		</>
	);
};
export default DocumentsSortComponent;
