import React, { FC, useEffect, useState } from 'react';
import styles from './ute-pagination.module.scss';
import { PaginationPageProps } from './ute-pagination-types';
import { DEFAULT_PAYMENTLIST_PER_PAGE } from 'src/helper/constants';
import { RightArrowIcon, LeftArrowIcon, RightDoubleArrowIcon, LeftDoubleArrowIcon } from 'src/assets/svg/arrow-icons';

interface PaginationProps {
	count: number;
	page: PaginationPageProps;
	goToPage: (pageIndex: number, pageSize: number) => void;
}

const UTEPagination: FC<PaginationProps> = (props) => {
	const { page, count } = props;
	const totalPages = Math.ceil(count / page.pageSize);

	const [pageIndexText, setPageIndexText] = useState(page.pageIndex.toString());

	const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		props.goToPage(1, Number(e.target.value));
	};
	const handlePageIndexChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const pattern = new RegExp(/^[0-9]*$/);
		if (pattern.test(e.target.value)) {
			setPageIndexText(e.target.value);
		}
	};
	const handlePageIndexEnter = (e: any) => {
		const newPageIndexString = e.target.value;
		if (e.key === 'Enter' && newPageIndexString.trim().length > 0 && page.pageIndex !== Number(newPageIndexString)) {
			props.goToPage(Number(newPageIndexString), page.pageSize);
		}
	};

	useEffect(() => {
		setPageIndexText(page.pageIndex.toString());
	}, [page.pageIndex, page.pageSize]);
	return (
		<section className={styles.utePagination}>
			<ul className={styles.showPageNumber}>
				<li
					data-test-auto='3ca9fd78-ed60-4d1b-b2fc-9ae8bc24ca53'
					title={page.pageIndex <= 1 ? '' : 'First Page'}
					className={page.pageIndex <= 1 ? styles.paginationActionDisabled : ''}
					onClick={() => props.goToPage(1, page.pageSize)}>
					<span>
						<LeftDoubleArrowIcon fillColor={page.pageIndex <= 1 ? '#C4C6C8' : '#0973BA'} />
					</span>
				</li>
				<li
					data-test-auto='89de0ca2-f796-4a40-9c41-7bea8fe15a7f'
					title={page.pageIndex <= 1 ? '' : 'Previous Page'}
					className={page.pageIndex <= 1 ? styles.paginationActionDisabled : ''}
					onClick={() => props.goToPage(page.pageIndex - 1, page.pageSize)}>
					<span>
						<LeftArrowIcon fillColor={page.pageIndex <= 1 ? '#C4C6C8' : '#0973BA'} />
					</span>
				</li>
				<li
					title={page.pageIndex.toString()}
					className={styles.currentPageNumber}>
					{page.pageIndex.toString()}
				</li>
				<li
					data-test-auto='c23ebac2-0b99-4d65-bd42-da6edb0e2f81'
					title={page.pageIndex >= totalPages ? '' : 'Next Page'}
					className={page.pageIndex >= totalPages ? styles.paginationActionDisabled : ''}
					onClick={() => props.goToPage(page.pageIndex + 1, page.pageSize)}>
					<span>
						<RightArrowIcon fillColor={page.pageIndex >= totalPages ? '#C4C6C8' : '#0973BA'} />
					</span>
				</li>
				<li
					data-test-auto='a108750f-7aee-43b3-9c11-bcbc843b6dc6'
					title={page.pageIndex >= totalPages ? '' : 'Last Page'}
					className={page.pageIndex >= totalPages ? styles.paginationActionDisabled : ''}
					onClick={() => props.goToPage(totalPages, page.pageSize)}>
					<span>
						<RightDoubleArrowIcon fillColor={page.pageIndex >= totalPages ? '#C4C6C8' : '#0973BA'} />
					</span>
				</li>
			</ul>
			<div className={styles.configurePagination}>
				<select
					name='pageSize'
					id='pageSize'
					className={`${styles.pageSize} ${
						count <= DEFAULT_PAYMENTLIST_PER_PAGE ? styles.paginationActionDisabled : ''
					}`}
					disabled={count <= DEFAULT_PAYMENTLIST_PER_PAGE}
					value={page.pageSize.toString()}
					onChange={handlePageSizeChange}>
					<option value='10'>10/page</option>
					<option value='15'>15/page</option>
					<option value='20'>20/page</option>
					<option value='25'>25/page</option>
					<option value='50'>50/page</option>
				</select>
				&nbsp;Go&nbsp;to&nbsp;
				<input
					type='text'
					name='goToPage'
					id='goToPage'
					className={`${styles.goToPage} ${
						count <= DEFAULT_PAYMENTLIST_PER_PAGE ? styles.paginationActionDisabled : ''
					}`}
					maxLength={totalPages.toString().length}
					value={pageIndexText}
					onChange={handlePageIndexChange}
					onKeyDown={handlePageIndexEnter}
					disabled={count <= DEFAULT_PAYMENTLIST_PER_PAGE}
				/>
				&nbsp;of&nbsp;<span className={styles.totalNumberOfPages}>{totalPages.toString()}</span>&nbsp;pages.
			</div>
		</section>
	);
};
export default UTEPagination;
