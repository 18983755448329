import { OTPValidationStatus } from '../common/common-types';

export type IMergeEmail = {
	emailId: string;
	isPrimary: boolean;
};

export type IMergeEmailListResponse = {
	mergeEmailList: IMergeEmail[];
	error: boolean;
};

export enum ADD_MERGE_EMAIL_ENUM {
	Success = 0,
	FailedDueToExistingUTE = 1,
	MaximumLimitReached = 2,
	Error = 3,
}

export type IAddMergeEmailResponse = {
	mergeEmailDetail: ADD_MERGE_EMAIL_ENUM;
	error: boolean;
};

export type IValidateOTPResponse = {
	otpDetail: OTPValidationStatus;
	error: boolean;
};

export type ICancelMergeResponse = {
	isSuccess: boolean;
	error: boolean;
};

export type IDeleteEmailResponse = {
	isSuccess: boolean;
	error: boolean;
};
