import { useState } from 'react';
import styles from './enter-pin.module.scss';
import { EyeClose, EyeOpen } from 'src/assets/svg/eye-icons';
import UTEButton from 'src/components/ute-button';
import { MAX_NUMBER_OF_ATTEMPTS } from 'src/helper/constants';
import { isValidNumber } from 'src/helper/utils';

type EnterPINProps = {
	pin: string;
	setPIN: (pin: string) => void;
	numberOfAttemptsLeft: number;
	handlePINSubmit: (callback: () => void) => void;
	setComponent: (component: string) => void;
};
const EnterPIN = ({ pin, setPIN, handlePINSubmit, numberOfAttemptsLeft, setComponent }: EnterPINProps) => {
	const [inputType, setInputType] = useState('password');
	const [isPINDisabled, setIsPINDisabled] = useState(true);

	const handlePINChange = (pinValue: string) => {
		if (pinValue === '' || isValidNumber(pinValue)) {
			setPIN(pinValue);
			if (pinValue.length === 6) {
				setIsPINDisabled(false);
			} else {
				setIsPINDisabled(true);
			}
		}
	};

	const handleEyeClick = () => {
		setInputType(inputType === 'password' ? 'text' : 'password');
	};
	const handleForgotPINClick = () => {
		setComponent('reset-pin');
	};

	const onClickContinue = () => {
		setIsPINDisabled(true);
		handlePINSubmit(() => {
			setIsPINDisabled(false);
		});
	};

	return (
		<>
			<div className='uteLoginHeader'>Enter&nbsp;your&nbsp;PIN</div>
			<div className='uteLoginSubHeader'>
				Oops! It seems we don’t recognize this device.
				<br />
				Please enter your PIN to continue.
			</div>
			<div className='formInputHeader'>PIN</div>
			<div
				className={`${styles.pinContainer} ${
					numberOfAttemptsLeft < MAX_NUMBER_OF_ATTEMPTS && numberOfAttemptsLeft > 0 && styles.error
				}`}>
				<input
					type={inputType}
					name='pin'
					id='pin'
					className={styles.pin}
					placeholder='Enter your 6-digit PIN'
					value={pin}
					onChange={(e) => {
						handlePINChange(e.target.value);
					}}
					maxLength={6}
					data-test-auto='1b73a6f2-1264-43c1-873e-cc21cecd9f99'
				/>
				<span
					className={styles.eyeContainer}
					onClick={handleEyeClick}>
					{inputType === 'password' ? <EyeClose /> : <EyeOpen />}
				</span>
			</div>

			{numberOfAttemptsLeft > 0 && (
				<div className={styles.invalidFeedback}>Incorrect PIN! you have {numberOfAttemptsLeft} more attempts left.</div>
			)}
			<div className={styles.forgotPIN}>
				<span
					className='formInputForgotText'
					onClick={handleForgotPINClick}>
					Forgot PIN?
				</span>
			</div>
			<div className={styles.continueContainer}>
				<UTEButton
					text='Continue'
					className={styles.continue}
					onClick={onClickContinue}
					disabled={isPINDisabled}
					dataTestAuto='bfaa4066-94aa-4de8-85ae-faa129de26c4'
				/>
			</div>
		</>
	);
};

export default EnterPIN;
