export const LockedIcon: React.FC = () => {
	return (
		<svg
			width='86'
			height='86'
			viewBox='0 0 86 86'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M23.3125 34.4H72.0037C72.0037 34.4 75.2497 34.4 75.2497 37.8298V78.9869C75.2497 78.9869 75.2497 82.4167 72.0037 82.4167H23.3125C23.3125 82.4167 20.0664 82.4167 20.0664 78.9869V37.8298C20.0664 37.8298 20.0664 34.4 23.3125 34.4Z'
				fill='#B5D5EA'
				stroke='#B5D5EA'
				strokeWidth='4.1875'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M39.5684 53.2999C39.5684 54.2104 39.9301 55.0837 40.5739 55.7276C41.2178 56.3714 42.0911 56.7331 43.0016 56.7331C43.9122 56.7331 44.7855 56.3714 45.4293 55.7276C46.0732 55.0837 46.4349 54.2104 46.4349 53.2999C46.4349 52.3893 46.0732 51.516 45.4293 50.8722C44.7855 50.2283 43.9122 49.8666 43.0016 49.8666C42.0911 49.8666 41.2178 50.2283 40.5739 50.8722C39.9301 51.516 39.5684 52.3893 39.5684 53.2999Z'
				stroke='#0973BA'
				strokeWidth='4.1875'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M43 56.7332V67.033'
				stroke='#0973BA'
				strokeWidth='4.1875'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M17.2497 34.4165H68.7489C68.7489 34.4165 72.1822 34.4165 72.1822 37.8498V79.0492C72.1822 79.0492 72.1822 82.4824 68.7489 82.4824H17.2497C17.2497 82.4824 13.8164 82.4824 13.8164 79.0492V37.8498C13.8164 37.8498 13.8164 34.4165 17.2497 34.4165Z'
				stroke='#0973BA'
				strokeWidth='4.1875'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M24.1152 22.4006C24.1152 17.3925 26.1047 12.5896 29.646 9.04829C33.1872 5.50704 37.9902 3.51758 42.9983 3.51758C48.0064 3.51758 52.8094 5.50704 56.3506 9.04829C59.8919 12.5896 61.8813 17.3925 61.8813 22.4006V34.4171H24.1152V22.4006Z'
				stroke='#0973BA'
				strokeWidth='4.1875'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
