import { Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import UTEButton from 'src/components/ute-button';
import CompletedTasksList from './completed-tasks-list';
import { ICompletedTaxReturn } from '../dashboard-types';
import { PaginationPageProps } from 'src/components/ute-pagination/ute-pagination-types';
import { DEFAULT_PAYMENTLIST_PER_PAGE } from 'src/helper/constants';
import CompletedTasksPagination from './completed-tasks-pagination';
import './completed-tasks-modal.style.scss';

type CompletedTasksModalProps = {
	completedTasks: ICompletedTaxReturn[];
	closeCompletedTasksModal: () => void;
};

const CompletedTasksModal = ({ completedTasks, closeCompletedTasksModal }: CompletedTasksModalProps) => {
	const totalCompletedTasks = completedTasks.length;
	const [tempCompletedTasks, setTempCompletedTasks] = useState<ICompletedTaxReturn[]>([]);
	const [page, setPage] = useState<PaginationPageProps>({ pageIndex: 1, pageSize: DEFAULT_PAYMENTLIST_PER_PAGE });

	useEffect(() => {
		setTempCompletedTasks(completedTasks.slice(0, 10));
	}, []);

	const onPageChange = (newPageIndex: number, newPageSize: number) => {
		const totalPages = Math.ceil(totalCompletedTasks / page.pageSize);
		if (
			(newPageIndex !== page.pageIndex && newPageIndex <= totalPages && newPageIndex >= 1) ||
			newPageSize !== page.pageSize
		) {
			const newPage = { ...page };
			newPage.pageIndex = newPageIndex;
			newPage.pageSize = newPageSize;
			setPage(newPage);
			setTempCompletedTasks(completedTasks.slice((newPageIndex - 1) * newPageSize, newPageIndex * newPageSize));
		}
	};

	return (
		<div className='completed-tasks-modal'>
			<Modal.Body>
				<CompletedTasksList completedTasksList={tempCompletedTasks} />
				<CompletedTasksPagination
					count={totalCompletedTasks}
					page={page}
					goToPage={onPageChange}
				/>
			</Modal.Body>
			<Modal.Footer>
				<UTEButton
					className='btn btn-primary'
					onClick={closeCompletedTasksModal}
					disabled={false}
					dataTestAuto='d57cb908-af8d-4538-a15b-fa414fae9e06'
					text='OK'
				/>
			</Modal.Footer>
		</div>
	);
};

export default CompletedTasksModal;
