import React from 'react';
import styles from './documents-head.module.scss';
import UTESearchBox from 'src/components/ute-search-box';
import SearchIcon from 'src/assets/svg/search-icon';
import UTEButton from 'src/components/ute-button';
import FilterIcon from 'src/assets/svg/filter-icon';
import { DocumentsSort, IFilter } from '../documents-types';
import DocumentsSortComponent from '../documents-sort';
import { generateExchangeTaxpayerAPIURL } from 'src/modules/common/common-api';
import { SendFilesIcon } from 'src/assets/svg/send-files-icon';
import UTEClientViewWrapper from 'src/components/ute-client-view-wrapper';

type DocumentsHeadProps = {
	searchText: string;
	showClearButton: boolean;
	onChangeSearchText: (searchText: string) => void;
	onSearch: () => void;
	onClearSearch: () => void;
	onClickFilter: () => void;
	sortOrder: DocumentsSort;
	onSort: (sortOrderReceived: DocumentsSort) => void;
	onClearSort: () => void;
	filter: IFilter;
	showSendFiles: boolean;
};
const DocumentsHead = ({
	searchText,
	showClearButton,
	onChangeSearchText,
	onSearch,
	onClearSearch,
	onClickFilter,
	filter,
	sortOrder,
	onSort,
	onClearSort,
	showSendFiles
}: DocumentsHeadProps) => {
	const showText = () => {
		const { fromDate, toDate, filterStatus, filterFileType } = filter;
		let count = 0;
		const filterStatusArray = filterStatus.length > 0 ? filterStatus.split(',') : [];
		const filterFileTypeArray = filterFileType.length > 0 ? filterFileType.split(',') : [];
		if (fromDate && toDate) count++;
		count += filterStatusArray.length + filterFileTypeArray.length;
		if (count === 0) {
			return 'Filter';
		} else {
			return (
				<span>
					Filter&nbsp;/&nbsp;<span className={styles.count}>{count}</span>
				</span>
			);
		}
	};

	return (
		<section className={styles.documentsHeadContainer}>
			<div className={styles.headActionsContainer}>
				<div className={styles.leftActions}>
					<UTESearchBox
						searchText={searchText}
						placeholderText='Search'
						searchIcon={<SearchIcon />}
						disabled={searchText.length < 2}
						searchOnClick={true}
						showClearButton={showClearButton}
						totalWidth='350px'
						height='38px'
						maxLength={260}
						onChangeSearchText={onChangeSearchText}
						onSearch={onSearch}
						onClearSearch={onClearSearch}
					/>
					<UTEButton
						text={showText()}
						icon={<FilterIcon />}
						className={styles.filter}
						onClick={onClickFilter}
						disabled={false}
						dataTestAuto='8c4abcc5-dd8f-420a-805e-c42d1697a0d7'
					/>
				</div>
				<div className={styles.rightActions}>
					<DocumentsSortComponent
						sortOrder={sortOrder}
						onSort={onSort}
						onClearSort={onClearSort}
					/>
					{
						showSendFiles && (
							<UTEClientViewWrapper dataTestAuto='6a836002-9d92-4567-9913-4bb349bd311e'>
								<UTEButton
									icon={React.createElement(SendFilesIcon, { className: styles.sendFilesIcon })}
									className={styles.btnSendFiles}
									dataTestAuto='65c92414-e726-4f38-a333-023fca374731'
									text='Send Files'
									onClick={() => {
										generateExchangeTaxpayerAPIURL();
									}}
									disabled={false}
									title='Send files to the firm'
								/>
							</UTEClientViewWrapper>
						)
					}
				</div>
			</div>
		</section>
	);
};

export default DocumentsHead;
