import axios, { AxiosResponse } from 'axios';
import {
	IDashboardOverview,
	IDashboardOverview_Products,
	IDashboardOverviewResponse,
	IPendingTaxReturn,
	IPendingTaxReturnResponse,
	initialDashboardData,
	IPendingSignatureReportResponse,
	IPendingSignatureReport,
	IPendingDocumentRequestResponse,
	IPendingDocumentRequest,
	ICompletedTaxReturn,
	ICompletedTaxReturnResponse,
} from './dashboard-types';
import { API_BASE_URL } from 'src/helper/constants';
import { apiCatchHandler } from 'src/helper/utils';
import { ProductType } from '../common/common-types';

export const getDashboardDetails = async (callback: (dashboardDetailsResponse: IDashboardOverviewResponse) => void) => {
	const dashboardDetailsResponse: IDashboardOverviewResponse = {
		dashboardDetails: initialDashboardData,
		error: false,
	};

	try {
		const [homePageResponse_SSR, homePageResponse_SSEXT, homePageResponse_SIG, homePageResponse_SSE] =
			await Promise.all([
				axios.get(`${API_BASE_URL}/api/dashboard/home-page-layout`, { withCredentials: true }),
				axios.get(`${API_BASE_URL}/api/dashboard/ssext-home-page-layout`, { withCredentials: true }),
				axios.get(`${API_BASE_URL}/api/dashboard/sig-home-page-layout`, { withCredentials: true }),
				axios.get(`${API_BASE_URL}/api/dashboard/sse-home-page-layout`, { withCredentials: true }),
			]);

		const homePageData_SSR: IDashboardOverview = homePageResponse_SSR.data;
		const homePageData_SSEXT: IDashboardOverview_Products = homePageResponse_SSEXT.data;
		const homePageData_SIG: IDashboardOverview_Products = homePageResponse_SIG.data;
		const homePageData_SSE: IDashboardOverview_Products = homePageResponse_SSE.data;

		dashboardDetailsResponse.dashboardDetails = {
			...homePageData_SSR,
			pendingTasksCount:
				homePageData_SSR.pendingTasksCount +
				homePageData_SSEXT.pendingTasksCount +
				homePageData_SIG.pendingTasksCount +
				homePageData_SSE.pendingTasksCount,
			completedTasksCount:
				homePageData_SSR.completedTasksCount +
				homePageData_SSEXT.completedTasksCount +
				homePageData_SIG.completedTasksCount +
				homePageData_SSE.completedTasksCount,
		};

		callback(dashboardDetailsResponse);
	} catch (error: any) {
		dashboardDetailsResponse.error = true;
		apiCatchHandler(error, dashboardDetailsResponse, callback);
	}
};

export const getEXTPendingTasks = (callback: (pendingTasksResponse: IPendingTaxReturnResponse) => void) => {
	const pendingTasksResponse: IPendingTaxReturnResponse = {
		pendingTaxReturns: [],
		error: false,
	};

	axios
		.get(`${API_BASE_URL}/api/dashboard/extension-pending-sign-tax-return`, { withCredentials: true })
		.then((response: AxiosResponse<IPendingTaxReturn[]>) => {
			const { data } = response;
			pendingTasksResponse.pendingTaxReturns = data;
			callback(pendingTasksResponse);
		})
		.catch((error) => {
			pendingTasksResponse.error = true;
			apiCatchHandler(error, pendingTasksResponse, callback);
		});
};

export const getSSRPendingTasks = (callback: (pendingTasksResponse: IPendingTaxReturnResponse) => void) => {
	const pendingTasksResponse: IPendingTaxReturnResponse = {
		pendingTaxReturns: [],
		error: false,
	};

	axios
		.get(`${API_BASE_URL}/api/dashboard/pending-sign-tax-return`, { withCredentials: true })
		.then((response: AxiosResponse<IPendingTaxReturn[]>) => {
			const { data } = response;
			pendingTasksResponse.pendingTaxReturns = data;
			callback(pendingTasksResponse);
		})
		.catch((error) => {
			pendingTasksResponse.error = true;
			apiCatchHandler(error, pendingTasksResponse, callback);
		});
};

export const getPendingSignatureTasks = async (
	callback: (pendingSignatureTasksResponse: IPendingSignatureReportResponse) => void,
) => {
	const pendingSignatureTasksResponse: IPendingSignatureReportResponse = {
		pendingSignatureReports: [],
		error: false,
	};

	axios
		.get(`${API_BASE_URL}/api/dashboard/signature-pending-reports`, { withCredentials: true })
		.then((response: AxiosResponse<IPendingSignatureReport[]>) => {
			const { data } = response;
			pendingSignatureTasksResponse.pendingSignatureReports = data;
			callback(pendingSignatureTasksResponse);
		})
		.catch((error) => {
			pendingSignatureTasksResponse.error = true;
			apiCatchHandler(error, pendingSignatureTasksResponse, callback);
		});
};

export const getSSEPendingTasks = async (callback: (documentResponse: IPendingDocumentRequestResponse) => void) => {
	const documentResponse: IPendingDocumentRequestResponse = {
		pendingDocumentRequests: [],
		error: false,
	};

	axios
		.get(`${API_BASE_URL}/api/dashboard/pending-document-request`, { withCredentials: true })
		.then((response: AxiosResponse<IPendingDocumentRequest[]>) => {
			const { data } = response;
			documentResponse.pendingDocumentRequests = data;
			callback(documentResponse);
		})
		.catch((error) => {
			documentResponse.error = true;
			apiCatchHandler(error, documentResponse, callback);
		});
};

export const getPendingTasks = (
	product: ProductType,
	callback: (pendingTasksResponse: IPendingTaxReturnResponse) => void,
) => {
	const pendingTasksResponse: IPendingTaxReturnResponse = {
		pendingTaxReturns: [],
		error: false,
	};

	axios
		.get(`${API_BASE_URL}/api/dashboard/pending-sign-tax-return/${product}`, { withCredentials: true })
		.then((response: AxiosResponse<IPendingTaxReturn[]>) => {
			const { data } = response;
			pendingTasksResponse.pendingTaxReturns = data;
			callback(pendingTasksResponse);
		})
		.catch((error) => {
			pendingTasksResponse.error = true;
			apiCatchHandler(error, pendingTasksResponse, callback);
		});
};

export const getCompletedTasks = async (callback: (completedTasksResponse: ICompletedTaxReturnResponse) => void) => {
	const completedTasksResponse: ICompletedTaxReturnResponse = {
		completedTasks: [],
		error: false,
	};

	try {
		const [completedTasksResponse1, completedTasksResponse2 ,completedTasksResponse3] = await Promise.all([
			axios.get<ICompletedTaxReturn[]>(`${API_BASE_URL}/api/dashboard/get-completed-tasks`, { withCredentials: true }),
			axios.get<ICompletedTaxReturn[]>(`${API_BASE_URL}/api/dashboard/get-extension-completed-tasks`, {
				withCredentials: true,
			}),
			axios.get<ICompletedTaxReturn[]>(`${API_BASE_URL}/api/dashboard/get-sse-completed-tasks`, { withCredentials: true }),
		]);
		const combinedTasks = [...completedTasksResponse1.data, ...completedTasksResponse2.data, ...completedTasksResponse3.data];
		combinedTasks.sort((a, b) => new Date(b.completionDate).getTime() - new Date(a.completionDate).getTime());
		completedTasksResponse.completedTasks = combinedTasks;
		callback(completedTasksResponse);
	} catch (error: any) {
		completedTasksResponse.error = true;
		apiCatchHandler(error, completedTasksResponse, callback);
	}
};
