import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from 'src/helper/constants';
import {
	IRecoveryMailOTPResponse,
	IRecoveryMailResponse,
	IRecoveryPhoneNumberOTPResponse,
	IRecoveryPhoneNumberResponse,
	IValidateNewMailOTPResponse,
	IValidateNewMailResponse,
} from './reset-email-id-types';
import { apiCatchHandler } from 'src/helper/utils';
import { OTPValidationStatus } from 'src/modules/common/common-types';

export const validateRecoveryMail = (
	companyId: number,
	existingEmail: string,
	recoveryEmail: string,
	callback: (recoveryMailResponse: IRecoveryMailResponse) => void,
) => {
	const recoveryMailResponse: IRecoveryMailResponse = {
		validated: false,
		error: false,
	};
	const encodedRecoveryEmail = encodeURIComponent(recoveryEmail);
	const encodedExistingEmail = encodeURIComponent(existingEmail);
	axios
		.post(
			`${API_BASE_URL}/api/primary-email-reset/validate-recovery-mail/${companyId}?primaryEmailId=${encodedExistingEmail}&recoveryEmailId=${encodedRecoveryEmail}`,
		)
		.then((response: AxiosResponse<boolean>) => {
			const { data } = response;
			recoveryMailResponse.validated = data;
			callback(recoveryMailResponse);
		})
		.catch((error) => {
			recoveryMailResponse.error = true;
			apiCatchHandler(error, recoveryMailResponse, callback);
		});
};

export const validateRecoveryPhoneNumber = (
	companyId: number,
	existingEmail: string,
	recoveryCountryCode: string,
	recoveryMobileNumber: string,
	callback: (recoveryPhoneNumberResponse: IRecoveryPhoneNumberResponse) => void,
) => {
	const recoveryPhoneNumberResponse: IRecoveryPhoneNumberResponse = {
		validated: false,
		error: false,
	};
	const encodedCountryCode = encodeURIComponent(recoveryCountryCode);
	const encodedMobileNumber = encodeURIComponent(recoveryMobileNumber);
	const encodedExistingEmail = encodeURIComponent(existingEmail);
	axios
		.post(
			`${API_BASE_URL}/api/primary-email-reset/validate-recovery-phone-number/${companyId}?primaryEmailId=${encodedExistingEmail}&countryCode=${encodedCountryCode}&phoneNumber=${encodedMobileNumber}`,
		)
		.then((response: AxiosResponse<boolean>) => {
			const { data } = response;
			recoveryPhoneNumberResponse.validated = data;
			callback(recoveryPhoneNumberResponse);
		})
		.catch((error) => {
			recoveryPhoneNumberResponse.error = true;
			apiCatchHandler(error, recoveryPhoneNumberResponse, callback);
		});
};

export const validateRecoveryMailOTP = (
	companyId: number,
	recoveryEmailId: string,
	otp: string,
	callback: (recoveryMailOTPResponse: IRecoveryMailOTPResponse) => void,
) => {
	const recoveryMailOTPResponse: IRecoveryMailOTPResponse = {
		otpResponse: OTPValidationStatus.InvalidOTP,
		error: false,
	};
	const encodedRecoveryEmail = encodeURIComponent(recoveryEmailId);
	axios
		.post(
			`${API_BASE_URL}/api/primary-email-reset/validate-recovery-mail-otp/${companyId}?recoveryEmailId=${encodedRecoveryEmail}&otp=${otp}`,
		)
		.then((response: AxiosResponse<OTPValidationStatus>) => {
			const { data } = response;
			recoveryMailOTPResponse.otpResponse = data;
			callback(recoveryMailOTPResponse);
		})
		.catch((error) => {
			recoveryMailOTPResponse.error = true;
			apiCatchHandler(error, recoveryMailOTPResponse, callback);
		});
};

export const validateRecoveryPhoneNumberOTP = (
	companyId: number,
	recoveryCountryCode: string,
	recoveryPhoneNumber: string,
	otp: string,
	callback: (recoveryPhoneNumberOTPResponse: IRecoveryPhoneNumberOTPResponse) => void,
) => {
	const recoveryPhoneNumberOTPResponse: IRecoveryPhoneNumberOTPResponse = {
		otpResponse: OTPValidationStatus.InvalidOTP,
		error: false,
	};
	const encodedCountryCode = encodeURIComponent(recoveryCountryCode);
	const encodedPhoneNumber = encodeURIComponent(recoveryPhoneNumber);
	axios
		.post(
			`${API_BASE_URL}/api/primary-email-reset/validate-recovery-phone-number-otp/${companyId}?countryCode=${encodedCountryCode}&phoneNumber=${encodedPhoneNumber}&otp=${otp}`,
		)
		.then((response: AxiosResponse<OTPValidationStatus>) => {
			const { data } = response;
			recoveryPhoneNumberOTPResponse.otpResponse = data;
			callback(recoveryPhoneNumberOTPResponse);
		})
		.catch((error) => {
			recoveryPhoneNumberOTPResponse.error = true;
			apiCatchHandler(error, recoveryPhoneNumberOTPResponse, callback);
		});
};

export const validateNewPrimaryMail = (
	companyId: number,
	oldEmailId: string,
	newEmailId: string,
	callback: (validateNewMailResponse: IValidateNewMailResponse) => void,
) => {
	const validateNewMailResponse: IValidateNewMailResponse = {
		validated: false,
		error: false,
	};
	const encodedOldEmailId = encodeURIComponent(oldEmailId);
	const encodedNewEmailId = encodeURIComponent(newEmailId);
	axios
		.post(
			`${API_BASE_URL}/api/primary-email-reset/validate-new-primary-mail/${companyId}?oldEmailId=${encodedOldEmailId}&newEmailId=${encodedNewEmailId}`,
		)
		.then((response: AxiosResponse<boolean>) => {
			const { data } = response;
			validateNewMailResponse.validated = data;
			callback(validateNewMailResponse);
		})
		.catch((error) => {
			validateNewMailResponse.error = true;
			apiCatchHandler(error, validateNewMailResponse, callback);
		});
};

export const validateNewPrimaryMailOTP = (
	companyId: number,
	oldEmailId: string,
	newEmailId: string,
	otp: string,
	callback: (validateNewMailOTPResponse: IValidateNewMailOTPResponse) => void,
) => {
	const validateNewMailOTPResponse: IValidateNewMailOTPResponse = {
		otpResponse: OTPValidationStatus.InvalidOTP,
		error: false,
	};
	const encodedOldEmailId = encodeURIComponent(oldEmailId);
	const encodedNewEmailId = encodeURIComponent(newEmailId);
	axios
		.post(
			`${API_BASE_URL}/api/primary-email-reset/validate-new-primary-mail-otp-and-reset/${companyId}?oldEmailId=${encodedOldEmailId}&newEmailId=${encodedNewEmailId}&otp=${otp}`,
		)
		.then((response: AxiosResponse<OTPValidationStatus>) => {
			const { data } = response;
			validateNewMailOTPResponse.otpResponse = data;
			callback(validateNewMailOTPResponse);
		})
		.catch((error) => {
			validateNewMailOTPResponse.error = true;
			apiCatchHandler(error, validateNewMailOTPResponse, callback);
		});
};
