import React from 'react';
import { Accordion } from 'react-bootstrap';
import { IFileGroup, IFile } from './left-panel-types';
import './left-panel.style.scss';

type PreviewLeftPanelProps = {
	fileGroups: IFileGroup[];
	onFileSelect: (url: string, data: any) => void;
};

const PreviewLeftPanel = ({ fileGroups, onFileSelect }: PreviewLeftPanelProps) => {
	return (
		<>
			<div className='pdf-viewer-left-panel'>
				<Accordion defaultActiveKey='0'>
					{fileGroups.map((fileGroup: IFileGroup, index) => {
						return (
							<Accordion.Item eventKey={index.toString()}>
								<Accordion.Header>{fileGroup.name}</Accordion.Header>
								<Accordion.Body>
									<ul>
										{fileGroup.files.map((file: IFile, i) => {
											return (
												<li
													onClick={() => {
														onFileSelect(file.url, file.data);
													}}
													title={file.name}>
													{file.name}
												</li>
											);
										})}
									</ul>
								</Accordion.Body>
							</Accordion.Item>
						);
					})}
				</Accordion>
			</div>
		</>
	);
};

export default PreviewLeftPanel;
