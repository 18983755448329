import React from 'react';
import styles from '../dashboard.module.scss';
import { ButtonComponent } from 'cp-common-ui-components';
import { IPendingSignatureReport } from '../dashboard-types';
import { getFormattedDate } from 'src/helper/utils';
import MFJIcon from 'src/assets/svg/mfj-icon';
import DelegatedIcon from 'src/assets/svg/delegated-icon';
import ClockHistoryIcon from 'src/assets/svg/clock-history-icon';
import UTEClientViewWrapper from 'src/components/ute-client-view-wrapper';

interface ISignaturePendingTasks {
	pendingSignatureTasks: IPendingSignatureReport[];
	tempPendingSignatureTasks: IPendingSignatureReport[];
	setTempPendingSignatureTasks: (tasks: IPendingSignatureReport[]) => void;
	handleOpenSignatureReport: (task: IPendingSignatureReport) => void;
}

export const SignaturePendingTasks: React.FC<ISignaturePendingTasks> = ({
	pendingSignatureTasks,
	tempPendingSignatureTasks,
	setTempPendingSignatureTasks,
	handleOpenSignatureReport,
}) => {
	const isShowMorePendingSignatureActive = () => {
		return pendingSignatureTasks.length === tempPendingSignatureTasks.length;
	};

	const showMorePendingSignatureTasks = () => {
		const rowsToDisplay = tempPendingSignatureTasks.length + 3;
		setTempPendingSignatureTasks(pendingSignatureTasks.slice(0, rowsToDisplay));
	};

	const showLessPendingSignatureTasks = () => {
		const rowsToDisplay = tempPendingSignatureTasks.length - 3;
		setTempPendingSignatureTasks(pendingSignatureTasks.slice(0, rowsToDisplay));
	};

	const backToTop = () => {
		const sectionBlock = document.getElementById('section-block');
		if (sectionBlock) {
			sectionBlock.scrollTop = 0;
		}
	};

	const getSIGPendingTaskToolTip = (task: IPendingSignatureReport) => {
		return `${task.name && task.name.length > 0 ? task.name : task.emailAddress}, Please sign your document`;
	};

	const getTaskName = (task: IPendingSignatureReport) => {
		return `${task.name} Signature Request ${getFormattedDate(new Date(task.sentOn))}`;
	};

	return (
		<div className={styles.actionSubCategory}>
			<div className={styles.actionSubCategoryHeader}>
				<span>SIGN DOCUMENTS</span>
				<span className={styles.count}>{pendingSignatureTasks.length}</span>
			</div>
			<div className={styles.actionSubCategoryTable}>
				<table className={`table table-striped table-hover ${styles.customTableStyles}`}>
					<tbody>
						{tempPendingSignatureTasks.map((task: IPendingSignatureReport, index: number) => {
							return (
								<tr key={task.clientGuid}>
									<td className={styles.ellipsisText}>
										<span
											style={!task.isDelegated ? { cursor: 'pointer' } : {}}
											title={!task.isDelegated ? getTaskName(task) : ''}
											className={`${styles.text} ${styles.ellipsis}`}>
											{getTaskName(task)}
										</span>
										{task.hasMultipleSigners && (
											<div className={styles.mfjWrapper}>
												<MFJIcon
													className={styles.mfjIcon}
													hoverText={!task.isDelegated ? getSIGPendingTaskToolTip(task) : ''}
												/>
											</div>
										)}
										{task.isDelegated && (
											<span className={styles.pillShapeDelegation}>
												<DelegatedIcon className={styles.delegatedIcon} /> Delegated
											</span>
										)}
										{task.isReminderSent && (
											<span className={styles.pillShapeReminder}>
												<ClockHistoryIcon className={styles.dueSoon} /> Due Soon
											</span>
										)}
									</td>

									<td>
										<UTEClientViewWrapper dataTestAuto='651dad41-e659-4e3a-8a4c-cfca17bde6be'>
											<ButtonComponent
												data-test-auto={`2e08330c-5b5c-4cb3-a90e-548e1fb4e${index.toString().padStart(3, '0')}`}
												onClick={() => handleOpenSignatureReport(task)}
												variant={'outline-primary'}
												size={'small'}
												buttonClassName={styles.pendingTasksBtn}>
												{task.isDocumentAccessed || task.isDelegated ? 'Continue' : 'Get Started'}
											</ButtonComponent>
										</UTEClientViewWrapper>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				{pendingSignatureTasks.length > 3 ? (
					<>
						<div
							className={styles.seeMore}
							onClick={
								isShowMorePendingSignatureActive() ? showLessPendingSignatureTasks : showMorePendingSignatureTasks
							}>
							{isShowMorePendingSignatureActive() ? 'Show less' : 'Show more'}
						</div>
						{isShowMorePendingSignatureActive() ? (
							<div className={styles.backToTopContainer}>
								<div
									className={styles.backToTop}
									onClick={backToTop}>
									Back to Top
								</div>
								<span className={styles.upperArrow}>&uarr;</span>
							</div>
						) : (
							<></>
						)}
					</>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};
