import { SignatureStatus } from 'src/helper/constants';
import { ClientTypes } from '../common/common-types';
import { SSRSignatureStatus } from '../documents/documents-types';

export interface IDashboardOverviewResponse {
	dashboardDetails: IDashboardOverview;
	error: boolean;
}

export interface IDashboardOverview_Products {
	pendingTasksCount: number;
	completedTasksCount: number;
}

export interface IDashboardOverview extends IDashboardOverview_Products {
	userName: string;
	lastLogin: string;
}

export const initialDashboardData: IDashboardOverview = {
	userName: '',
	pendingTasksCount: 0,
	completedTasksCount: 0,
	lastLogin: '',
};

export interface IPendingTaxReturn {
	documentId: number;
	name: string;
	taxyear: number;
	clientGuid: string;
	emailAddress: string;
	delegateeClientGuid: string;
	spouseName: string;
	isReminderSent: boolean;
	isMFJ: boolean;
	isVisited: boolean;
	isDelegated: boolean;
	signatureStatus: SignatureStatus | SSRSignatureStatus;
	signerType: ClientTypes;
}

export interface IPendingTaxReturnResponse {
	pendingTaxReturns: IPendingTaxReturn[];
	error: boolean;
}

export interface IPendingDocumentRequest {
	documentRequestId: number;
	documentRequestGuid: string;
	subject: string;
	emailAddress: string;
	isAccessed: boolean;
	dueDate: Date | null;
}
export interface IPendingDocumentRequestResponse {
	pendingDocumentRequests: IPendingDocumentRequest[];
	error: boolean;
}

export interface IPendingSignatureReport {
	signingId: number;
	clientGuid: string;
	sentOn: Date;
	recipientName: string;
	hasMultipleSigners: boolean;
	isDocumentAccessed: boolean;
	isDelegated: boolean;
	emailAddress: string;
	isReminderSent: boolean;
	name: string;
}

export interface IPendingSignatureReportResponse {
	pendingSignatureReports: IPendingSignatureReport[];
	error: boolean;
}

export interface ICompletedTaxReturn {
	taskName: string;
	completionDate: string;
}

export interface ICompletedTaxReturnResponse {
	completedTasks: ICompletedTaxReturn[];
	error: boolean;
}
