import React from 'react';

interface ISvgProps {
	width?: string;
	height?: string;
	className?: string;
	onClick?: () => void;
	assignRef?: any;
}

const NotificationIcon: React.FC<ISvgProps> = ({ width = '24', height = '24', className, onClick, assignRef }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			fill='none'
			viewBox='0 0 24 24'
			className={className}
			onClick={onClick}
			ref={assignRef}>
			<path
				fill='#05386B'
				d='M12 24a3 3 0 003-3H9a3 3 0 003 3zM12 2.877l-1.196.242A6.003 6.003 0 006 9c0 .942-.202 3.296-.688 5.613-.242 1.15-.565 2.348-.995 3.387h15.366c-.43-1.039-.753-2.236-.995-3.387C18.202 12.296 18 9.942 18 9a6.002 6.002 0 00-4.804-5.881L12 2.877zM21.329 18c.335.671.723 1.201 1.171 1.5h-21c.448-.299.836-.829 1.171-1.5C4.02 15.3 4.5 10.319 4.5 9c0-3.631 2.58-6.66 6.007-7.351a1.5 1.5 0 112.985 0A7.503 7.503 0 0119.5 9c0 1.319.481 6.3 1.829 9z'></path>
		</svg>
	);
};

export default NotificationIcon;
