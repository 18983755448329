import { OTPValidationStatus } from 'src/modules/common/common-types';

export enum PINValidationStatus {
	Success,
	InvalidPIN,
	LockedPIN,
}

export type IValidPINDetail = {
	status: PINValidationStatus;
	numberOfAttemptsLeft: number;
};

export type IValidatePINResponse = {
	validPINDetail: IValidPINDetail;
	error: boolean;
};

export const validPINDetailDefault: IValidPINDetail = {
	status: PINValidationStatus.InvalidPIN,
	numberOfAttemptsLeft: 0,
};

export type IGenerateOTPResponse = {
	error: boolean;
};

export type IValidOTPDetail = {
	status: OTPValidationStatus;
	retryCount: number;
	accessToken: string;
	refreshToken: string;
	errorDescription: string;
};
export type IValidateOTPResponse = {
	validOTPDetail: IValidOTPDetail;
	error: boolean;
};

export const validOTPDetailDefault: IValidOTPDetail = {
	status: OTPValidationStatus.Success,
	retryCount: 0,
	accessToken: '',
	refreshToken: '',
	errorDescription: '',
};
