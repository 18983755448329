import axios, { AxiosResponse } from "axios";
import { API_BASE_URL } from "../../../helper/constants";
import { apiCatchHandler } from "../../../helper/utils";
import { IContactInformation, IContactInformationResponse, initialContactInformation } from "./contact-information-types";

export const getContactInformation = async (callback: (contactInformationResponse: IContactInformationResponse) => void) => {
	const contactInformationResponse: IContactInformationResponse = {
		contactInformation: initialContactInformation,
		error: false,
	};
	axios
		.get(`${API_BASE_URL}/api/dashboard/contact-information`)
		.then((response: AxiosResponse<IContactInformation>) => {
			contactInformationResponse.contactInformation = response.data;
			callback(contactInformationResponse);
		})
		.catch((error) => {
			contactInformationResponse.error = true;
			apiCatchHandler(error, contactInformationResponse, callback);
		});
};