import {
	DocumentType,
	IGatherDocumentModel,
	IOrganizerDocumentModel,
	ITaxDocument,
	ITaxRecord,
	ITaxReturnDetails,
	OrganizerStatus,
	QuickInfo,
	SignatureStatus,
} from './documents-types';
import {
	generateExtTaxpayerRedirectionAPIURL,
	generateTaxpayerRedirectionAPIURL,
	generateSignatureTaxpayerAPIURL,
	generateTaxpayerClientViewAPIURL,
	generateEXTTaxpayerClientViewAPIURL,
} from '../common/common-api';
import {
	ProductType,
	TaxpayerRedirectionSourcePage,
	ClientTypes,
	SignatureRedirectionSourcePage,
} from '../common/common-types';
import { IUserActivity, OneHubEventType } from '../recent-activity/recent-activity-types';
import { saveUserActivity } from '../recent-activity/recent-activity-api';
import { IUserDetail } from 'src/context/ute-auth-context/auth-provider-types';
import { Guid } from 'src/helper/utils';
import moment from 'moment-timezone';

enum ActionType {
	None = 0,
	MoreActions = 1,
	Download = 2,
}

export const getFormattedDate = (dateString: string) => {
	if (dateString) {
		const localTimeZone = moment.tz.guess();
		const localTime = moment.utc(dateString).tz(localTimeZone).format('MMM D, YYYY');
		return localTime;
	}
	return '';
};

export const getFormattedExpiryDate = (node: ITaxRecord) => {
	let tempString;
	if (node?.documents && node?.documents?.expiryDate) {
		tempString = node?.documents?.expiryDate.substring(0, 10);
	} else if (node?.gatherDocuments && node?.gatherDocuments?.expiryDate) {
		tempString = node?.gatherDocuments?.expiryDate.substring(0, 10);
	} else if (node?.gatherSignedDocuments && node?.gatherSignedDocuments?.expiryDate) {
		tempString = node?.gatherSignedDocuments?.expiryDate.substring(0, 10);
	} else if (node?.sourceDocuments && node?.sourceDocuments?.expiryDate) {
		tempString = node?.sourceDocuments?.expiryDate.substring(0, 10);
	} else if (node?.organizerDocuments && node?.organizerDocuments?.expiryDate) {
		tempString = node?.organizerDocuments?.expiryDate.substring(0, 10);
	} else if (node?.organizerSignedDocuments && node?.organizerSignedDocuments?.expiryDate) {
		tempString = node?.organizerSignedDocuments?.expiryDate.substring(0, 10);
	} else if (node?.organizerSourceDocuments && node?.organizerSourceDocuments?.expiryDate) {
		tempString = node?.organizerSourceDocuments?.expiryDate.substring(0, 10);
	}
	return moment(tempString, 'YYYY-MM-DD').format('MMM DD, YYYY');
};

export const getFormattedDateFromString = (dateString: string) => {
	return moment.utc(dateString).local().format('MMM DD, YYYY');
};

export const showNumberOfFilesText = (count: number) => {
	return `${count} ${count === 1 ? 'document' : 'documents'}`;
};

export const showDateToolTip = (toolTip: QuickInfo) => {
	if (toolTip === QuickInfo.Signed) {
		return 'Signed';
	} else if (toolTip === QuickInfo.Received) {
		return 'Received';
	} else if (toolTip === QuickInfo.Uploaded) {
		return 'Uploaded';
	} else {
		return '';
	}
};

export const showGatherOrganizerDateToolTip = (organizerStatus: string) => {
	switch (organizerStatus) {
		case OrganizerStatus.Completed:
			return 'Completed';
		case OrganizerStatus.ManuallyCompleted:
			return 'Manually Completed';
		case OrganizerStatus.QuestionnaireCompleted:
			return 'Questionnaire Completed';
		case OrganizerStatus.PartiallyCompleted:
			return 'Partially Completed';
		default:
			return '';
	}
};

export const showGatherEsignDateToolTip = (signatureStatus: SignatureStatus) => {
	switch (signatureStatus) {
		case SignatureStatus.ManuallySigned:
			return 'Manually Signed';
		case SignatureStatus.PartiallySigned:
			return 'Partially Signed';
		case SignatureStatus.Esigned:
			return 'E-Signed';
		case SignatureStatus.Reviewed:
			return 'Reviewed';
		case SignatureStatus.PartiallyReviewed:
			return 'Partially Reviewed';
		default:
			return '';
	}
};

export const openTaxPayerSummaryPage = (
	document: ITaxDocument,
	actionType: ActionType,
	userDetail: IUserDetail,
	isClientView: boolean,
) => {
	if (!isClientView) {
		if (document.productType == ProductType.Returns) {
			generateTaxpayerRedirectionAPIURL(
				document.clientGuid,
				ProductType.Returns,
				actionType === ActionType.MoreActions
					? TaxpayerRedirectionSourcePage.Signcompleted
					: TaxpayerRedirectionSourcePage.Download,
				undefined,
				userDetail.emailAddress,
			);
		} else if (document.productType == ProductType.Extensions) {
			generateExtTaxpayerRedirectionAPIURL(
				document.clientGuid,
				actionType === ActionType.MoreActions
					? TaxpayerRedirectionSourcePage.Signcompleted
					: TaxpayerRedirectionSourcePage.Download,
				undefined,
				userDetail.emailAddress,
			);
		}
		let event = OneHubEventType.None;
		if (actionType === ActionType.MoreActions) {
			event = OneHubEventType.MoreActions;
		} else if (actionType === ActionType.Download) {
			event = OneHubEventType.Download;
		}
		let userActivity: IUserActivity = {
			eventId: event,
			eventData: {
				documentId: document.documentId,
				fileName: document.efileFormName,
				signerType: document.signerType,
			},
			productType: document.productType,
		};
		saveUserActivity(userActivity);
	} else {
		if (document.productType == ProductType.Returns) {
			generateTaxpayerClientViewAPIURL(
				document.clientGuid,
				ProductType.Returns,
				ClientTypes.Taxpayer,
				actionType === ActionType.MoreActions
					? TaxpayerRedirectionSourcePage.Signcompleted
					: TaxpayerRedirectionSourcePage.Download,
			);
		} else if (document.productType == ProductType.Extensions) {
			generateEXTTaxpayerClientViewAPIURL(
				document.clientGuid,
				ProductType.Extensions,
				ClientTypes.Taxpayer,
				actionType === ActionType.MoreActions
					? TaxpayerRedirectionSourcePage.Signcompleted
					: TaxpayerRedirectionSourcePage.Download,
			);
		}
	}
};

export const gtrOrgDownloadAction = (document: ITaxReturnDetails, isClientView: boolean) => {
	if (!isClientView) {
		generateTaxpayerRedirectionAPIURL(document.clientGuid, document.productType);
		let userActivity: IUserActivity = {
			eventId: OneHubEventType.Download,
			eventData: {
				documentId: document.documentId,
				fileName: document.efileFormName,
				clientGuid: document.clientGuid,
				signerType: document.signerType,
			},
			productType: document.productType,
		};
		saveUserActivity(userActivity);
	} else if (document.productType === ProductType.Organizers || document.productType === ProductType.Gather) {
		generateTaxpayerClientViewAPIURL(document.clientGuid, document.productType, ClientTypes.Taxpayer);
	}
};

export const saveGtrOrgDocumentPreViewAction = (
	documentId: number,
	documentGuid: string,
	document: IGatherDocumentModel | IOrganizerDocumentModel,
) => {
	const userActivity: IUserActivity = {
		eventId: OneHubEventType.View,
		eventData: {
			documentId: documentId,
			fileGuid: documentGuid,
			fileName:
				document.documentType === DocumentType.Organizer || document.documentType === DocumentType.CustomQuestion
					? document.efileFormName
					: document.documentType === DocumentType.Engagement ||
					  document.documentType === DocumentType.AdditionalEFile ||
					  document.documentType === DocumentType.OrganizerWithSignature
					? document.fileName ?? ''
					: document.sourceDocumentFileName ?? '',
			signerType: document.signerType,
			gatherFileData: {
				gatherDocumentType: document.documentType,
				sourceDocumentId: document.sourceDocumentId,
				fileName:
					document.documentType === DocumentType.Organizer || document.documentType === DocumentType.CustomQuestion
						? document.efileFormName
						: document.fileName,
				fileGuid: document.fileGuid ?? Guid.empty,
				clientId: document.clientId,
				taxYear: document.taxYear,
			},
		},
		productType: document.productType,
	};
	saveUserActivity(userActivity);
};

export const handleSignatureDownloadAction = (document: ITaxDocument) => {
	generateSignatureTaxpayerAPIURL(document.clientGuid, SignatureRedirectionSourcePage.Download);
	let userActivity: IUserActivity = {
		eventId: OneHubEventType.Download,
		eventData: {
			signingId: document.signingId,
			documentId: document.documentId,
			fileName: document.efileFormName,
			signerType: ClientTypes.Taxpayer,
			clientGuid: document.clientGuid,
		},
		productType: document.productType,
	};
	saveUserActivity(userActivity);
};
