import React, { useContext } from 'react';
import styles from './login-layout.module.scss';
import LoginLayoutImage from 'src/assets/svg/login-layout-svg.svg';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';

const LoginLayout = ({ children }: React.PropsWithChildren) => {
	const { companyNameAndLogo } = useContext(AuthContext);
	return (
		<main className={styles.layout}>
			<section className={styles.leftContainer}>
				<section className={styles.outerScrollContainer}>
					<div className={styles.innerContainer}>
						<div className={styles.companyLogo}>
							{companyNameAndLogo.logoPath && (
								<img
									src={companyNameAndLogo.logoPath}
									alt='Company Logo'
								/>
							)}
						</div>
						<div className={styles.content}>{children}</div>
					</div>
				</section>
				<footer className={styles.footerContainer}>
					<a
						href='https://safesend.com/legal-policies/terms-of-use/'
						target='_blank'>
						Terms of service
					</a>
					<span>|</span>
					<a
						href='https://safesend.com/legal-policies/privacy-statement/'
						target='_blank'>
						Privacy Policy
					</a>
				</footer>
			</section>
			<section
				className={styles.rightContainer}
				style={{ backgroundImage: `url(${LoginLayoutImage})` }}></section>
		</main>
	);
};

export default LoginLayout;
