import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from 'src/helper/constants';
import { apiCatchHandler, apiCatchHandlerNoData } from 'src/helper/utils';
import { ISSRDocumentPreview, K1ZipDownloadRequest } from './ssr-document-preview.types';
import { ClientTypes } from 'src/modules/common/common-types';
import { ProductType } from '../../common/common-types';

export const getDocumentsForPreview = (
	documentId: number,
	clientType: ClientTypes,
	productType: ProductType,
	callback: (response: ISSRDocumentPreview) => void,
	errorCallback: () => void,
) => {
	let endPoint: any;
	if (productType === ProductType.Returns) {
		endPoint = '/api/document-preview/ssr/';
	} else if (productType === ProductType.Extensions) {
		endPoint = '/api/document-preview/extension/';
	}
	axios
		.get(`${API_BASE_URL}${endPoint}` + documentId + `/` + clientType)
		.then((response: AxiosResponse<ISSRDocumentPreview>) => {
			const { data } = response;
			callback(data);
		})
		.catch((error) => {
			apiCatchHandler(error, errorCallback);
		});
};

export function getK1ZipFileDownloadUri(
	data: K1ZipDownloadRequest,
	successCallback: (uri: string) => void,
	errorCallback: () => void,
) {
	axios
		.post(`${API_BASE_URL}/api/document-preview/ssr/k1-zip-download`, data, { withCredentials: true })
		.then((response: AxiosResponse<string>) => {
			const { data } = response;
			successCallback(data);
		})
		.catch((error) => {
			apiCatchHandlerNoData(error, errorCallback);
		});
}