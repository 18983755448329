import { generateExchangeTaxpayerAPIURL } from '../common/common-api';
import styles from './send-files.module.scss';

const SendFiles = () => {
	const goToSSEDropOffPortal = () => {
		generateExchangeTaxpayerAPIURL();
	};

	return (
		<div className={styles.sendFilesModule}>
			<div className={styles.headerSendFiles}>Send Files</div>
			<div className={styles.body}>
				<div
					className={styles.bodyTitleContainer}
					onClick={goToSSEDropOffPortal}>
					Send Files to Tax Professional or Tax Preparer.
				</div>
			</div>
		</div>
	);
};

export default SendFiles;
