export const ExclamationError: React.FC = (props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM7.30115 3.65882C7.39571 3.55752 7.52808 3.5 7.66667 3.5H8.33333C8.47192 3.5 8.60428 3.55752 8.69885 3.65882C8.79341 3.76013 8.84168 3.89614 8.83215 4.0344L8.49881 8.86773C8.48073 9.1299 8.26279 9.33333 8 9.33333C7.73721 9.33333 7.51926 9.1299 7.50118 8.86773L7.16785 4.0344C7.15832 3.89614 7.20659 3.76013 7.30115 3.65882ZM9 11.6667C9 12.2189 8.55229 12.6667 8 12.6667C7.44772 12.6667 7 12.2189 7 11.6667C7 11.1144 7.44772 10.6667 8 10.6667C8.55229 10.6667 9 11.1144 9 11.6667Z'
				fill='#CC4A43'
			/>
		</svg>
	);
};
