import axios, { AxiosResponse } from "axios";
import { API_BASE_URL } from "src/helper/constants";
import { apiCatchHandler } from "src/helper/utils";
import { ISIGDocumentPreviewModel } from "./sig-document-preview.types";

export const getSignatureDocumentForPreview = (documentId:number, callback:(response:ISIGDocumentPreviewModel)=>void, errorCallback:()=>void)=>{
    axios   
        .get(`${API_BASE_URL}/api/document-preview/sig/`+documentId)
        .then((response:AxiosResponse<ISIGDocumentPreviewModel>) => {
        
            const {data} = response;
            callback(data);

        })
        .catch((error) => {
            apiCatchHandler(error, errorCallback);
        });
}