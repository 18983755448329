import React, { FC, ReactElement } from 'react';
import CloseIconUTEModal from 'src/assets/svg/close-icon-ute-modal';
import { Modal } from 'react-bootstrap';
import './ute-modal-layout.scss';

interface UTEModalLayoutProps {
	width: string;
	title: string;
	theme: string;
	hide: () => void;
	onClickOutside?: () => void;
	children: ReactElement<any, any>;
	className?: string;
}
const UTEModalLayout: FC<UTEModalLayoutProps> = (props) => {
	const { width, title, theme, hide, onClickOutside, children, className } = props;
	let widthClassName = `modal${width}`;
	return (
		<Modal
			show={true}
			onHide={onClickOutside ?? hide}
			className={`uteModalLayout ${widthClassName} ${className ? className : ''}`}>
			<Modal.Header className={`${theme}theme`}>
				<div>
					<Modal.Title>{title}</Modal.Title>
				</div>
				<div
					data-test-auto='e2ab6c41-b6f5-4181-b38f-3455585b2141'
					className='closeIcon'
					onClick={hide}>
					<CloseIconUTEModal />
				</div>
			</Modal.Header>
			{children}
		</Modal>
	);
};

export default UTEModalLayout;
