import { getDueDate, getFormattedDate } from 'src/helper/utils';
import { IPendingDocumentRequest } from '../dashboard-types';
import styles from '../dashboard.module.scss';
import { ButtonComponent } from 'cp-common-ui-components';
import ClockHistoryIcon from 'src/assets/svg/clock-history-icon';
import { DASHBOARD_LISTING_CONSTANTS } from 'src/helper/constants';
import UTEClientViewWrapper from 'src/components/ute-client-view-wrapper';

interface IDocumentRequestProps {
	pendingDocumentRequestTasks: IPendingDocumentRequest[];
	tempPendingDocumentRequestTasks: IPendingDocumentRequest[];
	setTempPendingDocumentRequestTasks: (tasks: IPendingDocumentRequest[]) => void;
	handleOpenDocumentRequest: (task: IPendingDocumentRequest) => void;
}

export const DocumentRequestTask: React.FC<IDocumentRequestProps> = ({
	pendingDocumentRequestTasks,
	tempPendingDocumentRequestTasks,
	setTempPendingDocumentRequestTasks,
	handleOpenDocumentRequest,
}) => {
	const isShowMorePendingTaskActive = () => {
		return pendingDocumentRequestTasks.length === tempPendingDocumentRequestTasks.length;
	};

	const showMorePendingDocumentRequestTasks = () => {
		const rowsToDisplay = tempPendingDocumentRequestTasks.length + 3;
		setTempPendingDocumentRequestTasks(pendingDocumentRequestTasks.slice(0, rowsToDisplay));
	};

	const showLessPendingDocumentRequestTasks = () => {
		setTempPendingDocumentRequestTasks(pendingDocumentRequestTasks.slice(0, 3));
	};

	const backToTop = () => {
		const sectionBlock = document.getElementById('section-block');
		if (sectionBlock) {
			sectionBlock.scrollTop = 0;
		}
	};

	return (
		<div className={styles.actionSubCategory}>
			<div className={styles.actionSubCategoryHeader}>
				<span>UPLOAD DOCUMENTS</span>
				<span className={styles.count}>{pendingDocumentRequestTasks.length}</span>
			</div>
			<div className={styles.actionSubCategoryTable}>
				<table className={`table table-striped table-hover ${styles.customTableStyles}`}>
					<tbody>
						{tempPendingDocumentRequestTasks.map((task: IPendingDocumentRequest, index: number) => {
							return (
								<tr key={task.documentRequestGuid}>
									<td className={styles.ellipsisText}>
										<span
											className={`${styles.text} ${styles.ellipsis}`}
											title={task.subject}
											style={{ cursor: 'default' }}>
											{task.subject}
										</span>
										{task.dueDate && (
											<span className={styles.pillShapeReminder}>
												<ClockHistoryIcon className={styles.dueSoon} />{' '}
												{`Due On: ${getDueDate(new Date(task.dueDate))}`}
											</span>
										)}
									</td>

									<td>
										<UTEClientViewWrapper dataTestAuto='7133e41e-0b1b-4a8d-9f13-145a563081b4'>
											<ButtonComponent
												data-test-auto={`2e08330c-5b5c-4cb3-a90e-548e1fb4e${index.toString().padStart(3, '0')}`}
												onClick={() => handleOpenDocumentRequest(task)}
												variant={'outline-primary'}
												size={'small'}
												buttonClassName={styles.pendingTasksBtn}>
												{task.isAccessed
													? DASHBOARD_LISTING_CONSTANTS.CONTINUE
													: DASHBOARD_LISTING_CONSTANTS.GET_STARTED}
											</ButtonComponent>
										</UTEClientViewWrapper>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				{pendingDocumentRequestTasks.length > 3 ? (
					<>
						<div
							className={styles.seeMore}
							onClick={
								isShowMorePendingTaskActive()
									? showLessPendingDocumentRequestTasks
									: showMorePendingDocumentRequestTasks
							}>
							{isShowMorePendingTaskActive()
								? DASHBOARD_LISTING_CONSTANTS.SHOW_LESS
								: DASHBOARD_LISTING_CONSTANTS.SHOW_MORE}
						</div>
						{isShowMorePendingTaskActive() ? (
							<div className={styles.backToTopContainer}>
								<div
									className={styles.backToTop}
									onClick={backToTop}>
									{DASHBOARD_LISTING_CONSTANTS.BACK_TO_TOP}
								</div>
								<span className={styles.upperArrow}>&uarr;</span>
							</div>
						) : (
							<></>
						)}
					</>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};
