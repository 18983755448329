import React, { useContext, useState } from 'react';
import EnterEmail from '../enter-email';
import EnterPIN from '../enter-pin';
import {
	IGenerateOTPResponse,
	IValidateOTPResponse,
	IValidatePINResponse,
	PINValidationStatus,
	validPINDetailDefault,
} from './login-component-types';
import { AuthContext } from 'src/context/ute-auth-context/auth-provider';
import { UTENotifier } from 'src/modules/notification/ute-notifier';
import { generateOTP, validateOTP, validatePIN } from './login-component-api';
import {
	fetchAccountRegistrationConsentCookieValue,
	fetchDeviceCookieValue,
	setInvalidEmailCookieCount,
	showCaptcha,
} from 'src/helper/utils';
import { LOGIN_CONSTANTS, RECOVERY_CONSTANTS } from 'src/helper/constants';
import LockedPIN from '../locked-pin';
import LockedOTP from '../locked-otp';
import { getUserDetail, validateCaptcha } from '../../common/common-api';
import { IUserDetailResponse, OTPValidationStatus, emptyUserDetail } from '../../common/common-types';
import Cookies from 'js-cookie';
import UTEOTPInput from 'src/components/ute-otp-input';
import { IOTPValidity, OTPValidityDefault } from 'src/components/ute-otp-input/ute-otp-input-types';
import { ICaptchaResponse } from 'src/modules/documents/documents-types';
import AccountSetupWizard from '../account-creation-guideline-popup';

type LoginComponentProps = {
	setComponent: (component: string) => void;
	accountCreatedEmail: string;
	resetEmail: string;
	loggedOutEmail: string;
};
const LoginComponent = ({ setComponent, accountCreatedEmail, resetEmail, loggedOutEmail }: LoginComponentProps) => {
	const [emailID, setEmailID] = useState(accountCreatedEmail || resetEmail || loggedOutEmail);
	const [userDetail, setUserDetail] = useState(emptyUserDetail);
	const [pin, setPIN] = useState('');
	const [validPINDetail, setValidPINDetail] = useState(validPINDetailDefault);
	const [otp, setOTP] = useState('');
	const [otpValidity, setOTPValidity] = useState<IOTPValidity>(OTPValidityDefault);
	const { companyId, updateAuthToken, updateUserDetails, updateRefreshToken } = useContext(AuthContext);
	const [islocked, setIsLocked] = useState<boolean>(false);
	const [showAccountCreationPopup, setShowAccountCreationPopup] = useState<boolean>(false);

	const handleEmailIDSubmit = (callback: () => void) => {
		const processUserDetailResponse = (userDetailResponse: IUserDetailResponse) => {
			if (userDetailResponse.error) {
				UTENotifier.Error(LOGIN_CONSTANTS.ERROR_FETCH_USER);
				callback();
			} else {
				if (userDetailResponse.userDetail.id) {
					Cookies.remove('invalidEmailCount');
					setUserDetail(userDetailResponse.userDetail);
					updateUserDetails(userDetailResponse.userDetail);

					if (userDetailResponse.userDetail.otpAttemptCount >= 3) {
						setIsLocked(true);
						setOTP('');
						callback();
					} else if (userDetailResponse.userDetail.pinAttemptCount >= 3) {
						setValidPINDetail({
							status: PINValidationStatus.LockedPIN,
							numberOfAttemptsLeft: 0,
						});
						callback();
					} else {
						if (fetchDeviceCookieValue(emailID) === 'True' && resetEmail === '') {
							setValidPINDetail({
								status: PINValidationStatus.Success,
								numberOfAttemptsLeft: 0,
							});
							generateOTPCall(companyId, emailID, userDetailResponse.userDetail.id);
						} else {
							callback();
						}
					}
				} else {
					setInvalidEmailCookieCount();
					UTENotifier.Error(LOGIN_CONSTANTS.INVALID_EMAIL_ID);
					callback();
					if (fetchAccountRegistrationConsentCookieValue(emailID) !== 'true') {
						setShowAccountCreationPopup(true);
					}
				}
			}
		};

		if (userDetail === emptyUserDetail && showCaptcha()) {
			const token = Cookies.get('captchaToken') || '';
			validateCaptcha(token, (isValid: ICaptchaResponse) => {
				if (isValid.response) {
					getUserDetail(companyId, emailID, false, processUserDetailResponse);
				} else {
					UTENotifier.Error(LOGIN_CONSTANTS.ERROR_CAPTCHA_VALIDATION);
					callback();
				}
			});
		} else {
			getUserDetail(companyId, emailID, false, processUserDetailResponse);
		}
	};

	const handlePINSubmit = (callback: () => void) => {
		validatePIN(companyId, userDetail.id, pin, userDetail.emailAddress, (validatePINResponse: IValidatePINResponse) => {
			if (validatePINResponse.error) {
				UTENotifier.Error(LOGIN_CONSTANTS.ERROR_VERIFY_PIN);
				callback();
			} else {
				if (validatePINResponse.validPINDetail.status == PINValidationStatus.Success) {
					generateOTPCall(companyId, emailID, userDetail.id);
				}
				setValidPINDetail({
					status: validatePINResponse.validPINDetail.status,
					numberOfAttemptsLeft: validatePINResponse.validPINDetail.numberOfAttemptsLeft,
				});
			}
		});
	};

	const generateOTPCall = (companyId: number, emailID: string, userDetailId: number) => {
		generateOTP(companyId, emailID, userDetailId, (generateOTPResponse: IGenerateOTPResponse) => {
			if (generateOTPResponse.error) {
				UTENotifier.Error(LOGIN_CONSTANTS.ERROR_SENDING_OTP);
			} else {
				UTENotifier.Success(LOGIN_CONSTANTS.OTP_SENT);
			}
		});
	};

	const updateLoggedInUserFlag = () => {
		localStorage.setItem(RECOVERY_CONSTANTS.UTE_LOGGEDIN_USER_FLAG, 'true');
	};

	const handleOTPSubmit = (callback: () => void) => {
		validateOTP(companyId, emailID, otp, userDetail.id, true, (validateOTPResponse: IValidateOTPResponse) => {
			if (validateOTPResponse.validOTPDetail.status === OTPValidationStatus.LockedByOTP) {
				setIsLocked(true);
				UTENotifier.Warning(LOGIN_CONSTANTS.OTP_LOCKED);
				setOTP('');
				callback();
			} else {
				if (validateOTPResponse.validOTPDetail.status === OTPValidationStatus.Success) {
					updateAuthToken(validateOTPResponse.validOTPDetail.accessToken);
					updateRefreshToken(validateOTPResponse.validOTPDetail.refreshToken);
					updateUserDetails(userDetail);
					updateLoggedInUserFlag();
				} else if (validateOTPResponse.validOTPDetail.status === OTPValidationStatus.OTPExpired) {
					UTENotifier.Error(LOGIN_CONSTANTS.OTP_EXPIRED);
					setOTP('');
					callback();
				} else if (validateOTPResponse.validOTPDetail.status === OTPValidationStatus.InvalidOTP) {
					setOTPValidity({
						isValid: false,
						message: validateOTPResponse.validOTPDetail.errorDescription,
					});
					setOTP('');
					callback();
				}
			}
		});
	};

	const handleResendCode = () => {
		generateOTP(companyId, emailID, userDetail.id, (generateOTPResponse: IGenerateOTPResponse) => {
			if (generateOTPResponse.error) {
				UTENotifier.Error(LOGIN_CONSTANTS.ERROR_SENDING_OTP);
			} else {
				UTENotifier.Success(LOGIN_CONSTANTS.OTP_SENT);
			}
		});
	};
	return (
		<>
			{islocked ? (
				<LockedOTP />
			) : (
				<>
					{userDetail.id === -1 && (
						<EnterEmail
							emailID={emailID}
							setEmailID={setEmailID}
							handleEmailIDSubmit={handleEmailIDSubmit}
							setComponent={setComponent}
							accountCreatedEmail={accountCreatedEmail}
							resetEmail={resetEmail}
						/>
					)}
					{userDetail.id !== -1 && validPINDetail.status === PINValidationStatus.InvalidPIN && (
						<EnterPIN
							pin={pin}
							setPIN={setPIN}
							handlePINSubmit={handlePINSubmit}
							numberOfAttemptsLeft={validPINDetail.numberOfAttemptsLeft}
							setComponent={setComponent}
						/>
					)}
					{userDetail.id !== -1 && validPINDetail.status === PINValidationStatus.Success && (
						<UTEOTPInput
							otpLength={8}
							otp={otp}
							setOTP={setOTP}
							otpValidity={otpValidity}
							setOTPValidity={setOTPValidity}
							handleOTPSubmit={handleOTPSubmit}
							handleResendCode={handleResendCode}
						/>
					)}
					{userDetail.id !== -1 && validPINDetail.status === PINValidationStatus.LockedPIN && (
						<LockedPIN setComponent={setComponent} />
					)}
					{showAccountCreationPopup && (
						<AccountSetupWizard
							setShowAccountCreationPopup={setShowAccountCreationPopup}
							emailID={emailID}
						/>
					)}
				</>
			)}
		</>
	);
};

export default LoginComponent;
