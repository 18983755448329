import { FC } from 'react';
import { RightArrowIcon, LeftArrowIcon, RightDoubleArrowIcon, LeftDoubleArrowIcon } from 'src/assets/svg/arrow-icons';
import { PaginationPageProps } from 'src/components/ute-pagination/ute-pagination-types';
import styles from './completed-tasks-pagination.module.scss';

interface PaginationProps {
	count: number;
	page: PaginationPageProps;
	goToPage: (pageIndex: number, pageSize: number) => void;
}

const CompletedTasksPagination: FC<PaginationProps> = (props) => {
	const { page, count } = props;

	const totalPages = Math.ceil(count / page.pageSize);

	return (
		<section className={styles.completedTasksPagination}>
			<ul className={styles.showPageNumber}>
				<li
					data-test-auto='edb48cc0-0700-4631-ac29-393dfb828add'
					title={page.pageIndex <= 1 ? '' : 'First Page'}
					className={page.pageIndex <= 1 ? styles.paginationActionDisabled : ''}
					onClick={() => props.goToPage(1, page.pageSize)}>
					<span>
						<LeftDoubleArrowIcon fillColor={page.pageIndex <= 1 ? '#C4C6C8' : '#0973BA'} />
					</span>
				</li>
				<li
					data-test-auto='b05c98ce-18b7-4320-ab82-a9ad1cdcc36f'
					title={page.pageIndex <= 1 ? '' : 'Previous Page'}
					className={page.pageIndex <= 1 ? styles.paginationActionDisabled : ''}
					onClick={() => props.goToPage(page.pageIndex - 1, page.pageSize)}>
					<span>
						<LeftArrowIcon fillColor={page.pageIndex <= 1 ? '#C4C6C8' : '#0973BA'} />
					</span>
				</li>
				<li
					title={page.pageIndex.toString()}
					className={styles.currentPageNumber}>
					{page.pageIndex.toString()}
				</li>
				<li
					data-test-auto='f1d48284-262c-43ab-ae1e-a7faf16db0d4'
					title={page.pageIndex >= totalPages ? '' : 'Next Page'}
					className={page.pageIndex >= totalPages ? styles.paginationActionDisabled : ''}
					onClick={() => props.goToPage(page.pageIndex + 1, page.pageSize)}>
					<span>
						<RightArrowIcon fillColor={page.pageIndex >= totalPages ? '#C4C6C8' : '#0973BA'} />
					</span>
				</li>
				<li
					data-test-auto='185cbb70-25a2-4c20-b0d6-8135a5ce2382'
					title={page.pageIndex >= totalPages ? '' : 'Last Page'}
					className={page.pageIndex >= totalPages ? styles.paginationActionDisabled : ''}
					onClick={() => props.goToPage(totalPages, page.pageSize)}>
					<span>
						<RightDoubleArrowIcon fillColor={page.pageIndex >= totalPages ? '#C4C6C8' : '#0973BA'} />
					</span>
				</li>
			</ul>
		</section>
	);
};

export default CompletedTasksPagination;
