import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from 'src/helper/constants';
import {
	ADD_MERGE_EMAIL_ENUM,
	IAddMergeEmailResponse,
	ICancelMergeResponse,
	IDeleteEmailResponse,
	IMergeEmail,
	IMergeEmailListResponse,
	IValidateOTPResponse,
} from './merge-emails-types';
import { apiCatchHandler } from 'src/helper/utils';
import { OTPValidationStatus } from '../common/common-types';

export const getMergeEmailList = (callback: (mergeEmailListResponse: IMergeEmailListResponse) => void) => {
	const mergeEmailListResponse: IMergeEmailListResponse = {
		mergeEmailList: [],
		error: false,
	};
	axios
		.get(`${API_BASE_URL}/api/merge`)
		.then((response: AxiosResponse<IMergeEmail[]>) => {
			const { data } = response;
			mergeEmailListResponse.mergeEmailList = data;
			callback(mergeEmailListResponse);
		})
		.catch((error: any) => {
			mergeEmailListResponse.error = true;
			apiCatchHandler(error, mergeEmailListResponse, callback);
		});
};

export const addMergeEmail = (emailId: string, callback: (addMergeEmailResponse: IAddMergeEmailResponse) => void) => {
	const addMergeEmailResponse: IAddMergeEmailResponse = {
		mergeEmailDetail: ADD_MERGE_EMAIL_ENUM.Success,
		error: false,
	};
	axios
		.post(`${API_BASE_URL}/api/merge/validate-email?emailId=${emailId}`)
		.then((response: AxiosResponse<ADD_MERGE_EMAIL_ENUM>) => {
			const { data } = response;
			addMergeEmailResponse.mergeEmailDetail = data;
			callback(addMergeEmailResponse);
		})
		.catch((error) => {
			addMergeEmailResponse.error = true;
			apiCatchHandler(error, addMergeEmailResponse, callback);
		});
};

export const validateOTP = (
	otp: string,
	emailId: string,
	callback: (validateOTPResponse: IValidateOTPResponse) => void,
) => {
	const validateOTPResponse: IValidateOTPResponse = {
		otpDetail: OTPValidationStatus.InvalidOTP,
		error: false,
	};
	axios
		.post(`${API_BASE_URL}/api/merge/validate-otp?otp=${otp}&emailId=${emailId}`)
		.then((response: AxiosResponse<OTPValidationStatus>) => {
			const { data } = response;
			validateOTPResponse.otpDetail = data;
			callback(validateOTPResponse);
		})
		.catch((error) => {
			validateOTPResponse.error = true;
			apiCatchHandler(error, validateOTPResponse, callback);
		});
};

export const cancelMerge = (emailId: string, callback: (cancelMergeResponse: ICancelMergeResponse) => void) => {
	const cancelMergeResponse: ICancelMergeResponse = {
		isSuccess: false,
		error: false,
	};
	axios
		.post(`${API_BASE_URL}/api/merge/cancel-merge?emailId=${emailId}`)
		.then((response: AxiosResponse<boolean>) => {
			const { data } = response;
			cancelMergeResponse.isSuccess = data;
			callback(cancelMergeResponse);
		})
		.catch((error) => {
			cancelMergeResponse.error = true;
			apiCatchHandler(error, cancelMergeResponse, callback);
		});
};

export const deleteEmail = (emailId: string, callback: (deleteEmailResponse: IDeleteEmailResponse) => void) => {
	const deleteEmailResponse: IDeleteEmailResponse = {
		isSuccess: false,
		error: false,
	};
	axios
		.delete(`${API_BASE_URL}/api/merge?emailId=${emailId}`)
		.then((response: AxiosResponse<boolean>) => {
			const { data } = response;
			deleteEmailResponse.isSuccess = data;
			callback(deleteEmailResponse);
		})
		.catch((error) => {
			deleteEmailResponse.error = true;
			apiCatchHandler(error, deleteEmailResponse, callback);
		});
};
