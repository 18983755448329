import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL, UPDATE_EMAIL_CONSTANTS } from 'src/helper/constants';
import { apiCatchHandler } from 'src/helper/utils';
import { IGenerateOTPResponse } from '../register/register-component/register-component-types';
import { OTPValidationStatus } from '../common/common-types';

export const verifyAndUpdateEmailID = (
	emailId: string,
	otp: string,
	successCallback: (status: OTPValidationStatus) => void,
	errorCallback: (message: string) => void,
) => {
	axios
		.post(`${API_BASE_URL}/api/account/verify-and-update-email?otp=${otp}`, JSON.stringify(emailId), {
			headers: { 'Content-Type': 'application/json-patch+json' },
		})
		.then((response: AxiosResponse<OTPValidationStatus>) => {
			const { data } = response;
			successCallback(data);
		})
		.catch((error) => {
			apiCatchHandler(error, UPDATE_EMAIL_CONSTANTS.ERROR_VALIDATE_ACCESS_CODE, errorCallback);
		});
};

export function generateOTP(
	companyId: number,
	emailID: string,
	callback: (generateOTPResponse: IGenerateOTPResponse) => void,
) {
	const generateOTPResponse: IGenerateOTPResponse = {
		error: false,
	};
	axios
		.get(`${API_BASE_URL}/api/otp/generate/${companyId}?email=${emailID}&registrationId=`, {
			withCredentials: true,
		})
		.then(() => {
			generateOTPResponse.error = false;
			callback(generateOTPResponse);
		})
		.catch((error) => {
			generateOTPResponse.error = true;
			apiCatchHandler(error, generateOTPResponse, callback);
		});
}
