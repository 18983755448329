import React from 'react';
import PhoneInput from '@sssuite-component-ui/react-phone-input-2';
import UTEButton from 'src/components/ute-button';
import { CountryData } from 'src/modules/cc-recipient/country-data';
import styles from './enter-recovery-information.module.scss';
import '@sssuite-component-ui/react-phone-input-2/lib/style.css';

type EnterRecoveryInformationProps = {
	recoveryType: string;
	setRecoveryType: (recoveryType: string) => void;
	recoveryEmail: string;
	setRecoveryEmail: (recoveryEmail: string) => void;
	existingEmail: string;
	setExistingEmail: (existingEmail: string) => void;
	recoveryCountryCode: string;
	setRecoveryCountryCode: (recoveryCountryCode: string) => void;
	recoveryMobileNumber: string;
	setRecoveryMobileNumber: (recoveryMobileNumber: string) => void;
	onMobileChange: (fullValue: string, countryObject: CountryData) => void;
	handleRecoveryInfoSubmit: () => void;
	actionInProgress: boolean;
};

const EnterRecoveryInformation = ({
	recoveryType,
	setRecoveryType,
	recoveryEmail,
	setRecoveryEmail,
	existingEmail,
	setExistingEmail,
	recoveryCountryCode,
	setRecoveryCountryCode,
	recoveryMobileNumber,
	setRecoveryMobileNumber,
	onMobileChange,
	handleRecoveryInfoSubmit,
	actionInProgress,
}: EnterRecoveryInformationProps) => {
	return (
		<>
			<div className='uteLoginHeader'>Reset your email ID</div>
			<div className='uteLoginSubHeader'>
				Enter your recovery email or phone number and
				<br />
				your existing email ID.
			</div>
			<div className={styles.radioBlock}>
				<input
					type='radio'
					name='selectRecoveryType'
					id='selectEmail'
					checked={recoveryType === 'Email'}
					onChange={() => {
						setRecoveryType('Email');
						setRecoveryCountryCode('+1');
						setRecoveryMobileNumber('');
					}}
				/>
				<label htmlFor='selectEmail'>Email Address</label>
			</div>
			<div className={styles.radioBlock}>
				<input
					type='radio'
					name='selectRecoveryType'
					id='selectPhone'
					checked={recoveryType === 'Phone'}
					onChange={() => {
						setRecoveryType('Phone');
						setRecoveryEmail('');
					}}
				/>
				<label htmlFor='selectPhone'>Phone Number</label>
			</div>
			{recoveryType === 'Email' ? (
				<>
					<div
						className='formInputHeader'
						style={{ paddingTop: '1rem' }}>
						Recovery Email
					</div>
					<input
						maxLength={320}
						type='text'
						name='recoveryEmail'
						id='recoveryEmail'
						className={styles.emailID}
						placeholder='Enter your recovery email'
						value={recoveryEmail}
						onChange={(e) => {
							setRecoveryEmail(e.target.value);
						}}
						data-test-auto='ea1038e6-b559-4c05-bf75-c9fd5314ed07'
					/>
				</>
			) : recoveryType === 'Phone' ? (
				<>
					<div
						className='formInputHeader'
						style={{ paddingTop: '1rem' }}>
						Phone Number
					</div>
					<div className={styles.countryCodeMobileNumberWrapper}>
						<PhoneInput
							containerClass={styles.phoneInput}
							country={'us'}
							autoFormat={true}
							placeholder=''
							value={`${recoveryCountryCode} ${recoveryMobileNumber}`}
							onChange={onMobileChange}
						/>
					</div>
				</>
			) : (
				<></>
			)}
			<div
				className='formInputHeader'
				style={{ paddingTop: '1rem' }}>
				Existing Email ID
			</div>
			<input
				maxLength={320}
				type='text'
				name='existingEmail'
				id='existingEmail'
				className={styles.emailID}
				placeholder='Enter your existing email ID'
				value={existingEmail}
				onChange={(e) => {
					setExistingEmail(e.target.value);
				}}
				data-test-auto='7e4ba767-8d9e-4c10-816a-ab1294dff739'
			/>

			<div className={styles.continueContainer}>
				<UTEButton
					text='Continue'
					className={styles.continue}
					onClick={handleRecoveryInfoSubmit}
					disabled={
						(!recoveryEmail.trim() && !recoveryMobileNumber.trim()) || !existingEmail.trim() || actionInProgress
					}
					dataTestAuto='26676d67-b5c2-42f6-ae00-fbb354fc84b1'
				/>
			</div>
		</>
	);
};

export default EnterRecoveryInformation;
