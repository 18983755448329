import UTEButton from 'src/components/ute-button';
import styles from './update-pin.module.scss';
import { useContext, useState } from 'react';
import PINUpdatePopup from './pop-up';
import UTEModalLayout from 'src/components/ute-modal-layout';
import { ClientHubEvents, IClientTracking } from '../common/common-types';
import { saveClientTrackingEvent } from '../common/common-api';
import { AuthContext } from '../../context/ute-auth-context/auth-provider';

const UpdatePIN = () => {
	const [showModal, setShowModal] = useState(false);
	const { userDetail, isClientView } = useContext(AuthContext);

	const handleUpdatePIN = () => {
		setShowModal(true);
		if (!isClientView) {
			let clientTracking: IClientTracking = {
				eventId: ClientHubEvents.ResetPINRequested,
				eventData: {
					emailId: userDetail.emailAddress,
					fileName: '',
					newEmailId: '',
					oldEmailId: '',
				},
				actedBy: userDetail.emailAddress,
				registrationId: userDetail.id,
			};
			saveClientTrackingEvent(clientTracking);
		}
	};

	return (
		<>
			{' '}
			<section
				id={styles.updatePINSection}
				className='pageModuleContainer'>
				<h2 className='pageModuleHeader'>Update PIN</h2>
				<div>
					<p className='pageModuleDescription'>Update your Client Portal login PIN</p>
				</div>
				<div>
					<UTEButton
						className={styles.btnDefault}
						dataTestAuto='121fbe00-abac-42cd-8be8-746f56747c81'
						text='Update'
						onClick={() => {
							handleUpdatePIN();
						}}
						disabled={false}
					/>
				</div>
			</section>
			{showModal && (
				<UTEModalLayout
					width='360'
					theme='lightblue'
					title='Update PIN'
					hide={() => {
						setShowModal(false);
					}}
					onClickOutside={() => {}}>
					<PINUpdatePopup
						setShowModal={setShowModal}
						onCancel={() => {
							setShowModal(false);
						}}
					/>
				</UTEModalLayout>
			)}
		</>
	);
};

export default UpdatePIN;
